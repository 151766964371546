import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow, IonText } from "@ionic/react"
import { pdfMake } from "../../lib/pdfMake" 
import { TableBehavior } from "../../components/ReportComponent/ReportTable"
import moment from "moment"
import { printOutline } from "ionicons/icons"

const RepBehavior=({table,detail, pdf}:any)=>{
     console.log("table ",table)
     console.log("detail ",detail)
     console.log("pdf ",pdf)
    let bodyTable= [
       [ 
        {
            ul: [  
                {   text: 'เบรกกะทันหัน' , alignment: "center" ,bold:true } ,
                { 
                    alignment: "center", 
                    layout: 'noBorders', 
                    columns: [
                        {
                            // % width
                            width: '50%',
                            text: 'วันที่'
                        },
                        {
                            // auto-sized columns have their widths based on their content
                            width: '50%',
                            text: 'สถานที่'
                        }, 
                    ]  
                },
            ], 
            type: 'none'
        },
        {
            ul: [  
                {   text: 'เร่งกระทันหัน' , alignment: "center",bold:true  } ,
                { 
                    alignment: "center", 
                    layout: 'noBorders', 
                    columns: [
                        {
                            // % width
                            width: '50%',
                            text: 'วันที่'
                        },
                        {
                            // auto-sized columns have their widths based on their content
                            width: '50%',
                            text: 'สถานที่'
                        }, 
                    ]  
                },
            ], 
            type: 'none'
        },
        {
            ul: [  
                {   text: 'เลี้ยวกระทันหัน' , alignment: "center",bold:true } ,
                { 
                    alignment: "center", 
                    layout: 'noBorders', 
                    columns: [
                        {
                            // % width
                            width: '50%',
                            text: 'วันที่'
                        },
                        {
                            // auto-sized columns have their widths based on their content
                            width: '50%',
                            text: 'สถานที่'
                        }, 
                    ]  
                },
            ], 
            type: 'none'
        },
        {
            ul: [  
                {   text: 'ความเร็วเกิน' , alignment: "center",bold:true  } ,
                { 
                    alignment: "center", 
                    layout: 'noBorders', 
                    columns: [
                        {
                            // % width
                            width: '50%',
                            text: 'วันที่'
                        },
                        {
                            // auto-sized columns have their widths based on their content
                            width: '50%',
                            text: 'สถานที่'
                        }, 
                    ]  
                },
            ], 
            type: 'none'
        },
        {
            ul: [  
                {   text: 'จอดติดเครื่องนาน' , alignment: "center" ,bold:true } ,
                { 
                    alignment: "center", 
                    layout: 'noBorders', 
                    columns: [
                        {
                            // % width
                            width: '50%',
                            text: 'วันที่'
                        },
                        {
                            // auto-sized columns have their widths based on their content
                            width: '50%',
                            text: 'สถานที่'
                        }, 
                    ]  
                },
            ], 
            type: 'none'
        },
       ],

       //rows
       [
        {
            layout: 'noBorders',
            table: {
                widths: ['50%', '50%'], 
                headerRows: 1,
                body:  pdf.hashbraking.length >0 ? pdf.hashbraking: [['-','-']] 
              } 
        },
        { 
            layout: 'noBorders',
            table: {
                widths: ['50%', '50%'], 
                headerRows: 1,
                body: pdf.hashacceration.length > 0 ?  pdf.hashacceration : [['-','-']]
              } 
        },
        {
            layout: 'noBorders',
            table: {
                widths: ['50%', '50%'], 
                headerRows: 1,
                body:  pdf.sharpturn.length > 0 ? pdf.sharpturn: [['-','-']]
              }   
        },
        {
            layout: 'noBorders',
            table: {
                widths: ['50%', '50%'], 
                headerRows: 1,
                body: pdf.speeding.length > 0 ? pdf.speeding: [['-','-']]
              } 
        }, 
        {
            layout: 'noBorders',
            table: {
                widths: ['50%', '50%'], 
                headerRows: 1,
                body: pdf.idleovertime.length > 0 ? pdf.idleovertime: [['-','-']]
              } 
        },
       ]
    ]
    const printPdf=()=>{
        pdfMake.createPdf({
            pageOrientation: 'landscape',
            content: [ 
                {text: detail?.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 5},
                {text:  `ทะเบียน  ${detail?.name}`, fontSize: 14 },
                {text: `วันที่ ${detail?.start} - ${detail?.end}`, fontSize: 14 ,   marginBottom: 15},
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 5 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths:['*',"*","*","*","*"],
                      body: bodyTable  
                    }
                  } 
            ],
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text: detail.reportName,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
            },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
            defaultStyle: {
                font: "THSarabunNew",
            }
        }).open()
    }

    return(
    <div>
         <IonButton  
            onClick={()=>{printPdf()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
        <IonGrid>
            <IonRow className="ion-justify-content-center" > 
                <IonCol size="10" >
                    <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >{detail?.reportName}</IonLabel><br/>
                    <IonLabel style={{fontSize:'.8em'}}>ทะเบียน {detail?.name} </IonLabel> <br/>
                    <IonLabel style={{fontSize:'.8em'}}>
                        <IonText>วันที่ {detail?.start}</IonText>
                        <IonText>-</IonText>
                        <IonText>วันที่ {detail?.end}</IonText>
                    </IonLabel>
                </IonCol>
                <IonCol size="10" >
                    <br/>
                    <TableBehavior rowsData={table.rows}/>
                </IonCol>
            </IonRow><br/>
        </IonGrid>

 
    </div>
    )
}

export default RepBehavior;