
import { IonCheckbox, IonCol, IonContent, IonIcon,IonGrid, IonItem, IonLabel, IonPopover, IonRow, IonList, IonButton, IonListHeader, IonToolbar, IonRadio, IonRadioGroup, useIonLoading, IonLoading, IonHeader, useIonToast, IonText, IonFooter, IonModal } from "@ionic/react";
import { chevronBackOutline,filterCircleOutline} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilters,  menuContentFilter,  menuFilter, setStorage, subMenuFilter } from "../actions";
import { getFilterJson, getlistTemplate, setFiltered ,setListTemplate, setLoading } from "../store/appSlice";
import "./css/FilterDevices.css"
import { getAddress, getCommand, getContentFilter, getEmei, getExpireDate, getIconFilter, getMessage, getName, getNavigation, getPhoneNumber, getQuickTrack, getRatePoint, getRealtimeTrack, getSensorBattery, getSensorCardName, getSensorCardNumber, getSensorFuel, getSensorGPS, getSensorGSM, getSensorMile, getSensorPTO, getSensorSpeed, getSensorTemperture, getShare, getStatusName, getUpdateTime, setAddress, setCommand, setContentFilter, setEmei, setExpireDate, setIconFilter, setMessage, setName, setNavigation, setPhoneNumber, setQuickTrack, setRatePoint, setRealtimeTrack, setSensorBattery, setSensorCardName, setSensorCardNumber, setSensorFuel, setSensorGPS, setSensorGSM, setSensorMile, setSensorPTO, setSensorSpeed, setSensorTemperature, setShare, setStatusName, setUpdateTime } from "../store/filterSlice";
import i18next, { t } from "i18next";
 
const filter = [
  {
    group_name: "General",
    member: [
      { type: "icon", card: true, detail: false },
      { type: "name", card: true, detail: false },
      { type: "emei", card: true, detail: false },
      { type: "status_name", card: true, detail: false },
      { type: "update_time", card: true, detail: false },
      { type: "address", card: true, detail: false },
      { type: "navigate", card: true, detail: false },
      { type: "expired_date", card: true, detail: false },
      { type: "phone_number", card: true, detail: false },
    ],
  },
  {
    group_name: "Sensors",
    member: [
      { type: "sensor_fuel", card: true, detail: false },
      { type: "sensor_speed", card: true, detail: false },
      { type: "sensor_battery", card: true, detail: false },
      { type: "sensor_GSM", card: true, detail: false },
      { type: "sensor_GPS", card: true, detail: false },
      { type: "sensor_PTO", card: true, detail: false },
      { type: "sensor_mile", card: true, detail: false },
      { type: "sensor_temperature", card: true, detail: false },
      { type: "driver_card_name", card: true, detail: false },
      { type: "driver_card_number", card: true, detail: false },
    ],
  },
  {
    group_name: "Options",
    member: [
      { type: "quick_track", card: true, detail: false },
      { type: "share", card: true, detail: false },
      { type: "messages", card: true, detail: false },
      { type: "command", card: true, detail: false },
    ],
  },
]

export const FilterDevices:React.FC  = () => { 
  const filterJson = useSelector(getFilterJson)
  const [open,setOpen] = useState(false)
  const dispatch = useDispatch()
  const contentFilter = useSelector(getContentFilter)
  const [showLoading, setShowLoading] =  useState(false);

  useEffect(()=>{
    dispatch(setFiltered(filter))
  },[])

  const setDeviceTheme=(e:any)=>{
    console.log('DeviceTheme ',e) 
    setStorage('content',e)
    setShowLoading(true)
    dispatch(setContentFilter(e)) 
    setFilterDeviceTheme(e)
    setShowLoading(false)
  }
 

  const setupSubContent=(menufilter:any)=>{ 
      let icon = menufilter.find((e:any)=> e.type ===10 || e.type === 110)
      let name = menufilter.find((e:any)=> e.type ===11 || e.type === 111)
      let imei = menufilter.find((e:any)=> e.type ===12 || e.type === 112)
      let address = menufilter.find((e:any)=> e.type ===13 || e.type === 113)
      let stsName = menufilter.find((e:any)=> e.type ===14 || e.type === 114)
      let updateTime = menufilter.find((e:any)=> e.type ===15 || e.type === 115)
      let nav = menufilter.find((e:any)=> e.type ===16 || e.type === 116)
      let expire = menufilter.find((e:any)=> e.type ===17 || e.type === 117)
      let phone = menufilter.find((e:any)=> e.type ===18 || e.type === 118)
      let fuel = menufilter.find((e:any)=> e.type ===19 || e.type === 119)
      let speed = menufilter.find((e:any)=> e.type ===20 || e.type === 120)
      let battery = menufilter.find((e:any)=> e.type ===21 || e.type === 121)
      let gsm = menufilter.find((e:any)=> e.type ===22 || e.type === 122)
      let gps = menufilter.find((e:any)=> e.type ===23 || e.type === 123)
      let mile = menufilter.find((e:any)=> e.type ===24 || e.type === 124)
      let temp = menufilter.find((e:any)=> e.type ===25 || e.type === 125)
      let driverMess = menufilter.find((e:any)=> e.type ===26 || e.type === 126)
      let driverId = menufilter.find((e:any)=> e.type ===27 || e.type === 127)
      let quickTrack = menufilter.find((e:any)=> e.type ===28 || e.type === 128)
      let share = menufilter.find((e:any)=> e.type ===29 || e.type === 129)
      let message = menufilter.find((e:any)=> e.type ===30 || e.type === 130)
      let trackRealtime = menufilter.find((e:any)=> e.type ===31 || e.type === 131)
      let pto = menufilter.find((e:any)=> e.type ===32 || e.type === 132)
      let cmd = menufilter.find((e:any)=> e.type ===33 || e.type === 133)
      
      console.log("icon ",icon)
      dispatch(setIconFilter({card: icon.card ,list: icon.list ,type:icon.type }))
      dispatch(setName({card: name.card,list: name.list ,type: name.type })) 
      dispatch(setEmei({card: imei.card,list: imei.list ,type: imei.type })) 
      dispatch(setStatusName({card: stsName.card,list:stsName.list ,type: stsName.type })) 
      dispatch(setUpdateTime({card:updateTime.card,list:updateTime.list ,type: updateTime.type})) 
      dispatch(setAddress({card:address.card,list:address.list ,type: address.type})) 
      dispatch(setNavigation({card:nav.card,list:nav.list  ,type: nav.type})) 
      dispatch(setExpireDate({card:expire.card,list:expire.list ,type: expire.type})) 
      dispatch(setPhoneNumber({card:phone.card,list:phone.list ,type: phone.type})) 
      // dispatch(setRatePoint({card:e.card,list:updateTime.list})) 
    
      dispatch(setSensorFuel({card: fuel.card,list: fuel.list ,type: fuel.type}))   
      dispatch(setSensorSpeed({card: speed.card,list: speed.list ,type: speed.type}))  
      dispatch(setSensorBattery({card: battery.card,list: battery.list ,type: battery.type}))  
      dispatch(setSensorGSM({card: gsm.card,list: gsm.list ,type: gsm.type }))    
      dispatch(setSensorGPS({card: gps.card,list: gps.list ,type: gps.type }))    
      dispatch(setSensorPTO({card: pto.card,list: pto.list ,type: pto.type }))    
      dispatch(setSensorMile({card: mile.card,list: mile.list ,type: mile.type  }))  
      dispatch(setSensorTemperature({card: temp.card,list: temp.list ,type: temp.type }))    
      dispatch(setSensorCardName({card: driverMess.card,list: driverMess.list ,type: driverMess.type }))  
      dispatch(setSensorCardNumber({card: driverId.card,list: driverId.list   ,type: driverId.type}))   
    
      dispatch(setQuickTrack({card: quickTrack.card,list: quickTrack.list  ,type: quickTrack.type }))   
      dispatch(setShare({card: share.card,list: share.list  ,type: share.type }))   
      dispatch(setMessage({card: message.card,list: message.list   ,type: message.type }))   
      dispatch(setCommand({card: cmd.card,list: cmd.list   ,type: cmd.type  }))   
      dispatch(setRealtimeTrack({card: trackRealtime.card,list:trackRealtime.list  ,type: trackRealtime.type })) 
    }

    const setFilterDeviceTheme=async(e:any)=>{
      let body = { 
        "filterContent": e ,
        "currentContent": e === "card" ? "list" : "card" 
       }
       dispatch(setLoading(true))
      let menufilter:[] = await menuContentFilter(body) 
      menufilter.sort((a:any, b:any) =>  a.type - b.type) 
      await setupSubContent(menufilter)
      dispatch(setLoading(false))
    }

    return (  
        <div className="set-center" style={{width:'2rem',height:'2rem'}} > 
         <IonIcon 
            icon={filterCircleOutline} 
            id="open-popover" 
            color={open?'primary':'medium' }
            style={{fontSize:'1.5rem',cursor:'pointer'}} 
            onClick={()=>{setOpen(!open)}} 
          /> 
          <IonModal isOpen={open} mode='ios' color='light' className=" ion-hide-md-up" initialBreakpoint={0.98} >
            <IonToolbar>
              <IonRow>
                <IonCol size="12"  >
                <IonLabel onClick={()=>{setOpen(false)}} color={"secondary"} className="set-center" style={{flexDirection:"row",justifyContent:"flex-start",cursor:'pointer'}}>  
                      <IonIcon icon={chevronBackOutline} color="secondary" /> <IonText>{t('close')}</IonText>
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonToolbar> 
            <IonContent className="ion-padding" >
             <div className="filter-content"  >
                <IonRow  style={{borderBottom:'1px solid #EEE',paddingBottom:'.5rem'}}>
                  <IonCol size="12" >
                     <IonLabel className="title">{t('filterTitle')}</IonLabel>
                  </IonCol>
                  <IonCol size="12"  >  
                      <IonRadioGroup 
                        value={contentFilter} 
                        onIonChange={(e)=>setDeviceTheme(e.detail.value) } 
                        style={{flexDirection:"row",justifyContent:"flex-start"}}
                        className="set-center"
                      >
                        <IonRadio mode="ios" value={'card'}  ></IonRadio><IonLabel >&nbsp; Card Content</IonLabel>  &nbsp;&nbsp;&nbsp;
                        <IonRadio mode="ios" value={'list'}  ></IonRadio><IonLabel >&nbsp; List Content</IonLabel> 
                      </IonRadioGroup> 
                  </IonCol>  
                </IonRow>

                <FiltersContent setupSubContent={(e:any)=>{setupSubContent(e)}}/>
              </div> 
            </IonContent> 
          </IonModal>

           <IonPopover  
            isOpen={open} mode='ios' color='light' 
            className="filter ion-hide-md-down ion-padding"  
            backdropDismiss={false} keepContentsMounted={true} 
            alignment='start'  size="auto" side="right" trigger="open-popover"
           >
             <IonHeader className="ion-no-border">
             <IonToolbar>
              <IonRow>
                <IonCol size="12"  >
                <IonLabel onClick={()=>{setOpen(false)}} color={"secondary"} className="set-center" style={{flexDirection:"row",justifyContent:"flex-start",cursor:'pointer'}}>  
                      <IonIcon icon={chevronBackOutline} color="secondary" /> <IonText>{t('close')}</IonText>
                  </IonLabel>
                </IonCol>
              </IonRow>
             </IonToolbar> 
             </IonHeader>
            <IonContent color='light'   className="ion-padding" > 
             <div className="filter-content"  >
                <IonRow  style={{borderBottom:'1px solid #EEE',paddingBottom:'.5rem'}}>
                  <IonCol size="12" >
                     <IonLabel className="title">{t('filterTitle')}</IonLabel>
                  </IonCol>
                  <IonCol size="12"  >  
                      <IonRadioGroup 
                        value={contentFilter} 
                        onIonChange={(e)=>setDeviceTheme(e.detail.value) } 
                        style={{flexDirection:"row",justifyContent:"flex-start"}}
                        className="set-center"
                      >
                        <IonRadio mode="ios" value={'card'}  ></IonRadio><IonLabel >&nbsp; Card Content</IonLabel>  &nbsp;&nbsp;&nbsp;
                        <IonRadio mode="ios" value={'list'}  ></IonRadio><IonLabel >&nbsp; List Content</IonLabel> 
                      </IonRadioGroup> 
                  </IonCol>  
                </IonRow>

                <FiltersContent setupSubContent={(e:any)=>{setupSubContent(e)}}/>
              </div>
            </IonContent> 
           </IonPopover>
 
        </div>
      ); 
    }
     
export default FilterDevices
 

const FiltersContent = ({setupSubContent}:any) => {
  const dispatch = useDispatch() 
  const template = useSelector(getContentFilter)
  const [toast] = useIonToast()
   
  const [generral,setGenerral] = useState({name:'',translate:{th:"",en:""},choices:[]})
  const [sensors,setSensor] = useState({name:'',translate:{th:"",en:""},choices:[]})
  const [option,setOption] = useState({name:'',translate:{th:"",en:""},choices:[]}) 
  const iconFilter = useSelector(getIconFilter)
  const name = useSelector(getName)
  const imei = useSelector(getEmei)
  const statusName = useSelector(getStatusName)
  const updateTime = useSelector(getUpdateTime)
  const address = useSelector(getAddress)
  const navigationFilter = useSelector(getNavigation)
  const expiredDate = useSelector(getExpireDate)
  const phoneNumber = useSelector(getPhoneNumber)
  const ratePoint = useSelector(getRatePoint)

  const sensorFuel = useSelector(getSensorFuel)  
  const sensorSpeed = useSelector(getSensorSpeed) 
  const sensorBattery = useSelector(getSensorBattery) 
  const sensorGSM = useSelector(getSensorGSM )  
  const sensorGPS = useSelector(getSensorGPS )  
  const sensorPTO = useSelector(getSensorPTO )  
  const sensorMile = useSelector(getSensorMile) 
  const sensorTemp = useSelector(getSensorTemperture )  
  const sensorCarsName = useSelector(getSensorCardName) 
  const sensorCarsNumber = useSelector(getSensorCardNumber)  

  const quicktrack =  useSelector(getQuickTrack  )
  const share =  useSelector(getShare  )
  const messsage =   useSelector(getMessage)  
  const cmd =  useSelector(getCommand ) 
  const realtimeTrack = useSelector(getRealtimeTrack)
 
  const getFilter=async()=>{
    const res:any = await  getFilters({})
   
    setGenerral(res?.general)
    setSensor(res?.sensors)
    setOption(res?.options)   
  }

  useEffect(()=>{
    console.log("iconFilter ",iconFilter)
    getFilter() 
  },[ ])
 

  const save=async ()=>{
    // dispatch(setFiltered(filter)) 
      //save card
      dispatch(setLoading(true))
      console.log("iconFilter ",iconFilter)
      console.log("name ",name)
      let body = []
      body.push({menuId: parseInt(iconFilter.type)  ,checked:   template === "card" ?  iconFilter.card :  iconFilter.list} )
      body.push({menuId: parseInt(name.type )       ,checked:   template === "card"?  name.card : name.list})
      body.push({menuId: parseInt(imei.type)        ,checked:   template === "card" ?  imei.card :imei.list})
      body.push({menuId: parseInt(statusName.type)  ,checked:   template === "card" ?  statusName.card :statusName.list})
      body.push({menuId: parseInt(updateTime.type)  ,checked:   template === "card" ?  updateTime.card :updateTime.list})
      body.push({menuId: parseInt(address.type)     ,checked:   template === "card" ?  address.card :address.list})
      body.push({menuId: parseInt(navigationFilter.type) ,checked:   template === "card" ?  navigationFilter.card :navigationFilter.list})
      body.push({menuId: parseInt(expiredDate.type) ,checked:   template === "card"?  expiredDate.card :expiredDate.list})
      body.push({menuId: parseInt(phoneNumber.type) ,checked:   template === "card"?  phoneNumber.card :phoneNumber.list})
      // body.push({menuId: parseInt(ratePoint.type)   ,checked:    ratePoint.card ?  ratePoint.card :ratePoint.list})
      body.push({menuId: parseInt(sensorFuel.type)  ,checked:   template === "card" ?  sensorFuel.card :sensorFuel.list})
      body.push({menuId: parseInt(sensorSpeed.type) ,checked:   template === "card" ?  sensorSpeed.card :sensorSpeed.list})
      body.push({menuId: parseInt(sensorBattery.type) ,checked:  template === "card" ?  sensorBattery.card :sensorBattery.list})
      body.push({menuId: parseInt(sensorGSM.type)   ,checked:   template === "card" ?  sensorGSM.card :sensorGSM.list})
      body.push({menuId: parseInt(sensorGPS.type)   ,checked:   template === "card" ?  sensorGPS.card :sensorGPS.list})
      body.push({menuId: parseInt(sensorPTO.type)   ,checked:   template === "card"?  sensorPTO.card :sensorPTO.list})
      body.push({menuId: parseInt(sensorMile.type)  ,checked:   template === "card" ?  sensorMile.card :sensorMile.list})
      body.push({menuId: parseInt(sensorTemp.type)  ,checked:   template === "card"?  sensorTemp.card :sensorTemp.list})
      body.push({menuId: parseInt(sensorCarsName.type) ,checked:   template === "card" ?  sensorCarsName.card :sensorCarsName.list})
      body.push({menuId: parseInt(sensorCarsNumber.type) ,checked:   template === "card" ?  sensorCarsNumber.card :sensorCarsNumber.list})
      body.push({menuId: parseInt(quicktrack.type)  ,checked:   template === "card"?  quicktrack.card :quicktrack.list})
      body.push({menuId: parseInt(share.type)       ,checked:   template === "card"?  share.card :share.list})
      body.push({menuId: parseInt(messsage.type)    ,checked:   template === "card" ?  messsage.card :messsage.list})
      body.push({menuId: parseInt(cmd.type)         ,checked:   template === "card"?  cmd.card :cmd.list})
      body.push({menuId: parseInt(realtimeTrack.type) ,checked: template === "card"?  realtimeTrack.card :realtimeTrack.list}) 
       
      console.log("body ",body  )
      const menufilter = await subMenuFilter(body)
      menufilter.sort((a:any, b:any) =>  a.type - b.type) 
      console.log("res ",menufilter)
      if(menufilter !==false){ 
        toast({
          message:"Saved your filter configuration.",
          mode:'ios',
          position:'top',
          color:'success'
        })
        dispatch(setLoading(false))
        return setupSubContent(menufilter)
      }
      dispatch(setLoading(false))
  }

  return(
    <div>  
      <IonGrid > 
        <IonList >
            <IonListHeader>
              <IonLabel> 
                {i18next.language=="th"?  generral?.translate.th : generral?.translate.en }
              </IonLabel> 
            </IonListHeader>  
              <IonItem lines="none" color='light' className="display-types " >
                <IonCheckbox  aria-label=""  slot="start" checked={iconFilter.card} 
                  onIonChange={(e)=>{dispatch(setIconFilter({card:e.target.checked,list:iconFilter.list ,type: '10'  }))}}  >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start" disabled  aria-label=""   checked={iconFilter.list}  
                 onIonChange={(e)=>{dispatch(setIconFilter({card:iconFilter.card  ,list:e.target.checked ,type: '110'}))}}  
                 ></IonCheckbox>
                <IonLabel >{t('filter_icon')}</IonLabel> 
              </IonItem>   
              
              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={name.card} 
                  onIonChange={(e)=>{console.log("e.target.checked ",e.target.checked) ;dispatch(setName({card:e.target.checked,list:name.list,type: '11'}))}}  >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={name.list}  
                 onIonChange={(e)=>{ dispatch(setName({card:name.card  ,list:e.target.checked!,type: '111'})) ;console.log("name ",name) }}  
                 ></IonCheckbox>
                <IonLabel >{t('filter_name')}</IonLabel>
              </IonItem> 

              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label="" disabled slot="start" checked={imei.card} 
                  onIonChange={(e)=>{dispatch(setEmei({card:e.target.checked,list:imei.list ,type: '12'}))}} 
                 >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start" disabled aria-label=""   checked={imei.list}  
                 onIonChange={(e)=>{dispatch(setEmei({card:imei.card,list:  e.target.checked ,type: '112'}))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_imei')}</IonLabel>
              </IonItem>   
              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={address.card}
                  onIonChange={(e)=>{dispatch(setAddress({card:e.target.checked,list:address.list ,type: '13'}))}} 
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"   aria-label=""   checked={address.list}  
                 onIonChange={(e)=>{dispatch(setAddress({card: address.card,list:  e.target.checked ,type: '113'}))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_address')}</IonLabel>
              </IonItem>  
              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={statusName.card}  
                 onIonChange={(e)=>{dispatch(setStatusName({card:e.target.checked,list:statusName.list ,type: '14'}))}} 
                >   <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={statusName.list} 
                 onIonChange={(e)=>{dispatch(setStatusName({card: statusName.card ,list: e.detail.checked ,type: '114'}))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_statusName')}</IonLabel>
              </IonItem>  

              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={updateTime.card} 
                  onIonChange={(e)=>{dispatch(setUpdateTime({card:e.target.checked,list:updateTime.list ,type: '15'}))}} 
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={updateTime.list} 
                  onIonChange={(e)=>{dispatch(setUpdateTime({card: updateTime.card ,list: e.detail.checked ,type: '115' }))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_updateTime')}</IonLabel>
              </IonItem> 

              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={navigationFilter.card} 
                   onIonChange={(e)=>{dispatch(setNavigation({card:e.target.checked,list:navigationFilter.list ,type: '16'}))}} 
                >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={navigationFilter.list} 
                  onIonChange={(e)=>{dispatch(setNavigation({card: navigationFilter.card ,list: e.detail.checked  ,type: '116'}))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_navigateToDevice')}</IonLabel>
              </IonItem>  

              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={expiredDate.card}  
                   onIonChange={(e)=>{dispatch(setExpireDate({card:e.target.checked,list:expiredDate.list ,type: '17'}))}} 
                >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start" disabled  aria-label=""   checked={expiredDate.list}  
                  onIonChange={(e)=>{dispatch(setExpireDate({card: expiredDate.card ,list: e.detail.checked ,type: '117'}))}} 
                ></IonCheckbox>
                <IonLabel >{t('filter_expireDate')}</IonLabel>
              </IonItem>   

              <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={phoneNumber.card}   
                   onIonChange={(e)=>{dispatch(setPhoneNumber({card:e.target.checked,list:phoneNumber.list ,type: '18' }))}} >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start" disabled aria-label=""   checked={phoneNumber.list}  
                  onIonChange={(e)=>{dispatch(setPhoneNumber({card: phoneNumber.card ,list: e.detail.checked ,type: '118'}))}}
                   ></IonCheckbox>
                <IonLabel >{t('filter_phoneNumber')}</IonLabel>
              </IonItem>  

              {/* <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={ratePoint.card}   
                   onIonChange={(e)=>{dispatch(setRatePoint({card:e.target.checked,list:ratePoint.list ,type: '16'}))}} >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={ratePoint.list}  
                  onIonChange={(e)=>{dispatch(setRatePoint({card: ratePoint.card ,list: e.detail.checked }))}}
                   ></IonCheckbox>
                <IonLabel > Rate Pont </IonLabel>
              </IonItem>    */}

        </IonList> 
        <IonList >
          <IonListHeader>
            <IonLabel>   {i18next.language=="th"?  sensors?.translate.th : sensors?.translate.en } </IonLabel> 
          </IonListHeader> 
          
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorFuel.card} 
                   onIonChange={(e)=>{dispatch(setSensorFuel({card:e.target.checked,list:sensorFuel.list  ,type: '19' }))}} 
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorFuel.list}  
                  onIonChange={(e)=>{dispatch(setSensorFuel({card: sensorFuel.card ,list: e.detail.checked ,type: '119'}))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorFuel')} </IonLabel>
            </IonItem>   

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorSpeed.card}   
                   onIonChange={(e)=>{dispatch(setSensorSpeed({card:e.target.checked,list: sensorSpeed.list  ,type: '20'}))}} 
                >   <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorSpeed.list}   
                  onIonChange={(e)=>{dispatch(setSensorSpeed({card:  sensorSpeed.card ,list: e.detail.checked  ,type: '120' }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorSpeed')} </IonLabel>
            </IonItem>   

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorBattery.card}  
                 onIonChange={(e)=>{dispatch(setSensorBattery({card:e.target.checked,list: sensorBattery.list  ,type: '21'}))}} 
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorBattery.list}  
                   onIonChange={(e)=>{dispatch(setSensorBattery({card:  sensorBattery.card ,list: e.detail.checked ,type: '21' }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorBattery')}</IonLabel>
            </IonItem>    

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorGSM.card}  
                  onIonChange={(e)=>{dispatch(setSensorGSM({card:e.target.checked,list:sensorGSM.list  ,type: '22'}))}} 
                > <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorGSM.list}  
                 onIonChange={(e)=>{dispatch(setSensorGSM({card: sensorGSM.card ,list: e.detail.checked ,type: '122' }))}} 
                ></IonCheckbox>
                <IonLabel >  {t('filter_sensorGSM')} </IonLabel>
            </IonItem> 

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorGPS.card} 
                  onIonChange={(e)=>{dispatch(setSensorGPS({card:e.target.checked,list:sensorGPS.list  ,type: '23'}))}} 
                 >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorGPS.list}
                   onIonChange={(e)=>{dispatch(setSensorGPS({card: sensorGPS.card ,list: e.detail.checked ,type: '123' }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorGPS')} </IonLabel>
            </IonItem> 

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorPTO.card} 
                 onIonChange={(e)=>{dispatch(setSensorPTO({card:e.target.checked,list:sensorPTO.list ,type: '32' }))}} 
                >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorPTO.list}  
                   onIonChange={(e)=>{dispatch(setSensorPTO({card: sensorPTO.card ,list: e.detail.checked ,type: '132' }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorPTO')} </IonLabel>
            </IonItem> 

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorMile.card} 
                 onIonChange={(e)=>{dispatch(setSensorMile({card:e.target.checked,list: sensorMile.list ,type: '24'}))}}  
                > <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start" disabled aria-label=""   checked={sensorMile.list}  
                   onIonChange={(e)=>{dispatch(setSensorMile({card:  sensorMile.card ,list: e.detail.checked ,type: '124'}))}} 
                 ></IonCheckbox>
                <IonLabel > {t('filter_sensorMile')} </IonLabel>
            </IonItem> 

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorTemp.card}   
                  onIonChange={(e)=>{dispatch(setSensorTemperature({card:e.target.checked,list: sensorTemp.list ,type: '25' }))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorTemp.list}  
                 onIonChange={(e)=>{dispatch(setSensorTemperature({card:  sensorTemp.card ,list: e.detail.checked ,type: '125'}))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorTemp')} </IonLabel>
            </IonItem> 
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorCarsName.card} 
                  onIonChange={(e)=>{dispatch(setSensorCardName({card:e.target.checked,list: sensorCarsName.list ,type: '26'}))}}  
                >
                  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorCarsName.list}  
                   onIonChange={(e)=>{dispatch(setSensorCardName({card:  sensorCarsName.card ,list: e.detail.checked ,type: '126' }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorDriverName')} </IonLabel>
            </IonItem> 
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={sensorCarsNumber.card}  
                  onIonChange={(e)=>{dispatch(setSensorCardNumber({card:e.target.checked,list: sensorCarsNumber.list ,type: '27' }))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={sensorCarsNumber.list}   
                  onIonChange={(e)=>{dispatch(setSensorCardNumber({card:  sensorCarsNumber.card ,list: e.detail.checked ,type: '127'}))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_sensorDriverID')} </IonLabel>
            </IonItem>  
  
          </IonList> 
          <IonList  >
            <IonListHeader>
              <IonLabel> {i18next.language=="th"?  option?.translate.th : option?.translate.en } </IonLabel>  
            </IonListHeader> 
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={quicktrack.card} 
                   onIonChange={(e)=>{dispatch(setQuickTrack({card:e.target.checked,list: quicktrack.list  ,type: '28' }))}}  
                 >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={quicktrack.list}  
                  onIonChange={(e)=>{dispatch(setQuickTrack({card:  quicktrack.card ,list: e.detail.checked ,type: '128' }))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_quickTrack')} </IonLabel>
            </IonItem> 

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={share.card}  
                  onIonChange={(e)=>{dispatch(setShare({card:e.target.checked,list: share.list ,type: '29' }))}}   
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={share.list} 
                  onIonChange={(e)=>{dispatch(setShare({card:  share.card ,list: e.detail.checked ,type: '129'}))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_share')} </IonLabel>
            </IonItem>     

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={messsage.card}   
                   onIonChange={(e)=>{dispatch(setMessage({card:e.target.checked,list: messsage.list ,type: '30'}))}}  
                > <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={messsage.list}  
                    onIonChange={(e)=>{dispatch(setMessage({card:  messsage.card ,list: e.detail.checked ,type: '130'}))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_message')} </IonLabel>
            </IonItem>    

            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={cmd.card}  
                  onIonChange={(e)=>{dispatch(setCommand({card:e.target.checked,list: cmd.list ,type: '33'}))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={cmd.list}  
                  onIonChange={(e)=>{dispatch(setCommand({card:  cmd.card ,list: e.detail.checked ,type: '133'}))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_command')}  </IonLabel>
            </IonItem>  
 
            <IonItem lines="none" color='light' className="display-types" >
                <IonCheckbox  aria-label=""  slot="start" checked={realtimeTrack.card}  
                  onIonChange={(e)=>{dispatch(setRealtimeTrack({card:e.target.checked,list: realtimeTrack.list ,type: '31'}))}}  
                >  <IonLabel> </IonLabel>
                </IonCheckbox>
                <IonCheckbox slot="start"  aria-label=""   checked={realtimeTrack.list}  
                  onIonChange={(e)=>{dispatch(setRealtimeTrack({card:  realtimeTrack.card ,list: e.detail.checked ,type: '131'}))}} 
                ></IonCheckbox>
                <IonLabel > {t('filter_realtimeTrack')}  </IonLabel>
            </IonItem>

        </IonList> 
      </IonGrid> 
      <IonFooter mode="ios" className="ion-padding">
        <IonButton  size="default" onClick={()=>save()}>{t('save')}</IonButton>
      </IonFooter>
     </div>
    )
} 