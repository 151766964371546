import { IonButton, IonCol, IonContent, IonIcon, IonLabel, IonRow, IonText, IonTitle } from "@ionic/react"
import { printOutline } from "ionicons/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { configPDF, pdfMake } from "../../../lib/pdfMake" 

const RepCircleDaily=({details,chart}:any)=>{
    const [chartImg,setChartImg] = useState("")
    let options = {
        series: [],
        chart: {  width: 500,  type: 'pie', },
        xaxis: { categories:[],    },
        labels: [] , 
        colors: [ '#ffce00','#10dc60', '#f04141','#666666'],
        plotOptions: { }, 
        dataLabels: {
          formatter(val:any, opts:any) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(1) + '%']
          }
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center', 
          floating: false,
        }
      }
      

      useEffect(()=>{
        const drawChart=async()=>{
            var element= document.querySelector("#circle-chart") 
            if(!element?.innerHTML){ 
                console.log("chart ",chart)
                options.series = chart.data
                options.colors = chart.color
                options.labels= chart.labels
                options.xaxis.categories = chart.xaxis 
                var chrt:any = new ApexCharts(element, options)
                chrt.render()
                setTimeout(async()=>{ 
                    let {imgURI}:any = await chrt?.dataURI()
                    setChartImg( imgURI) 
                },1000)  
                
            }
        }
        drawChart()
      },[ ])

      const printReport=()=>{
        console.log("chartImg ",chartImg)
        pdfMake.createPdf({ 
            watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
            images:{ chart: chartImg  },
            content:[  
                {text: details.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(details.start).format("DD MMMM   HH:mm")} - ${moment(details.end).format(" DD MMMM  HH:mm")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${details?.name} `, alignment:'center'}, 
                { image:"chart" }
            ],
            defaultStyle: {
                font: "THSarabunNew",
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text:'รายงานสถานะกราฟวงกลม' ,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
        },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
        }).open()
      }

    return(
    <div  className="ion-padding" >    
         <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
        <IonRow className="ion-margin-bottom" >
            <IonCol size="12" className="ion-text-center ion-padding-left" >
                <IonTitle className="ion-margin-bottom ion-no-padding">{details.reportName}</IonTitle> 
 
                <IonLabel style={{fontSize:'.85em'}}>
                    <IonText className="ion-margin-end" >วันที่</IonText> 
                    <IonText>{moment(details.dateStart).format("DD MMMM   HH:mm")}</IonText> -
                    <IonText>{moment(details.dateEnd).format(" DD MMMM  HH:mm")}</IonText>
                </IonLabel><br/>
                <IonLabel style={{fontSize:'.9em'}}>
                    <IonText>ทะเบียนรถ​ : {details?.name} </IonText>
                </IonLabel>
            </IonCol>
            <IonCol size="12" className="set-center" >
                <div id={"circle-chart"} ></div>
            </IonCol>
        </IonRow>
    </div>
    )
}
export default RepCircleDaily;