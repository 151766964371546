import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { setStorage } from "../actions";

const initialState = { 
  vehicles: [{dl:[{cc:4 ,cn: "CH1,CH2,CH3,CH4", id:"4sd000066"  }],nm:'',chnName:"",isOnline:0}]
};

export const mdvrSlice = createSlice({
  name: "mdvrstate",
  initialState,
  reducers: { 
    setVehicleMdvr  : (state, action) => {
      state.vehicles = action.payload;
    } 
  },
});

// Action creators are generated for each case reducer function
export const { 
   // setVehicleMdvr, 
} = mdvrSlice.actions;
  

export default mdvrSlice.reducer;
