import { 
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent, 
  IonGrid, 
  IonHeader,  
  IonIcon,  
  IonImg,  
  IonItem,  
  IonItemDivider,  
  IonItemGroup,  
  IonLabel,  
  IonList,  
  IonMenu, 
  IonPopover, 
  IonRippleEffect, 
  IonRow,
  IonText,
  IonToolbar,
  useIonPopover,  
} from '@ionic/react';
 
import {   addCircleOutline, atOutline, callOutline, caretDownCircleOutline,  checkmarkCircleOutline,  checkmarkOutline,  chevronForwardOutline,  cogOutline,  compass,  compassOutline,  diceOutline,  documentAttach,  documentAttachOutline,  documentText,  documentTextOutline,  ellipse,  fileTrayFullOutline,  golfOutline,  grid,  gridOutline,  heartOutline, heartSharp, home, homeOutline, logOutOutline, mailOutline, mailSharp, mapOutline, moon, notificationsCircleOutline, notificationsOffOutline, notificationsOutline, optionsOutline, paperPlaneOutline, paperPlaneSharp, peopleCircleOutline, peopleOutline, prism, prismOutline, push, removeCircleOutline, repeatOutline, settings, settingsOutline, starOutline, sunny, trashOutline, trashSharp, volumeHighOutline, volumeMuteOutline, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css'; 
import { menuController } from '@ionic/core/components';
import "./css/Menu.css"
import { useEffect, useRef, useState } from 'react';
import { getNotifyMessage, getNotifyUnreadMessage, getStorage, notifications, setStorage, updateReadMessageNotify } from '../actions';
import { DarkModeSegment } from './AppComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getDarkMode, getMenuOpen, getNotifierMessages, getProfile, getSubMenu, getUnReadMsg, setMenuOpen, setNotifierMessage, setProfile, setSignin, setSubMenu, setUnreadMsg } from '../store/appSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faArrowRightToBracket, faDoorClosed, faDoorOpen, faKey, faRightFromBracket, faRightToBracket, faRoute, faScrewdriverWrench, faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router'; 
import Draggable from 'react-draggable';
import { ChangeLang } from './NavBar';
import moment from 'moment'; 
import { getNotify,  setNotify } from '../store/menuSlice';
import { getVoiceEnable, setVoiceEnable, setNotifyVoice, getNotifyVoice, getSoundNotify, getNotifyEnable, setNotifyEnable } from '../store/settingSlice'; 
import { useCookies } from 'react-cookie';
import { Store } from 'react-notifications-component';
import React from 'react';

export interface AppPage {
  url: string;
  icon: any; 
  title: string;
  ionicicon: Boolean; 
  groups: Boolean;
  subpage: any[]
  component: JSX.Element | null
  allowGuest: Boolean | any
}
interface NotiferMessage { 
    title: String
    message:  String  
    userId: Number 
    accountId: Number 
    createDate:  String  
    eventTime: String | any
    name2:  String 
    acc: false 
    province:  String  
    amphur:  String 
    tambon: String  
    geoName:  String 
    timeOfStatus: Number
    driverName: null 
    driverId:  String  
    notiferCode: Number
}
 
let intervalPushAlert: any = null ;
const duration = 600;
export const NotificationsButton=()=>{
  const [open,setOpen] = useState(false)
  
  const nottifierMessage = useSelector(getNotifierMessages)
  const dispatch = useDispatch() 
  const unreadMsg = useSelector(getUnReadMsg) 
  const voiceEnable  = useSelector(getVoiceEnable) 
  const notify:any = useSelector(getNotify) 
  var timer: string | number | NodeJS.Timeout | undefined | any =  useRef() 
  var counting = 0; 

  const noticode=(code:any)=>{
    if(code  === -6) { return "ACC OFF"; }
    else if(code  === -7) {  return "ACC ON";  }
    else if(code  === 6)  { return "Over Speed"; } 
    else if(code  === 1)  { return "In Geofence"; }
    else if(code  === 2)  { return "Out Geofence"; }
    else if(code  === 25) { return "Door Close"; }
    else if(code  === 26) { return "Door Open";  }else{
      return ""
    }
  }

  const getSetting=async()=>{
    let voiceEn = await   getStorage("voiceEnable"  )
    let notifyVoice= await getStorage("notifyVoice"  )
    let notifyEnable= await getStorage("notifyEnable"  )
   
    if(notifyEnable){  dispatch(setNotifyEnable(notifyEnable)) }else{
      dispatch(setNotifyEnable(true))
    }
    if(voiceEn != null){
      dispatch(setVoiceEnable(voiceEn))
    }
    if(notifyVoice){
      dispatch(setNotifyVoice(notifyVoice))
    }else{
      dispatch(setNotifyVoice("Beep Alarm Clock"))
    }
  }

  const pushmessage=(mestopush:any)=>{
    if(voiceEnable){

    }
    const title:any = noticode(mestopush?.notiferCode)  
    Store.addNotification({
      title: `แจ้งเตือน:${title} `,
      message:  mestopush?.message ,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true
      }
    }) 
  }
  useEffect(()=>{
    getSetting() 
    getnotifications()
  },[unreadMsg, counting ,timer ,notify])
  
  const getnotifications=async()=>{ 
    let begin = new Date(moment().subtract(7,'day').format("YYYY-MM-DDT00:00:00")).getTime()
    let end =  new Date(moment().format("YYYY-MM-DDT23:59:59")).getTime() 
    let result = await notifications({begin,end}) 
  }


  return(<div  id='menu-notification'   style={{ position:"relative"}}   > 
      {notify?.message && notify?.message.filter((e:any)=> !e.readed).length > 0 && 
        <IonIcon icon={ellipse} color='danger' style={{position:"absolute",zIndex:3,top:".5rem",right:"0rem",fontSize:".7em"}} /> 
      }
      <IonIcon icon={notificationsOutline} /> 
  </div>)
}

export const NotiferMessageContent=({notify ,voiceEnable}:any)=>{
  const dispatch = useDispatch()
  const history = useHistory() 
  const isNoti = useSelector(getNotifyEnable)
 
  useEffect(()=>{  
    const getisnoti=async()=>{
      const isnoti = await getStorage("notifyEnable" ) 
      dispatch(setNotifyEnable(isnoti))
    }
    getisnoti()
  },[isNoti])

  return( 
  <div style={{width:"100%" , height:"90vh" , overflowY:"scroll"}} > 
        <IonToolbar color={"light"}> 
          <IonButtons  slot='start'  >
            <IonButton  size='small'   fill='clear' color={"dark"} onClick={()=>{ 
              setStorage("notifyEnable",isNoti?false:true)
              dispatch(setNotifyEnable(isNoti?false:true))
              }} >
              <IonIcon icon={isNoti? notificationsOutline : notificationsOffOutline} /> &nbsp; <IonText >การแจ้งเตือน </IonText>  
            </IonButton> 
          </IonButtons> 
          <IonButtons  slot='end' >
                <IonButton size='small' fill='clear' 
                  color={voiceEnable?"dark":"danger"}
                  onClick={()=>{
                    setStorage("voiceEnable" ,voiceEnable?false:true )
                    dispatch(setVoiceEnable(voiceEnable?false:true))
                  }}
                >
                   <IonIcon icon={voiceEnable?volumeHighOutline:volumeMuteOutline} />  
                </IonButton> &nbsp;&nbsp;&nbsp;
                <IonButton size='small' fill='clear'  onClick={()=>{ history.push("/notificationSetting") }}>
                  {/* <IonText >อ่านทั้งหมด </IonText>  &nbsp; <IonIcon icon={chevronForwardOutline} />  */}
                  <IonIcon icon={settingsOutline} />
                </IonButton> 
            </IonButtons>
        </IonToolbar>
      {/* </IonHeader> */}
      <div className='message-container' >
      <IonList className='ion-padding'> 
        {
          notify  && notify?.message.map((msg:NotiferMessage, index:any)=>
          <IonItem key={index}  color={"ligh"}  style={{fontSize:".9em",position:"relative"}}  > 
            <IonIcon color={
              msg.notiferCode === -6 ? "danger" :
              msg.notiferCode === -5 ? "success":
              msg.notiferCode === 6 ?  "warning":
              msg.notiferCode === 1 ? "success" :
              msg.notiferCode === 25? "success" :
              msg.notiferCode === 26 ?"danger" :"success"

            } icon={notificationsCircleOutline} style={{fontSize:"2em"}} /> &nbsp;
            <IonLabel  className="ion-text-nowrap">
              <IonText>  <strong> {msg?.title} </strong></IonText> <br/>
              {/* <IonText>{JSON.stringify(msg)}</IonText> */}
              {msg?.tambon === "" && msg?.province ==="" ? 
               <IonText>{msg?.message}</IonText> :
               <IonText><small>{msg?.geoName} {msg?.tambon} {msg?.amphur} {msg?.province}</small></IonText>
              }
            </IonLabel> 
            <IonLabel slot='end' > 
              <small>  {moment(msg?.eventTime).format("HH:mm, DD MMM YYYY")}  </small>
            </IonLabel>
 
          </IonItem>
          )
        } 
      </IonList>
      </div>
    </div> 
  )
}


interface SubPage {
  title: String | any
  url: String | any
  icon:  any 
  ionicicon: Boolean | any
  allowGuest:  Boolean | any 
}
export const appPages: AppPage[] = [  
  // {
  //   title: 'Noifications',
  //   url: '/notification',
  //   icon: notificationsOutline , 
  //   ionicicon: true , 
  //   groups: false  ,
  //   subpage:[] ,
  //   component: <NotificationsButton /> ,
  //   allowGuest: false
  // },
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: gridOutline , 
    ionicicon: true, 
    groups: false ,
    subpage:[],
    component:null,
    allowGuest: true
  },
  {
    title: 'หน้าแรก',
    url: '/home',
    icon:  homeOutline , 
    ionicicon: true, 
    groups: false  ,
    subpage:[],
    component:null,
    allowGuest: true
  },
  {
    title: 'Maps',
    url: '/routehistory',
    icon:  mapOutline, 
    ionicicon: true ,  
    groups: true,
    subpage:[ 
      {
        title: 'Monitor แบบกลุ่ม',
        url: '/groupmonitor',
        icon: "../assets/icon/map-add.svg", 
        ionicicon: true ,  
        allowGuest: true 
      },
      {
        title: 'ประวัติเส้นทาง',
        url: '/routehistory',
        icon: compassOutline, 
        ionicicon: true ,  
        allowGuest: true 
      },
      {
        title: 'ประวัติเส้นทาง แบบกลุ่ม',
        url: '/grouproutehistory',
        icon: "../assets/icon/map-compass.svg", 
        ionicicon: true ,  
        allowGuest: true 
      },
      {
        title: 'เขตพื้นที่ / สถานี',
        url: '/geofences',
        icon: prismOutline, 
        ionicicon: true ,  
        allowGuest: true   
      },
      {
        title: "กำหนดเส้นทาง",
        url: '/setRoute',
        icon: golfOutline , 
        ionicicon: true ,  
        allowGuest: true   
        
      }
    ],
    component:null,
    allowGuest: true
  },
  {
    title: 'Documents',
    url: '/routehistory',
    icon:  fileTrayFullOutline, 
    ionicicon: true ,  
    groups: true,
    subpage:[
      {
        title: 'รายงาน',
        url: '/reports',
        icon: documentTextOutline, 
        ionicicon: true ,  
        allowGuest: true
      },
      {
        title: 'แนะนำเส้นทาง',
        url: '/suggestRoute',
        icon: faRoute , 
        ionicicon: false , 
        groups: false ,  
        allowGuest: true
      }, 
      {
        title: 'พนักงาน',
        url: '/employees',
        icon: peopleOutline , 
        ionicicon: true , 
        groups: false  ,  
        allowGuest: false
      }, {
        title: 'กลุ่มอุปกรณ์',
        url: '/groupdevice',
        icon: diceOutline , 
        ionicicon: true , 
        groups: false  ,  
        allowGuest: false
      }, 
      {
        title: 'ผู้รับเชิญ',
        url: '/guests',
        icon: peopleCircleOutline , 
        ionicicon: true , 
        groups: false ,  
        allowGuest: false
      }, 
      
      //groupdevice
      // {
      //   title: 'Maintenance',
      //   url: '/maintenance',
      //   icon: cogOutline , 
      //   ionicicon: true ,  
      // },
      // {
      //   title: 'คู่มือ',
      //   url: '/manual',
      //   icon: documentAttachOutline , 
      //   ionicicon: true ,  
      // },
      {
        title: 'พฤติกรรมการขับ',
        url: "/drivingPoint" ,
        icon: starOutline , 
        ionicicon: true ,  
      }
    ],
    component:null,
    allowGuest: true
  }, 
   {
        title: 'Settings',
        url: '/settings',
        icon: settingsOutline , 
        ionicicon: true ,  
        groups: false  ,
        subpage:[],
        component:null,
        allowGuest: false
   }, 
  //  {
  //    title: 'Playback',
  //    url: '/mdvr/playback',
  //    icon: repeatOutline , 
  //    ionicicon: true, 
  //    groups: false ,
  //    subpage:[],
  //    component:null
  //  }, 
  // {
  //   title: 'Noifications',
  //   url: '/notification',
  //   icon: notificationsOutline , 
  //   ionicicon: true , 
  //   groups: false  ,
  //   subpage:[]
  // },
];

const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
 


const Menu = () => { 
  const dispatch = useDispatch()
  const [version,setVersion] = useState("0.0.1")
  const [current,setCurrnt] = useState(window.location.pathname)
  const isDark = useSelector(getDarkMode)
  const  profile =useSelector(getProfile)
  const [isGuest ,setIsGuest] = useState(false)
  const history = useHistory()
  const menuref:any = useRef()  

  const getprofile=async()=>{
    let prof = await getStorage("profile") 
    dispatch(setProfile(prof))
  }
  const logOut=async()=>{    
    await menuController.close() 
    setTimeout(()=>{ 
      localStorage.removeItem("isSignin")
      localStorage.removeItem("token") 
      dispatch(setSignin(false))
    },200)   
}

  useEffect(()=>{
    if(current !== window.location.pathname){
      setCurrnt(window.location.pathname)
    } 
  },[current,isDark, window.location.pathname ,menuref])

  return (<> 
     <IonMenu ref={menuref} swipeGesture={false} menuId="main-menu"  contentId="main" type='overlay' side={ 'start' } 
     onIonWillOpen={()=>{ getprofile()}} > 
        <IonContent className='ion-padding' >
           <IonGrid>
            <IonRow className='ion-justify-content-center' >
              <IonCol size='4' className='set-center' > 
                <img src='../assets/andaman-icon.svg'  style={{width:"1.5rem"}}/>  
              </IonCol>
              <IonCol size='8' >
                <IonLabel>
                   <h2 className='ion-no-margin' style={{fontSize:"1.2em",fontWeight:"bold"}} >{profile?.first_name}</h2>
                   <IonText>{profile?.last_name}</IonText>
                </IonLabel>
              </IonCol>
              <IonCol size='11' className='ion-text-right' > <br/>
                <DarkModeSegment />   
              </IonCol>
              <IonCol size='11' className='ion-text-right' > 
                 <IonLabel color={"medium"}> <small style={{margin:"1rem .5rem"}} >version {"0.1.5"}</small> </IonLabel>
              </IonCol>
            </IonRow><br/>
            {
            appPages.map((page:AppPage,index:any)=> 
            <IonList className='wrapper'  style={{padding:"0px" }}  key={index}  >
              {
                !page.groups ?
                <IonItem  style={{padding:"0px", }}  lines='none' mode='ios' onClick={()=>{history.push(page.url)}}>
                  {page.ionicicon? 
                    <IonIcon icon={page.icon} style={{ marginRight:'.5rem', fontSize:"1.3em"}} />  :
                    <FontAwesomeIcon icon={page.icon}  style={{ marginRight:'.5rem', fontSize:"1.3em"}} /> 
                  } 
                  <IonLabel style={{fontSize:".9em"}}>{page?.title}</IonLabel>
                </IonItem> :
                page.subpage ?  
                <MenuItemDever page={page} history={history} menu={menuref} />:<>
                {  page.component && !page.groups && page.component }
                </> 
              }
             </IonList>
            )} <br/>
            <IonItem button style={{padding:"0px", }}  lines='none' mode='md' onClick={()=>{logOut()}}  > 
              <IonIcon icon={logOutOutline} style={{ marginRight:'.5rem', fontSize:"1.3em"}} />   
              <IonLabel style={{fontSize:".9em"}}> ออกจากระบบ </IonLabel>
            </IonItem> 
            
           </IonGrid>
        </IonContent>
        {/* <MenuContent profile={profile} />   */}
      </IonMenu>
  </>);
}; 
export default Menu;

const MenuItemDever=({page,history,menu}:any)=>{
  const [openDevider , setOpenDevider] = useState(true) 
  async function closeMenu(url:any) { 
    console.log("url ",url) 
    await menu.current?.close()
    history.push(url)
  }
  return(
    <IonItemGroup className={`menu-group ${openDevider && "open"}`} style={{marginBottom:".5rem"}} >
     <IonItemDivider color={"transparent"} onClick={()=>{setOpenDevider(!openDevider)}} style={{background:"transparent",fontSize:"smaller",paddingLeft:"1.5rem",marginTop:".5rem"}}>
       <IonIcon  icon={openDevider?removeCircleOutline:addCircleOutline} /> &nbsp;
       <IonLabel>{page.title}</IonLabel> 
     </IonItemDivider>
        {
        openDevider && page?.subpage.map((sp:any, spIndex:any)=>
         <IonItem  lines='none'  key={spIndex} color={"transparent"} style={{padding:"0px",fontSize:"medium"}} mode='ios' onClick={()=>{closeMenu(sp.url);}} >
           {page.ionicicon? 
             <IonIcon icon={sp.icon} style={{ marginRight:'.5rem', fontSize:"1.3em"}} />  :
             <FontAwesomeIcon icon={sp.icon}  style={{ marginRight:'.5rem', fontSize:"1.3em"}} /> 
           } 
           <IonLabel  style={{fontSize:".9em"}}>{sp?.title}</IonLabel>
         </IonItem> 
         )
        }
     </IonItemGroup>
  )
}
 
const LogoProfile=()=>{
  const [profile,setProfile] = useState({
      "first_name": "สุทธิพงศ์",
      "id": 9641,
      "last_name": "ประทุม",
      "url_realtime": "http://server1.tracker.in.th/realtime/realtimeDevice.html?id="
  })

  useEffect(()=>{
    const getprofile=async()=>{
      let prof = await getStorage("profile") 
      setProfile(prof)
    }
    getprofile()
  },[])
  return(<>
    <img src='../assets/andaman-icon.svg' className='avatar'  id="avatar-profile" />
    <IonPopover trigger="avatar-profile" className='popover-profile' triggerAction="click" mode='ios'  side='right' alignment='start' >
        <IonContent  className="ion-padding"  style={{paddingBottom:"3rem"}} > 
            <div  className='set-center' style={{position:"relative",marginBottom:"2rem"}} > 
              <IonImg src='../assets/img/profile-card.svg' />
              <IonLabel style={{position:"absolute",lineHeight:".9rem" }} className='ion-text-center' >
                <IonText style={{fontWeight:"bold"}}>{profile.first_name} {profile.last_name}</IonText><br/>
                <sub style={{fontSize:".7em"}}  >Version {0.1}</sub>
              </IonLabel>
            
              <IonAvatar className='person' color='"transparent' >
                <IonImg src='../assets/icon/person.svg' />  
              </IonAvatar>
            </div>
            <IonItem mode='ios' lines='none' color={"transparent"} > 
              <IonAvatar  className='icon set-center'  >
                <IonIcon icon={atOutline} />
              </IonAvatar>
              <IonLabel  class="ion-text-wrap">andamantracking.development@gmail.com</IonLabel>
            </IonItem>
            <IonItem mode='ios' lines='none' color={"transparent"} > 
              <IonAvatar  className='icon set-center'  >
                <IonIcon icon={callOutline} />
              </IonAvatar>
              <IonLabel > 088-561-5611 </IonLabel>
            </IonItem>
            <IonItem mode='ios' lines='none' color={"transparent"} > 
              <IonAvatar  className='icon set-center'  >
                <IonIcon icon={homeOutline} />
              </IonAvatar>
              <IonLabel  class="ion-text-wrap">ที่อยู่ 96/17 หมู่ที่ 3 ตำบลกมลา อำเภอกะทู้ จังหวัดภูเก็ต 83150 โทรศัพท์ 082-4237549 </IonLabel>
            </IonItem> 
        </IonContent>
    </IonPopover>
  </>
  )
}
interface SiginDetail{
   account : String
   username : String
   password : String
}

export const CallapseMenu=({}:any)=>{
  const [current,setCurrnt] = useState(window.location.pathname)
  const history = useHistory() 
  const dispatch = useDispatch()  
  const popover = useRef<HTMLIonPopoverElement>(null);  
  // const [cookies, setCookie] = useCookies(['token']); 
  const [siginde,setSigninde] = useState<SiginDetail|null>(null)
  const [isGuest,setIsGuest] = useState(false)
  const getisguest=async()=>{ 
    const isguest = await getStorage("isGuest")
    setIsGuest(isguest)
  }
  const getsignin=async()=>{
    let s = await getStorage("sigin") 
    setSigninde(s)
    getisguest()
  }
  useEffect(()=>{ 
    getsignin()
    if(current !== window.location.pathname){
      // setCookie('token',cookies?.token ,{expires: new Date( moment().local().add(20,'minute').format() ) })
      setCurrnt(window.location.pathname)
    }else{ 
    } 
  },[window.location.pathname])
 
  const logOut=async()=>{   
    localStorage.removeItem("isSignin")
    localStorage.removeItem("token") 
    dispatch(setSignin(false)) 
  } 

  return(<div className='callapse-menu' > 
      <ul>
        <li> <LogoProfile/>  </li><br/><br/> 
        {/* {
        title: 'Admin',
        url: '/admin',
        icon: peopleOutline , 
        ionicicon: true, 
        groups: false ,
        subpage:[],
        component:null
      },   */}
      {siginde && siginde?.username === "admin" && <li id={`menu-Admin`} >
        <IonButton 
          size='small' fill='clear' color={"dark"}
          style={{height:"5rem"}}   
          onClick={()=>{history.push('/admin')}}
        >
          <IonIcon icon={peopleOutline} style={{ marginRight:'.5rem'}} />
        </IonButton>
      </li> }
        {
          appPages.map((page:AppPage,index:any)=> 
           (  isGuest === false || (isGuest === true && page.allowGuest === true ) ) &&   <li  key={index} id={`menu-${page.title}`}>
            {
              !page.groups && !page.component?  
              <IonButton 
                  size='small' fill='clear' color={"dark"}
                   style={{height:"5rem"}}   
                  onClick={()=>{history.push(page.url)}}
                >
                  {page.ionicicon? 
                    <IonIcon icon={page.icon} style={{ marginRight:'.5rem'}} />  : 
                    <FontAwesomeIcon icon={page.icon}  style={{ marginRight:'.5rem'}} /> 
                  } 
              </IonButton> : 
              page.component? page.component :
              <IonButton 
                size='small' fill='clear' color={"dark"} 
                 style={{height:"5rem"}}    
              >
                  {page.ionicicon? 
                    <IonIcon icon={page.icon} style={{ marginRight:'.5rem'}} />  :
                    <FontAwesomeIcon icon={page.icon}  style={{ marginRight:'.5rem'}} /> 
                  } 
                  
              </IonButton> 
            }

          {page.groups &&  <IonPopover  dismissOnSelect={true}  ref={popover} 
              trigger={`menu-${page.title}`} id={`menu-${page.title}-${index}`} 
              triggerAction='hover' mode='ios'   side="right" alignment='center'
              showBackdrop={false}  onMouseLeave={()=>{popover.current?.dismiss()}}
            >
              <IonContent> 
                 {  page?.subpage.map((sp:SubPage,index:any)=>
            
                   isGuest === false ?   <ItemPage key={index} sp={sp}  current={current} history={history} /> :
                     isGuest === true && sp.allowGuest === true ?  <ItemPage key={index} sp={sp}  current={current} history={history} /> : <></>
                )}
              </IonContent>
            </IonPopover>
          }
          </li>
        )}  
      </ul> 

      <ul style={{borderTop:"1px solid #DDD",borderBottom:"1px solid #DDD",paddingTop:".7rem" ,paddingBottom:".5rem" }}>
       <li className="set-center" >
          <ChangeLang style={"clear"} id="callasp-menu" />
        </li>
        <li className="set-center" > 
          <DarkModeSegment ui={"circle"}/> 
        </li>
      </ul><br/>

      <ul  className='end ' >
       <li className='signout set-center' style={{backgroundImage: "url('../assets/img/promthep.jpg')"}} >  
           <button  color={"dark"} onClick={()=>{logOut()}} className='ion-activatable ripple-parent circle'>
             <IonIcon icon={logOutOutline} />
             <IonRippleEffect></IonRippleEffect>
           </button>
        </li>
      </ul>
  </div>)
} 

const ItemPage=({sp,current,history}:any)=>{
  return(
    <IonItem   color={"transparent"}  lines='none' 
      className={`menu-page ${sp.url ===current && "active"}`} 
      routerDirection='forward'  
      onClick={()=>{ history?.push(sp.url)}}
    >    
    {sp.ionicicon ? <IonIcon icon={sp.icon} style={{ marginRight:'.5rem',fontSize:"1em"}} />  : <FontAwesomeIcon icon={sp.icon}  style={{ marginRight:'.5rem',fontSize:"1em"}} /> }
    <IonLabel className={`menu ${sp.url ===current && "active"}`}  > 
      <IonLabel style={{fontSize:"1.1em", }} >  
        {/* {sp.ionicicon ? 
          <IonIcon icon={sp.icon} style={{ marginRight:'.5rem'}} />  : 
          <FontAwesomeIcon icon={sp.icon}  style={{ marginRight:'.5rem'}} /> 
        }  */}
        <span  style={{fontSize:"smaller", }}>{sp.title}</span> 
      </IonLabel>
    </IonLabel> 
    <IonIcon icon={chevronForwardOutline} style={{fontSize:'.9em'}} color='medium' />
  </IonItem>
  )
}
  
export const MenuContent=({profile}:any)=>{
      const dispatch = useDispatch()
      const [version,setVersion] = useState("0.0.1")
      const [current,setCurrnt] = useState(window.location.pathname)
      const isDark = useSelector(getDarkMode) 
      const [menugroup, setMenugroup] = useState("")
      const history = useHistory()
      const menuOpen = useSelector(getMenuOpen)

      const logOut=async()=>{    
        await menuController.close()

        setTimeout(()=>{ 
          localStorage.removeItem("isSignin")
          localStorage.removeItem("token") 
          dispatch(setSignin(false))
        },200)   
      }
 

      useEffect(()=>{
        if(current !== window.location.pathname){
          setCurrnt(window.location.pathname)
        }else{ 
        } 
       
        // document.addEventListener('resize', (ev:any) =>{console.log("ev ",ev)  });   
      },[current,isDark, window.location.pathname])

      const changeAccordion=(val:any)=>{
        if(val!==undefined){
          setMenugroup(val?.toLowerCase())
        }else{
          setMenugroup("")
        }
      }

    return(<> 
      <IonContent className="ion-padding" >
        <IonHeader className='header ion-no-border ' > 
          <div className='header-menu set-center' style={{backgroundImage:`url('../../assets/img/promthep.jpg')`}}>
            <div className='inner-header'> </div> 
            <div className='front-menu set-center' >
               {isDark &&  <IonImg   src={`../../assets/img/sideLogo-white.svg`} />}
               {!isDark &&<IonImg   src={`../../assets/img/sideLogo.svg`} /> } 
              <div className='theme-toggle' >
                <DarkModeSegment /> 
              </div>
            </div> 
          </div>
        </IonHeader> 

          <IonGrid style={{marginTop:".5rem"}} id='menu-content'  > 
          
            {
              appPages.map((page:any,index:any)=><div key={index}>
               {
                !page.groups? 
                <IonItem color={"transparent"}  lines='none' 
                  className={`menu-page ${page.url ===current && "active"}`} 
                  routerDirection='forward'  
                  onClick={()=>{ history?.push(page.url)}}
                >    
                <IonLabel className={`menu ${page.url ===current && "active"}`}  > 
                  <IonLabel style={{fontSize:"1.1em", }} >  {page.ionicicon ? 
                      <IonIcon icon={page.icon} style={{ marginRight:'.5rem'}} />  :
                      <FontAwesomeIcon icon={page.icon}  style={{ marginRight:'.5rem'}} /> 
                    } <span  style={{fontSize:"smaller", }}>{page.title}</span> 
                  </IonLabel>
               </IonLabel> 
              </IonItem>:
              <IonItem  key={index} color={"transparent"} lines='none'  >   
                <IonAccordionGroup className='accordion-group-menu'  mode='ios' value={menugroup} onIonChange={(e:any)=>{ changeAccordion(e.detail.value)}} >
                  <IonAccordion value={page.title} mode='ios' toggleIcon={caretDownCircleOutline} toggleIconSlot="end">
                    <IonItem slot="header" color="light" className={`sub-menu-header   ${page.title?.toLowerCase() ===menugroup && "active"}`}>
                      <IonLabel className={`menu ${page.title?.toLowerCase() ===menugroup && "active"}`}  > 
                          <IonLabel style={{fontSize:"1.15em", }} >  {page.ionicicon ? 
                              <IonIcon icon={page.icon} style={{ marginRight:'.5rem'}} />  :
                              <FontAwesomeIcon icon={page.icon}  style={{ marginRight:'.5rem'}} /> 
                            } <span  style={{fontSize:"smaller", }}>{page.title}</span> 
                          </IonLabel>
                      </IonLabel> 
                    </IonItem>
                    <div className="ion-padding" slot="content">
                     {
                      page.subpage.map((sub:any,i:any)=>
                      <IonItem  color={"transparent"} 
                          key={i} lines='none'
                          className={`menu-page ${sub.url ===current && "active"}`} 
                          routerDirection='forward'  
                          onClick={()=>{ setMenugroup(page.title) ;history?.push(sub.url)}}
                        >    
                        <IonLabel className={`menu ${sub.url ===current && "active"}`}  > 
                            <IonLabel style={{fontSize:"1.1em", }} >  {sub.ionicicon ? 
                                <IonIcon icon={sub.icon} style={{ marginRight:'.5rem'}} />  :
                                <FontAwesomeIcon icon={sub.icon}  style={{ marginRight:'.5rem'}} /> 
                              } <span  style={{fontSize:"smaller", }}>{sub.title}</span> 
                            </IonLabel>
                        </IonLabel> 
                      </IonItem>
                      )
                     }
                     </div>
                  </IonAccordion>  
              </IonAccordionGroup>
              </IonItem>
               }
              </div>
              
              )
            }  
          </IonGrid>

          <div className='menu-user-bottom'> 
            <div className='content  ' >
              <IonRow className='content  '>
                <IonCol size='2.5' >
                  <IonAvatar>
                    <img alt="Silhouette of a person's head" src={`../../assets/icon/user-person.svg`} />
                  </IonAvatar>  
                </IonCol>
                <IonCol size='7' >
                  <IonLabel  > <strong>{profile.first_name}</strong>  </IonLabel> 
                  <p className='version'>Version {version}</p>
                </IonCol>
                <IonCol size='2.5' >
                   <button onClick={()=>{logOut()}} className='btn-logout set-center' > 
                    <IonIcon icon={logOutOutline} /> 
                   </button>
                </IonCol>
              </IonRow> 
            </div> 
          </div>
        </IonContent> 
      </>
    )
}


export const PopupSubmenu=({}:any)=>{
  const history = useHistory();
  const subMenu = useSelector(getSubMenu)
  const dispatch = useDispatch()
  
  const [opensubmenu,setOpenSubMenu] = useState(false)
  let  current = window.location.pathname
  useEffect(()=>{ 
    if(subMenu){
      console.log("subMenu ",subMenu)
      setOpenSubMenu(subMenu.open)
    }
    
  },[subMenu,window.location.pathname])

  return(<>
  {opensubmenu &&  
   <Draggable     >
      <div className='play-control-float' > 
    {
        subMenu?.pages?.map((sub:any,i:any)=>
        <IonItem color={"transparent"} 
            key={i} lines='none'
            className={`menu-page ${sub.url ===current && "active"}`} 
            routerDirection='forward'  
            onClick={()=>{history?.push(sub.url)}}
          >    
          <IonLabel color={"dark"} className={`menu ${sub.url ===current && "active"}`}  > 
              <IonLabel style={{fontSize:"1.1em", }} >  {sub.ionicicon ? 
                  <IonIcon icon={sub.icon} style={{ marginRight:'.5rem'}} />  :
                  <FontAwesomeIcon icon={sub.icon}  style={{ marginRight:'.5rem'}} /> 
                } <span  style={{fontSize:"smaller", }}>{sub.title}</span> 
              </IonLabel>
          </IonLabel> 
        </IonItem>
        )
      } 
    </div>
  </Draggable> 
}
 </>)
}
 