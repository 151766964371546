import { IonButton, IonCol, IonIcon, IonLabel, IonRow, IonText, IonTitle } from "@ionic/react";
import moment from "moment";
import { useState, useRef, useEffect } from "react";
import { configPDF, pdfMake } from "../../../lib/pdfMake" 
import { printOutline } from "ionicons/icons";

 
const RepStatusBar=({chart,details,orientation}:any)=>{

    const [chartImg,setChartImg] = useState("") 
    const [chartref , setChartRef] = useState<ApexCharts|null>(null)
    const chartRef:any = useRef()

    var apexchrt :any = null ;
    let options = {
        series: [],  
        chart: 	{ id: "bar-status"	, width: 900 , height: 350 ,  
          type: 'bar', 
          stacked: true,
          stackType: '100%'
        }, 
        xaxis: {  categories: [] , },
        yaxis: { 
            type: 'datetime', 
        },
        labels: [] , 
        colors: [ '#ffce00','#10dc60', '#f04141','#666666'],
        plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        // dataLabels: {
        //   formatter(val:any, opts:any) {
        //     const name = opts.w.globals.labels[opts.seriesIndex]
        //     return [name, val.toFixed(1) + '%']
        //   }
        // },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
        dataLabels: {
            formatter: function (val:any, opts:any) { 
                return  `${val}% (${chart.series[opts.seriesIndex ].rawdata[opts.dataPointIndex]})`  
            },
        },
        tooltip: {
            enabled: true,
            x: {
                show: true,
                format: 'dd MMM',
                formatter: function (val:any, opts:any) { 
                    return moment().format("DD MMM YY ")
                }, 
            },
            y: {
                formatter: function (val:any, opts:any) { 
                    return "ระยะเวลา "+chart.series[opts.seriesIndex ].rawdata[opts.dataPointIndex]
                }, 
            },
            z: {
                formatter: undefined,
                title: 'Size: '
            },
        }
        
      }

      useEffect(()=>{ 
        console.log("RepStatusBar chart  ", chart)
        console.log("RepStatusBar details  ", details)
        console.log("RepStatusBar orientation  ", orientation)

        const drawChart=async()=>{
            var element= document.querySelector("#bar-status") 
            if(!element?.innerHTML){ 
                options.series = chart.series 
                // options.colors = chart.color
                // options.labels= chart.labels
                let upH = 350
                let categories =    chart.categories.map((cat:any)=> {  upH= upH+10; return  moment(new Date(cat)).format("YYYY-MM-DD") })
                options.chart.height = upH
                console.log("categories ",categories)
                options.xaxis.categories = categories
 
                var chrt:any = new ApexCharts(element, options)
                setChartRef(chrt)
                chrt.render()
                setTimeout(async()=>{ 
                    let {imgURI}:any = await chrt?.dataURI()
                    setChartImg( imgURI) 
                },1500)  
            }
        } 
        drawChart()
      },[])

      const printReport=async ()=>{ 
        let {imgURI}:any = await chartref?.dataURI()
        // setChartImg( imgURI) 
        // console.log("chartImg ",chartImg)
        pdfMake.createPdf({ 
            watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
            images:{ chart: imgURI  }, 
            pageOrientation: orientation,
            content:[  
                {text: details.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(details.start).format("DD MMMM  YYYY")} - ${moment(details.end).format(" DD MMMM YYYY")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${details?.name} `, alignment:'center'}, 
                { image:"chart" ,width: 500 }
            ],
            defaultStyle: {
                font: "THSarabunNew",
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text: details.reportName ,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
        },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
        }).open()
      }

    return(
    <div  className="ion-padding" >     
       <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
       <IonRow className="ion-margin-bottom" >
            <IonCol size="12" className="ion-text-center ion-padding-left" >
                <IonTitle className="ion-margin-bottom ion-no-padding">{details.reportName}</IonTitle> 
 
                <IonLabel style={{fontSize:'.9em'}}>
                    <IonText>ทะเบียนรถ​ : {details?.name} </IonText>
                </IonLabel><br/>
                <IonLabel style={{fontSize:'.85em'}}>
                    <IonText className="ion-margin-end" >วันที่</IonText> 
                    <IonText>{moment(details.start).format("DD MMMM YYYY")}</IonText> -
                    <IonText>{moment(details.end).format(" DD MMMM YYYY")}</IonText>
                </IonLabel>
            </IonCol>
            <IonCol size="12" className="set-center" >
                <div id={"bar-status"} >   
                </div>
            </IonCol>
       </IonRow>
    </div>
    )
}

export default  RepStatusBar;