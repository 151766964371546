import { IonContent, IonPage } from "@ionic/react";

const PageNotFound: React.FC =()=>{
    return( 
    <IonPage>
        <IonContent> 
            <div style={{width:"100vw" , height:"100vh",backgroundImage:"url(../../assets/img/oean.jpeg)",backgroundSize:"cover"}} >

            </div>
        </IonContent>
    </IonPage>
    )
}
export default PageNotFound;