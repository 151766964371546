import i18next, { t } from "i18next";
import moment from "moment";


export function padTo2Digits(num:any) {
  return num.toString().padStart(2, '0');
}

export function convertMsToTime(milliseconds:any) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
 
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds,
  )}`;
}
 
export const setReport0014=async(res:any)=>{ 
  let behaviorGrouped = res.behaviorGrouped
  let hashbraking:any = behaviorGrouped.find((e:any)=> e.behaviorId ==129)
  let sharpturn:any = behaviorGrouped.find((e:any)=> e.behaviorId === 90 ||  e.behaviorId === 91)
  let hashacceration:any = behaviorGrouped.find((e:any)=> e.behaviorId ==130)
  let speeding:any = behaviorGrouped.find((e:any)=> e.speeding ==19)
  let idleovertime:any = behaviorGrouped.find((e:any)=> e.idleovertime ==133)
   
  let rowsData:any = []
  let  hashbrakingRow:any  =[]
  let  sharpturnRow:any   =[]
  let  hashaccerationRow:any   =[]
  let  speedingRow:any   =[]
  let idleovertimeRow:any   =[]
  let colsHashbraking:any=[]
  let colsSharpturn:any=[]
  let colsHashacceration:any=[]
  let colsSpeeding:any=[]
  let colsIdleovertime:any=[]


      if(hashbraking ){
        hashbrakingRow =  hashbraking?.event.map((e:any)=>{
          colsHashbraking.push([e.eventTimeStr , e.address])
          return {subs:  [e.eventTimeStr , e.address] } 
        })  
        console.log("hashbrakingRow ",hashbrakingRow)
      } 
      rowsData.push({
        cols: hashbrakingRow
      })

      if(sharpturn){
        sharpturnRow =   sharpturn?.event.map((e:any)=>{
          colsSharpturn.push([e.eventTimeStr , e.address])
          console.log("e ",e) 
          return {subs:  [e.eventTimeStr , e.address] } 
        })  
        console.log("sharpturnRow ",  sharpturnRow)
      } 
      rowsData.push({
        cols: sharpturnRow
      })

      if(hashacceration){
        hashaccerationRow =  hashacceration?.event.map((e:any)=>{
          colsHashacceration.push([e.eventTimeStr , e.address])
          return {subs:  [e.eventTimeStr , e.address] } 
        })  
        console.log("hashaccerationRow ",hashaccerationRow)
      }
      rowsData.push({
        cols: hashaccerationRow
      })

      if(speeding){
         speedingRow =   speeding?.event.map((e:any)=>{
          colsSpeeding.push([e.eventTimeStr , e.address])
          return {subs:  [e.eventTimeStr , e.address] } 
        })  
        console.log("speedingnRow ",speedingRow)
      }
      rowsData.push({
        cols: speedingRow
      })
      
      if(idleovertime){
         idleovertimeRow = idleovertime?.event.map((e:any)=>{
          colsIdleovertime.push([e.eventTimeStr , e.address])
          return {subs:  [e.eventTimeStr , e.address] } 
        })  
        console.log("idleovertimenRow ",idleovertimeRow)
      }
      rowsData.push({
        cols: idleovertimeRow
      })
  
  return  {
    table:{
      rows: rowsData
    },
    pdfCols:{
      hashbraking:colsHashbraking,
      sharpturn:colsSharpturn,
      hashacceration:colsHashacceration,
      speeding:colsSpeeding,
      idleovertime:colsIdleovertime,
    }
  }
}

export const setReport0021=async (res:any)=>{
  let events = await res.events.map((e:any)=>{
    return { 
    "count": e.count ,
    "status":e.status , "start":e.start ,"end": e.end,
    "statusDuration":e.statusDuration ,
    "distance": e.distance ,
    "expense": e.expense ,
    "driver":e.driver ,
    "maxSpeed":e.maxSpeed ,
    "address":e.address ,
    // lat:e.lat ,lng: e.lng
  }
  })
  let table2Head:any[] = []
  let table2Rows :any[] = []
  let tableOverviewColumn :any[] = []
  let eventHead:any = []
    if(events.length > 0){
      eventHead = Object.keys(events[0]);
    
      tableOverviewColumn.push(
        [
          { colSize:4 ,label: "จอดติดเครื่อง"    }, 
          { colSize:8 ,label:  res.overview.idle +"   ชั่วโมง"  },   
        ]
      )
      tableOverviewColumn.push(
        [
          { colSize:4 ,label: "เคลื่อนที่"    }, 
          { colSize:8 ,label: res.overview.move +"   ชั่วโมง"    },   
        ]
      )
      tableOverviewColumn.push(
        [
          { colSize:4 ,label: "หยุดนิ่ง"    }, 
          { colSize:8 ,label: res.overview.stop +"   ชั่วโมง"   },   
        ]
      )
      tableOverviewColumn.push(
        [
          { colSize:4 ,label: "ความเร็วสูงสุด"    }, 
          { colSize:8 ,label: res.overview.maxSpeed +"   กม. / ชั่วโมง"    },   
        ]
      )
      tableOverviewColumn.push(
        [
          { colSize:4 ,label: "ค่าใช้จ่าย"    }, 
          { colSize:8 ,label:  (res.overview.allExpense ? res.overview.allExpense.toFixed(2) :"-" )+"  บาท" },   
        ]
      )
    
      eventHead.map((key:any)=>{
        table2Head.push(
          { headerSize: 12/eventHead.length ,label: key ,id: key }
        )
      }) 
    console.log("eventHead ",eventHead)
    await  res.events.map((d:any)=>{ 
      console.log("events ", d)
      table2Rows.push( {  
          cols:  [ 
              { colSize: 12/eventHead.length ,label: d.count ,id: "count" },   
              { colSize: 12/eventHead.length ,label: d.status ,id: "status" },   
              { colSize: 12/eventHead.length ,label: d.start > 0 ? moment(d.start).format("HH:mm DD/MM/YYYY") :'-' ,id: "start" },   
              { colSize: 12/eventHead.length ,label: d.end > 0 ?moment(d.end).format("HH:mm DD/MM/YYYY"):'-' ,id: "end" },   
              { colSize: 12/eventHead.length ,label:  d.statusDuration  ,id: "statusDuration" },   
              { colSize: 12/eventHead.length ,label: (d.distance).toFixed(2)  ,id: "distance" },   
              { colSize: 12/eventHead.length ,label:  d.expense   ,id: "expense" },  
              { colSize: 12/eventHead.length ,label: d.driver ,id: "driver" },   
              { colSize: 12/eventHead.length ,label: d.maxSpeed ,id: "maxspeed" },   
              { colSize: 12/eventHead.length ,label: d.address ,id: "address" ,lat: d.lat ,lng: d.lng },   
            ]    
        })
      })
    } 
    console.log("table2Rows ",table2Rows)
    
      return { table2Head ,table2Rows, eventHead ,tableOverviewColumn}
      
  }


  export const setReport0023=async (res:any)=>{
    let header:any =[]
    let rowData:any =[] 
    try{
      let minutestable = res.minutes
      let maxSpeed = res.maxSpeed
      let keysobject:any = Object.keys(minutestable[0]);
      let colsSize = 0.7
      await  keysobject.map((key:any)=>{
          header.push(
            { headerSize: colsSize ,label: key ,id: key }
          )
      })   
  
      await minutestable.map((e:any)=>{
        console.log("miniute ", e)
        rowData.push({
          cols:  [ 
              { colSize: colsSize ,label: moment(e.time).format("DD/MMM HH:mm") ,id: "time" },   
              { colSize: colsSize ,label: e.key  ,id: "key" },   
              { colSize: colsSize ,label: e.status.toString() ,id: "status" , speed:e.speed},   
              { colSize: colsSize ,label: e.station ? e.station :"-" ,id: "station" },   
              { colSize: colsSize ,label: e.address ,id: "address" , lat:e.lat , lng:e.lng},   
              { colSize: colsSize ,label: e.distance.toFixed(0)+" km." ,id: "distance" },   
              { colSize: colsSize ,label: e.driver? e.driver :"-" ,id: "driver" },   
              { colSize: colsSize ,label: e.temp ? e.temp:"-",id: "temp" },   
              { colSize: colsSize ,label: e.lat ,id: "lat" },   
              { colSize: colsSize ,label: e.lng ,id: "lng" },     
              { colSize: colsSize ,label: e.speed ,id: "speed" },
              { colSize: colsSize ,label: e.speedStatus ?"Normal" :"เกินความเร็ว" ,id: "speedStatus" },   
              { colSize: colsSize ,label: e.mile>0 ? e.mile.toFixed(2) : "0"  ,id: "mile" },
              { colSize: colsSize ,label: e.sattlelite ,id: "sattlelite" },
              { colSize: colsSize ,label: e.fuel ? e.fuel:"-" ,id: "fuel" },
              { colSize: colsSize ,label: e.rpm ? e.rpm:"-" ,id: "rpm" },
              { colSize: colsSize ,label: e.oilTank  ? e.oilTank:"-",id: "oilTank" }, 
          ]
       })
      })
      let tableMaxSpeed =   [
        {
          cols:[
            { colSize: 6 ,label: "ความเร็วสูงสุด" ,id: "maxspeed_title"},   
            { colSize: 6 ,label: maxSpeed.speed ,id: "maxspeed"},    
          ]
        },{
          cols:[
            { colSize: 6 ,label: "เวลา" ,id: "time_title"},   
            { colSize: 6 ,label: maxSpeed.time ,id: "time"},    
          ]
        },
        {
          cols:[
            { colSize: 6 ,label: "สถานที่ " ,id: "address_title"},   
            { colSize: 6 ,label: maxSpeed.location ,id: "address"},    
          ]
        }
      ]
  
      return {
        table :{
          header:header ,
          rowData: rowData
        },
        maxSpeed: tableMaxSpeed
      }

    }catch(err){
      return {
        table :{
          header:header ,
          rowData: rowData
        },
        maxSpeed: []
      }
    }

  }

  export const setReport0026=(res:any)=>{
    let header:any =[]
    let rowData:any =[]
    try{ 
      let keysobject:any = Object.keys(res[0]);
      keysobject.map((key:any)=>{
          header.push(
            { headerSize: 12/keysobject.length ,label: key ,id: key }
          )
      }) 
      res.map((e:any,i:any)=>{
        console.log("date ",moment(e.date).format())
          rowData.push({
              cols:  [ 
                  { colSize: 12/keysobject.length ,label: moment(e.date).format("DD MMM YYYY") ,id: keysobject[0] },   
                  { colSize: 12/keysobject.length ,label: (e.distance).toFixed(2)+" km" ,id: keysobject[1] },   
                  { colSize: 12/keysobject.length ,label:  e.expense  ,id: keysobject[2] },   
                  { colSize: 12/keysobject.length ,label:  e.driverId    ,id: keysobject[3] },   
                  { colSize: 12/keysobject.length ,label:  e.driverName  ,id: keysobject[4] },    
              ]
          })
      }) 
    }catch(err){

    }
    return {
        rowData , header
    }

  }
 

  export const setReport0031=async (res:any)=>{
    let events = await res.events.map((e:any)=>{
      return { "status":e.status , "start":e.start ,"end": e.end,
      "statusDuration":e.statusDuration ,
      "distance": e.distance ,
      "driver":e.driver ,
      "maxSpeed":e.maxSpeed ,
      "address":e.address ,}
    }) 
    let header:any[] = []
    let rowData :any[] = [] 
     try{ 
      let eventHead:any = Object.keys(events[0]); 
      eventHead.map((key:any)=>{
        header.push(
          { headerSize: 12/eventHead.length ,label: key ,id: key }
        )
      }) 
     await  res.events.map((d:any)=>{  
      rowData.push( {  
          cols:  [ 
              { colSize: 12/eventHead.length ,label: d.status ,id: "status" },   
              { colSize: 12/eventHead.length ,label: d.start > 0 ? moment(d.start).format("HH:mm DD/MM/YYYY") :'-' ,id: "start" },   
              { colSize: 12/eventHead.length ,label: d.end > 0 ?moment(d.end).format("HH:mm DD/MM/YYYY"):'-' ,id: "end" },   
              { colSize: 12/eventHead.length ,label: d.statusDuration > 0 ?convertMsToTime(d.statusDuration):"-" ,id: "statusDuration" },   
              { colSize: 12/eventHead.length ,label: (d.distance).toFixed(2) ,id: "distance" },   
              { colSize: 12/eventHead.length ,label: d.driver ,id: "driver" },   
              { colSize: 12/eventHead.length ,label: d.maxSpeed ,id: "maxspeed" },   
              { colSize: 12/eventHead.length ,label: d.address ,id: "address" ,lat: d.lat,lng: d.lng},   
            ]    
        })
      })
     }catch(err){

     }
    
      return { table: {header ,rowData }}
  }

  export const setReport0033=async(res:any)=>{
    let header:any =[]
    let rowData:any =[]
    try{ 
      let keysobject:any = Object.keys(res[0]);
      let colsSize =  2
      await  keysobject.map((key:any)=>{
        if(key !== 'lat' && key !== 'lng'){
          header.push(
            { headerSize: colsSize ,label: key ,id: key }
          )
        }
      })   
  
      await res.map((d:any,index:any)=>{
        rowData = [...rowData , {
          cols:[
            { colSize: colsSize ,label: moment(d.start).format("HH:mm DD/MMM/YY") ,id: "start"  },   
            { colSize: colsSize ,label: moment(d.end).format("HH:mm DD/MMM/YY") ,id: "end"  },   
            { colSize: colsSize ,label: d.engineStatus ,id: "engineStatus"  },   
            { colSize: colsSize ,label: d.duration > 0 ?convertMsToTime(d.duration):"-" ,id: "duration"  },  
            { colSize: colsSize ,label: d.driver ,id: "driver"  }, 
            { colSize: colsSize ,label: d.address ,id: "address" ,lat: d.lat,lng: d.lng},     
          ]
        }] 
      })
    }catch(err){

    }

    return {   rowData ,  header   }

  }

  export const setReport0035=async(res:any)=>{
    let header:any =[]
    let rowData:any =[]
    try{
      let keysobject:any = Object.keys(res[0]);
   
      let colsSize =  2.4
      await  keysobject.map((key:any)=>{
        if(key !== 'lat' && key !== 'lng'){
          header.push(
            { headerSize: colsSize ,label: key ,id: key }
          )
        }
      })  
      console.log("header ",header)  
      await res.map((d:any,index:any)=>{ 
        rowData = [...rowData , {
          cols:[
            { colSize: colsSize ,label: moment(d.time).format("HH:mm DD/MMM/YY") ,id: "time"  },
            { colSize: colsSize ,label:  d.duration > 0 ?convertMsToTime(d.duration):"-"   ,id: "duration"  },     
            { colSize: colsSize ,label:  d.address ,id: "address" ,lat: d.lat,lng: d.lng },   
            { colSize: colsSize ,label: d.status ,id: "status"  },   
            { colSize: colsSize ,label: d.driver.length > 0 ?d.driver:"-"   ,id: "driver"  },  
          ]
        }] 
      }) 
    }catch(err){

    } 
    return {   rowData ,  header   } 
  }

  export  const setReport0041=async(res:any)=>{ 
    console.log("res ",res)

    let colsSize = 1 
    let headers = [
      { colSize: 1 ,label: "#",id: "#"  },  
      { colSize: colsSize  ,label: "vehicle" ,id: "vehicle"  },  
      { colSize: colsSize ,label: "inTime" ,id: "inTime"  },  
      { colSize: colsSize ,label: "address In" ,id: "addressIn"  }, 
      { colSize: colsSize ,label: "outTime" ,id: "outTime"  },  
      { colSize: colsSize ,label: "address Out" ,id: "addressOut"  }, 
      { colSize: colsSize ,label: "duration" ,id: "duration"  },  
      { colSize: colsSize ,label: "driver" ,id: "driver"  },    
      { colSize: colsSize ,label: "mileage" ,id: "mileage"  },  
    ]
    let rowsData:any =[]
    await  res.map((d:any,index:any)=>{  
      rowsData.push({
          cols:[
            { colSize: 1 ,label:  index+1,id: "#"  },  
            { colSize: colsSize  ,label: d.vehicle ,id: "vehicle"  },  
            { colSize: colsSize ,label: d.inTime ,id: "inTime"  },  
            { colSize: colsSize ,label: d.addressIn ,id: "addressIn"  }, 
            { colSize: colsSize ,label: d.outTime ,id: "outTime"  },  
            { colSize: colsSize ,label: d.addressOut ,id: "addressOut"  }, 
            { colSize: colsSize ,label: d.duration ,id: "duration"  },  
            { colSize: colsSize ,label: d.driver ,id: "driver"  },    
            { colSize: colsSize ,label: d.mileage ,id: "mileage"  },   
          ]
      })
    })    
    return {   table: {headers ,rowsData }}
  }
  export  const setReport0042=async(res:any)=>{  
    let colsSize = 1 
    let headers = [
      { colSize: 1 ,label: "#",id: "#"  },  
      { colSize: colsSize  ,label: "vehicle" ,id: "vehicle"  },  
      { colSize: colsSize ,label: "Out Time" ,id: "Out Time"  },  
      { colSize: colsSize ,label: "address Out" ,id: "addressOut"  }, 
      { colSize: colsSize ,label: "InTime" ,id: "InTime"  },  
      { colSize: colsSize ,label: "address In" ,id: "addressIn"  }, 
      { colSize: colsSize ,label: "duration" ,id: "duration"  },  
      { colSize: colsSize ,label: "driver" ,id: "driver"  },    
      { colSize: colsSize ,label: "mileage" ,id: "mileage"  },  
    ]
    try{  
    let rowsData:any =[]
    await  res.map((d:any,index:any)=>{  
      rowsData.push({
          cols:[
            { colSize: 1 ,label:  index+1,id: "#"  },  
            { colSize: colsSize  ,label: d.vehicle ,id: "vehicle"  },  
            { colSize: colsSize ,label: d.inTime ,id: "inTime"  },  
            { colSize: colsSize ,label: d.addressIn ,id: "addressIn"  }, 
            { colSize: colsSize ,label: d.outTime ,id: "outTime"  },  
            { colSize: colsSize ,label: d.addressOut ,id: "addressOut"  }, 
            { colSize: colsSize ,label: d.duration ,id: "duration"  },  
            { colSize: colsSize ,label: d.driver ,id: "driver"  },    
            { colSize: colsSize ,label: d.mileage ,id: "mileage"  },   
          ]
      })
    })  
    return {   table: {headers ,rowsData }}
   }catch(err){
    return {   table: {headers ,rowsData:[] }}
   }  
  }
  export  const setReport0043=async(res:any)=>{
    let colsSize = 3 
    let headers = [
      { colSize: 1 ,label: "#",id: "no"  },  
      { colSize: colsSize  ,label: "vehicle" ,id: "vehicle"  },   
      { colSize: colsSize  ,label: "status" ,id: "status"  },   
      { colSize: colsSize  ,label: "address" ,id: "address"  },  
      { colSize: colsSize  ,label: "mileage" ,id: "mileage"  },   
      { colSize: colsSize  ,label: "time" ,id: "time"  },   
    ]
    try{  
     
       colsSize = 3 
      headers = [
        { colSize: 1 ,label: "#",id: "no"  },  
        { colSize: colsSize  ,label: "vehicle" ,id: "vehicle"  },   
        { colSize: colsSize  ,label: "status" ,id: "status"  },   
        { colSize: colsSize  ,label: "address" ,id: "address"  },  
        { colSize: colsSize  ,label: "mileage" ,id: "mileage"  },   
        { colSize: colsSize  ,label: "time" ,id: "time"  },   
      ]
      let rowsData:any =[]
      await  res.map((d:any,index:any)=>{  
        rowsData.push({
            cols:[
              { colSize: 1 ,label: index+1 ,id: "no"  },  
              { colSize: colsSize  ,label: d.vehicle ,id: "vehicle"  },   
              { colSize: colsSize  ,label: d.status  ,id: "status"  },   
              { colSize: colsSize  ,label: d.address ,id: "address"  },  
              { colSize: colsSize  ,label: d.mileage ,id: "mileage"  },   
              { colSize: colsSize  ,label: d.time    ,id: "time"  },   
            ]
        })
      })    
      return {   table: {headers ,rowsData }}
    }catch(err){

      return {   table: {headers ,rowsData:[] }}
    }
  }

  export  const setReport0051=(res:any)=>{
    let color:any = res.color 
    let data = res.data
    let status = res.status 
    let time = res.time 

    let colorsArr = Object.values(color); 
    let dataArr = Object.values(data); 
    let statusArr = Object.values(status); 
    let timeArr = Object.values(time); 

    return {
      color : colorsArr ,
      data: dataArr.map((e)=> { return Number(e)}),
      labels: statusArr ,
      xaxis: timeArr
    }
  }

   
  export  const setReport0052=(result:any)=>{
    let gasIdle = result.filter((e:any)=> e.color === "#ffd433" ||  e.color === "yellow")
    let gasStop = result.filter((e:any)=> e.color === "red")
    let gasMove = result.filter((e:any)=> e.color === "green")
    let gasAll =  result 

    let dataIdle:any[] = []
    let dataStop:any[] = []
    let dataMove:any[] = [] 
    let dataAll:any[] = [] 

    let xCategories:any[] =[]
    gasAll.map((e:any)=> {  
      xCategories.push(  new Date(moment(e.time).add(7,'hour').format()).getTime() )
      dataAll.push([  new Date(moment(e.time).add(7,'hour').format()).getTime(), Number(e.gasolone) ])
    })

    gasIdle.map((e:any)=>{
      dataIdle.push([  new Date(moment(e.time).add(7,'hour').format()).getTime(), Number(e.gasolone) ])
    })
    gasStop.map((e:any)=>{
      dataStop.push([  new Date(moment(e.time).add(7,'hour').format()).getTime(), Number(e.gasolone) ])
    })
    gasMove.map((e:any)=>{
      dataMove.push([  new Date(moment(e.time).add(7,'hour').format()).getTime(), Number(e.gasolone) ])
    })

    let seriesAll= {
      name: 'All',
      type: 'area',
      data: dataAll
    }
    let seriesMove = {
      name: 'Move',
      type: 'scatter',
      data: dataMove
    }
    let seriesIdle = {
      name: 'Idle',
      type: 'scatter',
      data: dataIdle
    }
    let seriesStop = {
      name: 'Stop',
      type: 'scatter',
      data: dataStop
    }

    return {
      xCategories,seriesAll,seriesMove,seriesIdle,seriesStop 
    }
  }

  export  const setReport0053=(result:any)=>{
    let gasIdle = result.filter((e:any)=> e.coler === "#ffd433" ||  e.coler === "yellow")
    let gasStop = result.filter((e:any)=> e.coler === "red")
    let gasMove = result.filter((e:any)=> e.coler === "green")
    let gasAll =  result 

    let dataIdle:any[] = []
    let dataStop:any[] = []
    let dataMove:any[] = [] 
    let dataAll:any[] = [] 

    let xCategories:any[] =[]
    gasAll.map((e:any)=> {
      xCategories.push(  moment(e.time).add(7,'hour').format() )
      dataAll.push([    moment(e.time).add(7,'hour').format(), parseFloat(e.temp) ])
    })

    gasIdle.map((e:any)=>{
      dataIdle.push([    moment(e.time).add(7,'hour').format(), parseFloat(e.temp) ])
    })
    gasStop.map((e:any)=>{
      dataStop.push([    moment(e.time).add(7,'hour').format(), parseFloat(e.temp) ])
    })
    gasMove.map((e:any)=>{
      dataMove.push([    moment(e.time).add(7,'hour').format(), parseFloat(e.temp) ])
    })

    let seriesMove = {
      name: 'Move',
      type: 'scatter',
      data: dataMove
    }
    let seriesIdle = {
      name: 'Idle',
      type: 'scatter',
      data: dataIdle
    }
    let seriesStop = {
      name: 'Stop',
      type: 'scatter',
      data: dataStop
    }
    let seriesAll= {
      name: 'All',
      type: 'area',
      data: dataAll
    }

    return {
      xCategories,seriesAll,seriesMove,seriesIdle,seriesStop 
    }
  }

  export  const setReport0056=(result:any)=>{
    console.log("result ",result)
    let gasIdle = result?.gasolone.filter((e:any)=> e.status === 24)
    let gasStop = result?.gasolone.filter((e:any)=> e.status === 23)
    let gasMove = result?.gasolone.filter((e:any)=> e.status === 7)
    let gasAll =  result?.gasolone 
    let speedAll =  result?.speeds 

    let dataIdle:any[] = []
    let dataStop:any[] = []
    let dataMove:any[] = [] 
    let dataAll:any[] = [] 
    let dataSpdAll:any[] = [] 

    let xCategories:any[] =[]
    gasAll.map((e:any)=> { 
      dataAll = [...dataAll , [ moment(e.time).add(7,'hour').format(), parseFloat(e.gasolone) ]] 
    })
    speedAll.map((e:any)=> {
      xCategories.push(  moment(e.time).add(7,'hour').format() )
      // dataSpdAll.push([    moment(e.time).add(7,'hour').format(), parseFloat(e.speed) ])
      dataSpdAll = [...dataSpdAll, [ moment(e.time).add(7,'hour').format(), parseFloat(e.speed) ]]
    })

    gasIdle.map((e:any)=>{
      dataIdle.push([    moment(e.time).add(7,'hour').format(), parseFloat(e.gasolone) ])
    })
    gasStop.map((e:any)=>{
      dataStop.push([    moment(e.time).add(7,'hour').format(), parseFloat(e.gasolone) ])
    })
    gasMove.map((e:any)=>{
      dataMove.push([    moment(e.time).add(7,'hour').format(), parseFloat(e.gasolone) ])
    })

    let seriesMove = {
      name: 'Move',
      type: 'scatter',
      data: dataMove
    }
    let seriesIdle = {
      name: 'Idle',
      type: 'scatter',
      data: dataIdle
    }
    let seriesStop = {
      name: 'Stop',
      type: 'scatter',
      data: dataStop
    }
    let seriesAll= {
      name: 'All',
      type: 'area',
      data: dataAll
    }
    let seriesSpeedAll= {
      name: 'Speed',
      type: 'line',
      data: dataSpdAll
    }
    return {
      xCategories,seriesAll,seriesMove,seriesIdle,seriesStop ,seriesSpeedAll
    }
  }

  export const setReport0061=(result:any)=>{
    let headerstring = ["เวลาเริ่มต้น","สถานที่เริ่มต้น","เวลาสิ้นสุด","สถานที่สิ้นสุด","ระยะเวลา","ความเร็วสูงสุด"]
    let columns:any = []
    let rowData:any = []
    if(result.length > 0){ 
      let colsSize =   12/headerstring.length 
      headerstring.map((key:any)=>{
        columns.push(
          { headerSize: colsSize,label: key ,id: key }
        )
      })
      result.map((d:any)=>{
        rowData.push({
          cols:[
            { colSize: colsSize ,label: moment(d.timestart).format("HH:mm:ss DD/MM") ,id: "timestart"  },  
            { colSize: colsSize ,label: d.locationStart,id: "locationStart"  }, 
            { colSize: colsSize ,label: moment(d.timeend).format("HH:mm:ss DD/MM") ,id: "timeend"  }, 
            { colSize: colsSize ,label: d.locationEnd  ,id: "locationEnd"  }, 
            { colSize: colsSize ,label: convertMsToTime(d.duration) ,id: "duration"  }, 
            { colSize: colsSize ,label: d.maxSpeed +" km/h" ,id: "maxSpeed"  }, 
          ]
      })
      }) 
    }
    return {columns,rowData}
  }
 

  export const setReport0016=async (result:any)=>{
    console.log("setReport0016 result",result)
    let headerstring = [{en:"status" , th:"สถานะ"},{en:"address", th:"สถานที่ี"},{en:"distance", th:"ระยะทาง"},{en:"driverId", th:"เลขใบขับขี่"},{en:"driverMessage", th:"คนขับ"},{en:"endTime", th:"สิ้นสุด"},{en:"fuel", th:"น้ำมัน"} ,{en:"lat", th:"ละติจูด"},{en:"lon", th:"ลองติจูด"},{en:"maxSpeed", th:"ความเร็วสูงสุด"},{en:"startTime", th:"เริ่มต้น"}]
    let columns:any = []
    let rowData:any = []  
    console.log("result ",result)
    if(result?.report.length > 0){ 
      let colsSize =   12/headerstring.length 
      headerstring.map((key:any)=>{
        columns.push(
          { headerSize: colsSize,label: i18next.language === "th" ? key?.th : key?.en ,id: key?.en }
        )
      })
     await  result?.report.map(async(repday:any)=>{
        let statusstr = ""
        await  repday.summary.map((summ:any,sumi:any)=>{
          if(summ.status === 7) {
            statusstr = t("status_move")
          } else if( summ.status === 24 ) {
            statusstr= t("status_idle")  
          }else if(summ.status === 23){
            statusstr= t("status_stop")  
          } else{statusstr= t("status_offline")   } 
  
          console.log("summ  ",summ.status , statusstr )
          rowData.push({ 
            cols: [
              { colSize: colsSize ,label: statusstr ,id: headerstring[sumi]?.en },  
              { colSize: colsSize ,label: summ.events[0].address.toString()  ,id: headerstring[sumi]?.en  },  
              { colSize: colsSize ,label: summ.distance?.toFixed(2)  ,id: headerstring[sumi]?.en  },  
              { colSize: colsSize ,label: summ.driverId?.toString()  ,id: headerstring[sumi]?.en  },  
              { colSize: colsSize ,label: summ.driverMessage?.toString()  ,id: headerstring[sumi]?.en  },  
              { colSize: colsSize ,label: repday?.date+" " +summ.endTimeStr  ,id:headerstring[sumi]?.en   },  
              { colSize: colsSize ,label: summ.fuel?.toFixed(2)  ,id: headerstring[sumi]?.en  },  
              { colSize: colsSize ,label: summ.lat? summ.lat.toString()  :""  ,id: headerstring[sumi]?.en  },  
              { colSize: colsSize ,label: summ.lon? summ.lat.toString()  :""    ,id: headerstring[sumi]?.en   },  
              { colSize: colsSize ,label: summ.maxSpeed? summ.maxSpeed.toString() :"" ,id:headerstring[sumi]?.en  },  
              { colSize: colsSize ,label: repday?.date+" " +summ.startTimeStr  ,id: headerstring[sumi]?.en },  
            ]
          })
        })
        
      }) 
    }
    return {columns,rowData}
  }

  export default {}
