import { configureStore } from "@reduxjs/toolkit";
import appstateReducer from "./appSlice";
import mapStateReducer from "./mapSlice";
import dashboardReducer from "./dashboardSlice";
import filterReducer from "./filterSlice";
import reportReducer from "./reportSlice";
import menuReducer from "./menuSlice";
import settingReducer from "./settingSlice"
import mdvrReducer from "./mdvrSlice"

export default configureStore({
  reducer: {
    appstate: appstateReducer,
    mapstate: mapStateReducer,
    dashboardState: dashboardReducer,  
    filteredState: filterReducer,
    reportState: reportReducer ,
    menuState: menuReducer,
    settingState: settingReducer,
    mdvrReducer: mdvrReducer
  },
});
