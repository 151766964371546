import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRoute, IonRow, IonText } from "@ionic/react"
import { Device } from "../pages/schema";
import { checkmarkCircle, closeCircle, settingsOutline, trashBinOutline } from "ionicons/icons"; 

import L, {   Map as MapType  } from "leaflet"
import * as Moment from "moment-timezone"
import { extendMoment } from 'moment-range'
import "./css/DevicesTable.css"; 
import { useDispatch } from "react-redux";
import { setPreviewDevice } from "../store/mapSlice"; 
import { getStorage } from "../actions";
import { useEffect, useState } from "react";
import { DeviceStatusNameTime } from "./IconStatus";

const moment = extendMoment(Moment);
moment.locale("th")

interface DevicesTable{
    devices: Device[]
    mapref: MapType | any
    editDevice: (e:any)=> void
}

const DevicesTable=({devices,mapref,editDevice,children}:any)=>{
    const dispatch = useDispatch()
    const [isGuest,setIsGuest] = useState(false)
    const getisguest=async()=>{ 
        const isguest = await getStorage("isGuest")
        setIsGuest(isguest)
    }

    useEffect(()=>{getisguest() },[])
    
    const isExpired=(expire_date:any)=>{  
        const start =  moment(new Date()).add(543,"year") ;
        const end =moment(expire_date); 
        const r1 = moment.range(start.tz('Asia/Bangkok'), end.tz('Asia/Bangkok'));  
        var diff = r1.diff('minute') ; 
      
        return diff > 0 ? false : true
    } 

    return(
    <IonGrid>
        <IonRow>
            <IonCol size="12" >
               {children}
            </IonCol>
            <IonCol size="12" >
                <div className="container-devices-table" >
                <table className="devices-table"   >
                    <tr className="row-header">
                        <th style={{width:"5%"}} className="ion-text-center">#</th>
                        <th style={{width:"7%"}} className="ion-text-center">กลุ่ม</th>
                        <th style={{width:"12%"}}>ทะเบียน</th>
                        <th style={{width:"12.5%"}}>คนขับ</th>
                        <th style={{width:"12.5%"}}>คนขับ2</th>
                        <th >สถานะ</th>
                        <th >หมายเหตุ</th>
                        <th >ครบกำหนด</th>
                        <th style={{width:"22%"}}>สถานที่</th>
                        <th >อัพเดตล่าสุด</th> 
                        <th style={{width:"3%"}}></th> 
                    </tr>
                    {
                        devices && devices.map((d:Device , i:any)=>
                        <tr key={i} className="row-record" >
                            <td className="ion-text-center" ><IonLabel color={"dark" }>{i+1}</IonLabel></td>
                            <td className="ion-text-center" >{d.groupName ? d.groupName : "-"}</td>
                            <td ><IonLabel color={"dark" }>{d.name}</IonLabel></td>
                            <td ><IonLabel color={"dark" }>{d.employee}</IonLabel></td>
                            <td ><IonLabel color={"dark" }>{d.employee2}</IonLabel></td>
                            <td ><IonLabel color={"dark" }>
                                {/* {d.status_name} {d.status_time} */}
                                {/* <DeviceStatusNameTime online={d.online} status={d.status} statusName={d.status_name} statusTime={d.status_time} /> */}
                                <IonLabel color={
                                    d.online === 0 ? "medium" : 
                                    d.status === 7 ? "success" : 
                                    d.status === 23 ? "danger":
                                    d.status === 24 ? "warning": "medium"
                                }>
                                    <IonText >({d.online === 0 ? "Offline":"Online"}) {d.status_name}</IonText> <IonText>{d.status_time}</IonText>
                                </IonLabel>
                            </IonLabel></td>
                            <td ><IonLabel color={"dark" }>{d.note}</IonLabel></td>
                            <td  >
                                <div className="set-center" style={{width:"100%", height:"100%",alignItems:"flex-start"}}  > 
                                    <IonLabel color={"dark" } className="set-center-row   "> 
                                    <IonIcon 
                                        icon={isExpired(d.expiration_date) ?  closeCircle : checkmarkCircle} 
                                        color={isExpired(d.expiration_date)?"danger":"success"}  
                                        style={{marginRight:".2rem"}}
                                    />
                                    {moment(d.expiration_date).format("DD MMMM YYYY")}
                                    </IonLabel>
                                </div> 
                            </td>
                            <th >
                                <IonLabel color={"dark" } 
                                style={{cursor:"pointer"}}
                                onClick={()=>{   
                                    dispatch(setPreviewDevice({active:true, device: d}));
                                    mapref.setView([d.latitude , d.longitude]) }}
                                >
                                    {d.address}
                                </IonLabel></th>
                            <td ><IonLabel color={"dark" }>{ moment(d.event_stamp).format("HH:mm DD/MM/YY")}</IonLabel></td>
                            <td > 
                               { !isGuest &&   <IonButton fill="clear" onClick={(e:any)=>{return editDevice(d)}}  > 
                                    <IonIcon icon={settingsOutline} /> 
                                </IonButton> }
                            </td> 
                        </tr> 
                        )
                    }
                </table> 
             </div>
            </IonCol>
        </IonRow> 
    </IonGrid>
    )
}
export default DevicesTable;

