import { IonButton, IonCol, IonGrid,IonIcon,IonLabel,IonRow, IonText } from "@ionic/react"
import { printOutline } from "ionicons/icons";
import { useEffect } from "react";
import { pdfMake } from "../../lib/pdfMake" 
import { borderCells } from "../../lib/Excels";
import FileSaver from "file-saver";
import * as Excel from "exceljs";
import moment from "moment";
import 'sheetjs-style' 
import { t } from "i18next";
 
const RepFuelUsagedById=({table,detail,orientation}:any)=>{

    useEffect(()=>{
        // console.log("RepFuelUsagedById detail ",detail)
        // console.log("RepFuelUsagedById table ",table)
    },[])

    const printReport=async ()=>{

        let bodyTable:any = [] 
        let headers =await table.header.map((el:any)=>{
             return  {text: el.label  , alignment:'center', bold:true  } 
        })
        table.rows.map((rows:any,i:any)=>{  
            let row = [
                {text: i+1 , alignment:'center' } ,
                {text: rows.date , alignment:'center' } ,
                {text: `${t("distance")} ${rows.consumptionMove.toFixed(2) } (${rows.distance} ${t("kmUnit")} ) `, alignment:'center' } ,
                {text: `${rows.consumptionIdle.toFixed(2) } (${rows.statusTime}) `, alignment:'center' } ,
            ] 
            bodyTable.push(row)
        }) 
        
        bodyTable.unshift(headers)
 
        pdfMake.createPdf({ 
            pageOrientation: orientation, 
            content:[  
                {text: detail.reportName, fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(detail.start).format("DD MMMM YYYY")} - ${moment(detail.end).format(" DD MMMMYYYY")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${detail?.name} `, alignment:'center'}, 
                 
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths: ["10%" , "20%" , "35%" , "35%"] , // headers.map((e:any)=>{return  "auto"}),
                      body: bodyTable  
                    }
                  } 
            ],
            defaultStyle: {
                font: "THSarabunNew",
            }
        }).open()  
    }

    const printExcel=async ()=>{
        try{   
            var options = {
                filename: './streamed-workbook.xlsx',
                useStyles: true,
                useSharedStrings: true
            }; //@ts-ignore 
            let workbook = new Excel.Workbook(options);
            var ws = workbook.addWorksheet( detail?.reportName , {properties:{tabColor:{theme:1}}});
            
            ws.columns =[{width:10},{width: 15},{width: 25},{width: 25},{width: 15},{width: 15},{width: 15},{width: 15},{width: 15},{width: 50}] 
    
            const header = await table?.header.map((e:any)=> { return e?.label})
            ws.mergeCells('A1', 'J1');                      
            ws.getCell("A1").value = detail?.reportName

            ws.mergeCells('A3', 'J3'); 
            ws.getCell("A3").value = `ทะเบียน ${detail?.name}`
            ws.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 

            ws.mergeCells('A4', 'J4'); 
            ws.getCell("A4").value =  `วันที่ ${moment(detail.start).format("DD MMMM   HH:mm")} - ${moment(detail.end).format(" DD MMMM  HH:mm")}`
            ws.getCell("A4").alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
            ws.addRow(['']) 
          
            ws.addRow([''])
            ws.addRow([''])

            ws.addRow(header)
            const rowheader = ws.getRow(1); 
            rowheader.eachCell({includeEmpty: true}, ((cell:any) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
                cell.font = { bold: true ,  family: 4, size: 16, underline: true, }
                cell.border = borderCells
            }));  

            table.rows.map((rows:any,i:any)=>{  
                let row = [
                    i+1  ,
                    rows.date ,  
                    `${t("distance")} ${rows.consumptionMove.toFixed(2) } (${rows.distance} ${t("kmUnit")} ) `  , 
                    `${rows.consumptionIdle.toFixed(2) } (${rows.statusTime}) `  ,
                ] 
                console.log("rows ",rows)
                
                let rowadded = ws.addRow(row)
                rowadded.height =   rowadded.height*2 ;
                rowadded.eachCell({includeEmpty: true}, ((cell:any,column:any) => {  
                    cell.border = borderCells 
                    cell.inset= [0.25, 0.25, 0.35, 0.35]
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
                }));   
            }) 
            ws.addRow([''])  
            ws.getCell(`A${table.rows.length+15}`).value = detail.pttprice
            
            let fileName=`${detail?.name}-${moment(detail.start).format("DD MMMM   HH:mm")} - ${moment(detail.end).format(" DD MMMM  HH:mm")}.xlsx`;  
            workbook.xlsx.writeBuffer() 
            .then(function(buffer:any) { 
                const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                FileSaver.saveAs(data, fileName);
            }); 
 
        }catch(err){
            console.log("errr ",err)
        }
    }


    return(<div className="ion-padding" >   
      <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
        <IonButton onClick={()=>{printExcel()}}  
            mode="ios" fill="clear"  
            style={{position:"relative", top:"1rem", right:"1rem"}}   >
            <IonLabel style={{color:"#12753D", fontSize:".8em"}}> Excel </IonLabel> &nbsp;
            <img src="../assets/icon/excel.svg" style={{width:"1.7rem"}} />
        </IonButton>
        <IonGrid>
            <IonRow> 
                <IonCol size="12" >
                    <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >{detail.reportName}</IonLabel><br/>
                    <IonLabel style={{fontSize:'.8em'}}> ทะเบียน  {detail?.name} </IonLabel> <br/>
                    <IonLabel style={{fontSize:'.8em'}}>
                        <IonText>วันที่ {moment(detail.start).format("DD MMM YYYY HH:mm")}</IonText>
                        <IonText>-</IonText>
                        <IonText>วันที่ {moment(detail.end).format("DD MMM YYYY HH:mm")}</IonText>
                    </IonLabel>
                </IonCol>
            </IonRow><br/> 

        <IonRow> 
            <IonCol size="12" >
                <table style={{width:"100%"}}>
                    <tr>
                        { table?.header.map((e:any)=>  <th key={e.id} className="ion-text-center ion-padding bg-light-shade" style={{width: e.width}}> {e.label} </th> ) } 
                    </tr> 
                    { table?.rows.map((e:any,i:any)=> 
                        <tr key={i} style={{fontSize:".9em"}} className={i%2 > 0 ? "bg-light-tint":"bg-light"} >  
                            <td className="ion-text-center ion-padding-horizontal" style={{padding:"5px"}}> {i+1} </td>
                            <td className="ion-text-center ion-padding-horizontal"> {e.date} </td>
                            <td className="ion-text-center ion-padding-horizontal"> {e.consumptionMove.toFixed(2)} ( {t("distance")} {e.distance}  {t("kmUnit")} ) </td>  
                            <td className="ion-text-center ion-padding-horizontal"> {e.consumptionIdle.toFixed(2)} ( {e.statusTime})</td>   
                        </tr>
                    )}  
                </table>
            </IonCol>
        </IonRow>
     </IonGrid>
    </div>)
}
export default RepFuelUsagedById;