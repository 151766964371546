import { useEffect,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFastTrack, getFocusZone, getGeoZones, getIsMeasure,getLastPoint,   getMapRoute,   getMapRouting,   getOpenStreetView,   getRouteIndex, getStreetView, getStreetViewPosition, getTrackWhilePlay, 
  setFocusZone, setGeozones, setLastPoint, setMapRoute, setMapRouting, setMeasureArea, setMeasureDistance, setMeasurement, setOpenStreetView, setPlayAction, setRouteIndex, setStreetView, setStreetViewPosition } from '../store/mapSlice';
import {  Invalidate, LayersControlMap, MarkerIcon, MarkerQuickTrack, PlayControl, RouteTrackingRealtime, SegmentZoomControl, ToolbarMap } from './LeafletLayers'; 
import { FeatureGroup, LayersControl, MapContainer, Marker, Polygon, Polyline, Popup, Tooltip , useMap, useMapEvents ,GeoJSON} from 'react-leaflet';
import { getBackup, getDeviceSelect, getDevices, getGooggleEnable, getIsGroupDevice } from '../store/appSlice';
import { circle, defaultPin, iconStop, pinend, pinstart, streetviewIcon } from './PublicMarker';
import { calDistance,  getLongdokey,  polygonArea, primary } from '../actions';
import {  IonRow, IonCol, IonIcon, IonLabel, IonButton, IonAvatar, IonChip, useIonActionSheet, useIonLoading, IonPage,  } from '@ionic/react';
import { arrowRedo, closeCircle } from 'ionicons/icons'; 
import MarkerClusterGroup from 'react-leaflet-cluster';  
import { useJsApiLoader } from '@react-google-maps/api'; 
import L , {  Icon, LatLngExpression, Marker as MarkerType, Popup as PopupType, Tooltip as TooltipType  } from 'leaflet';  
import { Geolocation } from '@capacitor/geolocation';
import { t } from 'i18next';
import axios from 'axios';

import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker"
import './leaflet.css' 
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw'; 
import 'react-leaflet-markercluster/dist/styles.min.css';

let max = 0
let playindex =0  
let count = 0

export const RouteQuickTrack=({stop}:any)=>{
    const fastTrack = useSelector(getFastTrack)  
    const limeOptions = { color: primary }  
    const trackPlay = useSelector(getTrackWhilePlay)
    const routeIndex = useSelector(getRouteIndex)  
    const [stopPosition,setStopPosition] = useState({type: "FeatureCollection", features: []})
    const map = useMap()
 
    useEffect(()=>{ 
      console.log("RouteQuickTrack fastTrack ",fastTrack)
      if(fastTrack!==null){ 
        parseToGeoJson(fastTrack.list)
        if(routeIndex === 0  ){
          map?.fitBounds(fastTrack?.bound)
        }
        if(routeIndex){
          //  map?.setView(fastTrack?.bound[routeIndex])

          // panToOffset(map,fastTrack?.bound[routeIndex], [200,0],17)
        }
        if(routeIndex >= fastTrack.bound.length-1){
          return stop(null)
        }
      }
    },[fastTrack,routeIndex])

    const parseToGeoJson=async(list:any)=>{
      let mygeojson:any = {type: "FeatureCollection", features: []}
      await list.filter((e:any)=> e.status === 23 ).map((point:any)=>{ 
        let coordinate = [parseFloat(point.longitude), parseFloat(point.latitude)];
        let properties = {point , ...{ iconStop } };       
        let feature:any = {"type": "Feature", "geometry": {"type": "Point", "coordinates": coordinate}, "properties": properties}
        //@ts-ignore
        mygeojson.features.push(feature);
      }) 
      setStopPosition(mygeojson)
      // this.setState({ stopPosition: mygeojson })
      console.log("mygeojson ",mygeojson)
    }

    const popupContent=(feature:any)=>{ 
      return `  <div style={{maxWidth:"30vw"}} >${feature.properties.point.address}</div>  `
     }
   
    return(<> 
     
      {
          fastTrack!==null ?
          <FeatureGroup>
            {
              fastTrack.bound && fastTrack.bound.length > 0  && <>
              {
               fastTrack.bound.length > 0 ?
               <Polyline pathOptions={limeOptions} positions={fastTrack.bound} />:<></>
              }
              {routeIndex< fastTrack.bound.length && <>
                <MarkerQuickTrack index={routeIndex}/>
                {fastTrack.bound.length >0 &&<Marker icon={pinstart} position={fastTrack.bound[0]} />}
                {fastTrack.bound.length >2 &&<Marker icon={pinend} position={fastTrack.bound[fastTrack.bound.length -1 ]} />}
              </> 
              }
              </>
            }
             <LayersControl position="bottomright" >
              {
                stopPosition.features.length > 0 &&
                <LayersControl.Overlay checked name="Stop Position" >
                  <GeoJSON //@ts-ignore
                    id={'stop-position'} //@ts-ignore
                    data={stopPosition}
                    filled= {true} 
                    pointRadiusMinPixels= {2} 
                    pointRadiusScale= {2000} 
                    getPointRadius={ (f:any) => 11 - f.properties.scalerank }
                    getFillColor={ [200, 0, 80, 180]}
                    //@ts-ignore
                    pointToLayer={  (feature, latlng) => {
                      return L.marker(latlng ,{ icon : iconStop }).bindPopup( popupContent(feature))
                    }} 
                  />    
                </LayersControl.Overlay>
              }
            </LayersControl>
          </FeatureGroup>:
          <></>
        } 
      </>
    )
  }
  
const TrackingMap = () => {  
    const dispatch = useDispatch()
    const [position,setPosition] = useState({ lat: 14.340027, lng:100.586026 })
    const device = useSelector(getDeviceSelect)
    const [zoom,setZoom] = useState(13)
    const isMeasure = useSelector(getIsMeasure)   
    const fastTrack = useSelector(getFastTrack)  
    const isGroup = useSelector(getIsGroupDevice)
    const devices = useSelector(getDevices)  
    const backup = useSelector(getBackup)  
    const [speed,setSpeed] = useState ({name:"4x",val:200})
    const routeIndex = useSelector(getRouteIndex)
    const [interval,setinterval] = useState(0) 
    const [random,setRandom] = useState(0)
    const iconsizes = [
        {
          iconSize: [40, 40], 
          iconAnchor: [15, 50],
          statusiconSize: [15, 15], 
          statusiconAnchor: [0, 58],  
        },
        {
          iconSize: [50, 50], 
          iconAnchor: [15, 60],
          statusiconSize: [15, 17], 
          statusiconAnchor: [0, 68],  
        },
        {
          iconSize: [60, 60],
          iconAnchor: [25, 70],
          statusiconSize: [20, 20], 
          statusiconAnchor: [5, 78],
        },
    ]
   
    useEffect(()=>{  
        setDeviceSelect()
        reciveFasttrack() 
           
    },[ device,isMeasure,fastTrack,playindex,isGroup,zoom]) 

    const randomIconSize = ()=>{ 
      let index = Math.floor(Math.random()* iconsizes.length) 
      return  iconsizes[zoom>17 ?2 : index]
  }

    const setDeviceSelect=()=>{ 
        if(device!==null){ 
             setPosition({ lat:device.latitude , lng: device.longitude }) 
        }
    }
    
    const reciveFasttrack=()=>{
        if(fastTrack !== null){ 
            if( fastTrack.bound.length > 0  ){  
                setPosition({ lat:fastTrack.bound[0][0] , lng: fastTrack.bound[0][1] })  
                 max =  fastTrack.bound.length-1  
              } 
        } 
    }
 
    const play=(e:any)=>{  
        if(routeIndex < fastTrack?.list.length-2){ 
            let interval:any = setInterval(()=>{  
                count+=1 
                dispatch(setRouteIndex(count)) 
            },speed.val)
            setinterval(interval)
            dispatch(setPlayAction("playing"))
        }else{
            stop(null) 
            count=0
            dispatch(setRouteIndex(0))
        }
    }
    const pause=(e:any)=>{
        if(routeIndex<15){
            console.log("please wait ")
        }else{
            console.log("click pause ",e)
            dispatch(setPlayAction("pause")) 
            clearInterval(interval) 
        }
    }
    const stop=(e:any)=>{
        if(routeIndex<15){
            console.log("please wait ")
        }else{
            console.log("click stop ",e)
            count=0 
            dispatch(setPlayAction("stop")) 
            dispatch(setRouteIndex(0)) 
            clearInterval(interval)
        }
    }

    const createClusterCustomIcon = function (cluster:any) {
      return L.divIcon({
        html:  
          `<div class="cluster-inner set-center" >${ cluster.getChildCount()/2  }</div>  `, 
        className: `cluster-outer ${ cluster.getChildCount()/2 >5 ?'large':'small' }  `,
        iconSize: L.point(40, 40, true)
      });
    };
  
       
    return (
    <div  style={{position:"relative",width:'99%'}}> 
    <ToolbarMap />
    {fastTrack&&<PlayControl speed={speed} setSpeed={(e:any)=>{return setSpeed(e)}} play={(e:any)=>{return  play(e)}} pauseAction={(e:any)=>{return  pause(e)}} stopAction={(e:any)=>{return  stop(e)}}/> }
    <SegmentZoomControl zoom={zoom} action={(e:any)=>{setZoom(e)}}/>
    <MapContainer 
      id='map-tracking'   
        center={[ position.lat, position.lng]}
        zoom={zoom}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{borderRadius:"15px" }}
        maxZoom={19}
        ref={async (comp:any)=>{   
            comp?.invalidateSize()
            comp?.on('zoom',(e:any)=> { 
              setZoom(e.target._zoom) 
            })   
        }} 
    >  
        <SelectPlaceToStreetView/>
        <RouteTrackingRealtime   />
        <GeofencesMarker zoom={zoom} />
        <RouteQuickTrack current={routeIndex} stop={stop} />  
          {zoom > 4 && fastTrack === null?<>  
            {
              isGroup ?
              <MarkerClusterGroup   
                  iconCreateFunction={createClusterCustomIcon} 
                  ref={ (markers:any) =>{
                    markers?.on('clusterclick',(a:any)=> {
                      a.layer.zoomToBounds();
                      console.log('cluster length '+a.layer.getAllChildMarkers().length)
                    }); 
                    }
                  }
                  iconAnchor={[25, 70]}
                  disableClusteringAtZoom={10}
                  spiderfyDistanceMultiplier={2}
                  spiderfyOnMaxZoom={false}  
                  showCoverageOnHover= {false}
                  zoomToBoundsOnClick={false} >
                  {
                    backup.filter((device:any)=> device.event_id > 0).map((device:any,index:any)=>
                      <MarkerIcon key={index} device={device} vehicle={device.name}  markersize={randomIconSize()} />
                    )
                  }
                </MarkerClusterGroup>:
                <FeatureGroup>
                  {
                    backup.filter((device:any)=> device.event_id!==null).map((device:any,index:any)=>
                      <MarkerIcon key={index} device={device} vehicle={device.vehicle}  markersize={randomIconSize()} />
                    )
                  }
                </FeatureGroup>
            } 
          </>:<></>}
          {isMeasure && <MeasureDistanceFeature /> } 
        <LayersControlMap  />

        <Invalidate />
    </MapContainer> 
   </div>
 );
}; 
export default TrackingMap;


export const SetActiveArea=(latlng:any, targetZoom:any)=>{
  const map = useMap()
  
  var targetPoint = map.project(latlng, targetZoom).subtract([ window.innerWidth / 2, 0]),
  targetLatLng = map.unproject(targetPoint, targetZoom);

 
  return map.setView(targetLatLng, targetZoom);
}


export const panToOffset=(map:any ,latlng: any, offset: number[] , targetZoom:any ) =>{
 

  var x = map.latLngToContainerPoint(latlng).x - offset[0]
  var y = map.latLngToContainerPoint(latlng).y - offset[1]
  console.log("panToOffset xy ",x , y) 

  var point =map.containerPointToLatLng([x, y])
  console.log("panToOffset point ",point)
  return map.setView(point, targetZoom  )
}

 
export const SelectPlaceToStreetView=()=>{
  const streetview = useSelector(getStreetView)
  const dispatch = useDispatch()
  const [previewpoint , setPreviewPoint] = useState({ lat: 0, lng: 0 })
  const steetPosi = useSelector(getStreetViewPosition) 
  const isStreetview = useSelector(getOpenStreetView) 
   
  const map = useMap()
  
  const mapEvent = useMapEvents({
    click(e) {
      if(streetview){
        console.log("e ",e)
        const latitude = e.latlng.lat
        const longitude = e.latlng.lng 
        const point:any = { lat: latitude , lng: longitude }
        setPreviewPoint(point)
        dispatch(setStreetViewPosition(point))
        map.flyTo(point)
      
      }else{
        dispatch(setStreetViewPosition(null)) 
      }
    }, 
  })
  useEffect(()=>{ 
  },[previewpoint,steetPosi])

  const viewInStreetView=()=>{
    console.log(" isStreetview"+isStreetview , " streetview "+streetview, " steetPosi ",steetPosi) 
    // if(isStreetview){
    //   dispatch(setOpenStreetView(true))
    //   dispatch(setStreetViewPosition(previewpoint)) 
    // }else{
      window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${previewpoint?.lat},${previewpoint?.lng}&heading=-45&pitch=38&fov=80`) 
    // }
  }

  return(<> 
   {streetview && previewpoint.lat > 0 &&   <Marker 
      position={[previewpoint?.lat,previewpoint?.lng]}  
      icon={ streetviewIcon } 
      ref={(mrkStRef)=>{
        mrkStRef?.openPopup()
        mrkStRef?.on("click",(e)=>{ 
          if(isStreetview){
            dispatch(setStreetViewPosition(e.target._latlng))
          }
        })
        mrkStRef?.on("dragend",(e)=>{ 
          setPreviewPoint(e.target._latlng)
          if(isStreetview){
            dispatch(setStreetViewPosition(e.target._latlng))
          }
        })
      }}
      draggable
    >
      <Popup autoPan> 
        <IonLabel className='cursor-pointer' onClick={()=>{viewInStreetView()}} >View in Street view</IonLabel>
      </Popup>
    </Marker> }
  </>)
}

export const MeasureDistanceFeature=()=>{ 
  const [measurement,setMyMeasurement] = useState([{lat:0,lng:0}])
  const isMeasure = useSelector(getIsMeasure) 
  const [distance,setDistance] = useState(0)
  const [routeMeasure,setRouteMeasure]=useState([])
  const lastPoint = useSelector(getLastPoint) 
  const dispatch = useDispatch()
  const map = useMapEvents({
    click(e){
      drawMeasurement(e)
    }
  })

  useEffect(()=>{
    setMyMeasurement([])
    if(!isMeasure){
      setRouteMeasure([])
    }
  },[])
 
  const convertMilesToKm=(miles:any)=>{
    return Math.round(miles  * 1.609344);
  } 

  const convertMilesToMeter=(miles:any)=>{
    return Math.round(miles  * 1609.344);
  } 

  const drawMeasurement=async (e:any)=>{
    if(isMeasure){   
      //@ts-ignore   
      let beforeSpread:any = [...measurement, {lat: e.latlng.lat , lng: e.latlng.lng}]
    
      if(e.latlng !== null && e.latlng !== undefined){//@ts-ignore 
        //if(e.latlng !=  lastPoint){
          dispatch(setLastPoint({lat: e.latlng.lat , lng: e.latlng.lng}))
          dispatch(setMeasurement(beforeSpread))
          let newmeasure: any[] = []
          if(measurement.length >= 0){  
            await  beforeSpread.forEach(async (e:any,index:any)=>{
              newmeasure[index] = [e.lat,e.lng] 
            })//@ts-ignore 
            setRouteMeasure(newmeasure) 
          } 
          setMyMeasurement(beforeSpread)
         

          if(beforeSpread.length > 1){
            let cord1 = {lat: beforeSpread[beforeSpread.length-1].lat , lng :  beforeSpread[beforeSpread.length-1].lng }
            let cord2 =  {lat: beforeSpread[beforeSpread.length-2].lat , lng :  beforeSpread[beforeSpread.length-2].lng }
            let lastDistane = await calDistance(cord1 ,cord2)
            let newDis = distance+lastDistane
            setDistance(newDis)
            dispatch(setMeasureDistance(`${convertMilesToMeter(distance)} Meter (${distance.toFixed(2)} Miles)`)) 
          }
          if(beforeSpread.length > 2){ 
            let lat:any =[]
            let lon:any =[]
            // let points:any = []
            newmeasure.forEach((coord,i)=>{ 
              lat[i] = coord[0]
              lon[i] = coord[1]
            })
             let area = polygonArea(lat,lon) 
            dispatch(setMeasureArea(area)) 
          }
    
        }
     // } 
    }else{
    setMeasurement([{lat:0,lng:0}])
    setRouteMeasure([])
     
      dispatch(setLastPoint({lat:0,lng:0}))
      dispatch(setMeasurement([])) 
    }
  }

  return(
    <FeatureGroup>
       { 
         isMeasure? 
            <FeatureGroup>
              {
                 measurement.map((e,index)=> 
                <Marker key={index} icon={circle} position={[e.lat,e.lng]}></Marker> 
              )}
              {
                 measurement.length >= 3 ?
                <Polygon positions={routeMeasure}></Polygon>:
                <Polyline positions={routeMeasure} ></Polyline>
              } 
            </FeatureGroup> :<></>
          }
    </FeatureGroup>
  )
}

interface GeoJson{
  type: String 
  features: any[]
}
export const GeofencesMarker=({zoom}:any)=>{ 
  const geozones = useSelector(getGeoZones) 
  const zonefocus = useSelector(getFocusZone) 
  const dispatch = useDispatch()
  const maprouting : any = useSelector(getMapRouting)
  const [ionaction] = useIonActionSheet();
  const [setLoading, dimissLoading] = useIonLoading(); 
  const [position , setPosition] = useState<any|null>(null)
  const maproute = useSelector(getMapRoute) 
  const [geoZoneJson,setGeoZoneJson] = useState<GeoJson>({type: "FeatureCollection", features: []}) 
  const [geoJsonGroup,setZoneJsonGroup] = useState<GeoJson[] | []>([])
  const [markerzones,setMarkerZones] = useState<MarkerType[]>([])

  const map = useMapEvents({
    click(e) {  
      if(maproute){ 
        let latlng:LatLngExpression = [e.latlng.lat , e.latlng.lng]  
        setPosition(latlng) 
      }
    }, 
  })
  
  useEffect(()=>{  
    if(zonefocus){  
      map.fitBounds(zonefocus.bound,{padding:[50,-50]})
    }else{ 
    } 
    parseToGeoJson()
  },[position ,zoom ,geozones])



  const clickMapRouting =async (zone:any)=>{
    console.log("clickMapRouting zone ",zone)
    if(maprouting?.start !== null && maprouting?.end !== null ){
        ionaction({
            header: zone.name ,
            cssClass: "actions-markers",
            mode:"ios" ,
            buttons: [
              {
                text: 'ตั้งเป็นต้นทาง', 
                data: {
                  action: 'start',
                },
                handler:async ()=>{
                    console.log("Set Start ")
                    const routing = {
                      start: {
                          name: zone.name ,
                          position: {
                            lat: zone.position[0].lat,
                            lng: zone.position[0].lon
                          }
                      } ,
                      end: maprouting?.end
                  }
                    await dispatch(setMapRouting(routing))
                    getRoute(routing)
                }
              },
              {
                text: 'ตั้งเป็นปลายทาง', 
                data: {
                  action: 'end',
                },
                handler:async ()=>{  
                    console.log("Set End ")
                    const routing = {
                      start: maprouting?.start  ,
                      end: {
                          name: zone.name ,
                          position: {
                            lat: zone.position[0].lat,
                            lng: zone.position[0].lon
                          }
                      }
                  }
                    await dispatch(setMapRouting(routing))
                  getRoute(routing)
                }
              },
            ]
        })
    }else{
        try { 
            const position = await Geolocation.getCurrentPosition({
                enableHighAccuracy: true,
                timeout: 20000,
                maximumAge: 0
            })
            if(position){
                const lat = position?.coords.latitude 
                const lng = position?.coords.longitude
                let routingbegin ={
                    name: "ตำแหน่งของคุณ" ,
                    position: {
                        lat: lat,
                        lng: lng
                    }
                } 
                let routing = { 
                    start: routingbegin ,
                    end: {
                        name: zone?.name ,
                        position: {
                          lat: zone.position[0].lat,
                          lng: zone.position[0].lon
                        }
                    }  
                }
                console.log("routing ",routing)
                await dispatch(setMapRouting(routing))
                getRoute(routing)
            } 
        }catch(err){
            console.log("err ",err)
        } 
    }
}

  const getRoute=async(routing:any)=>{  
    setLoading({message: t('loading')}) 
    const lkey = await getLongdokey({})
    console.log("getRoute lkey ",lkey)
    let allroutes:any = [] 
    const {features , data ,meta} = await axios.get("https://api.longdo.com/RouteService/geojson/route",{
        params:{
            flon: routing?.start?.position?.lng,
            flat: routing?.start?.position?.lat,
            tlon: routing?.end?.position?.lng ,
            tlat: routing?.end?.position?.lat,
            mode:'d',
            type: 17,
            locale:'th',
            timeload: new Date().toISOString() ,
            key:'736fb33b73ca6dd992f9d618cee793c6'
        }
    }).then((res)=>{ 
        console.log("data ",res.data )
        return res.data
    }).catch(err =>{ 
      console.log("err ",err)
      dimissLoading() 
      return {
        features:null , data:null  ,meta:null 
      }
    }) 
    
    if(features){
        let subroutes:any = []
        await features.map((fea:any)=>{
            let coordinates = fea.geometry?.coordinates.map((e:any)=> { return [e[1],e[0]] }) 
            var distance = fea?.properties?.distance  
            subroutes.push({coordinates , distance}) 
            allroutes = allroutes.concat(coordinates) 
            map?.fitBounds(allroutes)  
        } )  
        dispatch(setMapRoute({
            allroutes : allroutes ,
            subroutes: subroutes ,
            data: data ,
            features: features
        }))  
        let el = document.getElementById("container-map-route")
        el?.classList.add("slide-in")

    }
    dimissLoading()
  }
  

   const parseToGeoJson=async()=>{
      let geogroup: GeoJson[] = []
      let mygeojson:any = {type: "FeatureCollection", features: []}
      let mymarkerAll:any = {type: "FeatureCollection", features: []}

      let features: any[] = []
       await geozones.map((point:any,index:any)=>{  
           if(index+1 < geozones.length){ 
                   let coordinate = [parseFloat(point?.longitude), parseFloat(point?.latitude)];
                   let properties = {point , ...{name:point?.name ,  icon:  new Icon({ 
                        iconUrl: `../assets/icon-place/map-icon-${point.icon !== null && point.icon !==  undefined?point.icon: 0}.png`,
                        iconSize: [25, 25],
                        rotationOrigin: "center",
                        iconAnchor: [0, 0], 
                    }) } 
                  };       
                   let feature:any = {"type": "Feature", "geometry": {"type": "Point", "coordinates": coordinate}, "properties": properties} 
                   mygeojson.features.push(feature); 
                  //  console.log(`${index } < 100)`)
                   if(index%100 !== 0){
                    features.push(feature);  
                   }else{
                    geogroup.push({type: "FeatureCollection", features: features }) 

                    features.push(feature);   
                    features = []
                   }
           } 
       })  
       setGeoZoneJson(mygeojson)   
       setZoneJsonGroup(geogroup)
      //  if(markerzones.length ===  markerzones.length){
        setTimeout(()=>{
          map?.on('moveend', placeMarkersInBounds);
         },500)
      //  }
     
   }

  const createMarker=(feature:any, latlng:any)=>{
    let markergroup:MarkerType[] = markerzones
    let markerzone =  L.marker(latlng ,  { icon : feature.properties.icon })
      .bindTooltip( feature.properties.name ,{ offset: [15,  30] ,direction:"bottom" , className: 'leaflet-tooltip-geozone' })
      map.on('zoom',(e)=>{ 
        if(e.target._zoom  > 4){ 
          markerzone?.openTooltip()
        }else{ markerzone?.closeTooltip() }  
      }) 
      markergroup.push(markerzone) 
    return markerzone
  }

  //  map?.on('moveend', placeMarkersInBounds);
   function placeMarkersInBounds() { 
       var mapBounds = map?.getBounds();
       for (var i = markerzones.length -1; i >= 0; i--) {
           var m = markerzones[i];
           var shouldBeVisible = mapBounds?.contains(m.getLatLng());
           if ( !shouldBeVisible || map.getZoom() < 10) {
               map?.removeLayer(m);
           } else if ( shouldBeVisible || map.getZoom() >= 10) {
               map?.addLayer(m);
               m?.openTooltip()
           }
       }
   }

  return(
    <FeatureGroup>
        {zonefocus &&   
            <Polygon positions={zonefocus.bound} fillOpacity={0.5} color='#F5D423' >
              <Tooltip 
                direction="bottom" 
                className="polygon-place-name"
                offset={[0, -30]}
                opacity={1}
                permanent >
                  <div style={{width:'8.5rem'}}>
                    <IonRow>
                      <IonCol size='8' className='set-center' >
                        <IonLabel color={"dark"} >{zonefocus.name}</IonLabel>
                      </IonCol>
                      <IonCol size='4' className='set-center'  > 
                           <IonIcon icon={closeCircle} color='danger' onClick={()=>{dispatch(setFocusZone(null))}} /> 
                      </IonCol>
                    </IonRow>
                  </div>
              </Tooltip>
            </Polygon>}
            <LayersControl position="topright" >
                {
                  geoJsonGroup.map((geojson:GeoJson , index:any)=> 
                    <LayersControl.Overlay checked key={index} name={index+1 +") เขตพื้นที่ / สถานี"}  >
                     <GeoJSON //@ts-ignore
                        id={index+'geozone-position'} //@ts-ignore
                        data={geojson} 
                        //@ts-ignore
                        pointToLayer={  (feature, latlng) => {
                         return createMarker(feature, latlng)
                         
                        }} 
                        />     
                    </LayersControl.Overlay>
                  )
                } 
              </LayersControl>
                 
      {position && <Marker position={position} icon={new Icon({ 
          iconUrl: `../assets/icon-place/map-icon-0.png`,
          iconSize: [25, 25],
          rotationOrigin: "center",
          iconAnchor: [0, 0], 
       })} >
        <Popup position={position}  >
          <div className='set-center' style={{minWidth:"7rem"}}> 
            <IonLabel> {`${position[0].toFixed(5)}  ${position[1].toFixed(5)}`}  </IonLabel>
              <IonChip   color="secondary" onClick={()=>{clickMapRouting({ name: `${position[0]}  ${position[1]}` , position:[ {lat: position[0] , lon: position[1] }  ]   })}} >
                <IonAvatar className="set-center">
                    <IonIcon icon={arrowRedo}  color="dark" />
                </IonAvatar>
               <IonLabel  color="dark">เส้นทาง</IonLabel>
            </IonChip>
         </div>
        </Popup> 
      </Marker> 
      }
 
    </FeatureGroup>
  )
}

 
export const MapStreetView=()=>{ 
  const streetview = useSelector(getStreetView) 
  const [mapsize,setMapSize] = useState({width:10,height:10})
  const [ggKey,setGGKey] = useState("AIzaSyCBZkyGixJtsUWNlF-apgT7HI8W5Cur71I")
  const steetPosi = useSelector(getStreetViewPosition)
  const isStreetView = useSelector(getOpenStreetView)
  const dispatch = useDispatch()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCBZkyGixJtsUWNlF-apgT7HI8W5Cur71I"
  }) 

  const closeStreetView=()=>{
    dispatch(setStreetView(false)); 
    dispatch(setOpenStreetView(false))
  }

  useEffect(()=>{
    //getmapsize()
  },[isStreetView])
 

  return(<div id="streetview-ifram" style={{width:"98vh",height:'100%' ,position:'relative'}}>
      <IonButton 
      onClick={()=>{closeStreetView()}}
      fill='clear' size='small' 
      style={{position:'absolute', zIndex:999 ,top:'1rem',right:'3rem' }} >
        <IonIcon icon={closeCircle} color='primary' />
      </IonButton>
      <div  style={{position:'absolute', zIndex:2 ,width:"100%",height:'100%' }} >
      {streetview&&isStreetView &&steetPosi &&
        <iframe
            width={'100%'}
            height={'100%'}
            style={{ border: 0 }}
            loading="lazy"
            // @ts-ignore
            allowfullscreen={true}
            src={`https://www.google.com/maps/embed/v1/streetview?key=${ggKey}&location=${steetPosi.lat},${steetPosi.lng}&heading=210&pitch=10&fov=35`}
        /> }
      </div>
  </div>)
}

 