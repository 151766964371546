import { faArrowLeft, faBarsStaggered, faRoute, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonLabel, IonIcon, IonPopover, IonList, IonItem, IonHeader, IonToolbar, IonButton, IonRippleEffect, IonMenuToggle, IonMenuButton, IonContent, IonText, IonImg } from "@ionic/react";
import { shareOutline, caretDown, chevronForwardOutline, home, gridOutline, ellipsisVerticalOutline, ellipsisHorizontalOutline, logOutOutline, compassOutline, documentOutline, documentTextOutline, prismOutline, documentAttachOutline, settingsOutline, peopleOutline, notificationsOutline, cogOutline, arrowBackCircleOutline, arrowForwardCircleOutline, languageOutline, checkmarkDone, checkmarkCircle } from "ionicons/icons";
import { useHistory } from "react-router";
import "./css/NavBar.css"
import { DarkModeSegment } from "./AppComponent";
import { useDispatch, useSelector } from "react-redux";
import { getMenuOpen, setMenuOpen, setSignin } from "../store/appSlice"; 
import { menuController } from "@ionic/core";
import { useState } from "react";
import { AppPage, appPages } from "./Menu";
import { useTranslation } from "react-i18next";
import { setStorage } from "../actions";
import i18next, { t } from "i18next";

const NavBar=()=>{
    const history = useHistory()
    const dispatch = useDispatch()
    const menuOpen = useSelector(getMenuOpen)

    const logOut=async()=>{   
        localStorage.removeItem("isSignin")
        localStorage.removeItem("token") 
        dispatch(setSignin(false)) 
    }
    const openMenu=async()=>{  
        await menuController.enable(true,"main-menu")
        await menuController.open('main-menu');
    }

    return( 
        <IonToolbar color={"light"} className="nav-toolbar ion-hide-md-down" > 
        <ul className="nav-bar" > 
            {/* <li className="set-center  ion-hide-md-up"  > 
               <IonMenuButton color={"dark"}></IonMenuButton>
            </li> */}
             <li className="set-center ion-hide-md-up"   > 
{/*                
                    <button  className={`menu ion-activatable ripple-parent  ${menuOpen && "close-menu"}`} >
                       <IonIcon icon={arrowForwardCircleOutline} style={menuOpen ?{transform:"rotate(180deg)"}:{transform:"rotate(0deg)"}}  />  
                        <IonLabel>&nbsp;{t('navMenu')}</IonLabel>
                        <IonRippleEffect></IonRippleEffect>
                    </button>  */} 
                                <IonMenuButton /> 
            </li>  
            <li className="set-center ion-hide-md-down" onClick={()=>{history.push("/home")}}> 
              <button  className="home  ion-activatable ripple-parent " >
                <IonIcon icon={home} mode="ios" />
                <IonLabel>&nbsp;{t('navHome')}</IonLabel>
                <IonRippleEffect></IonRippleEffect>
              </button>
            </li> 
            <li className="set-center ion-hide-md-down"  onClick={()=>{history.push("/dashboard")}} > 
              <button  className="menu  ion-activatable ripple-parent"  >
                <IonIcon icon={gridOutline} />
                <IonLabel>&nbsp;{t('navDashboard')}</IonLabel>
                <IonRippleEffect></IonRippleEffect>
              </button>
            </li>
            <MoreMenu /> 
        </ul> 
        <ul className="nav-bar end ion-hide-md-down"   > 
            <li className="set-center" > 
               <ChangeLang id={"changelang-navbar-menu"} />
            </li>
            <li className="set-center" > 
              <DarkModeSegment /> 
            </li>
            <li className="set-center"  onClick={()=>{logOut()}} > 
              <button  className="menu"  >
                <IonIcon icon={logOutOutline} />
                <IonLabel>&nbsp;{t('navSignout')}</IonLabel>
              </button>
            </li>
        </ul>
    </IonToolbar>
    )
}
export default NavBar;

const languagesOption= [
    { code:"th" , name: "ภาษาไทย" , icon: '../assets/icon/th.png' },
    { code:"en" , name: "English" , icon: '../assets/icon/uk.png'},
]
export const ChangeLang=({style,id}:any)=>{ 
    const [menu,setMenu] = useState(false)
    const { t, i18n } = useTranslation()
   
    const changeLangauge=(lang:any)=>{
        i18n.changeLanguage(lang)  
        setStorage('language',lang)
    }
   
    return(
        <>
        <IonButton fill={style?style:"outline"} color={"dark"} mode="ios" size="small" id={id+window.location.pathname} onClick={()=>{setMenu(!menu)}} >
            <IonIcon icon={languageOutline} />
        </IonButton>
        <IonPopover isOpen={menu} onDidDismiss={()=>{setMenu(false)}} trigger={id+window.location.pathname}  mode="ios" side="bottom" alignment='center'>
            <IonContent  >
            {
                languagesOption.map((lang,index)=>  
                <IonItem key={index} mode="md" className="ion-text-nowrap"  
                        onClick={()=>{changeLangauge(lang.code)}}  button
                >
                    <IonLabel  style={{display:"flex",flexDirection:"row",alignItems:"center"}} >
                      {i18n.language === lang.code && <IonIcon icon={checkmarkCircle} color="success" />}   &nbsp; <IonText>{lang.name}</IonText>
                    </IonLabel>
                    <IonImg src={lang.icon} style={{width:"1.5rem", filter: `grayscale(${i18n.language === lang.code? '0%':'80%'})` }} />
                </IonItem>
                )
            } 
            </IonContent>
        </IonPopover>
        </>
    )
}


export const MoreMenu=()=>{
    const history = useHistory()
    const [more,setMore] = useState(false)
    const [opensub,setOpenSub] = useState(false)

    return(
    <li className="set-center ion-hide-md-down" id="more-menu" > 
        <button onClick={()=>{setMore(true)}} className="menu ion-activatable ripple-parent"  >
            <IonIcon icon={ellipsisHorizontalOutline} style={more ?{transform:"rotate(90deg)"}:{transform:"rotate(0deg)"}} />
            <IonLabel>&nbsp;{t('navMore')}</IonLabel>
            <IonRippleEffect></IonRippleEffect>
        </button>
        <IonPopover isOpen={more} onDidDismiss={()=>{setMore(false)}} trigger="more-menu" className='nest-option' mode="ios"  dismissOnSelect={true} side="bottom" alignment='center'  > 
                {/* {
                    appPages.filter((e)=> e.groups).map((page:AppPage)=> 
                     <SubMenu id={"submenu-"+page.title} setOpen={(e:any)=>{ return setOpenSub(e) }} open={opensub} subpage={page.subpage} />
                    )
                } */}
            <IonList className='more-menu-list' style={{padding:'0px'}}>
                {
                    appPages.map((page:AppPage, index:any)=> 
                    <IonItem key={index} mode="ios" className="menu-page" id={"submenu-"+page.title} button={true} detail={false} onClick={()=>{
                        page.groups ? setOpenSub(true) :  history.push(page.url)
                    }} > 
                        <IonLabel className="menu-item" >  
                            <IonIcon icon={page.icon} style={{ marginRight:'.5rem', fontSize:"large" }} /> 
                            <span style={{fontSize:"small"  }} >{page.title}</span>  
                        </IonLabel> 
                        <IonIcon icon={ page.groups ?ellipsisHorizontalOutline :  chevronForwardOutline} style={{fontSize:'.8em'}} color="medium" slot="end" />
                        
                        { page.groups &&  <SubMenu id={"submenu-"+page.title} setOpen={(e:any)=>{ return setOpenSub(e) }} open={opensub} subpage={page.subpage} /> }
                    </IonItem> 
                    )
                } 
            </IonList> 
        </IonPopover>
    </li>
    )
}

const SubMenu=({id,subpage,setOpen,open}:any)=>{
    const history = useHistory()

    return( 
        <IonPopover trigger={id} isOpen={open} mode="ios" side="right" slot="end" triggerAction="click" onDidDismiss={()=>{return setOpen(false)}}>
            <IonContent>
                <IonList className='more-menu-list' style={{padding:'0px'}}>
                    {subpage.map((page:AppPage)=>
                        <IonItem mode="ios" className="menu-page" button={true} detail={false} onClick={()=>{ history.push(page.url)}} > 
                            <IonLabel className="menu-item" >  
                                {page.ionicicon ?
                                 <IonIcon icon={page.icon} style={{ marginRight:'.5rem', fontSize:"large" }} /> :
                                 <FontAwesomeIcon  icon={page.icon} style={{ marginRight:'.5rem', fontSize:"large" }}  />    
                                } 
                                <span style={{fontSize:"small"  }} >{page.title}</span>  
                            </IonLabel> 
                            <IonIcon icon={chevronForwardOutline} style={{fontSize:'.8em'}} color="medium" slot="end" />
                        </IonItem>
                    )}
                </IonList>
            </IonContent>

        </IonPopover>
    )
}

const ServiceMenu=()=>{
    return(
    <IonPopover trigger="nested-trigger" className='nest-option' dismissOnSelect={true} side="right" alignment='start'  triggerAction='hover'> 
        <IonList className='services-list' style={{padding:'0px'}}>
            <IonItem button={true} detail={false} onClick={()=>{ }}>
               <IonLabel>Server 1</IonLabel>  
                <IonIcon icon={shareOutline} slot='end' />
           </IonItem>
            <IonItem button={true} detail={false} onClick={()=>{ }}>
                <IonLabel>Server 2</IonLabel>  
                <IonIcon icon={shareOutline} slot='end' />
           </IonItem>
           <IonItem button={true} detail={false} lines='none' onClick={()=>{ }}>
                <IonLabel>Server 3</IonLabel>  
                <IonIcon icon={shareOutline} slot='end' />
           </IonItem> 
       </IonList> 
    </IonPopover>
    )
}
 