import { IonCol, IonContent,  IonLoading,  IonPage, IonProgressBar, IonRow, IonSplitPane } from '@ionic/react'; 
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStorage, allDevices, geofences, setStorage, userSetting, allGeometry, menuFilter } from '../actions';
import { DevicesContainer ,DevicesModals } from '../components';  
import { CustomNotify, IconShare } from '../components/AppComponent';   
import { DialogDevice } from '../components/OptionDialogs';
import  { MapStreetView } from '../leaflet/TrackingMap';
import { getIsSignin, getLoading, getMenuOpen, getWindowWidth, setBackupDevices, setDarkMode, setDefaultMap, setDevices, setGoogleEnable, setIsGroupDevice, setLoading, setProfile, setShowLicense, setSignin } from '../store/appSlice';
import { getOpenStreetView, getStreetView, getStreetViewPosition, setDeviceBound, setGeozones } from '../store/mapSlice';
import { useTranslation } from 'react-i18next';  
import toast, { Toaster } from 'react-hot-toast';
import { setAddress, setCommand, setContentFilter, setEmei, setExpireDate, setIconFilter, setMessage, setName, setNavigation, setPhoneNumber, setQuickTrack, setRealtimeTrack, setSensorBattery, setSensorCardName, setSensorCardNumber, setSensorFuel, setSensorGPS, setSensorGSM, setSensorMile, setSensorPTO, setSensorSpeed, setSensorTemperature, setShare, setStatusName, setUpdateTime } from '../store/filterSlice';
import appconf from "../api.config.json"

import '../theme/style.css';  
interface zones { 
    "geoId": number,
    "name":  string,
    "canDelete": boolean,
    "icon": number,
    "position":   { lat: number , lon:number}[]
}

// map.on('moveend, placeMarkersInBounds);

// placeMarkersInBounds();

// function placeMarkersInBounds() {
//     var mapBounds = map.getBounds();
//     for (var i = myMarkers.length -1; i >= 0; i--) {
//         var m = myMarkers[i];
//         var shouldBeVisible = mapBounds.contains(m.getLatLng());
//         if (m._icon && !shouldBeVisible) {
//             map.removeLayer(m);
//         } else if (!m._icon && shouldBeVisible) {
//             map.addLayer(m);
//         }
//     }
// }


const Home: React.FC = () => {
  const dispatch = useDispatch() 
  const loading = useSelector(getLoading) 
  const {t,i18n}= useTranslation()
  const streetview = useSelector(getStreetView) 
  const steetPosi = useSelector(getStreetViewPosition)
  const isStreetView = useSelector(getOpenStreetView)
  const menuOpen = useSelector(getMenuOpen)  

  const getSetting=async()=>{ 
    /* Setting Lang */
    let locaLang = getStorage('language')  
    if(locaLang !== null && locaLang !== undefined){  
      i18n.changeLanguage(locaLang) 
    }else{ 
      i18n.changeLanguage('th') 
      localStorage.setItem('language','th') 
    }  
  
    const token = getStorage('token')
    if(token){  
      const settings = await userSetting()  
      const showLice =  settings.showName ==="1" ? true :false 
      dispatch(setShowLicense( showLice ))
      dispatch(setIsGroupDevice(settings.groupDevice))
      setStorage('defaultTile',settings.defaultMap)   
      dispatch(setDefaultMap(settings.defaultMap)) 
      dispatch(setGoogleEnable(settings.googleMapEnabled === 1?true:false))
    }else{ 
      dispatch(setShowLicense( false ))
      dispatch(setIsGroupDevice(false))
      setStorage('defaultTile',false)   
      dispatch(setDefaultMap("Longdo")) 
      dispatch(setGoogleEnable(false))
    }
 
  }
 

  const getPtofile=async()=>{
    let prof = await getStorage('profile') 
    dispatch(setProfile(prof)) 
  }
  
  const getDevices=async()=>{
    dispatch(setLoading(true)) 
    let token = await getStorage("token") 
    if(token){ 

      let result = await allDevices({}) //devicesAll({user_id: parseInt(user_id) })
      console.log("result ",result)
      await dispatch(setDevices(result))
      await dispatch(setBackupDevices(result)) 
      let bound:any = []
      await result.filter((device:any)=>device.event_id !== null).map((device:any)=>
        bound = [...bound , [parseFloat(device.latitude),parseFloat(device.longitude)] ]
      )
      dispatch(setDeviceBound(bound))
    }
    dispatch(setLoading(false))
  
  }
 
  const preloader=()=>{
    if (document.readyState === 'complete') {
      dispatch(setLoading(false)) 
    } else { 
      dispatch(setLoading(true)) 
      // Remove the event listener when component unmounts
      return () =>{  dispatch(setLoading(false)) }
    }
    setTimeout(()=>{
      loading!==false ?  dispatch(setLoading(false)) : dispatch(setLoading(false)) 
    },5000)
  }

  const singin=async()=>{
    const isSign = await getStorage("isSignin")
    dispatch(setSignin(isSign))
  }

  const closeTab=()=>{
    const handleTabClose =(event:any) => {
      event.preventDefault(); 
      console.log('beforeunload event triggered'); 
      return   (event.returnValue =   'Are you sure you want to exit?');
    
    } 
  } 

  useEffect(()=>{
    closeTab()
    addMarginMain()
    getPtofile()
    preloader()
    getSetting()
    getDevices()
    dispatch(setLoading(false))  

    const getZone=async()=>{   
      try{ 
        let zones:zones[]  = await allGeometry()   
        let newzones:any = []  
        if(typeof zones === 'object'){ 
        zones?.map((zone:any,index:any)=>{
          let points = zone.position.map((el:any)=> {return [el.lat,el.lon]})
          newzones[index] = {...zone,...{points:points}}
        }) 
        dispatch(setGeozones(newzones))
        }
      }catch(err){
        // console.log("err ",err)
      }
    }
    getZone()
    singin() 
    
  },[streetview,isStreetView,menuOpen,loading])

  const addMarginMain=async()=>{
    // const main = document.getElementById("main")
    // main?.classList.add("margin-main")
  }
 
    
  // const firebaseMessaging = useFirebaseMessaging({
  //   vapidKey:"BE0JWLK75rgXI8GQUV0KPYgD2pqHQFciLa9MkiKdDjZfRcm5fA34mopPZ5oYGzSVgymF6CLFYnslpENsVg7FHAg",
  //   forceSupport:false,
  //   onFcmRegistrationToken: (fcmRegistrationToken: string) => {
  //     console.log("fcmRegistrationToken ",fcmRegistrationToken)
  //   },
  //   onMessage: (message) => {
  //     console.log(`Received foreground message`, message)
  //     const snackMessage = message?.notification?.title 
  //   },
  // })
  const getMenuFilter=async()=>{
    const menufilter= await menuFilter({});
    menufilter.sort((a:any, b:any) =>  a.type - b.type)  
    let icon = menufilter.find((e:any)=> e.type ===10 || e.type === 110)
    let name = menufilter.find((e:any)=> e.type ===11 || e.type === 111)
    let imei = menufilter.find((e:any)=> e.type ===12 || e.type === 112)
    let address = menufilter.find((e:any)=> e.type ===13 || e.type === 113)
    let stsName = menufilter.find((e:any)=> e.type ===14 || e.type === 114)
    let updateTime = menufilter.find((e:any)=> e.type ===15 || e.type === 115)
    let nav = menufilter.find((e:any)=> e.type ===16 || e.type === 116)
    let expire = menufilter.find((e:any)=> e.type ===17 || e.type === 117)
    let phone = menufilter.find((e:any)=> e.type ===18 || e.type === 118)
    let fuel = menufilter.find((e:any)=> e.type ===19 || e.type === 119)
    let speed = menufilter.find((e:any)=> e.type ===20 || e.type === 120)
    let battery = menufilter.find((e:any)=> e.type ===21 || e.type === 121)
    let gsm = menufilter.find((e:any)=> e.type ===22 || e.type === 122)
    let gps = menufilter.find((e:any)=> e.type ===23 || e.type === 123)
    let mile = menufilter.find((e:any)=> e.type ===24 || e.type === 124)
    let temp = menufilter.find((e:any)=> e.type ===25 || e.type === 125)
    let driverMess = menufilter.find((e:any)=> e.type ===26 || e.type === 126)
    let driverId = menufilter.find((e:any)=> e.type ===27 || e.type === 127)
    let quickTrack = menufilter.find((e:any)=> e.type ===28 || e.type === 128)
    let share = menufilter.find((e:any)=> e.type ===29 || e.type === 129)
    let message = menufilter.find((e:any)=> e.type ===30 || e.type === 130)
    let trackRealtime = menufilter.find((e:any)=> e.type ===31 || e.type === 131)
    let pto = menufilter.find((e:any)=> e.type ===32 || e.type === 132)
    let cmd = menufilter.find((e:any)=> e.type ===33 || e.type === 133)

    console.log("icon ",icon)
    dispatch(setIconFilter({card: icon.card ,list: icon.list ,type:icon.type }))
    dispatch(setName({card: name.card,list: name.list ,type: name.type })) 
    dispatch(setEmei({card: imei.card,list: imei.list ,type: imei.type })) 
    dispatch(setStatusName({card: stsName.card,list:stsName.list ,type: stsName.type })) 
    dispatch(setUpdateTime({card:updateTime.card,list:updateTime.list ,type: updateTime.type})) 
    dispatch(setAddress({card:address.card,list:address.list ,type: address.type})) 
    dispatch(setNavigation({card:nav.card,list:nav.list  ,type: nav.type})) 
    dispatch(setExpireDate({card:expire.card,list:expire.list ,type: expire.type})) 
    dispatch(setPhoneNumber({card:phone.card,list:phone.list ,type: phone.type})) 
    // dispatch(setRatePoint({card:e.card,list:updateTime.list})) 
  
    dispatch(setSensorFuel({card: fuel.card,list: fuel.list ,type: fuel.type}))   
    dispatch(setSensorSpeed({card: speed.card,list: speed.list ,type: speed.type}))  
    dispatch(setSensorBattery({card: battery.card,list: battery.list ,type: battery.type}))  
    dispatch(setSensorGSM({card: gsm.card,list: gsm.list ,type: gsm.type }))    
    dispatch(setSensorGPS({card: gps.card,list: gps.list ,type: gps.type }))    
    dispatch(setSensorPTO({card: pto.card,list: pto.list ,type: pto.type }))    
    dispatch(setSensorMile({card: mile.card,list: mile.list ,type: mile.type  }))  
    dispatch(setSensorTemperature({card: temp.card,list: temp.list ,type: temp.type }))    
    dispatch(setSensorCardName({card: driverMess.card,list: driverMess.list ,type: driverMess.type }))  
    dispatch(setSensorCardNumber({card: driverId.card,list: driverId.list   ,type: driverId.type}))   
  
    dispatch(setQuickTrack({card: quickTrack.card,list: quickTrack.list  ,type: quickTrack.type }))   
    dispatch(setShare({card: share.card,list: share.list  ,type: share.type }))   
    dispatch(setMessage({card: message.card,list: message.list   ,type: message.type }))   
    dispatch(setCommand({card: cmd.card,list: cmd.list   ,type: cmd.type  }))   
    dispatch(setRealtimeTrack({card: trackRealtime.card,list:trackRealtime.list  ,type: trackRealtime.type })) 
  }

  useEffect(() => {
    (async () => {   
      let fcmToken = await getStorage("fcm_token")
      // if(!fcmToken){
      //   let token =  await requestForToken(); 
      //   if(token){
      //   setStorage('fcm_token',token)   
      //   firebaseMessaging.init()

        // const regitered =  navigator.serviceWorker.register('/firebase-messaging-sw.js')
        //   .then(function(registration) { 
        //         onMessage(messaging, (payload:any) => {  
        //             var song = new Audio();
        //             song.src = '../../assets/sounds/horn_car_2.wav';
        //             song.play();
        //             toast.custom((t) =>( 
        //               <CustomNotify t={t} title={payload.notification.title} message={payload.notification.body} icon={"https://www.w3schools.com/howto/img_avatar.png"} /> ),
        //               {position:'top-right' ,id: payload.notification.notificationId } 
        //             ); 
        //             //registration.showNotification(payload.data.title, { body: payload.data.body });
        //         });
        //       return navigator.serviceWorker.ready.then(async function(registration:any) {
        //         registration.pushManager.subscribe({userVisibleOnly: true})
        //         .then( (subscription:any)=> {
        //           console.log('Subscribed for push:', subscription.endpoint);
        //         })
        //         .catch( (error:any)=> {
        //           console.log('Subscription failed:', error);
        //         });
        //       });
        //     })
        //     .catch(function(error) {
        //       console.log('Service worker registration failed:', error);
        //     }); 
        //   }
        // }
        getMenuFilter()
        let contFil:any = []
        contFil = await getStorage('content') 
        dispatch(setContentFilter(contFil)) 
    })();

    navigator.geolocation.getCurrentPosition((coord)=>{})
     
   
  }, [ ])

  return (
    <IonPage >  
        <DialogDevice />
        <Toaster />
      <IonContent fullscreen> 
       <IonLoading 
            isOpen={loading}
            onDidDismiss={() => dispatch(setLoading(false))}
            message={'Please wait...'}  
            mode='ios'
       /> 
          <IonRow >    
              <IonCol  style={{padding:'0px'}}>
                <div  className='ion-hide-sm-down' ><DevicesContainer/></div>
                <div  className='ion-hide-sm-up' ><DevicesModals/></div> 
                {/* <TrackingMap /> */}
              </IonCol>
              {streetview&&isStreetView&&steetPosi && <IonCol sizeLg='3' sizeXl='4'  style={{padding:'0px'}} >
                <MapStreetView/>
              </IonCol>}
            
            </IonRow>   
        <IconShare/> 
      </IonContent> 
    </IonPage>
  );
};

export default Home;

const InitializingFirebaseMessaging=()=>{

  return(
    <IonProgressBar type="indeterminate" color="primary"></IonProgressBar>
  )
}

const CopyRegistrationToken=({fcmRegistrationToken}:any)=>{
  console.log("fcmRegistrationToken ",fcmRegistrationToken)
 return(<></>)
}

export const AuthCheck=()=>{
  const dispatch = useDispatch()
  const isSinin = useSelector(getIsSignin) 
  // const [cookies, setCookie] = useCookies(['token']);

  const signout=()=>{ 
      dispatch(setSignin(false))
      setTimeout(()=>{ 
          window.location.pathname = 'signin'
      },200) 
  
  } 

  useEffect(()=>{  
    const authenSignin=async()=>{
      let isSign =await getStorage("isSignin")
      let token = await getStorage("token")
      let isSigninParse = JSON.parse(isSign||"false")
      dispatch(setSignin(isSigninParse))

      let appVersion = await getStorage('appVersion')  

      
      // if(isSinin === false){
      //   signout()
      // } 
      // console.log("token ",cookies)
      if(!token ){ 
        dispatch(setSignin(false))
        setStorage('isSignin', false)
        setTimeout(()=>{ 
            window.location.pathname = 'signin'
        },200) 
      }
      if(appVersion && appVersion != appconf.versionNumber){ 
        localStorage.removeItem("token")
        dispatch(setSignin(false))
        setStorage('isSignin', false)
        setTimeout(()=>{ 
            window.location.pathname = 'signin'
        },200)
      }
    }
    authenSignin()
 
  },[])

  return(<></>)
}
