import React, { useEffect, useRef, useState } from "react"
import "./css/DevicesContainer.css"
import "./css/ContainerHistory.css"
import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonPopover, IonRippleEffect, IonRow, IonSelect, IonSelectOption, IonSpinner, IonToolbar, useIonLoading, useIonToast } from "@ionic/react"
import { checkmarkCircle, chevronBack, chevronForward, closeCircle,   colorFilterOutline,   ellipseOutline,   map,   pause, play } from "ionicons/icons"
import { DateRange,  DeviceSearchPopup,  DevicesList, DropDownDevice } from "./AppComponent"
import { useDispatch, useSelector } from "react-redux"
import { getPlayAction, getRouteId, getRouteIndex, getRouteList, getRouteSelected, getViewRourtSelect, setControlPosition, setHistoryMapPosition, setPlaySize, setRouteID, setRouteIndex, setRouteList, setRouteSelected, setViewRouteSelect } from "../store/mapSlice" 
import { colourStyles, routeHistory, toastFail } from "../actions"
import moment from "moment"
import { getBackup, getDateEnd, getDateStart , getOS, getSearchSelect, setSearchSelect } from "../store/appSlice"
import sha1 from "sha1"
import { ChromePicker  } from 'react-color';
import { SwiperSlide,Swiper, useSwiper } from "swiper/react" 
import 'swiper/css';
import { RouteDragable } from "./DevicesContainer"
import PastRouteMap from "../leaflet/PastRouteMap"
import { useHistory } from "react-router" 
import Select  from 'react-select'
import { Device } from "@capacitor/device"
 

export const ContainerHistory=(props:any)=>{
    const  routes = useSelector(getRouteList)
    const datestart = useSelector(getDateStart)
    const dateend = useSelector(getDateEnd)
    const device = useSelector(getSearchSelect)
    const dispatch = useDispatch()
    const [color,setColor] = useState("#FD5F06")
    const [isSearch,setIsSearch] = useState(false)
    const [toastWarning] = useIonToast();
    const [show,setShow] = useState(false)
    const playingId  = useSelector(getRouteId)
    const routeSelected = useSelector(getRouteSelected)
    const viewSelect =useSelector(getViewRourtSelect)
    const [loading, setLoading] = useState(false)
    const [modalMap,setModalMap] = useState(false)
    const history = useHistory()
    const [vehihle,setVehicle] = useState({device_id:0 ,value: 0,name:"Select"})
    const [select,setSelect] = useState() 

    useEffect(()=>{
         
    },[routeSelected,datestart,dateend,device,viewSelect,playingId])

    const findRoute=async ()=>{
        console.log("props?.vehicle ",props.vehicle)
        if(!isSearch && props?.vehicle !== null){ 
            setLoading(true)
            setIsSearch(true)
            let start =  new Date(moment(datestart).zone(0).format()).getTime()
            let end = new Date(moment(dateend).zone(0).format()).getTime()
            let deviceId = props?.vehicle.device_id
            
            let routelist = await routeHistory(
                {
                    deviceId: deviceId,
                    date:  moment(datestart).format("DD/MM/YYYY"),
                    start: moment(datestart).format("YYYY-MM-DD HH:mm:ss"),
                    end: moment(dateend).format("YYYY-MM-DD HH:mm:ss"),
                    dateEnd: moment(dateend).format("DD/MM/YYYY")
                }
            )
            console.log("routelist ",routelist)
            let bound:any = [] = await routelist.list.map(  (el:any) =>{
                return [el.latitude ,el.longitude]
            })
            
            let routeid = await sha1(`start:${start},end:${end},id:${deviceId},create:${moment().format()}`)
                 if(routelist.list.length >0){
                    let listRoute:any = routes
                    listRoute = [...listRoute,{
                        _id : routeid ,
                        name: props?.vehicle.name ,
                        route: bound,
                        list: routelist.list,
                        color: color,
                        show: true ,
                        count: routelist.count,
                        datetime_start: routelist.datetime_start,
                        datetime_stop: routelist.datetime_stop ,
                        speed_max: routelist.speed_max ,
                        time_all: routelist.time_all,
                        distance_all: routelist.distance_all,
                        typeId:  props?.vehicle.deviceIconId !== null? props?.vehicle.deviceIconId :1
                    }]
                
                    dispatch(setRouteList(listRoute))
                    dispatch(setSearchSelect(null))
                    setLoading(false)
                }else{
                    toastWarning({
                        message:"Not found route on your date selected !!",
                        ...toastFail
                    }) 
                } 
                setIsSearch(false)
                setLoading(false)
        }else{
            toastWarning({
                message:"Please select device and search route again !!",
                ...toastFail
            }) 
        } 
    } 

    return(
        <div className={props.myClass}   > 
           <IonLoading mode="ios" message="Please wait..."  isOpen={loading} />
           <IonGrid>
            <IonRow>
                <IonCol size="12" >
                    <Swiper 
                        draggable={routeSelected?true:false} 
                        onSlideChange={(e) => console.log('slide change',e)}
                        onSwiper={(swiper) => console.log(swiper)}
                        allowTouchMove={false}
                        style={{height:"65vh"}}
                        >
                        <SwiperSlide >
                            <IonGrid>
                                <IonRow>
                                    {/* <IonCol size="11" >
                                        <DropDownDevice  vehihle={vehihle} setVehicle={(e:any)=>{setVehicle(e) }} /> 
                                    </IonCol> */}
                                    <IonCol size="11"  style={{paddingLeft:'0px',paddingBottom:'0px'}}>
                                        {props.children} 
                                    </IonCol>
                                    <IonCol size="1" className="set-center ion-text-center" >
                                        <IonButton id="color-trigger" fill="clear" onClick={()=>{setShow(true)}} >
                                            <IonIcon icon={colorFilterOutline} style={{color: color,cursor:"pointer"}}  />
                                            <IonPopover   className="popover-color" trigger="color-trigger" size="auto" alignment="start">
                                                <IonContent>
                                                    <ChromePicker color={color} onChange={(e)=>{setColor(e.hex) }}  />
                                                </IonContent>
                                            </IonPopover>
                                        </IonButton>  
                                    </IonCol>
                                </IonRow>
                                <DateRange padInput={".5rem"} />
                                <IonRow>  
                                    <IonCol size="12" className="ion-no-padding" > 
                                        <IonButton mode="ios" expand="block" onClick={()=>{findRoute()}} >{isSearch?<IonSpinner></IonSpinner>:<></>} &nbsp; Search </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>  

                            <div className="history-list-content ion-padding" >
                                {routes.map((e:any,index:any)=>
                                <CardHistory key={index} detail={e} index={index} size={routes.length} mobileMap={(e:any)=>{ setModalMap(e) ;console.log("mobileMap e ",e)}} />
                                )} 
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <RouteSelectContent />
                        </SwiperSlide> 
                    </Swiper>
                    
                  <IonModal isOpen={modalMap}  onIonModalDidDismiss={()=>{setModalMap(false)}}>
                    <IonHeader className="header ion-no-border">
                        <IonToolbar >
                            <IonButtons slot="end" >
                                <IonButton  onClick={()=>{setModalMap(false)}} > 
                                    <IonIcon icon={closeCircle} /> 
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                      <PastRouteMap  history={history} /> 
                    </IonContent>
                  </IonModal>
                </IonCol>
            </IonRow>
           </IonGrid>
        </div>
    )
} 
 
export const CardHistory=(props:any)=>{
    const detail  = props.detail
    const routes = useSelector(getRouteList)
    const playing  = useSelector(getRouteId)
    const [check,setCheck] = useState(true)
    const [removing,setRemoving] = useState(false) 
    const dispatch = useDispatch() 

    const removeRoute=(id:any)=>{
        dispatch(setRouteSelected(null))
        dispatch(setRouteID(""))
        setRemoving(true) 

        setTimeout(()=>{
            let listRoute:any = routes
            listRoute = listRoute.filter((e:any)=> e._id !== id)
            dispatch(setRouteList(listRoute))
            setRemoving(false)
        },500)
        
    }

    const hideRoute=async(id:any,isshow:any)=>{
        let listRoute:any[] = routes
        let updatelist  = listRoute
        let select 
        let update
        let index = 0

        select = listRoute.find((e:any)=> e._id === id)
        index = listRoute.findIndex((e:any)=> e._id === id)
        update  =  {...select,...{show:isshow}}

        updatelist = listRoute.map( obj => { 
            if (obj._id === id) {
                return     { ...obj ,...{show: isshow}}
             }else{
                return obj
             }
        })  
        let newlist =   updatelist
         console.log("updatelist ",newlist)
          
        setTimeout(()=>{
            dispatch(setRouteList(newlist))
        },100)
    }
    const playRoute =(e:any)=>{
        console.log("e ",e)
        dispatch(setControlPosition({x: e.clientX, y:e.clientY}))
        dispatch(setRouteID(props.routID))
    }
    const clickRoute=(e:any)=>{
        console.log('latlng ',e )
        if(e && e!== undefined ){
            dispatch(setHistoryMapPosition({ lat: e.latitude , lng: e.longitude },) )
        }
    }

    const openmobileMap=async(e:any)=>{
       let info = await Device.getInfo() 
       console.log("info ",info)
       if(info.platform !== 'web'){ 
        return props.mobileMap(e)
       }
    }
 
  
    return(
        <> 
        <div className={`
        ${  props.size===1?"one":
            props.size >1 && props.index === 0 ?"top":
            props.size >1 && props.index === props.size-1?"bottom":
            "normal "
        } card-history `
              }
            style={{cursor:'pointer'}}
            onClick={()=>{clickRoute( detail.list[0] )}} 
        >
           
            <IonRow  > 
                <IonCol size="10" className="set-center" style={{flexDirection:"row",justifyContent:'flex-start'}} > 
                    <IonCheckbox class="check-route"  aria-label=""  checked={detail.show} onIonChange={(e)=>{hideRoute(detail._id,e.detail.checked)}} ></IonCheckbox>  
                    <IonLabel  style={{fontSize:'.8em',fontWeight:'bold',marginLeft:'1rem'}} > 
                        {detail.name}<br/>   
                    </IonLabel>
                </IonCol>
                <IonCol size="2" className="ion-text-right" >
                    {
                        removing ? <IonSpinner name="crescent"></IonSpinner> :
                        <IonIcon icon={closeCircle} color="danger" onClick={()=> removeRoute(detail._id)} /> 
                    }
                </IonCol> 
                <IonCol size="4" className="set-center"  style={{alignItems:"flex-start"}} > 
                    <IonLabel className="card-title" style={{marginBottom:'.5rem'}}>Distance</IonLabel> <sub >{detail.distance_all}</sub>
                </IonCol>
                <IonCol size="4" className="set-center"  style={{alignItems:"flex-start"}} > 
                    <IonLabel className="card-title" style={{marginBottom:'.5rem'}}>Duration</IonLabel>  <sub>{detail.time_all}</sub>
                </IonCol>
                <IonCol size="4" className="set-center" style={{alignItems:"flex-end"}} >
                    {playing === detail._id ?
                     <PlayingButton mobileMap={(e:any)=>{return openmobileMap(e)}} />:
                     <PlayButton routID={detail._id} size={detail.count} mobileMap={(e:any)=>{return openmobileMap(e)}}/>
                    } 
                </IonCol>
            </IonRow>
            {
                playing === detail._id &&
                <IonRow style={{marginBottom:'1rem'}}>
                    <IonCol size="4" className="set-center" style={{alignItems:"flex-start"}}>
                        <IonLabel className="card-title" style={{marginBottom:'.5rem'}}>Start: </IonLabel> 
                         <sub>{moment(detail.datetime_start).format("HH:mm DD/MM/YY")}</sub>
                    </IonCol>
                    <IonCol size="4" className="set-center" style={{alignItems:"flex-start"}}>
                        <IonLabel  className="card-title" style={{marginBottom:'.5rem'}}>End: </IonLabel>
                         <sub>{moment(detail.datetime_stop).format("HH:mm DD/MM/YY")}</sub>
                    </IonCol>
                    <IonCol size="4" className="set-center" style={{alignItems:"flex-start"}}>
                        <IonLabel className="card-title" style={{marginBottom:'.5rem'}}>Speed max: </IonLabel>
                        <sub> {detail.speed_max} km/h</sub>
                    </IonCol>
                </IonRow>
            }

        </div>
        </>
    )
}

export const PlayingButton=(props:any)=>{ 
    const dispatch = useDispatch()
    const os = useSelector(getOS)
    const swiper = useSwiper()
    const pauseRoute =()=>{
        dispatch(setRouteID(""))
    }

    const slideToMap=()=>{
        // dispatch(setViewRouteSelect(true))
        swiper?.slideNext()
        if( os === "andoid" || os== "ios" ){
            swiper?.slideNext()
        }
    }

    return(
        <div className="set-center" style={{fontSize:".7em",flexDirection:"row" }} > 
            <IonButton mode="ios" color={"primary"} size="small" className="ion-hide-sm-up"  style={{fontSize:"1em"}} onClick={()=>{return props.mobileMap(true) }}>
                <IonIcon  icon={map}></IonIcon>  
            </IonButton>
            <IonButton mode="ios" color={"primary"} size="small"  style={{fontSize:"1em"}} onClick={()=>{pauseRoute()}} ><IonIcon slot="start" icon={pause}></IonIcon> Pause </IonButton>
            <IonButton mode="ios" color={"primary"} size="small"  
              onClick={()=>{slideToMap()}}
              style={{fontSize:"1em", width:'2rem'}}  >
                <IonIcon icon={chevronForward} />
            </IonButton>  
        </div>
    )
}



export const PlayButton=(props:any)=>{ 
    const dispatch = useDispatch()
    const swiper = useSwiper()
    const os = useSelector(getOS)
    const routes = useSelector(getRouteList) 

    const playRoute =async(e:any)=>{
        console.log("playRoute ", props)
        const find = await routes.find((e:any)=>e._id ===props.routID)
        dispatch(setControlPosition({x: e.clientX, y:e.clientY}))
        dispatch(setRouteID(props.routID))
        dispatch(setPlaySize(props.size)) 
        dispatch(setRouteSelected(find))
        // dispatch(setViewRouteSelect(true))
        swiper?.slideNext()
        console.log("os ",os)
        // if( os === "andoid" || os== "ios" ){
        //     swiper?.slideNext()
        //   }
        //   swiper?.slideNext()
    }
    return(
        <IonButton mode="ios" color={"secondary"} size="small" style={{fontSize:".7em"}} onClick={(e)=>{playRoute(e);return props.mobileMap(true) }}><IonIcon slot="start" icon={play}></IonIcon>Play</IonButton>
    )
}


const RouteSelectContent=()=>{
    const dispatch = useDispatch()
    const route = useSelector(getRouteSelected)
    const viewRoute = useSelector(getViewRourtSelect)
    const swiper = useSwiper()
    const routeIndex = useSelector(getRouteIndex)
    const playAction = useSelector(getPlayAction)
      
    useEffect(()=>{
        if(route){
            swiper?.slidePrev()
        }  
    },[route,playAction])

    const selectEvent=(index:any)=>{
        dispatch(setRouteIndex(index))
    }

    const backButton=()=>{ 
        try{ 
            // dispatch(setViewRouteSelect(false))
            swiper?.slidePrev();
            swiper?.slideTo(0)
        }catch(err){
            console.log(" swiper ",swiper.activeIndex)
            console.log("err ",err)
        }
    }

    return(<div style={{width:'100%',height:'100%'}} >
        <IonGrid>
            <IonRow style={{marginBottom:'.5rem'}}>
                <IonCol size="1.5" >
                    <IonButton 
                    fill="clear"
                    size="small" 
                    onClick={()=>{ backButton()}} >
                        <IonIcon icon={chevronBack} />
                    </IonButton>
                </IonCol>
                <IonCol size="10.5" className="set-center" style={{alignItems:'flex-start'}}>
                    <IonLabel style={{marginBottom:'.3rem'}}><strong>{route?.name}</strong></IonLabel>
                    {route&&<sub>Start {moment(route?.datetime_start).format("HH:mm DD/MM/YY")} to {moment(route?.datetime_stop).format("HH:mm DD/MM/YY")}</sub>}
                </IonCol> 
            </IonRow> 
            {route&&<IonRow className="ion-justify-content-end"> 
                <IonCol size="3.5" className="set-center"  style={{alignItems:"flex-start"}} > 
                    <IonLabel className="card-sub-title" style={{marginBottom:'.5rem'}}>Distance</IonLabel> 
                    <strong  className="card-sub-det">{route?.distance_all}</strong>
                </IonCol>
                <IonCol size="3.5" className="set-center"  style={{alignItems:"flex-start"}} > 
                    <IonLabel className="card-sub-title" style={{marginBottom:'.5rem'}}>Duration</IonLabel>  
                    <strong className="card-sub-det">{route?.time_all}</strong>
                </IonCol> 
                <IonCol size="3.5" className="set-center" style={{alignItems:"flex-start"}}>
                    <IonLabel className="card-sub-title" style={{marginBottom:'.5rem'}}>Speed Max </IonLabel>
                    <strong className="card-sub-det"> {route?.speed_max} km/h</strong>
                </IonCol>
            </IonRow>}
            <br/>
           {route && <IonRow>
                <IonCol size="12" > 
                    <RouteDragable list={route?.list} />
                </IonCol>
            </IonRow>
            }
            <div className="route-list-container">
                {
                    route?.list.map((list:any,index:any)=>
                        <ListEvents key={index} playindex={routeIndex} selfindex={index} list={list} swiper={swiper} selectEvent={(e:any)=>selectEvent(e)} /> 
                    )
                }
            </div>
        </IonGrid>
    </div>)
}

export const ListEvents=({playindex,list,swiper,selectEvent ,selfindex}:any)=>{ 
    const playAction = useSelector(getPlayAction)
    useEffect(()=>{ 
    },[playindex, playAction])

    return(  <div  onClick={()=>{return selectEvent(playindex)}} className={`route-list cursor-pointer ${selfindex==playindex?"current":""}` } 
        ref={(el:any) =>{ if(playAction==="playing"&&swiper.activeIndex=== 1 ){el?.scrollIntoView({top: 5,behavior:'smooth'}); }}}
        >
            <IonRow>
                <IonCol size="1" className="set-center" >
                    <IonIcon 
                    icon={selfindex==playindex?checkmarkCircle:ellipseOutline} 
                    color={selfindex==playindex?"warning":"medium"} />
                </IonCol>
                <IonCol size="1" className="set-center">
                    <div style={{  width:'1.1rem',height:'1.1rem',transform:`rotate(${list.heading}deg)`}} className={ `marker-status-divicon   ${ 
                        list.status===7? 'marker-move': 
                        list.status===23?'marker-stop': 
                        list.status===24?'marker-idle':'marker-stop'
                    }`}></div>
                </IonCol>
                <IonCol size="4" className="set-center" style={{alignItems:'flex-start'}} > 
                     <IonLabel className="status-name" color={ 
                        list.status === 7 ?"success": 
                        list.status === 24 ? "warning" : 
                        list.status === 23 ? "danger" :"medium"} 
                    >
                        <span className="status-name" >
                            {list.status === 7 ?"MOVE" :
                             list.status === 23?"STOP" : 
                             list.status === 24?"IDLE"  : "STOP"
                        }</span> 
                    </IonLabel>
                    <IonLabel className="status-time">{moment(list.event_stamp).format("HH:mm:ss DD/MM")}</IonLabel>
                </IonCol>
                <IonCol size="6" >
                    <IonLabel class="address">{list.address.length>50?`${list.address.substring(0,50)}..`: list.address}</IonLabel>
                </IonCol>
            </IonRow>
        </div>
    )
}

