import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals'; 
import {I18nextProvider} from "react-i18next"; 
import i18next from "i18next";
import en from './translate/en.json'
import th from './translate/th.json'   

import store from './store/store'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie';
import { unregister } from './serviceWorkerRegistration';
 

  
i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'th',                              // language to use
  resources: {
      en: {  translation: en , },
      th: { translation: th , },
  },
});

unregister();
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <CookiesProvider >
      <I18nextProvider i18n={i18next}>   
        <Provider store={store}>
          <App /> 
        </Provider>
      </I18nextProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
