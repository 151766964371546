import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow, IonText } from "@ionic/react"
import { table } from "console"
import { TableDistance, TableDistanceRange } from "../../components/ReportComponent/ReportTable"
import { useEffect, useState } from "react"
import { printOutline } from "ionicons/icons"
import { pdfMake } from "../../lib/pdfMake" 
import { ExportExcel } from "../../components/ReportComponent/ReportComponent"
import { borderCells } from "../../lib/Excels"
import * as Excel from "exceljs";
import FileSaver from "file-saver"
import axios from "axios"
import { dowloadRepDistance } from "../../actions"


const RepDistanceRange =({details,table,origin}:any)=>{
    const printReport=()=>{
        let bodyTable:any = []  
        let headers = table.theaders.map((el:any)=>{ 
             return  {text: el.label.toUpperCase()  , alignment:'center', bold:true  } 
        })
        // headers.unshift({text: "ทะเบียน"  , alignment:'center', bold:true  })
        // headers.unshift({text: "ลำดับ"  , alignment:'center', bold:true  }) 

        table.trows.map((rows:any,index:any)=>{  
            let row =  rows?.distances.map((dis:any)=>{ 
                return  {text:dis  , alignment:'center' }  
            })
            row.unshift({text: rows.name  , alignment:'center' }  ) 
            row.unshift({text: index+1  , alignment:'center' }  ) 
          
            bodyTable.push(row)
        })  
        bodyTable.unshift(headers)
        console.log("bodyTable ",bodyTable)
        const widths =  headers.map((e:any , i:any)=>{
            if(i === 0){
                return 10
            }else{
                return 35
            }
        })
        pdfMake.createPdf({ 
            pageOrientation: 'landscape',
            watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
            content:[  
                {text: details?.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${ details.start } - ${ details.end } `, alignment:'center'},
                // {text:`ทะเบียนรถ  ${details?.vehicle} `, alignment:'center'}, 
                {text:""},
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths: widths,
                      body: bodyTable  
                    }
                  } 
            ],
            defaultStyle: {
                font: "THSarabunNew",
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text: details?.reportName ,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
        },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
        }).open()

    }

    useEffect(()=>{  
    },[])

    const exportExcel=async ()=>{ 
        console.log("origin ",origin)
        let body= origin 
        let name =  details?.reportName
        let description = `วันที่ ${ details.start } - ${ details.end } `
         const excel = await dowloadRepDistance(body,name,description )  
        FileSaver.saveAs(excel, `${details?.reportName}.xlsx`); 
    }
 
    
    return(
    <div className="ion-padding" >    
      <IonGrid>
            <IonRow className="ion-justify-content-center" > 
             
                <IonCol size="12" >
                    <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >{details?.reportName}</IonLabel><br/>
                    {/* <IonLabel style={{fontSize:'.8em'}}>ทะเบียน {details.vehicle} </IonLabel> <br/> */}
                    <IonLabel style={{fontSize:'.8em'}}>
                        <IonText>วันที่ {details.start} </IonText>
                        <IonText>-</IonText>
                        <IonText> วันที่ {details.end}</IonText>
                    </IonLabel>
                    <div onClick={()=>{exportExcel()}}  style={{border:"1px solid #DDD",width:"fit-content",borderRadius:"7px" , marginTop:".5rem"}} > <ExportExcel />  </div>
                </IonCol>
                <IonCol size="12" >
                    <br/>
                    {table && <TableDistanceRange headers={table.theaders} rowsData={table?.trows}  />}
                    
                </IonCol>
            </IonRow><br/>
        </IonGrid>
    </div>
    )
}
export default RepDistanceRange

 