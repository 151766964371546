import { TileLayer, Marker,   FeatureGroup, Tooltip } from "react-leaflet"
import { useState, useEffect } from "react"
import { MapContainer } from "react-leaflet"
import MarkerClusterGroup from "react-leaflet-cluster"
import { useSelector } from "react-redux"
import { getIsGroupDevice, getShowLicebse } from "../store/appSlice"
import { Icon, DivIcon } from "leaflet"


export const MapGroupDevices=(props:any)=>{  
    const [position,setPosition] = useState({ lat: 14.340027, lng:100.586026 })    
    const [zoom,setZoom]=useState(16) 
    const isGroup = useSelector(getIsGroupDevice)
    
    
      useEffect(()=>{  
        console.log("MapGroupDevices ",props) 
        setTimeout(()=>{
            setZoom(17)
        },300)
         console.log("isGroup ",isGroup)
      },[position,isGroup ]) 
      
  
      return(
        <div style={{width:"100%"}}>  
        <MapContainer
          id="map-show-name"  //@ts-ignore 
          center={[position.lat , position.lng]}
          zoom={zoom} 
          zoomControl={false}  
          ref={(map)=>{  
            map?.invalidateSize()      
          }}  
          style={{borderRadius:'15px'}}
        >  
         <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
  
  {
    isGroup ?  
        <MarkerClusterGroup
         ref={ (markers:any) =>{
            markers?.on('clusterclick',(a:any)=> {
              a.layer.zoomToBounds();
            });
          }
         }
         disableClusteringAtZoom={17}
         spiderfyDistanceMultiplier={2}
         spiderfyOnMaxZoom={false}  
         showCoverageOnHover= {false}
         zoomToBoundsOnClick={false} > 
            <Marker position={[position.lat , position.lng]} icon={
              new Icon({
                iconUrl: "../assets/icon/orange-marker.png",
                iconSize: [40, 40],
                iconAnchor: [20, 20],
            })
            }>   
            </Marker> 
            <Marker 
            //@ts-ignore
            rotationAngle={0}
            rotationOrigin="center" 
            icon={ new DivIcon({
                className: `marker-status-divicon marker-stop `,
                iconSize: [20, 20], 
                iconAnchor: [10, 30],  
            })} 
            position={[position.lat , position.lng]}
                
            />
         </MarkerClusterGroup>
           :
        <FeatureGroup>
           <Marker position={[position.lat , position.lng]} icon={
              new Icon({
                iconUrl: "../assets/icon/orange-marker.png",
                iconSize: [40, 40],
                iconAnchor: [20, 20],
            })
            }>   
            </Marker> 
            <Marker 
            //@ts-ignore
            rotationAngle={0}
            rotationOrigin="center" 
            icon={ new DivIcon({
                className: `marker-status-divicon marker-stop `,
                iconSize: [20, 20], 
                iconAnchor: [10, 30],  
            })} 
            position={[position.lat , position.lng]}
                
            />
        </FeatureGroup>
   }

      </MapContainer>
      </div>
    )
  } 



  export const MapShowName=(props:any)=>{  
    const [position,setPosition] = useState({ lat: 14.340027, lng:100.586026 })  
    const [findLocate,setFindLocate] = useState(false)  
    const [zoom,setZoom]=useState(16) 
    const showname = useSelector(getShowLicebse)
    
    
      useEffect(()=>{  
      //  setLocation()  
      setTimeout(()=>{
        setZoom(17)
      },300)
      },[position,showname ]) 
      
  
      return(
        <div style={{width:"100%"}}>  
        <MapContainer
          id="map-show-name"  //@ts-ignore 
          center={[position.lat , position.lng]}
          zoom={zoom} 
          zoomControl={false}  
          ref={(map)=>{  
            map?.invalidateSize()      
          }}  
          style={{height: props.height,borderRadius:'15px'}}
        >  
         <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
  
            <Marker position={[position.lat , position.lng]} icon={
              new Icon({
                iconUrl: "../assets/icon/orange-marker.png",
                iconSize: [40, 40],
                iconAnchor: [20, 20],
            })
            }>  
             {showname? <Tooltip   
              direction="bottom"  permanent  opacity={1}
              className="marker-place-name"
              offset={[0,20]}>
               <small> เลขทะเบียนอุปกรณ์ </small>
            </Tooltip>:<></> }
            </Marker> 
      </MapContainer>
      </div>
    )
  } 


