import { TileLayer, Marker,   FeatureGroup, Tooltip, useMap, useMapEvents, Polyline, Popup, Circle } from "react-leaflet"
import { useState, useEffect, useMemo, useRef } from "react"
import { MapContainer } from "react-leaflet" 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenRuler, faPencil, faXmark } from "@fortawesome/free-solid-svg-icons"
import { IonButton, IonIcon, IonImg, IonItem, IonLabel, IonText, useIonToast } from "@ionic/react"
import { useDispatch, useSelector } from "react-redux"
import { getRouteSet, getRouteDraw, setRouteDraw, setRouteSet } from "../store/mapSlice"
import { circleWhite, squreRotate } from "./PublicMarker"
import conf from "../api.config.json" 
import { faTrashCan } from "@fortawesome/free-regular-svg-icons"
import { calDistance } from "../actions"
import toast, { Toaster, resolveValue } from 'react-hot-toast';
import { Map as MapType, Marker as MarkerType} from "leaflet"
import { copyOutline, copySharp } from "ionicons/icons"
 
const SetRouteMap=({isAdd,accept ,cratemapref}:any)=>{  
    const [position,setPosition] = useState({ lat: 14.340027, lng:100.586026 })  
    const [zoom,setZoom]=useState(16) 
    const [readyDraw,setReadyDraw] = useState(false)
    const [edit,setEdit] = useState(false)
    const [showRadar,setShowRadar] = useState(false)
    const routeset = useSelector(getRouteSet)
    const dispatch = useDispatch()
    const [mapref,setMapRef] = useState<MapType| null>()
    
    useEffect(()=>{   
      setTimeout(()=>{
        setZoom(17)
      },300)
    },[position,edit,routeset ]) 
      
  
    return(
     <div style={{width:"100%" }}>  
        {(isAdd || routeset) && <>
          <button className={`btn-ready-draw ${readyDraw && "active"} set-center`} onClick={()=>{setReadyDraw(!readyDraw)}} > 
              <IonLabel  > <FontAwesomeIcon icon={faPenRuler} /> </IonLabel>
          </button>
          {readyDraw &&<button className={`btn-edit-draw ${edit && "active"} set-center`} onClick={()=>{setEdit(!edit)}} > 
              <IonLabel  > 
                <FontAwesomeIcon icon={faPencil} /> 
              </IonLabel>
          </button>}
          {readyDraw &&<button className={`btn-del-draw   set-center`} onClick={()=>{dispatch(setRouteDraw([]));dispatch(setRouteSet(null)) }}  > 
              <IonLabel color={"light"}> 
                <FontAwesomeIcon icon={faTrashCan} /> 
              </IonLabel>
          </button>}
          {readyDraw &&<button className={`btn-show-radar ${showRadar && "active"}   set-center`} onClick={()=>{setShowRadar(!showRadar)}}  > 
              <IonLabel color={"light"}> 
                <IonIcon icon={"../assets/icon/radar.svg"}  />
              </IonLabel>
          </button>}
        </>}

        <MapContainer
          id="map-setroute"  //@ts-ignore 
          center={[position.lat , position.lng]}
          zoom={zoom} 
          zoomControl={false}  
          ref={(map)=>{  
            map?.invalidateSize()      
            if(map){
              setMapRef(map)
              cratemapref(map)
            }
          }}  
          style={{borderRadius:'15px'}}
        >  <MapInvalidate/>
         <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          /> 
            {(isAdd && readyDraw) && <DrawSetRoute isEdit={edit} edit={(e:any)=>{setEdit(e)}} accept={accept} showRadar={showRadar} />}
            {routeset && <RouteSet accept={accept} showRadar={showRadar} />}
      </MapContainer>
    </div>
    )
  } 
export default SetRouteMap;

interface Route {
  lat:any ,
  lng:any
}
const RouteSet=({showRadar}:any)=>{
  const map = useMap()
  const routeset = useSelector(getRouteSet)
  const [isEdit,edit] = useState(false)
  const dispatch = useDispatch()
  const [routeDraw , setRouteDraw] = useState<any>([])
  const [accept,setAccept] = useState(200)
  const [routeseting,setRouteseting] = useState(null)

  useEffect(()=>{ 
    if(routeset){
      setRouteDraw(routeset?.route)
      setAccept(routeset?.acceptDistance)
      if(routeseting === null ){ 
        map.fitBounds(routeset?.route)
        setRouteseting(routeset)
      }
    }else{
      setRouteseting(null)
    }

  },[map,routeset])

  const dragMarker=async(previous:any,latlng:any,index:any,marker:MarkerType)=>{  
    let editroute:{ lat:any,lon:any,order:any }[] = routeset?.route  
    let cord1 :any
    let cord2 =   {lat: latlng.lat , lng: latlng.lng}
  console.log("routeDraw[index+1] ",editroute[index+1])
    if(index === 0){
      cord1 =  {lat: latlng.lat , lng: latlng.lng}
      cord2 =     {lat: editroute[index+1].lat  , lng: editroute[index+1].lon}

    }else if(index > 0 && index < editroute.length-1){
      cord1 =  {lat: latlng.lat , lng: latlng.lng}
      cord2 = {lat: editroute[index+1]?.lat, lng: editroute[index+1]?.lon } // routeDraw[index+1]  
    }else if(index >= editroute.length-1){
      cord1 =   {lat: editroute[editroute.length-2].lat, lng: editroute[editroute.length-2]?.lon} //routeDraw[routeDraw.length-2] 
      cord2 =  {lat: latlng.lat , lng: latlng.lng} 
    }  

    let lastDistane =   calDistance(cord1 ,cord2)
    let km = Math.round(lastDistane  * 1609.344); 

    if(km >= Number(accept)){
      toast.error(` ${km} เมตร เกินระยะทาง ${accept} เมตรที่ยอมรับได้ `,{id:"accept-fail"})
      marker.setLatLng(previous)
    }else{  
      let routeEdited:any =await editroute.map((el,i)=>{
        if(i!==index){
          return el
        }else{ 
          return {...el , ...{lat:  latlng.lat , lon:latlng.lng ,}} // [ latlng.lat , latlng.lng ]
        }
      })  
       setRouteDraw(routeEdited) 
       let alldis = await calAllDistance()
       let updateroute  = {...routeset , ...{route: routeEdited ,allDistance :alldis}}
      console.log("routeEdited ",updateroute)
      dispatch(setRouteSet(updateroute))
    } 
  }

  const removeMarker=async(index:any)=>{
    let editroute:Route[] = routeDraw 
    let routeEdited:any =await editroute.map((el,i)=>{
      if(i!==index){
        return el
      }else{
        return null
      }
    }) 
    routeEdited = routeEdited.filter((e:any)=>e!==null)
    setRouteDraw(routeEdited)  
    let updateroute  = {...routeset , ...{route: routeEdited  }}
    dispatch(setRouteSet(updateroute))
  }
   
  const calAllDistance=async()=>{
    let alldis = 0
    await routeDraw.map((e:{lat:any , lon:any},index:any)=> {
      if(index < routeDraw.length-1){ 
        let cord1 = {lat: e.lat, lng: e.lon}
        let cord2 = {lat: routeDraw[index+1]?.lat , lng : routeDraw[index+1]?.lon}
        let lastDistane =   calDistance(cord1 ,cord2)
        let km = Math.round(lastDistane  * 1609.344); 
        alldis += km
      }
    })
    return alldis
  }

  return(
    <FeatureGroup>
      {routeset && <Polyline positions={routeset.route.map((e:any)=>{return [e.lat,e.lon]})} color={conf.color} /> }
      {
        routeset?.route.map((position:any, index:any)=><FeatureGroup>
          {showRadar && <Circle center={[position.lat,position.lon]} radius={accept} key={index}  /> }
          <Marker  
            position={[position.lat,position.lon]}  
            icon={!isEdit?circleWhite:squreRotate} 
            draggable={isEdit}  
            ref={(mark)=>{
              mark?.on("dragend",()=>{
                let previous = [position.lat,position.lon]
                dragMarker(previous ,mark.getLatLng(),index,mark)
              })
            }}>
              <Popup>
                <div style={{width:'9rem'}} >
                  <IonItem mode="ios" className="popup-markroute-menu" button onClick={()=>{return edit(!isEdit?true:false)}} > 
                    {!isEdit?  
                     <IonLabel  class="ion-text-wrap" ><FontAwesomeIcon icon={faPencil} /> &nbsp;&nbsp;  Edit </IonLabel>:
                     <IonLabel  class="ion-text-wrap" color={"danger"} ><FontAwesomeIcon icon={faXmark} /> &nbsp;&nbsp;  Cancle </IonLabel> }
                  </IonItem>
                  <IonItem mode="ios"   button className="popup-markroute-menu"  onClick={()=>{removeMarker(index)}} >  
                     <IonLabel  class="ion-text-wrap"><FontAwesomeIcon icon={faTrashCan} />&nbsp;&nbsp; Remove </IonLabel>
                  </IonItem>
                  <IonItem lines="none" button onClick={()=>{ navigator.clipboard.writeText(`${position.lat},${position.lon}`);}}   >
                    <IonLabel  class="ion-text-wrap" >
                     <IonIcon icon={copyOutline} mode="ios" /><br/>
                     <IonText  style={{fontSize:".8em"}}>  {(position.lat).toFixed(5)}{(position.lon).toFixed(5)} </IonText>
                    </IonLabel> 
                    
                 
                  </IonItem>
                </div>
              </Popup>  
            </Marker> 
        </FeatureGroup>)
      }  
      
    </FeatureGroup>
  )
}

const DrawSetRoute=({isEdit,edit,accept,showRadar}:any)=>{
    interface Route {
      lat:any ,
      lng:any
    }
    const routeDraw = useSelector(getRouteDraw)
    const dispatch = useDispatch() 
   //const [toast,setToast] = useIonToast()
    const map =  useMapEvents({
        async click(e) { 
            let lastPosition = {lat:e.latlng.lat , lng:e.latlng.lng}
            let addPositon = [...routeDraw, lastPosition] 

            if(routeDraw.length > 1){
              let cord1 = {lat: routeDraw[routeDraw.length-1].lat , lng :  routeDraw[routeDraw.length-1].lng }
              let cord2 =  {lat:e.latlng.lat , lng:e.latlng.lng}
              let lastDistane = await calDistance(cord1 ,cord2)
              let km = Math.round(lastDistane  * 1609.344); 
              if(km> Number(accept)){
                toast.error(` ${km} เมตร เกินระยะทาง ${accept} เมตรที่ยอมรับได้ `)
              }else{
                dispatch(setRouteDraw(addPositon)) 
              }
            }else{
              dispatch(setRouteDraw([...routeDraw, lastPosition] )) 
            }
        }, 
    })
    const dragMarker=async(latlng:any,index:any)=>{ 
      let editroute:Route[] = routeDraw  
      let cord1 :any
      let cord2 =   {lat: latlng.lat , lng: latlng.lng}

      if(index === 0){
        cord1 =  {lat: latlng.lat , lng: latlng.lng}
        cord2 =  routeDraw[index+1] 
 
      }else if(index > 0 && index < routeDraw.length-1){
        cord1 =  {lat: latlng.lat , lng: latlng.lng}
        cord2 =  routeDraw[index+1]  
      }else if(index >= routeDraw.length-1){
        cord1 =  routeDraw[routeDraw.length-2] 
        cord2 =  {lat: latlng.lat , lng: latlng.lng} 
      }  
 
      let lastDistane =   calDistance(cord1 ,cord2)
      let km = Math.round(lastDistane  * 1609.344); 
 
      if(km >= Number(accept)){
        toast.error(` ${km} เมตร เกินระยะทาง ${accept} เมตรที่ยอมรับได้ `,{id:"accept-fail"})
      }else{  
        let routeEdited =await editroute.map((el,i)=>{
          if(i!==index){
            return el
          }else{ 
            return {lat: latlng.lat , lng: latlng.lng}  
          }
        })  
        dispatch(setRouteDraw(routeEdited)) 
        console.log("routeEdited ",routeEdited)
      } 
    }

    const removeMarker=async(index:any)=>{
      let editroute:Route[] = routeDraw 
      let routeEdited =await editroute.map((el,i)=>{
        if(i!==index){
          return el
        }else{
          return null
        }
      }) 
      routeEdited = routeEdited.filter((e)=>e!==null)
      dispatch(setRouteDraw(routeEdited))  
    }
     
    
    useEffect(()=>{ 
    },[routeDraw])

    return(<FeatureGroup>
      <Toaster />
         {
            routeDraw.length > 0 &&<FeatureGroup>
                <Polyline positions={routeDraw} color={conf.color} />
                {routeDraw.map((posit:any,index:any)=>

                  <FeatureGroup  key={index} >
                    {showRadar && <Circle center={posit} radius={accept}  /> }
                    <Marker  
                      position={posit} 
                      icon={!isEdit?circleWhite:squreRotate} 
                      draggable={isEdit}  
                      ref={(mark)=>{
                        mark?.on("dragend",()=>{
                          dragMarker(mark.getLatLng(),index)
                        })
                      }} 
                    >
                      <Popup>
                        <div style={{width:'9rem'}} >
                          <IonItem mode="ios" className="popup-markroute-menu" button onClick={()=>{return edit(!isEdit?true:false)}} > 
                            {!isEdit?  
                             <IonLabel  class="ion-text-wrap" ><FontAwesomeIcon icon={faPencil} /> &nbsp;&nbsp;  Edit </IonLabel>:
                             <IonLabel  class="ion-text-wrap" color={"danger"} ><FontAwesomeIcon icon={faXmark} /> &nbsp;&nbsp;  Cancle </IonLabel> }
                          </IonItem>
                          <IonItem mode="ios" lines="none"  button className="popup-markroute-menu" onClick={()=>{removeMarker(index)}} > 
                             <IonLabel  class="ion-text-wrap"><FontAwesomeIcon icon={faTrashCan} />&nbsp;&nbsp; Remove </IonLabel>
                          </IonItem>
                        </div>
                      </Popup>
                    </Marker>
                  </FeatureGroup>

                )} 
            </FeatureGroup>
         }

    </FeatureGroup>)
}

const MapInvalidate=()=>{
    const map = useMap()
    useEffect(()=>{
        map.invalidateSize()
    },[map])
    return(<></>)
}