import { IonAvatar, IonBackButton, IonBadge, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRippleEffect, IonRow, IonSegment, IonSegmentButton, IonText, IonTextarea, IonTitle, IonToolbar } from "@ionic/react"
import { useEffect, useState } from "react"  
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {   SelectMultiplePopover, SelectPopover, Tables } from "../components/AppComponent";
import { getNotifyMessage,notifyTable, tablesNotification } from "../actions";
import "./css/Notification.css"
import { addCircle, chevronBack, chevronBackOutline, notificationsOutline, play, search } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getBackup, getMenuOpen } from "../store/appSlice";
import { getGeoZones } from "../store/mapSlice";
import NavBar from "../components/NavBar";
import { getNotify, setNotify } from "../store/menuSlice";
import moment from "moment";
import { Swiper as Swipers } from "swiper/types";
import { useHistory } from "react-router";
import 'swiper/css';    
import { faKey, faTruckFast, faRightToBracket, faRightFromBracket, faDoorClosed, faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Notifications: React.FC =()=>{ 
    const [table,setTable] = useState({header:[],detail:{},body:[] })
    const [current,setCurrnt] = useState(1)
    const notify = useSelector(getNotify)
    const [segment,setSegment] = useState("message")
    const [swiperRef,setSwiperRef] = useState<Swipers|null>(null)
    const [lasttime,setLastTime] = useState(0)
    const history = useHistory()

    const dispatch = useDispatch()
    useEffect(()=>{
        if( new Date().getTime()-lasttime > 60000){ 
            getNoti()
        }
    },[notify])

    const nextPage=(page:any)=>{
        setCurrnt(page)
    }

    const getNoti=async()=>{ 
        if(notify){ 
            let notiTable:any = await notifyTable(notify?.message) 
            setTable(notiTable)
        }else{
            let message = await getNotifyMessage({
                start: new Date(moment().subtract(1,'day').format()).getTime() ,
                end: new Date().getTime() ,
                deviceId: 0
              }) 
              setLastTime(new Date().getTime())
              dispatch(setNotify({
                update:  new Date().getTime(),
                message:message
              })) 
        }
    }
    const changeSegment=(val:any)=>{
        setSegment(val) 
        if(val === "message"){
            swiperRef?.slideTo(0)
        }else if(val === "add"){
            swiperRef?.slideTo(1)
        }else if(val === "setting"){ 
            swiperRef?.slideTo(2)
        }
    }

    return(
        <IonPage >
        {/* <AuthCheck />   */}
        <IonHeader color='transparent' className='ion-no-border  '   > 
        <IonToolbar color='light' mode='ios' className='ion-no-padding'>  
            <NavBar />  
            <IonButtons slot="start"  className='ion-hide-md-up  '  >
             <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
            </IonButtons>
            <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Notifications'}</IonTitle>
        </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="content"> 
            <IonGrid>
              <IonRow>  
                <IonCol  size='12'  className="notification-content" >
                   
                    <div  style={{height:'90vh' }} className="ion-padding-horizontal" >
                    <IonRow className="ion-justify-content-center">
                    <IonCol sizeXs="12" sizeMd="10"> 
                    <IonLabel className="ion-hide-md-down" style={{fontWeight:"bold",fontSize:"1.2em"}} > Notifications </IonLabel> 
                    <div style={{maxWidth:"25rem",marginTop:"1rem"}} >
                        <IonSegment value={segment} mode="ios" onIonChange={(e:any)=>{changeSegment(e.detail.value) }}>
                            <IonSegmentButton value={"message"}>
                                <IonLabel>Messages</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={"add"}>
                                <IonLabel>Add Notification</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value={"setting"}>
                                <IonLabel>Setting</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </div>
                    <Swiper  
                        draggable={false} 
                        onSlideChange={(e) => console.log('slide change',e)}
                        onSwiper={(swiper) => setSwiperRef(swiper)}
                        allowTouchMove={false} 
                    >
                        <SwiperSlide>
                            <div style={{position:'relative',overflowX:'scroll',   alignItems:"center"}}  className="wrapper  ion-hide-md-down"  >
                                {/* <BtnAddNoti /> */} 
                                <Tables 
                                    title={''} 
                                    description={""} 
                                    headers={table.header} rows={table.body[current-1]} 
                                    tableHead={table.detail}  
                                    role="notificatios"
                                    current={current}
                                    nextPage={(e:any)=>{return nextPage(e)}}
                                    tablestyle={"stripes"}
                                />  
                            </div> 
                            <NotificationsList />
                          
                        </SwiperSlide>
                        <SwiperSlide>
                            <AddNotification  onSlideBack={()=>{setSegment("message") }} />
                        </SwiperSlide>
                    </Swiper>
                     </IonCol>
                    </IonRow>
                    </div>
                </IonCol>  
            </IonRow> 
            </IonGrid>
        </IonContent> 
        </IonPage>
    )
}
export default Notifications

const NotificationsList=()=>{
    const notifyMessage = useSelector(getNotify)
    useEffect(()=>{
        console.log("notifyMessage ",notifyMessage)
    },[])
    const isLastNoti=(time:any)=>{
        let diff = new Date().getTime() - time
        if(diff > 600000){
          return false
        }else{
          return true
        }
      }
    

    return(
        <IonList className="ion-margin-top ion-hide-md-up" color="transparent">
            { 
            notifyMessage?.message.map((msg:any,index:any)=>
            <IonItem key={index} style={{fontSize:".9em"}} lines="none" color={"transparent"} >
            <IonAvatar className='set-center notify-avatar'  style={{position:"relative"}}>
              { isLastNoti(msg.eventTime) && <IonBadge color="success" mode='ios'  >New</IonBadge> }
              {
                msg.notiferCode === -6 ? <IonText color={"danger"} ><FontAwesomeIcon icon={faKey} /></IonText>:
                msg.notiferCode === -5 ? <IonText color={"success"} ><FontAwesomeIcon icon={faKey} /></IonText> :
                msg.notiferCode === 6 ? <IonText color={"warning"} ><FontAwesomeIcon icon={faTruckFast} /></IonText>: 
                msg.notiferCode === 1 ? <IonText color={"success"} ><FontAwesomeIcon icon={faRightToBracket} /></IonText>:
                msg.notiferCode === 2 ? <IonText color={"danger"} ><FontAwesomeIcon icon={faRightFromBracket} /></IonText>: 
                msg.notiferCode === 25 ? <IonText color={"success"} ><FontAwesomeIcon icon={faDoorClosed} /></IonText>:
                msg.notiferCode === 26 ? <IonText color={"danger"} ><FontAwesomeIcon icon={faDoorOpen} /></IonText>: 
                <IonIcon icon={notificationsOutline} />
              } 
            </IonAvatar>

            <IonLabel  className="ion-text-nowrap">
              <strong>{msg?.notifertype} | {msg?.eventTimeStr}</strong> <br/> 
              <small >
                {msg?.message}
              </small>
            </IonLabel> 
          </IonItem>
            )
            } 
        </IonList>
    )
}

const BtnAddNoti=()=>{
    const swiper = useSwiper()
    return(
        <div className="btn-add-notification set-center cursor-pointer ion-activatable ripple-parent circle"
        onClick={()=>{swiper.slideNext()}}
       >
           <IonIcon icon={addCircle} color="light" style={{position:"absolute",zIndex:99}} />
           <IonRippleEffect></IonRippleEffect>
       </div>
    )
}

const notificationType = [
    {value:'opendoor-no',   label:'เเจ้งเตือนเปิด-ประตู(NO)'  , format:"notifier.template.door"} ,
    {value:'closedoor-nc',  label:'เเจ้งเตือนเปิด-ประตู(NC)'  , format:"notifier.template.door"} ,
    {value:'temperature',   label:'แจ้งเตือนอุณหภูมิ'          , format:"{0} temperature{10} engine {9} time {3} {4} http://maps.google.com/maps?f=q&q={5}&z=16"} ,
    {value:'keycloseopen',  label:'แจ้งเตือนเปิด/ปิดกุญแจ'     , format:"{0} engine {9} time {3} {4}  http://maps.google.com/maps?f=q&q={5}&z=16 "} ,
    {value:'zone',          label:'แจ้งเตือนพื้นที่'            , format:"{0} IN/OUT {1} time {3} {4}   http://maps.google.com/maps?f=q&q={5}&z=16"} ,
    {value:'idle',          label:'จอดติดเครื่อง'            , format:"Idle alert from vehicle {0} at {3} Address {4} location {5} time {7}"} , 
    {value:'maxspeed',      label:'ความเร็วเกินกำหนด'       , format:"{0} speed  {6} time {3} {4}  http://maps.google.com/maps?f=q&q={5}&z=16"} ,
]
	
const formatchoices = [ "{0} - อุปกรณ์","{1} - พื้นที","{2} - ความเร็วเกิน","{3} - เวลา","{4} - สถานที่","{5} - พิกัด","{6} - ความเร็ว","{7} - เลขบัตร","{8} - คนขับ","{9} - เปิด/ปิด","{10} - อุณหภูมิ"]
const soundsNoti = [
    { id:1  , name:"Beep Alarm Clock" ,url:"../../assets/sounds/beep-alarm-clock.wav" },
    { id:2  , name:"Beep Alarm Digital" ,url:"../../assets/sounds/beep-alarm-digital-clock.wav" },
    { id:3  , name:"Beep Alarm Warning" ,url:"../../assets/sounds/beep-alarm-warning.wav" },
    { id:4  , name:"Bell Casino" ,url:"../../assets/sounds/bell-casino-reward.wav" },
    { id:5  , name:"Bell School Calling" ,url:"../../assets/sounds/bell-school-calling.wav" },
    { id:6  , name:"Bell" ,url:"../../assets/sounds/bell.wav" },
    { id:7  , name:"Horn Circus Clown" ,url:"../../assets/sounds/horn-at-circus-clown.wav" },
    { id:8  , name:"Horn Small Car" ,url:"../../assets/sounds/horn-small-car.wav" },
    { id:9  , name:"Horn Car" ,url:"../../assets/sounds/horn_car.mp3" },
    { id:10 , name:"Siren Ambulance" ,url:"../../assets/sounds/siren-ambulance-us.wav" },
    { id:11 , name:"Siren Police(US)" ,url:"../../assets/sounds/siren-police-us.wav" },
    { id:12 , name:"Siren Police" ,url:"../../assets/sounds/siren-police.wav" },
    { id:13 , name:"Siren Slot Machine" ,url:"../../assets/sounds/siren-slot-machine-win.wav" },
]

const AddNotification=({onSlideBack}:any)=>{ 
    const swiper = useSwiper()
    const [notiType,setNotiType] = useState({value:'maxspeed',label:'ความเร็วเกินกำหนด'})
    const [alldevice,setAllDevice] = useState(true)
    const backup = useSelector(getBackup)
    const [notiformat,setNotiFormat] = useState("{0} ความเร็ว  {6}  เวลา  {3}   {4}  http://maps.google.com/maps?f=q&q={5}&z=16")
    const [formatchoice , setFormatChoice] = useState(formatchoices)
    const [selecteds,setSelecteds] = useState([])
    const zones = useSelector(getGeoZones)
    const [alarm,setAlarm] = useState("Beep Alarm Clock");
    const [zoneSelect,setZoneSelect] = useState({
        "value": 3717,
        "label": "ออฟฟิตหนองคาย"
    })
    let audio: HTMLAudioElement | null = null;
    const history = useHistory()

    useEffect(()=>{  
        console.log("hishtory ",history)
        const locationState:any = history?.location?.state
        if(locationState !== undefined){
            if(locationState?.geozones){
                setNotiType(notificationType[4])
                setNotiFormat(notificationType[4]?.format)
                let zone = zones[4]
                setZoneSelect({
                    "value": zone?.geoId,
                    "label": zone?.name
                })
            }
        }

    },[selecteds])  

    const selectDevice=(selection:any)=>{
        console.log("selectDevice ",selection)
        const find = selecteds.find((e:any)=> e.value === selection.value)
        console.log("find ",find) 
        if(!find){ 
            let select:any = selecteds
            select[select?.length] = selection  
            //@ts-ignore
            setSelecteds(select) 
        }
    }

    const clearSelected=(index:any)=>{ 
        let select:any[] = selecteds
        select.splice(index,1)
        select = select.filter((e:any)=> e !== null) 
    }

    const onchangeAlarm=async(val:any)=>{
        setAlarm(val)
        let sound = await soundsNoti.find((e)=> e.name.indexOf(val) > -1)
        if(audio){  
            audio.remove()
        } 
        console.log("audio ",audio)
        audio = new Audio(sound?.url)  
        setTimeout(()=>{
            audio?.play()
        },500)
        //play(sound?.url)
    }

    const changeNotiferrType=(notifer:any)=>{
        setNotiFormat(notifer?.format) 
    }

    return(<div > 
        <IonGrid>
            <IonRow>
                <IonCol size="12" style={{borderBottom:'1px solid #DDD'}}>
                    <IonLabel 
                    style={{fontSize:'1.5em',display:'flex',alignItems:'center'}} 
                    className="cursor-pointer" onClick={()=>{swiper.slidePrev(); return onSlideBack()}}> 
                        <IonIcon icon={chevronBack} />  
                        <strong> Add Notification </strong>  
                    </IonLabel>
                </IonCol> 
            </IonRow>
            <IonRow style={{marginTop:'1rem'}}>
                <IonCol sizeXs="12" sizeMd="4" style={{padding:'1rem'}} >
                    <div className="input" >
                       <IonInput mode="ios"  placeholder="ชื่อการแจ้งเตือน" ></IonInput>
                    </div>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4"  style={{padding:'1rem'}} >
                    <SelectPopover 
                        id={'notitype'}
                        contents={notificationType} 
                        title={'Notification Type'} 
                        subtitle={"select notification type."}  
                        selected={(e:any)=>{changeNotiferrType(e);  return setNotiType(e)}}
                        current={notiType} 
                    />   
                </IonCol>
                {notiType.value ==="temperature" && 
                <IonCol sizeXs="12" sizeMd="4" style={{padding:'1rem'}} >
                    <div className="input" >
                        <IonInput mode="ios"  placeholder="อุณหภูมิ" ></IonInput>
                    </div>
                </IonCol>}
                {notiType.value ==="zone" && 
                <IonCol sizeXs="12" sizeMd="4" style={{padding:'1rem'}} >
                    <SelectPopover 
                        id={'geozone'}
                        contents={zones.map((e:any)=>{return {value: e.geoId , label: e.name ,format: e.format}})} 
                        title={'Geozones'} 
                        subtitle={"select zone to notification."}  
                        selected={(e:any)=>{return setZoneSelect(e)}}
                        current={zoneSelect} 
                    />   
                </IonCol>}
                {notiType.value ==="idle" && 
                <IonCol sizeXs="12" sizeMd="4" style={{padding:'1rem'}} >
                    <div className="input" >
                        <IonInput mode="ios"  placeholder="จอดนาน(นาที)" ></IonInput>
                    </div>
                </IonCol>} 
                {notiType.value ==="maxspeed" && 
                <IonCol sizeXs="12" sizeMd="4" style={{padding:'1rem'}} >
                    <div className="input" >
                        <IonInput mode="ios"  placeholder="เตือนความเร็ว" ></IonInput>
                    </div>
                </IonCol>}
                
               
            </IonRow><br/>
           
            <IonRow>
                <IonCol size="12">
                    <strong>ช่องทางการแจ้งเตือน</strong>
                </IonCol>
                <IonCol sizeXs="6" sizeMd="2.5"> 
                  <IonLabel className="notification-way" ><IonCheckbox></IonCheckbox>&nbsp;&nbsp;  แจ้งเตือนผ่านหน้าเว็บ</IonLabel>
                </IonCol>
                <IonCol sizeXs="6" sizeMd="2.5"> 
                  <IonLabel className="notification-way"  ><IonCheckbox></IonCheckbox>&nbsp;&nbsp;  เตือนทาง E-mail</IonLabel>
                </IonCol>
                <IonCol sizeXs="6" sizeMd="2.5"> 
                  <IonLabel className="notification-way"  ><IonCheckbox></IonCheckbox>&nbsp;&nbsp;  เตือนทาง Line</IonLabel>
                </IonCol>
            </IonRow>
            {notiType.value ==="zone" &&  
            <IonRow>
               <IonCol size="2.5"> 
                  <IonLabel className="notification-way" ><IonCheckbox></IonCheckbox>&nbsp;&nbsp;   เตือนเข้าพื้นที่</IonLabel>
                </IonCol>
                <IonCol size="2.5"> 
                  <IonLabel className="notification-way" ><IonCheckbox></IonCheckbox>&nbsp;&nbsp;   เตือนออกพื้นที่</IonLabel>
                </IonCol>
            </IonRow>
            }
            <br/> 
            <IonRow> 
                <IonCol size="12"> 
                  <IonLabel className="notification-way" ><IonCheckbox checked={alldevice} 
                     onIonChange={(e:any)=>{setAllDevice(e.detail.checked)}}
                    ></IonCheckbox>&nbsp;&nbsp;  เลือกยานพาหนะทั้งหมด</IonLabel>
                </IonCol> 
                {!alldevice && <IonCol size="12" style={{padding:'.5rem'}}> 
                  <SelectMultiplePopover 
                    id={'devices'}
                    contents={backup.map((e:any)=>{ return {value: e.device_id , label: e.name} })} 
                    title={'Devices'} 
                    subtitle={"select devices."}  
                    selected={(e:any)=>{return selectDevice(e)}} 
                    clearSelect={(e:any)=>{return clearSelected(e)}}
                    current={selecteds} 
                  /> 
                </IonCol>}
            </IonRow><br/> 
            <IonRow> 
                <IonCol size="12">
                    <strong>รูปแบบการแจ้งเตือน</strong>
                </IonCol>
                <IonCol size="12">
                    <div className="input" >
                      <IonTextarea mode="ios" value={notiformat} placeholder="Notification format" onIonChange={(e:any)=>{setNotiFormat(e.detail.value)}}></IonTextarea>
                    </div>
                </IonCol>
                <IonCol size="12" >
                    {formatchoice.map((format:any,index:any)=> 
                     <IonButton 
                     onClick={()=>{setNotiFormat(notiformat+' '+format)}}
                     key={index} size="small" fill="clear" color={"dark"} > 
                        <IonIcon icon={addCircle}  />&nbsp;{format}
                     </IonButton>
                    )}
                </IonCol>
            </IonRow><br/>
            <IonRow>
                <IonCol size="12">
                    <strong>เสียงการแจ้งเตือน</strong>
                </IonCol>

                <select name={alarm} id="alarm-sound" onChange={(e)=>{ onchangeAlarm(e.target.value);}}> 
                    {soundsNoti.map((e:any,id:any)=> <option key={id} value={e.name} > {e.name} </option> )}
                </select> 
                <IonCol size="12" className="ion-text-right" style={{borderTop:'1px solid #EEE'}} >
                    <IonButton size="small" color={"secondary"} mode="ios" > <IonLabel>บันทึก</IonLabel> </IonButton>  &nbsp;
                    <IonButton size="small" color={"danger"} mode="ios" > <IonLabel>ยกเลิก</IonLabel> </IonButton>
                </IonCol> 
            </IonRow>
            
        </IonGrid>
    </div>)
}