import { faDoorClosed, faDoorOpen, faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonModal, IonRow, IonText, IonToolbar } from "@ionic/react"
import { radio, arrowDownCircle, arrowUpCircle, car, closeCircle, closeCircleOutline, keyOutline, key } from "ionicons/icons"
import moment from "moment"
import { useEffect, useState } from "react"
import { MapContainer, Marker, TileLayer } from "react-leaflet"
import { defaultPin } from "../../leaflet/PublicMarker"
import "../../pages/css/Reports.css"



const Cell =({id, children}:any)=>{
    const [showEdit,setShowEdit] = useState(false)

    return(<div className="cells" onMouseOver={()=>{setShowEdit(true)}}  onMouseLeave={()=>{setShowEdit(false)}}   >
        {children}  {id!="no" && showEdit && <IonButton size="small" fill="clear" >
            <FontAwesomeIcon icon={faPenToSquare} color="#CCC" />
        </IonButton> } 
    </div>)
}


const HeaderTable=({headers}:any)=>{
    return(
    <IonRow className="header" >
        {
        headers.map((head:any,hedeIndex:any)=> 
            <IonCol key={hedeIndex}  className="ion-no-padding ion-text-center" size={head.headerSize}  style={{ padding: ".5rem",}}>
                <IonLabel>{head.label.toUpperCase()}</IonLabel> 
            </IonCol> 
            )
        }
    </IonRow> 
    )
}

export const TableDeviceInOut=({headers,rowsData}:any)=>{

    return(
    <div  className="table" >
        <IonGrid> 
           <HeaderTable headers={headers} />  
           {
                rowsData.map((row:any , rowIndex:any) =>   
                <IonRow key={rowIndex} >
                    {
                        row.cols.map((col:any,colIndex:any)=> 
                            <IonCol 
                                key={colIndex} 
                                // size={col.colSize}  
                                className="ion-text-center" 
                            > 
                                <Cell id={col.id}  > 
                                       <IonLabel>{col.label}</IonLabel>   
                                </Cell> 
                            </IonCol>
                        )
                    }
                </IonRow>
            )} 
        </IonGrid>
    </div>
    )
}
export const TableSpeedTemp=({headers,rowsData,edit}:any)=>{  

    return(
        <div className="table" >
        <IonGrid>
            <HeaderTable headers={headers} /> 
            <div style={{width:"100%", height:"20rem",overflowY:"scroll"}} >
            {
                rowsData.map((row:any , rowIndex:any) =>   
                <IonRow key={rowIndex} >
                    {
                        row.cols.map((col:any,colIndex:any)=> 
                            <IonCol 
                                key={colIndex} 
                                size={col.colSize}  
                                className="ion-text-center" 
                            > 
                                <Cell id={col.id} type={headers[rowIndex].type} >
                                    { 
                                        headers[colIndex].type ==="status" ? <IonLabel>{col.label? <IonLabel color={"success"}> <IonIcon icon={radio} /> <IonText>Connect</IonText> </IonLabel> :<IonLabel color={"danger"}> <IonIcon icon={radio} /> <IonText>Disconnect</IonText> </IonLabel>  }  </IonLabel>:
                                        headers[colIndex].type ==="door_status" ? <IonLabel>{col.label? <IonLabel color={"success"}>  <FontAwesomeIcon icon={faDoorClosed} /> <IonText>Door Close</IonText> </IonLabel> :<IonLabel color={"danger"}>  <FontAwesomeIcon icon={faDoorOpen} /> <IonText>Door Open</IonText> </IonLabel>  }  </IonLabel>:
                                        headers[colIndex].type ==="date" ? <IonLabel>{`${moment(col.label).format("HH:mm:ss DD/MMM") }  `}  </IonLabel>:
                                        headers[colIndex].type ==="temp" ? <IonLabel > <TempChange temp={col.label}  row={rowsData}  index={rowIndex} /> </IonLabel>:
                                        <IonLabel>{col.label}</IonLabel> 
                                    }
                                   
                                </Cell> 
                            </IonCol>
                        )
                    }
                </IonRow>
            )} 
            </div>
        </IonGrid>
    </div>
    )
}

const TempChange=({temp,row ,index}:any)=>{
  
    return(<IonLabel>
        {index > 0 && index < row.length-1? <IonLabel>
             &nbsp;
            {row[index-1].cols[3].label > temp || row[index+1].cols[3].label > temp ? <IonText color="success" ><IonIcon icon={arrowDownCircle} color="success" /> {temp}</IonText> : 
            row[index-1].cols[3].label < temp || row[index+1].cols[3].label > temp ?  <IonText color="danger" ><IonIcon icon={arrowUpCircle} color="danger" /> {temp}</IonText>:
            <IonLabel>   {temp}</IonLabel>
            }
        </IonLabel>: <IonLabel>   {temp}</IonLabel>}
    </IonLabel>)
}



export const TableOverview=({overview}:any)=>{ 
    return(
       <div>
         <IonGrid>
            { overview.map((row:any,index:any)=>
            <IonRow key={index}  className={` ${index%2 === 0 ? "event-row":"add-row"}  `} style={{fontSize:".8em"}} >
               {
                    row.map((col:any , i:any)=>
                    <IonCol key={i} size={col.colSize} style={{borderRight:"1px solid #FFF",paddingLeft:'.5rem'}}> 
                        {col.id === "distance" ?
                            <IonLabel>{col.label > 0 ? col.label.toFixed(0):"0"}</IonLabel>:
                            <IonLabel>{col.label}</IonLabel>  
                        }
                    </IonCol>
                    )
                } 
            </IonRow>
            )}
           
         </IonGrid>
       </div>
    )
}

export const TableEvents=({headers,rowsData }:any)=>{  
    return(
        <div className="table" >
          <IonGrid>
            <HeaderTable headers={headers} /> 
            <div style={{width:"100%", overflowY:"scroll"}} >
           {
                rowsData.map((row:any , rowIndex:any) =>   
                <IonRow key={rowIndex}  className={` ${rowIndex%2 === 0 ? "event-row":"add-row"}  `}>
                    {
                        row.cols.map((col:any,colIndex:any)=> 
                            <IonCol 
                                key={colIndex} 
                                size={col.colSize}  
                                className="set-center ion-text-center" 
                            >   
                              <CellsEvent label={col.label} id={col.id} cols={col} />
                            </IonCol>
                        )
                    }
                </IonRow>
            )}  
            </div>
          </IonGrid>
        </div>
    )
}

export const TableDistance=({headers,rowsData}:any)=>{
    return(
    <div className="table" >
        <IonGrid>
            <HeaderTable headers={headers} /> 
            <div style={{width:"100%", height:"20rem",overflowY:"scroll"}} >
            {
            rowsData.map((row:any , rowIndex:any) =>   
            <IonRow key={rowIndex} >
                {
                   row.cols.map((col:any,colIndex:any)=> 
                   <IonCol 
                       key={colIndex} 
                       size={col.colSize}  
                        className="ion-text-center" 
                     > 
                        <Cell id={col.id} > 
                           <IonLabel>{  col.label}</IonLabel>   
                        </Cell> 
                    </IonCol>
                    )
               }
            </IonRow>
            )} 
            </div>
        </IonGrid>
    </div>
    )
}

export const TableIginition=({headers,rowsData}:any)=>{
    return(
    <div className="table" >
        <IonGrid>
            <HeaderTable headers={headers} /> 
            <div style={{width:"100%", height:"20rem",overflowY:"scroll"}} >
            {
            rowsData.map((row:any , rowIndex:any) =>   
            <IonRow key={rowIndex} >
                {
                   row.cols.map((col:any,colIndex:any)=> 
                   <IonCol 
                       key={colIndex} 
                       size={col.colSize}  
                       className="ion-text-center set-center" 
                     > 
                        <CellsEvent label={col.label} id={col.id} cols={col} /> 
                    </IonCol>
                    )
               }
            </IonRow>
            )} 
            </div>
        </IonGrid>
    </div>
    )
}
const tdstyle = {width:"fit-content",  padding:".3rem"  , border:"1px solid #DDD"}
export const TableDistanceRange=({headers,rowsData}:any)=>{ 
   
    return(
    <div className="table" style={{  overflowY:'scroll' , color:"#000"}} >
        <table>
            <tr style={{borderBottom:"2px solid #EDEDED"}} >  
                {  headers.map((head:any,index:any)=>  <th key={index} style={{backgroundColor:"#DDD"}} className="ion-text-center" > 
                    <small style={{fontWeight: 500}}>{head?.label}</small> 
                </th> ) } 
            </tr>
            {
                rowsData.map((row:any ,rowIndex:any)=><tr key={rowIndex}>
                   <td style={{...tdstyle , ...{backgroundColor: rowIndex%2 !== 0 ? "#EFEFEF" : "#FFFFFF" }}}  className="ion-text-center"  ><small>{rowIndex+1}</small> </td>
                   <td style={{...tdstyle , ...{backgroundColor: rowIndex%2 !== 0 ? "#EFEFEF" : "#FFFFFF" }}}    ><small>{row?.name}</small> </td>
                   {
                    row?.distances.map((e:any,colIndex:any)=> <td key={colIndex} 
                     className={`ion-text-center  `} 
                     style={{backgroundColor: rowIndex%2 !== 0 ? "#EFEFEF" : "#FFFFFF" , border:"1px solid #DDD"}} ><small>{e}</small></td>  )
                   }
                </tr> )
            } 
        </table> 
    </div>
    )
}

export const TablePTOCloseOpen=({headers,rowsData}:any)=>{ 
    return(
    <div className="table" style={{height: "20rem", overflowY:'scroll'}} >
        <IonGrid>
            <HeaderTable headers={headers} /> 
            <div style={{width:"100%", height:"20rem",overflowY:"scroll"}} >
            {
            rowsData.map((row:any , rowIndex:any) =>   
            <IonRow key={rowIndex} >
                {
                   row.cols.map((col:any,colIndex:any)=> 
                   <IonCol 
                       key={colIndex} 
                       size={col.colSize}  
                       className="ion-text-center set-center" 
                     > 
                     <IonLabel style={{fontSize:".8em"}} >
                        {col.id === "status" ?
                         <IonText color={col.label ? "danger":"success"}>
                           <FontAwesomeIcon  icon={col.label ? faDoorOpen : faDoorClosed} />  &nbsp; 
                           {col.label ?"open":"close" }
                         </IonText>:
                         <IonText> {col.label}</IonText>
                        }
                     </IonLabel>
                    
                    </IonCol>
                    )
               }
            </IonRow>
            )} 
            </div>
        </IonGrid>
    </div>
    )
}

export const TableFuelUsage =({headers,rowsData}:any)=>{ 
    console.log("headers  ",headers )
    console.log("rowData ", rowsData)

    return(
    <div className="table" style={{height: "20rem", overflowY:'scroll'}} >
        <IonGrid>
           {headers &&   <HeaderTable headers={headers} /> } 
            <div style={{width:"100%", height:"20rem",overflowY:"scroll"}} >
            { rowsData && rowsData.map((row:any , rowIndex:any) =>   
            <IonRow key={rowIndex}  className={` ${rowIndex%2 === 0 ? "event-row":"add-row"}  `} >
                {
                   row.cols.map((col:any,colIndex:any)=> 
                   <IonCol 
                       key={colIndex} 
                       size={col.colSize}  
                       className="ion-text-center set-center" 
                     > 
                     <IonLabel style={{fontSize:".8em"}} > 
                         <IonText> {col.label}</IonText> 
                     </IonLabel>
                    
                    </IonCol>
                    )
               }
            </IonRow>
            )} 
            </div>
        </IonGrid>
    </div>
    )
}


const CellsEvent=({label,id,cols}:any)=>{ 
     
    return(<IonLabel style={{display:"flex",flexDirection:"column",alignItems: 'center',}}>
        {id === "status" ?<> 
            <IonLabel className="ion-text-center" 
                style={{color: label.toString() === '7'  &&  Number(cols?.speed ) < 30 && "#72fc00"}}
                color={ 
                        label.toString() === '7' ? `${
                            Number(cols?.speed ) < 30 ? "#72fc00" /* move slow */ : "success"/* move faster */
                        }`: 
                        label.toString() === '24' ? "warning" /* idle */:  
                        label.toString() === '23' ? "danger" /* stop */ : "medium" /* status not match*/
                } >  
                <IonIcon icon={car}  style={{fontSize:"1.6em"}}  /> <br/> 
               <IonText  style={{fontSize:".7em"}}  >
                {
                    label.toString() === '7' ? <> 
                        {Number(cols?.speed ) < 30 ?"เคลื่อนที่ช้า" : "เคลื่อนที่"}
                    </>  :  
                    label.toString() === '24' ? "จอดติดเครื่อง" :
                    label.toString() === '23' ? "หยุดนิ่ง" : "-"
                }
                </IonText> 
            </IonLabel>    
        </>:
        id === "distance" ?<> 
           {label > 0 ? <IonText  style={{fontSize:'.8em'}}> {!label? label : label } km </IonText>:
           <IonText style={{fontSize:'.8em'}}> {label } </IonText>}
        </>: 
        id === "address" ?<>
            <AddressTextCel label={label}  cols={cols} /> 
        </>:
        id === "engineStatus"? <> 
            <IonChip outline={true} mode="ios"   color={ label ? "success":"danger"}>
                <IonIcon icon={key} style={{fontSize:"1em"}} /> &nbsp; 
                 <IonText style={{fontSize:".8em"}} >{ label ? "กุญแจเปิด" :"กุญแจปิด"} </IonText> 
            </IonChip> 
        </>: 
        id === "key" ? 
          <IonLabel className="ion-text-center" >   
              <IonText color={label ? "success": "danger"} > 
               <IonIcon icon={key}  style={{fontSize:"1.3em"}} /> <br/>
               <span  style={{fontSize:".8em"}}>{label ? "กุญแจเปิด" :"กุญแจปิด"}</span>
              </IonText> 
        </IonLabel>   : 
        <IonText style={{fontSize:".7em"}} > {label !== null? label?.toString().substring(0,30) :"-"} </IonText> 
       } 
    </ IonLabel>)
}

const AddressTextCel=({label ,cols}:any)=>{
    const [modal,setModal] = useState(false)
    const  [showmap,setShowmap] = useState(true)
    return(<>
        <IonText  style={{fontSize:'.8em'}} 
        onClick={()=>{setModal(true); console.log("cols ",cols ) }}
       > {label.toString().substring(0,35)} 
       </IonText> 
       {cols?.lat && cols?.lng &&  <IonModal isOpen={modal} trigger="open-modal" onIonModalWillDismiss={()=>{setShowmap(false)}} onIonModalWillPresent={()=>{setShowmap(true)}} onIonModalDidDismiss={()=>{setModal(false)}}>
            <IonHeader className="ion-no-border" mode="ios" >
                <IonToolbar>
                    <IonLabel slot="start">{label}</IonLabel>
                    <IonButton slot="end" fill="clear"  onClick={()=>{setShowmap(false);setModal(false)}} >
                        <IonIcon icon={closeCircleOutline} />
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {showmap&&<MapContainer 
                 zoomControl={false}  maxZoom={20} 
                 center={[cols.lat,cols.lng]}
                 zoom={10} 
                 id="map-address"
                 scrollWheelZoom={true} 
                 ref={(comp:any) => {
                   comp?.invalidateSize() 
                   comp?.setView([cols.lat,cols.lng])
                   comp?.on("zoom",()=>{
                     comp?.invalidateSize() 
                   })
                   setTimeout(()=>{
                    comp?.setZoom(12)
                   },300)
                 }} 
                >
                    <TileLayer 
                        attribution={"© openstreetmap"}
                        url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"} 
                    />
                    <Marker position={[cols.lat,cols.lng]} icon={defaultPin}  />
                </MapContainer>}
            </IonContent>
        </IonModal>}
    </>)
}


export const TableBehavior=({rowsData}:any)=>{
    const headers = [
        {
            label: "เบรกกะทันหัน" ,
            subs:[ "วันที่" ,"สถานที่" ]
        },{
            label: "เร่งกระทันหัน" ,
            subs:[ "วันที่" ,"สถานที่" ]
        },{
            label: "เลี้ยวกระทันหัน" ,
            subs:[ "วันที่" ,"สถานที่" ]
        },{
            label: "ความเร็วเกิน" ,
            subs:[ "วันที่" ,"สถานที่" ]
        },{
            label: "จอดติดเครื่องนาน" ,
            subs:[ "วันที่" ,"สถานที่" ]
        }
    ]
 
    return(
        <div className="table" >
            <IonGrid>
                <IonRow  style={{borderBottom:"1px solid #ccc",borderTop:"1px solid #ccc"}} >
                    {
                        headers.map((e,index)=>
                        <IonCol size={"2.4"} style={{borderRight:"1px solid #ccc",borderLeft:"1px solid #ccc"}}> 
                            <IonRow>
                                <IonCol size="12" className="ion-text-center" style={{borderBottom:"1px solid #ccc"}}>
                                     <IonLabel style={{fontSize:".8em"}}>{e.label}</IonLabel>
                                </IonCol>
                                {
                                    e.subs.map((col)=>  <IonCol size="6" >
                                       <IonLabel style={{fontSize:".8em"}}>{col}</IonLabel>
                                    </IonCol>)
                                }
                            </IonRow> 
                        </IonCol>  
                        )
                    }
                </IonRow>
                <div style={{width:"100%", height:"20rem",overflowY:"scroll"}} >
            {
                rowsData.map((row:any , rowIndex:any) =>   
                <IonRow key={rowIndex} className={` ${rowIndex%2 === 0 ? "event-row":"add-row"}  `} 
                >
                    {
                        row.cols.map((e:any,colIndex:any)=> 
                            <IonCol 
                                key={colIndex} 
                                size={"2.4"}  
                                className="ion-text-center" 
                                style={{boderRight:"1px solid #DDD"}}
                            > 
                                <IonRow>
                                 {
                                    e.subs.map((col:any)=>  <IonCol size="6" >
                                       <IonLabel style={{fontSize:".8em"}}>{col}</IonLabel>
                                    </IonCol>)
                                }
                                </IonRow>
                            </IonCol>
                        )
                    }
                </IonRow>
            )} 
            </div>
            </IonGrid>
        </div>
    )
}


export const TableMinuteReport=({headers,rowsData}:any)=>{ 
    return( <div className="table" style={{ padding:".5rem",width:"100%", height:"55vh",overflowY:"scroll"}} > 
            <table  className="table-minute"  style={{width:"100%",padding:"1rem"}} >
                <tr className="header" >
                    {headers?.map((h:any,index:any)=>
                    <th key={index} className="ion-text-left"  >
                        <IonLabel>{h.label}</IonLabel>
                    </th>
                    )} 
                </tr> 
                {
                    rowsData?.map((row:any,index:any)=>
                     <tr key={index} className={` ${index%2 === 0 ? "event-row":"add-row"}  `} >
                        {
                            row.cols.map((col:any,i:any)=>
                            <td key={i}  >
                               <CellsEvent label={col.label} id={col.id} cols={col} /> 
                               
                            </td>)
                        }
                     </tr>
                    )
                } 
                  
            </table> 
    </div>)
}
