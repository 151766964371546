import React, { createElement, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "apexcharts"
import moment from "moment";

 
interface Props{
  type: String
  labels: String[]
  colors: String[]
  series: ApexAxisChartSeries | Number[]
  getUri: (uri: String)=> void
}
export const PieChart : React.FC<Props>=(props)=>{
    const [series,setSeries] = useState<ApexAxisChartSeries>() 
    const [options,setOptions] = useState<ApexCharts.ApexOptions>(
        {
          chart: {
            width: 380,  //@ts-ignore
            type: "pie" ,
          }, //@ts-ignore
          labels: ["Label"] ,
          colors:['#85807e', '#c73f0e' ],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
      }, 
    ) 
    useEffect(()=>{
      const setupChart=()=>{ 
        let updateoptions:any  = options
        updateoptions.labels = props.labels 
        updateoptions.colors = props.colors 
 
        setOptions(updateoptions)
        //@ts-ignore
        setSeries(props?.series)
         
      } 
      setupChart()
    },[]) 

    const chartURI=async(chart:any)=>{
      if(chart){  

        let uri = await chart.render().then(async()=>{
          window.setTimeout(function() {
            return  chart.dataURI().then(async(uri:any) => {
                  console.log(uri); 
                 return uri.imgURI
              })
          }, 1000) 
        })
        return props.getUri(await uri)

        // let uri =await chart.dataURI().then((e:any)=>{ return e.imgURI }) 
        // if(uri.length > 6){
        //   return props.getUri(await uri)
        // }
      }else{
        return ''
      }
    }
 
    return (  
      <div id="chart" style={{position:'fixed',zIndex:-999}}> 
        <ReactApexChart options={ options} series={ series}   
          ref={(res:any)=>{res?.chart.update() ;return  chartURI(res?.chart)   }}
          //@ts-ignore
          type={"pie"} width={380} 
        />
      </div>
    ) 
}

export const pieChartURI=async (props:any)=>{ 
   console.log("props ",props)
  const  options  =  {
      series: props?.data ,
      chart: {
        width: 380,  //@ts-ignore
        type: "pie" ,
      }, //@ts-ignore
      labels:  props.labels  ,
      colors: props.colors ,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
  }  
      try{ 
        const canvas = document.querySelector("#circle-chart");  
        let chart =await new Chart(canvas, options) 
        await chart.render().then(() => {
            window.setTimeout(async function() {
              let datauri = await chart.dataURI().then((uri:any) => {
                  console.log(uri); 
                  return uri.imgURI
              }) 

              return props.getUri(  datauri)
          }, 1000)  
      })  
      }catch{
        return props.getUri('')
      }
   return (<div  ></div>)
}

export const lineChartURI=async (props:any)=>{ 
  const  options  = {
    series: props.series ,  
    chart: {
      height: 350,
      type: 'line',
      zoom: {  enabled: false  }
    },
    dataLabels: { enabled: false  },
    stroke: { curve: 'straight' },  
    xaxis:  props.xaxis
  };

  try{ 
    const canvas = document.querySelector("#line-chart");  
    let chart =await new Chart(canvas, options) 
    await chart.render().then(() => {
        window.setTimeout(async function() {
          let datauri = await chart.dataURI().then((uri:any) => {
              console.log('line-chart ',uri); 
              return uri.imgURI
          })  
          return props.getUri(  datauri)
      }, 1000)  
  })  
  }catch{
    return props.getUri('')
  }
}



export const lineScatterChartURI=async (props:any)=>{ 
  console.log("props lineScatterChartURI ",props)
  const options ={
    series: props.series.data ,  
    xaxis: 	{ type: props.xaxis.type ,  categories: props.xaxis.categories.map((date:any)=>{return moment(date).add(7,'hour').format() }) } ,
    chart: 	{ 	id: "line"	, type: 'line', zoom:{zoomedArea:true }},
    fill: 	{ 	type:'solid'	},
    stroke: { 	curve: 'smooth'	, width: 1 },
    markers: { 	size: [2, 2,2,1] , strokeWidth: 0, },
    tooltip: { shared: false,  },
    colors: ['#fce303','#fc1803','#6ffc03','#134985','#fce303','#fc1803',],
    yaxis: {
      labels: {
        formatter: (value:any)=> {
          return value.toFixed(0) ;
        }
      },
    }, 
  }

  try{ 
    const canvas = document.querySelector("#line-scatter-chart");  
    console.log("options ",options)
    let chart =await new Chart(canvas, options) 
    await chart.render().then(() => {
        window.setTimeout(async function() {
          let datauri = await chart.dataURI().then((uri:any) => {
              console.log('line-scatter-chart ',uri); 
              return uri.imgURI
          })  
          return props.getUri(  datauri)
      }, 1000)  
  })  
  }catch{
    return props.getUri('')
  }

}



export {}