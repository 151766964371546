import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonModal, IonRadio, IonRadioGroup, IonRow, IonTitle, IonToggle, IonToolbar, useIonActionSheet, useIonAlert, useIonToast } from "@ionic/react"
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { checkmarkDoneOutline, close, closeCircleOutline, closeOutline, key, keyOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { getSessions, getStorage, revokeSession, setDeviceIcon, setStorage, toastFail, toastSuccess } from "../../actions";
import { DevicesList } from "../../components";
import { SettingIcon } from "../../components/OptionDialogs";
import { getDeviceSelect, getModalDashboard, getSearchSelect, setModalDashboard } from "../../store/appSlice"; 
import { getNotifyVoice, getVoiceEnable, setVoiceEnable, setNotifyVoice, getSoundNotify } from "../../store/settingSlice";
import "../css/Setting.css"
import { DropDownDevice } from "../../components/AppComponent";
import { getMapDark, setMapDark } from "../../store/mapSlice";

export const Languages=()=>{
    const [present] = useIonActionSheet();
    const [result, setResult] = useState<OverlayEventDetail>();
    const [lang,setLang] = useState("TH")

    const setLanguage=(e:any)=>{ console.log("e ", setLang(e)) }
    const showlanges=()=>{
        present({
            header: 'Languages',
            subHeader: 'Select your languages',
            mode:'ios',
            buttons: [
              {
                text: 'Thai', 
                data: {  action: 'delete', },
                handler:(()=>{
                     setLanguage("TH")
                })
              },
              {
                text: 'English',
                data: { action: 'share', },
                handler:(()=>{
                    setLanguage("ENG")
               })
              }]
        })
    }
return(
    <>
       <IonItem lines='none' mode='ios' color={"transparent"} onClick={()=>{showlanges()}} >
            <IonLabel>Languages</IonLabel>
            <IonLabel slot="end" >{lang}</IonLabel>
        </IonItem>
    </>
)}


export const DefaultMap=()=>{ 
    const [open,setOpenModal] = useState(false)
    const [layer,setLayer]= useState('longdo') 
    const modalMap = useRef<HTMLIonModalElement>(null);
    const mapdark = useSelector(getMapDark)

    const dispatch = useDispatch() 
    const layers = [
        {value:"longdo" , name:"Longdo"  ,path:"../assets/img/layer-longdo.PNG" },
        {value:"openstreet" , name:"Openstreet"  ,path:"../assets/img/layer-openstreet.PNG" },
        {value:"sattlelite" , name:"Sattlelite"  ,path:"../assets/img/layer-sattlelite.PNG" },
        {value:"openstreet_soft" , name:"Openstreet Soft"  ,path:"../assets/img/layer-softpink.JPG" },
    ]

    const openModalMap=(e:any)=>{ 
        setOpenModal(e)

        dispatch(setModalDashboard(false)) 
    }

    const setDefaultDarkmap=(bool:any)=>{
      dispatch(setMapDark(bool))
      setStorage("darkmap",bool) 
      if(bool){ 
        document.body.classList.add( "map-dark" )
      }else{ 
        document.body.classList.remove( "map-dark" )
      } 
    }
 
    return(
    <> 
    <IonItem lines='none' id="open-modal" mode='ios' onClick={()=>openModalMap(true)} color={"transparent"} >
        <IonLabel>Default Map</IonLabel>
        <IonLabel slot="end">{layer}</IonLabel>
    </IonItem> 
    <IonModal isOpen={open}  ref={modalMap} mode='ios'  onDidDismiss={(ev) => openModalMap(false)}>
        <IonContent className="ion-padding">
            <IonItem>
                <IonLabel>DefaultMap</IonLabel>
                <IonIcon icon={closeCircleOutline} color='primary' slot="end" />
            </IonItem>

            <div className="ion-padding" >
            <IonRadioGroup value={layer}>
              <IonRow>
                    {
                    layers.map((layer,index)=>
                    <IonCol size="4" key={index} className="default-map-choice" style={{position:"relative"}} >
                        <div className="set-center"  onClick={()=>{setLayer(layer.value) }}
                        style={{flexDirection:"row",justifyContent:"flex-start",position:"absolute",backgroundColor:"rgba(255,255,255,.7)",width:"100%",padding:".2rem",cursor:"pointer"}} >
                            <IonRadio  mode="ios" value={layer.value}></IonRadio> &nbsp;<IonLabel>{layer.name}</IonLabel>
                        </div>
                        <div style={{width:'100%',height:"7rem",backgroundImage:`url(${layer.path})`,backgroundSize:"cover",borderRadius:'5px'}} ></div>
                    </IonCol>
                    )}
              </IonRow>
              <br/><br/>
              <IonRow>
                <IonCol size="12" > 
                  
                  <IonItem color={"transparent"} lines="none" >
                    <IonLabel slot="end">แผนที่มืด เมื่อเปิดโหมดมืด</IonLabel>
                    <IonToggle checked={mapdark} onIonChange={(e)=>{setDefaultDarkmap(e.detail.checked); }}></IonToggle>
                  </IonItem>

                </IonCol>
              </IonRow>
            </IonRadioGroup> 

            </div>
        </IonContent>
    </IonModal> 
    </>
    )
}


export const LineKey=()=>{
    const [showalert,setShowAlert] = useState(false)
    return(
        <>
        <IonItem lines='none' mode='ios' color={"transparent"} onClick={()=>{setShowAlert(true)}} >
            <IonLabel>เปลี่ยนไลน์คีย์</IonLabel>
        </IonItem>
        <IonAlert
            mode="ios"
            isOpen={showalert}
            onDidDismiss={() => setShowAlert(false)}
            header="Line key" 
            message="Change line key"
            buttons={['OK']}
            inputs={ [
                {
                  placeholder: 'Password',
                }, 
                {
                  type: 'textarea',
                  placeholder: 'Enter line key',
                },
              ]}
        />
        </>
    )
} 
 
interface Sound{ 
  id: Number 
  name: String
  url: String | any
}

export const NotifySetting=()=>{
  const [modal,setModal] = useState(false)
  const alarm  =  useSelector(getNotifyVoice) //useState("Beep Alarm Clock")
  const voiceEnable  = useSelector(getVoiceEnable)  // useState(true) 
  const soundsNoti = useSelector(getSoundNotify)
  const notifyVoice = useSelector(getNotifyVoice)
  const [sound , setSound] = useState<Sound|null>(null)
   

  const dispatch = useDispatch() 
  let audio:HTMLAudioElement|null = null ;

  const chageSound=async(name:any)=>{
    setSound(null)
    let sound = await soundsNoti.find((e:any)=> e?.name.indexOf(name) > -1)
    setSound(sound)
  }
  
  useEffect(()=>{  
  },[notifyVoice , sound])

  return(
    <>
    <IonItem lines='none' mode='ios' color={"transparent"}  >
      <IonLabel slot="start" >เปิดเสียงแจ้งเตือน</IonLabel>
      <IonToggle slot="end" mode="ios" checked={voiceEnable} onIonChange={(e)=>{ dispatch(setVoiceEnable(e.detail.checked))}} > </IonToggle>
    </IonItem>
    {
          voiceEnable && 
          <IonItem  lines='none' mode='ios' color={"transparent"} >
            <IonLabel  >เสียงแจ้งเติอน </IonLabel>

            {sound && <audio id={"play-control"} controls >
               <source type="audio/wav" src={sound?.url} />
               <source type="audio/mp3" src={sound?.url} />
            </audio> }

            <select name={alarm} slot="end" id="alarm-sound" style={{margin:".5rem"}} 
            onChange={(e)=>{chageSound(e.target.value); dispatch(setNotifyVoice(e.target.value));}}> 
                {soundsNoti.map((e:any,id:any)=> <option key={id} value={e.name} > {e.name} </option> )}
            </select>
          </IonItem>
        } 
    </>
  )
}

export const IconVehicleType=()=>{
    const [isOpen, setIsOpen] = useState(false);
    const selected = useSelector(getSearchSelect)
    const [icon,setIcon] = useState(1)
    const [present] = useIonToast()
    const [vehihle,setVehicle] = useState({device_id:0 ,value: 0,name:"Select"})

    useEffect(()=>{},[selected])

    const updateDeviceTypeId=async()=>{
      if(vehihle.device_id > 0){ 
        const res = await setDeviceIcon({
            deviceId: vehihle.device_id ,
            deviceIconId: icon
        })
        if(res){
            present({
                message: 'Update device icon type succes !',
                ...toastSuccess
            });
        }else{
            present({
                message: 'Update device icon type Fail !',
                ...toastFail
            });
        }
        console.log("changeSelection ",res)
    }else{
        console.log("selected ",selected)
        present({
            message: 'Please select device !!',
            ...toastFail
        });
    }
    }

    return(
        <>
        <IonItem lines='none' mode='ios' color={"transparent"} onClick={() => setIsOpen(true)} >
            <IonLabel>ไอคอนอุปกรณ์</IonLabel>
        </IonItem> 

        <IonModal mode="ios" isOpen={isOpen} onDidDismiss={(e)=>{setIsOpen(false)}}>
          <IonHeader className="header  ion-no-border">
            <IonToolbar>
              <IonTitle> Change Icon </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonGrid>
                <IonRow className="ion-justify-content-center" >
                    <IonCol size="11" >
                       <DropDownDevice  vehihle={vehihle} setVehicle={(e:any)=>{setVehicle(e) }} /> 
                      {/* <DevicesList /> <br/> */}
                    </IonCol>
                    <IonCol size="12" >
                      <SettingIcon deviceId={selected?.device_id} setIcon={(id:any)=>setIcon(id)} />
                    </IonCol>
                    <IonCol size="12" >
                      <IonButton  onClick={()=>{updateDeviceTypeId()}} >Save</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid> 
          </IonContent>
        </IonModal>
        </>
    )
}


export const LoginSessions=()=>{
  const [isOpen, setIsOpen] = useState(false);
  const [list,setList] = useState([])
  const [token,setToken] = useState("")
  const [presentAlert] = useIonAlert(); 
  const [presentToast] = useIonToast();

  const revoke=async(data:any)=>{ 
    const server = await getStorage("server")
    const result = await revokeSession({server:server, token:token , id:data.id , session:data.session })
    if(result.result){
      setup()
      presentToast({
        message: 'Revoke session complete !!',
        duration: 3000,
        color:'success',
        icon: checkmarkDoneOutline
      })
    }else{
      presentToast({
        message: 'Revoke session fail !!',
        duration: 3000,
        color:'danger',
        icon: closeOutline
      })
    }
  }
  
  const setup=async ()=>{
    const server = await getStorage("server")
    const token = await getStorage("token")
    const userId = await getStorage("userId")
    setToken(token)
    const sessions = await getSessions({server:server,token:token,userId:userId})
    setList(sessions)
    console.log("sessions ",sessions)
   }

  useEffect(()=>{ 
   setup()
  },[])

  return(
      <>
      <IonItem lines='none' mode='ios' color={"transparent"} onClick={() => setIsOpen(true)} >
          <IonLabel>Login Sessions</IonLabel>
      </IonItem> 

      <IonModal mode="ios" isOpen={isOpen} onDidDismiss={(e)=>{setIsOpen(false)}}>
        <IonHeader className="header  ion-no-border">
          <IonToolbar>
            <IonTitle> Sessions </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonGrid>
            {
              list.map((ses:any,index)=>
              <IonRow key={index} style={{marginBottom:'.5rem'}}>
                  <IonCol size="1" className="set-center" style={{fontSize:'1.3em'}}>
                    {token === ses.session ? <IonIcon icon={key} color="success" /> :<IonIcon icon={keyOutline}  /> }
                    
                  </IonCol>
                  <IonCol size="9" >
                    <IonLabel>
                       {token === ses.session ? <IonLabel color={"primary"}> current  </IonLabel> :<></> }
                      <IonLabel>{ ses.session.substr(0,50) }...</IonLabel><br/>
                      <sub>UUID: {ses.uuid} | User ID {ses.user_id}</sub>
                    </IonLabel>
                  </IonCol>
                  <IonCol size="2" className="set-center" >
                    <IonButton size="small" color={"danger"} 
                    onClick={() =>
                      presentAlert({
                        mode:'ios',
                        header: 'Confirm to delete sesssion !',
                        buttons: [
                          {
                            text: 'Cancel',
                            role: 'cancel', 
                          },
                          {
                            text: 'OK',
                            role: 'confirm',
                            handler: () => { 
                              revoke(ses)
                            },
                          },
                        ], 
                      })
                    }> <small>Revoke</small> </IonButton>
                  </IonCol>
                </IonRow>
              )
            } 
          </IonGrid> 
        </IonContent>
      </IonModal>
      </>
  )
}