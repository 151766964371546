import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonCol, IonRow, IonImg, IonIcon, IonLabel, IonChip, IonNavLink, IonInput, IonItem, IonCheckbox } from "@ionic/react"
import { batteryChargingOutline, batteryDeadOutline, batteryHalfOutline, chatbubbleOutline, chevronBack, chevronForward, flameOutline, idCard, idCardOutline, locateOutline, locationOutline, pin, shareSocialOutline, speedometerOutline, terminalOutline, thermometerOutline, videocamOutline, wifiOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome" 
import {  faCircleCheck, faCircleXmark,  } from '@fortawesome/free-solid-svg-icons' 
import { useDispatch, useSelector } from "react-redux"; 
import { SlideNextButton } from "./DevicesContainer"; 
import { ChipFuel, ChipGPS, ChipGSM, ChipPTO, ChipSpeed, ChipStreetView, ChipTemp, DeviceIcon, DeviceName, DeviceStatusNameTime, DeviceUpdateTime, MileInput } from "./IconStatus";
import { OptionDialog } from "./OptionDialogs";
import { getTrackRealtime, setTrackRealtime } from "../store/mapSlice"
import  config from "../api.config.json"
import { getAddress, getExpireDate, getName, getRatePoint, getRealtimeTrack, getSensorBattery, getSensorCardName, getSensorCardNumber, getSensorFuel, getSensorGPS, getSensorGSM, getSensorMile, getSensorSpeed, getSensorTemperture, getStatusName, getUpdateTime } from "../store/filterSlice"
import { IconRouteToday } from "./AppComponent"
import { useSwiper } from "swiper/react"

import * as Moment from "moment-timezone"
import { extendMoment } from 'moment-range'
import "./css/CardDevice.css"

const moment = extendMoment(Moment);
moment.locale("th")
  
export const CardDevice  =({device,modal , select} :any)=>{ 
    const showName = useSelector(getName)
    const status = useSelector(getStatusName)
    const updateTime = useSelector(getUpdateTime)
    const speed = useSelector(getSensorSpeed)
    const battery = useSelector(getSensorBattery)
    const fuel = useSelector(getSensorFuel)
    const gps = useSelector(getSensorGPS)
    const gsm = useSelector(getSensorGSM)
    const temp = useSelector(getSensorTemperture)
    const address = useSelector(getAddress)
    const expiredDate = useSelector(getExpireDate)
    const cardNumber = useSelector(getSensorCardNumber)
    const cardName = useSelector(getSensorCardName)
    const miledge = useSelector(getSensorMile)
    const realtimeTrack = useSelector(getRealtimeTrack)
    const ratePoint = useSelector(getRatePoint)
    const swiper = useSwiper()

    useEffect(()=>{
 
    },[])

    const isExpired=(expire_date:any)=>{
        const start =  moment(new Date()).add(543,"year") ;
        const end =moment(expire_date); 
        const r1 = moment.range(start.tz('Asia/Bangkok'), end.tz('Asia/Bangkok'));  
        var diff = r1.diff('minute') ; 
      
        return diff > 0 ? false : true
    } 

    return( <>
    { device.event_id !==null ? 
     <IonCard className="card" mode="ios">
        {   expiredDate.card &&
            <IonChip   className="chip float"  >
                { isExpired(device.expiration_date)?
                    <IonLabel aria-label="icon-expired" color={"danger"}><FontAwesomeIcon icon={faCircleXmark} /></IonLabel>:
                    <IonLabel aria-label="icon-actived" color={"success"}><FontAwesomeIcon icon={faCircleCheck} /></IonLabel>
                }
            <IonLabel aria-label={isExpired(device.expiration_date)?"expired":"actived"} className="expire-date"> &nbsp;{moment(device.expiration_date).format("DD MMMM YYYY")} </IonLabel>
            </IonChip>
        }
        <IonCardHeader> 
            <IonRow>
                {realtimeTrack.card && 
                <IonCol size="1" className="set-center" >
                    <CheckTrackRealtime device={device} />
                </IonCol> }
                <DeviceIcon deviceIconId={device.deviceIconId} statusName={device?.status_name} status={device.status} heading={device.heading}  online={device.online} employee={device.employee} /> 
                <IonCol  style={{paddingBottom:'0'}}>
                    {showName.card && <DeviceName size="1em" deviceid={device?.device_id} name={device?.name2 ? device?.name2 : device?.name} update={device.event_stamp} showRatePonit={ratePoint.card} />  } 
                    {status.card && <DeviceStatusNameTime online={device.online} status={device.status} statusName={device.status_name} statusTime={device.status_time} />}
                    <br/>
                    {updateTime.card && <small>{device.event_stamp}</small>}
                </IonCol> 
                <IonCol size='1'className="set-center" >  
                   {device?.event_id > 1  &&     <SlideNextButton  device={device} modal={modal} select={(e:any)=>{modal?.setCurrentBreakpoint(0.8);  return select(e) }} />  } 
                   <IconRouteToday  device={device} swiper={swiper} /> 
                </IonCol>
                
                <IonCol size="12"> 
                    <ChipStreetView device={device} />
                    {speed.card && <ChipSpeed speed={device.speed}/> }
                    {fuel.card && <ChipFuel fuel_liters={device.fuel_liters}/> }
                    {battery.card && <IonChip  className="chip stack" >
                        <IonIcon
                        icon={
                            device.fld_sensorHigh === null?batteryDeadOutline:
                            device.fld_sensorHigh === "1" ?batteryHalfOutline:batteryChargingOutline } 
                        color={ 
                        device.fld_sensorHigh === null?"medium":
                        device.fld_sensorHigh ==="1" ? "warning":"success"}></IonIcon>
                        <IonLabel>{device.fld_sensorHigh}</IonLabel>
                    </IonChip>}
                   {gsm.card &&  <ChipGSM fld_signalStrength={device.fld_signalStrength} />}
                   {gps.card &&  <ChipGPS satellites={device.satellites} />  }
                   {device.door_status!==null ? <ChipPTO closeOpenSensor={device.door_status}/> :<></>}
                   {temp.card &&  <ChipTemp temperature={device.temperature}/> }
                </IonCol>
            </IonRow> 
        </IonCardHeader>
  
        <IonCardContent> 
            <IonRow> 
               {  device?.odoCheckToday &&  <IonCol size="12" >
                   <IonLabel>{device?.odoToday? device?.odoToday:"-"}</IonLabel>
                 </IonCol> 
                }
                <IonCol size="12"  style={{paddingTop:'0'}}>
                    {address.card &&<IonLabel>{device.address} </IonLabel> }
                </IonCol> 
                {cardNumber.card && device.fld_driverID &&<IonCol className="set-center" size="1.5" >
                  <IonIcon color="success" icon={idCard} style={{fontSize:'1.5em'}} /> 
                </IonCol>}
                {cardNumber.card && <IonCol size="10"  style={{paddingTop:'0'}}> 
                    <IonLabel>  {device.fld_driverID} {cardName.card ? device.fld_driverMessage :''}</IonLabel>
                </IonCol> }
                <IonCol size="12"  style={{paddingTop:'0',paddingLeft:"0"}}>
                    {miledge.card && <MileInput mile={device.mile} car={device} /> }
                </IonCol> 
            </IonRow>
            <IonRow>
                <OptionDialog device={device} /> 
            </IonRow>
        </IonCardContent> 
   </IonCard>:<></>} 
   </>
    )
}
 

export const CheckTrackRealtime=({device}:any)=>{
    const trackRealtime:any [] = useSelector(getTrackRealtime)
    const [check,setCheck] = useState(false)
    const dispatch = useDispatch()

    const isTracking=async()=>{ 
        if(trackRealtime.length > 0){
            let found = await trackRealtime.find((track:any)=> track.device_id === device.device_id )
           
            if(found!==undefined && found !== null){
                setCheck(true)
            }else{ setCheck(false) }
        }else{
            setCheck(false)
        }
    }

    const checkToTrack=async(check:any)=>{
        console.log("check ", check)
        if(check){
            const checkAlready =await trackRealtime.find((track:any)=> track.device_id===device.device_id )
            if(!checkAlready){
                const tracking = {
                    device_id: device.device_id ,
                    name: device.name ,
                    line: {
                        isDash: false,
                        dashArray:null, //[5, 10] 
                        color: config.color, 
                    },
                    route:[[parseFloat(device.latitude),parseFloat(device.longitude)]]
                }
                let arrays:any[] = []
                arrays = trackRealtime
                console.log("arrays ",arrays)
                const addDeviceTrack = [...arrays, tracking]
                dispatch(setTrackRealtime(addDeviceTrack))
                isTracking()
            }else{
                console.log("device "+device.name+" is already tracking. ")
            }
        }else{
            const removeDeviceTrack =await trackRealtime.filter((track:any)=> track.device_id!==device.device_id )
            await dispatch(setTrackRealtime(removeDeviceTrack))
            setCheck(false)
        }
       
    }

    useEffect(()=>{
        isTracking()
    },[trackRealtime,check])

    return( <IonCheckbox checked={check} aria-label="" onIonChange={(e)=>{checkToTrack(e.detail.checked)}} ></IonCheckbox>)
}
 
