import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPopover, IonRadio, IonRadioGroup, IonRippleEffect, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, useIonPopover } from "@ionic/react"
import { addCircle, caretDown, checkmarkCircle, chevronBack, chevronBackOutline, chevronDown, chevronForward, chevronForwardOutline, close, closeCircle, compassOutline, copyOutline, ellipsisVertical, filterCircleOutline, folderOpen, folderOpenOutline, logoFacebook, logoTwitter, moon, moonOutline, searchOutline, star, sunny, sunnyOutline } from "ionicons/icons"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBackup, getDateEnd, getDateStart, getDevices, getKeyword, getSharePop, setDateEnd, setDateStart, setDevice, setDevices, setKeyword, setSharePop,getDeviceSelect, getSearchSelect, setSearchSelect, getDarkMode, setDarkMode, getOS, setLoading, setBackupDevices, getDeviceGroup, setSignin } from "../store/appSlice"
import { faFacebookMessenger, faLine } from "@fortawesome/free-brands-svg-icons"
import { QRCodeCanvas } from "qrcode.react";
import { allDevices, colourStyles, deviceByGroup, deviceEvent, fasttrack, getStorage, historyRoute, setStorage } from "../actions"
import { Swiper ,SwiperSlide, useSwiper } from "swiper/react" 
import "./css/AppComponent.css"
import { t } from "i18next" 
import { getGeoZones, getMapDark, setFastTrack, setPreviewDevice, setQuickTrackList } from "../store/mapSlice"
import sha1 from "sha1"
import { faRoute } from "@fortawesome/free-solid-svg-icons"
import { BtnDel, BtnEdit, BtnActive, AddEditTableModal, BtnMapDialog } from "../pages/Admin"
import toast from "react-hot-toast"
import Select, { StylesConfig } from 'react-select'
import { convertMsToTime } from "../pages/Reports/ReportUtils" 
import {Swiper as SwiperType } from "swiper"
 

export const DateRange=({padInput,datestart,dateend}:any)=>{
    const dateStart = useSelector(getDateStart) 
    const dateEnd = useSelector(getDateEnd)
    const modal = useRef<HTMLIonModalElement>(null);
    const input = useRef<HTMLIonInputElement>(null);
    const [dateModal,setDateModal] = useState(false)
    const dispatch = useDispatch()

    const [start,setStart] = useState("")
    const [end,setEnd] = useState("")
    const [max,setMax] = useState("")
  
    useEffect(()=>{ 
        // setStart(moment().format('YYYY-MM-DDT00:00'))
        // setEnd(moment().format('YYYY-MM-DDT23:59'))
        setMax(moment().add(7,'days').format('YYYY-MM-DDT23:59'))
        
     
            console.log("datestart ",datestart)
            setStart(datestart)
            setMax(moment(datestart).add(7,'days').format('YYYY-MM-DDT23:59:59'))
      
 
            console.log("dateend ",dateend)
            setEnd(dateend)
       
    },[])
  
    function confirm() {
      modal.current?.dismiss(input.current?.value, 'confirm');
    }

    const confirmDateTime=()=>{ 
        dispatch(setDateStart(start))
        dispatch(setDateEnd(end))
    }
   
    return(
        <>
            <IonRow>
                <IonCol size="5.5" style={{padding:'0px'}}>
                <div className='input date ion-text-center'  onClick={()=>{setDateModal(true)}}  style={{padding:padInput?padInput:".3rem",cursor:'pointer',width:'100%'}}    > 
                    <IonLabel style={{fontSize:'.85em'}} color={"dark"}>{moment(start).format("DD/MM/YY HH:mm")}</IonLabel>
                </div >
                </IonCol>
                <IonCol size="1" className="set-center" >
                    <IonIcon icon={ellipsisVertical}  />
                </IonCol>
                <IonCol size="5.5" style={{padding:'0px'}} >
                <div className='input date ion-text-center' onClick={()=>{setDateModal(true)}} style={{padding:padInput?padInput:".3rem",cursor:'pointer',width:'100%'}}   > 
                    <IonLabel  style={{fontSize:'.85em'}} color={"dark"}>{moment(end).format("DD/MM/YY HH:mm")}</IonLabel>
                </div >
                </IonCol>
            </IonRow>

            <IonModal ref={modal} isOpen={dateModal}  mode="ios" className="daterange-modal" onWillDismiss={(ev) => setDateModal(false)}>
            <IonHeader>
                <IonToolbar> 
                <IonTitle>เริ่มต้น - สิ้นสุด</IonTitle> 
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonGrid>
                <IonRow>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="6" className="ion-justify-content-center" >
                        <IonDatetime value={start} locale="th-TH" onIonChange={(e:any)=>{setStart(e.detail.value);  setMax(moment(e.detail.value).add(7,'days').format('YYYY-MM-DDT23:59'))}}></IonDatetime></IonCol>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="6" className="ion-justify-content-center" >
                        <IonDatetime value={end} min={start} max={max} locale="th-TH" onIonChange={(e:any)=>{setEnd(e.detail.value)}}></IonDatetime></IonCol>
                </IonRow>
                </IonGrid>
            </IonContent>
            <IonFooter>
            <IonToolbar> 
                <IonButtons slot="start">
                    <IonButton strong={true} color='danger' onClick={() => confirm()}>
                    Cancle
                    </IonButton> 
                </IonButtons>
                <IonButtons slot="end"> 
                    <IonButton strong={true} color='secondary' onClick={() =>{confirmDateTime();confirm()}}>
                    <b>Confirm</b>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            </IonFooter>
            </IonModal>
        </>
    )
}

export const DateStart=(props:any)=>{    
    const dateStart = useSelector(getDateStart)
    const [popDateStart,setPopDateStart] = useState(false)

    return (
        <>
        <div className='input date ion-text-center'  id="open-date-start-modal" onClick={()=>{setPopDateStart(true)}}  style={{padding:".5rem",cursor:'pointer'}}    > 
            <IonLabel  color={"dark"}>{dateStart}</IonLabel>
         </div > 
         <IonModal mode="ios" trigger="open-date-start-modal" isOpen={popDateStart} onDidDismiss={()=>{setPopDateStart(false)}} >
            <IonContent className="ion-padding">
                <IonDatetime locale="th-TH" doneText="OK" className='popover-date'  >
                    <span slot="time-label">Time</span>
                </IonDatetime>
            </IonContent>
        </IonModal>
      </>
      );
    }


// export const DateStart=(props:any)=>{
//     const dispatch = useDispatch()
//     const dateStart = useSelector(getDateStart)
//     const [popDateStart,setPopDateStart] = useState(false)

//     useEffect(()=>{ 
//         dispatch(setDateStart(moment().format('DD/MM/YYYY 00:00')))
//     },[])
 
//     const changeDateTime=(val:any)=>{
//         const dateFormat = val
//         dispatch(setDateStart(dateFormat))
//     }
//     const dateFormat=(datetime:any)=>{
//         let date =  moment(datetime).format("DD/MM/YYYY HH:mm").toString()
//         console.log("date ",date)
//         return datetime
//     }

//     return(<>
//         <div className='input date ion-text-center' onClick={()=>{setPopDateStart(true)}}  style={{padding:".5rem",cursor:'pointer'}}    > 
//             <IonLabel  color={"dark"}>{dateFormat(dateStart) }</IonLabel>
//         </div >
//         <IonModal  
//             mode="md"    
//             className='popover-date' 
//             isOpen={popDateStart}
//             onDidDismiss={()=>{
//                 setPopDateStart(false)
//             }} 
//         >
//             <IonDatetime locale="th-TH" doneText="OK"  onIonChange={(e)=>{changeDateTime(e.detail.value)}}>
//                 <span slot="time-label">Time</span>
//             </IonDatetime>
//         </IonModal>
//     </>)
// }


export const DateEnd=(props:any)=>{
    const dispatch = useDispatch()
    const dateEnd = useSelector(getDateEnd)
    const dateStart = useSelector(getDateStart)
    const [popDateEnd,setPopDateEnd] = useState(false)

    useEffect(()=>{ 
        dispatch(setDateEnd(moment().format('DD/MM/YY 23:59')))
    },[])

    const changeDateTime=(val:any)=>{ 
        const dateFormat = val
        dispatch(setDateEnd(dateFormat))
    }

    return(<>
        <div className='input date ion-text-center' onClick={()=>{setPopDateEnd(true)}} style={{padding:".5rem",cursor:'pointer'}}   > 
            <IonLabel color={"dark"}>{dateEnd}</IonLabel>
        </div >
        <IonPopover
            mode="md"    
            className='popover-date' 
            isOpen={popDateEnd}
            onDidDismiss={()=>{setPopDateEnd(false)}}  
        >
            <IonDatetime  value={dateEnd} doneText="OK" locale="th-TH"   onIonChange={(e)=>{changeDateTime(e.detail.value)}}>
                <span slot="time-label">Time</span>
            </IonDatetime>
        </IonPopover>
    </>)
}



export const IconShare=()=>{
    const sharepop = useSelector(getSharePop)
    const dispatch = useDispatch()
    const [realtime,setRealtime] = useState("")

    const setPopShow=(e:any)=>{
        dispatch(setSharePop(e))
    }

    useEffect(()=>{
        console.log("sharepop ",sharepop)
        const getProfile=async()=>{
            const profile = await getStorage("profile")
            console.log("profile ", profile)
            
            const linkRealtime = profile.url_realtime
            const code = btoa(JSON.stringify(sharepop.id) +':Andaman067');
            setRealtime(linkRealtime+code)
        }
        if(sharepop.ishow){
            getProfile()
        }
    },[sharepop])

    const shareSocail=(socail:any)=>{
         
        if(socail === "facebook"){
            window.open("https://www.facebook.com/sharer/sharer.php?u="+realtime,'popup','width=600,height=600') 
        }else if(socail === 'twitter'){
            window.open("https://twitter.com/intent/tweet?text="+realtime,'popup','width=600,height=600')
        }else if(socail === 'line'){
            window.open("https://social-plugins.line.me/lineit/share?url="+realtime,'popup','width=600,height=600')
        }else if(socail === 'messenger'){ 
             window.open("fb-messenger://share?link="+realtime+"&app_id=229058943455862",'popup','width=600,height=600')
             
        }
    }
    
    const coppyText=(text:any)=>{
        navigator.clipboard.writeText(text);
    }

    return(
    <>  
        <IonPopover mode="ios" trigger={"share-popover"+sharepop.id} side="right" alignment="start" className="popover-share" showBackdrop={false} isOpen={sharepop.ishow} 
            onDidDismiss={()=>{setPopShow({ishow:false,id:sharepop.id})}} >
            <IonContent class="ion-padding">
                <IonIcon icon={close} className='close-share-popover' onClick={()=>{setPopShow({ishow:false,id:sharepop.id})}} />
                <IonRow>
                    <IonCol size="7" > 
                        <IonLabel className="socail" onClick={()=>{shareSocail("messenger")}} style={{color:"#38529A"}}> <FontAwesomeIcon icon={faFacebookMessenger} /> </IonLabel>
                        <IonIcon  className="socail" onClick={()=>{shareSocail("facebook")}} style={{color:"#38529A"}} icon={logoFacebook} />
                        <IonIcon  className="socail" onClick={()=>{shareSocail("twitter")}}  style={{color:"#1C96E9"}} icon={logoTwitter} />
                        <IonLabel className="socail" onClick={()=>{shareSocail("line")}}  style={{color:"#3ACE00"}}><FontAwesomeIcon icon={faLine} /></IonLabel>
                        <br/><br/>

                        <div className="input"  >
                          <IonItem lines="none" color={"transparent"}>
                            <IonInput placeholder="Realtime URL" slot="start" value={realtime}></IonInput>
                            <IonButton slot="end" onClick={()=>{coppyText(realtime)}} >Copy</IonButton>
                          </IonItem>
                        </div>
                    </IonCol>
                    <IonCol size="5" className="set-center"  >
                        <QRCodeCanvas
                            id="qrCode"
                            value={realtime}
                            size={110}
                            bgColor={"#FFFFFF"}
                            level={"L"} 
                        />
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPopover> 
    </>
    )
}

export const CustomNotify=({t,title,message,icon}:any)=>{ 
    // console.log("t ",t) 
    return (
      <div className={`custom-notification ${t.visible? "slide-bottom":"slide-bck-bottom"}`  } >
         <IonButton size="small" color={"dark"}  fill="clear"  className="closd" onClick={()=>{toast.dismiss(t.id)}} >
            <IonIcon icon={close}  />
          </IonButton>
      <IonRow>
          {icon && <IonCol size="2"> 
            <div  className="special-icon-notify" style={{backgroundImage: `url("${icon}")`}}  >  </div>
          </IonCol> }
          <IonCol size="10" className="set-center">
            <IonLabel>
              <IonLabel><strong>{title}</strong></IonLabel> <br/>
              <IonLabel className="message">{message}</IonLabel>
            </IonLabel>
          </IonCol> 
      </IonRow>
      </div>
    )
  }


export const DropDownGeozone=({zoneSelect , selectZone}:any)=>{
    const [focus,setFocus] = useState(false)
    const geofences = useSelector(getGeoZones)
    const [select,setSelect] = useState<any>({value:0 , name:"Select"}) 
    const [keyword,setKeyword] = useState("")
    const [geozones,setGeozone] = useState([])
    const [geozoneBackup,setGeozoneBackup] = useState([])
  
    const clear=()=>{
       return setSelect({value:0 , name:"Select"}) 
    }

    useEffect(()=>{ 
        setGeozone(geofences)
        setGeozoneBackup(geofences)
    },[])

    const searchOption=async(word:any)=>{
        if(word.length >1 ){
            setKeyword(word) 
            const needle:any = word  
            const filter = await geofences.filter((e:any)=> e.name?.toLowerCase().indexOf(needle) > -1)
            setGeozone(filter)
        } else{
            setGeozone(geozoneBackup)
        }
    }
    
    return(
    <div id="devices-drop-down-container" >
        <div className="dropdown-input"  >
            <IonIcon  icon={searchOutline} style={{fontSize:"1.2em",marginRight:".5rem"}} />
            <IonInput placeholder="เขตพื้นที่" mode="ios"  value={keyword}  
              onIonInput={(e:any)=>{searchOption(e.detail.value)}}  
              onIonFocus={()=>{setFocus(true)}} 
            >  </IonInput> 

           {select && select?.value > 0 ?  <IonButton  fill="clear" size="small"  color="medium" 
                 onClick={()=>{ setKeyword("");setSelect({value:0 , name:"Select"}) ; return selectZone({value:0 , name:"Select"})}}
             >   <IonIcon icon={closeCircle} style={{transition:`rotate(${focus?'180':'0'}deg)`}}/>
             </IonButton> : <IonButton  fill="clear" size="small"  color="medium" 
                 onClick={()=>{setFocus(false)}}
             >   <IonIcon icon={caretDown} style={{transition:`rotate(${focus?'180':'0'}deg)`}}/>
             </IonButton> }  
        </div>
        <div  id="devices-drop-down-input"  > 
        <IonList className="wrapper" style={{maxHeight:"25rem",overflowY:'scroll'}} >
            {
                geozones.map((op:any,index:any)=> 
                 <IonItem key={index}  
                 className={`ion-activatable ${zoneSelect && op.value === zoneSelect?.value && "active" } `}    
                 onClick={()=>{
                    setKeyword(op?.name)
                    setSelect({value: op?.id , name: op?.name}); 
                    return selectZone({value: op?.id , name: op?.name})
                }} >
                    <IonLabel> {op.name} </IonLabel>
                    <IonRippleEffect></IonRippleEffect>
                    <IonIcon icon={chevronForward} slot="end" style={{fontSize:".7em"}} />
                 </IonItem>
                )
            }
            </IonList>
        </div> 
    </div>
    )
}

interface SelectOption {
    device_id:number 
    value: number 
    label: string
}
interface SelectOptionCheck {
    checked: Boolean
    device_id:number 
    value: number 
    label: string
}
interface DropDownDevice{
    vehihle:any
    setVehicle:any
    optionsBack:SelectOption[]
}

export const DropDownDevice=({vehihle,setVehicle,optionsbackup  }:any)=>{
    const [focus,setFocus] = useState(false)
    const backup = useSelector(getBackup)   
    const [keyword,setKeyword] = useState<any>("")
    const [select , setSelect] = useState<SelectOption | null>()
    const [options,setOption] = useState<SelectOption[]>(optionsbackup) 
    const [optionsGroup,setOptionGroup] = useState<any[]>()
    const [optionsBack,setOptionBackup] = useState<SelectOption[]>(optionsbackup)  
    const [asGroup , setAsGroup] = useState(true)
    const deviceGroup = useSelector(getDeviceGroup)
    const [accGroup ,setAccGroup ] = useState("")
    const refDropdown = useRef<any>() 
 
    const handleClickOutside=(e:any)=>{  
        if (refDropdown && !refDropdown?.current?.contains(e.target)) {
            if(focus) { setFocus(false)  }
        }  
    }

    const searchOption=async(word:String)=>{
        if(word.length >1 ){
            setKeyword(word) 
            const needle:any = word  
            const filter = await optionsBack.filter((e:any)=> e.label?.toLowerCase().indexOf(needle) > -1 )
            setOption(filter)
        } else{
            setOption(optionsBack)
        }
    }
    const clear=()=>{
       return setVehicle({device_id:0, value:0 ,label: ""})
    }

    useEffect(()=>{   
        setOption(optionsbackup) 
        setOptionBackup(optionsbackup) 
        if(refDropdown){
            document.addEventListener("mousedown",  handleClickOutside );
        }  
        if(vehihle ){
            setKeyword(vehihle?.label)
        }
        const setupOptions=async()=>{
            const newOption:any[] = await  backup.map((e:any)=>{
                return {device_id:e.device_id, value: e.device_id , label: e.name, name2:e.name2}
            }) 
            setOption(newOption) 
        }
        setupOptions()
        setGroupOption()
    },[vehihle, optionsbackup,accGroup])
    
    const setGroupOption=async()=>{ 
        let deviceGroup = await deviceByGroup({}) 
         const newOption:any[] =  await  deviceGroup?.devices.map((e:any)=>{ 
           return  {...e , devices: e.devices } 
        })   
        setOptionGroup(newOption)
    }
 

    return(
    <div id="devices-drop-down-container" >
        <div className="set-center-row" style={{background:"transparent"}}> 
        <IonButton fill="clear" color={"dark"} onClick={()=>{setAsGroup(!asGroup)}} > 
            <IonIcon icon={folderOpenOutline} color="dark" /> 
            {!asGroup && <IonIcon icon={checkmarkCircle} color="primary" style={{position:"absolute" , bottom:"-3px", right:"-6px", fontSize:".9em"}}/>}
        </IonButton>
        <div className="dropdown-input"  >
     
            <IonIcon  icon={searchOutline}  color="dark"  style={{fontSize:"1.2em",marginRight:".5rem"}} />
            <IonInput placeholder="ทะเบียน" mode="ios"  value={keyword}  color={"dark"} className="input-devicename"
              onIonInput={(e:any)=>{searchOption(e.detail.value)}}  
              onIonFocus={()=>{setFocus(true)}} 
            >  </IonInput>
           {select && select?.value > 0 ?  <IonButton  fill="clear" size="small"  color="medium" 
                 onClick={()=>{ clear()}}
             >   <IonIcon icon={closeCircle} style={{transition:`rotate(${focus?'180':'0'}deg)`}}/>
             </IonButton> : <IonButton  fill="clear" size="small"  color="medium" 
                 onClick={()=>{setFocus(false)}}
             >   <IonIcon icon={caretDown} style={{transition:`rotate(${focus?'180':'0'}deg)`}}/>
             </IonButton> }  
        </div>
        </div>
        <div  id="devices-drop-down-input"  >  
            {asGroup ? 
            <IonList className="wrapper" style={{maxHeight:"25rem",overflowY:'scroll'}} >
            {
               options &&  options.map((op:any,index:any)=> 
                <IonItem key={index} color={select && op.value === select?.value ?"primary":"light"} className={`ion-activatable   `}  
                onClick={()=>{setSelect(op); return setVehicle(op);   }} >
                    <IonLabel  class="ion-text-wrap"> {op.label} </IonLabel>
                    <IonRippleEffect></IonRippleEffect>
                    <IonIcon icon={chevronForward} slot="end" style={{fontSize:".7em"}} />
                </IonItem>
                )
            }</IonList> :
            <IonAccordionGroup value={accGroup}   className="devices-accordion-group" onIonChange={(e)=>{setAccGroup(e.detail.value) }}>
                {optionsGroup?.map((d:any)=> <IonAccordion key={JSON.stringify(d.group_id)} value={d.group_id} > 
                <IonItem slot="header" color={"light"}  className="ion-text-wrap"   > 
                    <IonCheckbox checked={accGroup === d.group_id?true:false} value={d.group_id}  >{d.group} </IonCheckbox> &nbsp;
                    <IonLabel>{d.group}</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content" style={{maxHeight:"15rem", overflowY:"scroll"}}>
                    {d.devices.map((op:any,deIndex:any)=>  
                    <IonItem key={deIndex} color={select && op.device_id === select?.value ?"primary":"light"}  className={`ion-activatable`}  
                      onClick={()=>{setSelect({
                        device_id: op.device_id , 
                        value:  op.device_id ,  
                        label:  op.name  
                      }); return setVehicle({
                        device_id: op.device_id , 
                        value:  op.device_id ,  
                        label:  op.name  
                      });   }} >
                          <IonLabel  class="ion-text-wrap"> {op.name} </IonLabel>
                          <IonRippleEffect></IonRippleEffect>
                          <IonIcon icon={chevronForward} slot="end" style={{fontSize:".7em"}} />
                      </IonItem>
                    ) }
                   
                </div>
                </IonAccordion> ) }
            </IonAccordionGroup>
            }
        </div> 
    </div>
    )
}

export const DropDownDeviceMultiple=({vehicles,setVehicle   }:any)=>{
    const [focus,setFocus] = useState(false)
    const backup = useSelector(getBackup)   
    const [keyword,setKeyword] = useState<any>("")
    const [select , setSelect] = useState<SelectOption | null>()
    const [options,setOption] = useState<SelectOption[]>([])
    const [optionsBack,setOptionBackup] = useState<SelectOptionCheck[]>([])  

    const [optsCheck , setOptsCheck] = useState([])
    const refDropdown = useRef<any>()
    const dispatch = useDispatch()
 
    const handleClickOutside=(e:any)=>{  
        if (refDropdown && !refDropdown?.current?.contains(e.target)) {
            if(focus) { setFocus(false)  }
        }  
    }

    const searchOption=async(word:String)=>{
        if(word.length >1 ){
            setKeyword(word) 
            const needle:any = word  
            const filter = await optionsBack.filter((e:any)=> e.label?.toLowerCase().indexOf(needle) > -1 )
            setOption(filter)
        } else{
            setOption(optionsBack)
        }
    }
    const clear=()=>{
       return setVehicle({device_id:0, value:0 ,label: ""})
    }

    useEffect(()=>{   
        if(refDropdown){
            document.addEventListener("mousedown",  handleClickOutside );
        }  
       
        const setupOptions=async()=>{
            const newOption:any[] = await  backup.map((e:any)=>{
                return {checked:false , device_id:e.device_id, value: e.device_id , label: e.name}
            }) 
            setOption(newOption) 
        }
        setupOptions()
    },[vehicles])
 

    return(
    <div id="devices-drop-down-container" >
        <div className="dropdown-input"  >
     
            <IonIcon  icon={searchOutline} style={{fontSize:"1.2em",marginRight:".5rem"}} />
            
            <IonInput placeholder="ทะเบียน" mode="ios"  value={ vehicles.filter((val:any)=>val.checked).length > 0 ? 
                vehicles.filter((val:any)=>val.checked).map((e:any)=> e.label ).reverse()  :keyword}  color={"dark"}
              onIonInput={(e:any)=>{searchOption(e.detail.value)}}  
              onIonFocus={()=>{setFocus(true)}} 
            >  </IonInput>  
           {select && select?.value > 0 ?  <IonButton  fill="clear" size="small"  color="medium" 
                 onClick={()=>{ clear()}}
             >   <IonIcon icon={closeCircle} style={{transition:`rotate(${focus?'180':'0'}deg)`}}/>
             </IonButton> : <IonButton  fill="clear" size="small"  color="medium" 
                 onClick={()=>{setFocus(false)}}
             >   <IonIcon icon={caretDown} style={{transition:`rotate(${focus?'180':'0'}deg)`}}/>
             </IonButton> }  
        </div>
        <div  id="devices-drop-down-input"  >  
            <IonList className="wrapper" style={{maxHeight:"25rem",overflowY:'scroll'}} >
            {
                vehicles.map((op:any,index:any)=> 
                <IonItem key={index}  className={`ion-activatable ${select && op.value === select?.value && "active" } `}    >
                    <IonCheckbox checked={op?.checked}     value={op.value}
                     onIonChange={(e:any)=>{
                       setSelect(op);   
                       return setVehicle( index ,   {...op , ...{checked: e.detail.checked }  });   
                     }}/>
                    <IonLabel>&nbsp; {op.label} </IonLabel>
                    <IonRippleEffect></IonRippleEffect>
                    <IonIcon icon={chevronForward} slot="end" style={{fontSize:".7em"}} />
                </IonItem>
                )
            }</IonList> 
        </div> 
    </div>
    )
}

export const DevicesList  =({setSelect}:any)=>{
    const devices = useSelector(getDevices) 
    const select = useSelector(getSearchSelect) 
    const dispatch = useDispatch()
    const  keyword  = useSelector(getKeyword) 
    const [options,setOption] = useState<SelectOption[]>([])
    const [value,setValue] = useState<SelectOption>() 

    useEffect(()=>{ 
        if(!select){
            dispatch(setKeyword(""))
        }
    },[keyword , select])
   
      useEffect(()=>{
        const selection=async()=>{
           let opt:SelectOption[] = await devices.map((el:any)=> { return {...el, ...{ value: el.device_id, label: el.name } } })
           opt.unshift({device_id:0, value:0 ,label: "Select Device"})
           console.log("opt ",opt)
           setOption(opt)
        }
        selection()
      },[select])
    
      const handleChange=(e:any)=>{
        console.log("handleChange ",e) 
        setValue(e)
        return setSelect(e)
      }
   
      return(
        <> 
         <Select 
            options={options} 
            className="input"  
            styles={colourStyles}
            value={value}
            onChange={handleChange}
            onInputChange={handleChange} 
         /> 
        </>
      )
  } 

export const  DeviceSearchPopup=({vehihle,setVehicle,id}:any)=>{
    const [options,setOptions] = useState([]) 
    const [optionsback,setOptionsBack] = useState([]) 
    const [keysearch,setKeySearch] = useState("")
    const devices = useSelector(getBackup)
    const dispatch= useDispatch();
    const [open,setOpen] = useState(false)
    const popover = useRef<HTMLIonPopoverElement>(null);
  
    useEffect(()=>{  
      const loaddevice=async()=>{
        const devicelist:any[] = await  allDevices({}) 
        dispatch(setDevices(devicelist))
      }
      loaddevice()
  
      dispatch(setLoading(false)) 
      setSelectOption()  
      optionWithKey()
    },[   ])
  
   const setSelectOption=async()=>{
    let newoptions:any = []
     await devices.map((e:any)=>{ 
      newoptions.push({device_id: e.device_id ,value: e.device_id  ,name: e.name ,deviceIconId: e.deviceIconId})
     })
     setOptions(newoptions)
     setOptionsBack(newoptions)
   }
  
   const optionWithKey=()=>{
    if(keysearch.length > 1){
      const needle = keysearch.toUpperCase()
      let filteroption = options.filter((e:any)=> e?.name.toUpperCase().search(needle) > -1 )
      console.log("filteroption ",filteroption)
      setOptions(filteroption)
    }else if(keysearch.length < 1){
      setOptions(optionsback)
    }
   }
  
    const searchDevice=(val:any)=>{ 
      setKeySearch(val)
      if(val.length > 1){
        const needle = val.toUpperCase()
        let filteroption = options.filter((e:any)=> e?.name.toUpperCase().search(needle) > -1 )
        console.log("filteroption ",filteroption)
        setOptions(filteroption)
      }else if(val.length < 1){
        setOptions(optionsback)
      }
    }
  
    return(
      <> 
      <IonRow style={{width: "100%"}}>
        <IonCol  className="set-center input set-center"   id={id} style={{display:"flex",flexDirection:'row',justifyContent:"flex-start",minHeight:"2.5rem"}}   onClick={()=>{setOpen(true)}} >
          <IonLabel color={"dark"} className='ion-no-margin' >Select: {vehihle.name}</IonLabel>  
        </IonCol>
         {vehihle.device_id > 0 &&  
        <IonCol size="1.5" > 
            <IonButton size="small" fill="clear" color={"medium"}  
             onClick={()=>{return  setVehicle({device_id:0 ,value:0 ,name: "เลือกอุปกรณ์" });}}
            >
                <IonIcon icon={closeCircle}  />
           </IonButton>
        </IonCol>
        }
      </IonRow>
          
      <IonPopover trigger={id} isOpen={open}   className='popover-device-select' mode='ios' 
      onIonPopoverDidDismiss={()=>{
        setKeySearch("") ;
        setOptions(optionsback)
      }}>
        <IonContent    > 
          <IonList>   
            <IonSearchbar  value={keysearch} onIonInput={(e:any)=>{ searchDevice(e.detail.value) }} ></IonSearchbar>
             <div  style={{height:"25rem" ,overflowY:"scroll" }} >
                {  options.map((dev:any,index:any) => 
                  <IonItem 
                    key={index} className='ion-text-wrap' 
                    style={{width:"100%"}} button 
                    onClick={()=>{ 
                      setVehicle({device_id: dev.device_id ,value: dev.device_id ,name: dev.name });
                      setOpen(false);
                       
                    }} 
                   >
                      <IonLabel  className='ion-text-wrap'  >{dev.name}</IonLabel>
                  </IonItem>
                ) } 
             </div>
          </IonList>
        </IonContent>
     </IonPopover> 
    </>
    )
  
  }

export const SwipBack=()=>{
    const swipe = useSwiper()
   
    const backAction=()=>{
        swipe.slidePrev()
    } 
    return(
        <IonButton className="btn-back-swiper mobile-mode" mode="ios" onClick={()=>{backAction()}}>
            <IonIcon icon={chevronBack} />
        </IonButton>
    )
}

export const AppTable=(props:any)=>{
    const rowHeader = props.header
    const contents = props.content

    useEffect(()=>{
        console.log("contents ",contents)
    },)

    const findValue=(data:any,index:any)=>{ 
          console.log("data ",data)
        return data.value 
    }

    return(
     <table className="app-table" >
        <tr className="app-table-headers">
            {rowHeader.map((head:any,index:any)=>
                <th key={index} className="header" aria-label={index==0?'first': index === rowHeader.length-1? 'last' :"between"} >{head.name}</th>
            )} 
        </tr>
        {
            contents.map(( content:any,i:any)=>
            <tr className="app-table-row">
            {  content.row.map((data:any,index:any)=>
                <td key={index} className="feild" 
                 aria-label={i===contents.length-1&&index===0?'bottom-left':i===contents.length-1&&index===content.row.length-1?"bottom-right":'bttom-row'} 
                >  
                 {findValue(data,index)}
                </td>
            )} </tr>
        )}
        </table>
    )
}

const IonTooltip=(props:any)=>{

    return(
        <div className="hover-text">hover me
            <span className="tooltip-text" id={props.position}>I'm a tooltip!</span>
        </div>
    )
}

export const StartRate=({point}:any)=>{

    return(
        <IonLabel style={{fontWeight:'200',fontSize:'.8em'}}>
            <IonIcon icon={star} color="warning" />
            <IonLabel> {`( ${point} )`}</IonLabel>
        </IonLabel>
    )
}

export const DarkModeSegment=({ui}:any)=>{
    const dispatch = useDispatch()
    const [dark,setDark] = useState("light")
    const isDarkMode = useSelector(getDarkMode) 
    const mapdark = useSelector(getMapDark)
  
    const  toggleDarkTheme=(shouldAdd:boolean)=> {
      document.body.classList.toggle('dark', shouldAdd);
    }
  
     useEffect(()=>{   
        if(isDarkMode){
            setDark("dark")
        }else{
            setDark("light")
        } 
      },[isDarkMode , mapdark])

      const changeMode=(value:any)=>{
        setDark(value)
        setStorage("darkmode",value === "dark"?true:false)
        if(value === "light"){
            toggleDarkTheme(false) 
            dispatch(setDarkMode(false)) 
        }else{ 
            toggleDarkTheme(true) 
            dispatch(setDarkMode(true)) 
        } 
      }

    return(<>
     {ui === "circle" ? <IonButton size="small" fill="clear" color={"dark"}  onClick={()=>{changeMode(dark ==="light"?"dark":"light") }}>
        {dark === "light" ? <IonIcon icon={sunnyOutline} /> : <IonIcon icon={moonOutline} />}
     </IonButton>:
        <IonSegment  id="themeSegment" mode='ios' value={dark} onIonChange={(e:any)=>{changeMode(e.detail.value)}}>
            <IonSegmentButton value={"light"} >
                <IonLabel style={{flexDirection:"row"}}  className='set-center'><IonIcon icon={sunny} />&nbsp; <small>Light</small></IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={"dark"}>
                <IonLabel  style={{flexDirection:"row"}}  className='set-center'><IonIcon icon={moon} />&nbsp; <small>Dark</small></IonLabel>
            </IonSegmentButton>
       </IonSegment>
     } 
    </>
    )
}

export const NoEvent  = ({message,height}:any) => {
    return (
      <div className="container ion-text-center set-center" style={{height: height?height:'25rem'}}>
        <IonImg src="../../assets/icon/empty.png"  style={{width:'4rem',marginBottom:".5rem"}}/>
        <p><IonLabel color={'medium'} >{message}</IonLabel>  </p>
      </div>
    );
  }; 


export const IconRouteToday=({device,swiper}:any)=>{
    const dateStart = useSelector(getDateStart) 
    const dateEnd = useSelector(getDateEnd)  
    const [color,setColor] = useState("#FD5F06")
    const dispatch = useDispatch()

    const fastTrack=async()=>{
        swiper?.slideTo(2);  
        dispatch(setPreviewDevice({active:true, device: device}))
        let lang =await  getStorage('language');
        let token =await  getStorage('token'); //'96a7461bda051faaca7777ce93351975b15b868546c6074a99fbedda388245fe'
        let host =  await getStorage("api") 
       
        let deviceId =  device.device_id
        // let date = moment(dateStart).format("DD/MM/YYYY")
        // let dateend = moment(dateEnd).format("DD/MM/YYYY")
        // let start = moment(dateStart).format("YYYY-MM-DD 00:00:00")   
        // let end = moment(dateEnd).format("YYYY-MM-DD 23:59:59") 

        let bodydate = [] 
        for(let i =0 ;i<7 ;i++ ){
            let between = {
                start: new Date(moment(dateStart).subtract(i,'day').format("YYYY-MM-DDT00:00:00")).getTime() ,
                end: new Date(moment(dateStart).subtract(i,'day').format("YYYY-MM-DDT23:59:59")).getTime() ,
            } 
            await bodydate.push(between)
        } 
        let date = moment(bodydate[bodydate.length-1].start).format("DD/MM/YYYY")
        let start = moment(bodydate[bodydate.length-1].start).format("YYYY-MM-DD 00:00:00")   
        let end = moment(bodydate[0].end).format("YYYY-MM-DD 23:59:59") 
        let dateend = moment(bodydate[0].end).format("DD/MM/YYYY")

        let routeid = await sha1(`start:${start},end:${end},id:${deviceId},create:${moment().format()}`)
        let result = await fasttrack({
            body: {
                rangeDate: bodydate ,
                deviceId:deviceId
            } ,
            routeid:routeid
        })
        // let route = await historyRoute({deviceId,date,dateend,start,end,token,host})
        console.log("result ",result) 
         
        let routeBound:any = []
        // result.list.forEach((list:any)=>{
        //   routeBound =[...routeBound,[list.latitude,list.longitude]]
        // })
       
        console.log("result.list ",result)
        let tarck={   
            _id : routeid+"1" ,
            deviceId: result?.deviceId,
            name: device.name ,
            route: null ,
            list: result?.list ,   
            typeId: !device.deviceIconId ?1:device.deviceIconId , 
            bound: result?.bound ,
            color: color,
            count: result?.count,
            datetime_start: result.datetime_start,
            datetime_stop: result.datetime_stop ,
            speed_max: result.speed_max ,
            time_all: result.time_all,
            distance_all: result.allDistancce,
            distance: result.distance,
        }
        
        console.log("fasttrack result ",result)
        tarck.distance_all = (result.allDistancce/1000).toFixed(0)+" "+t('kmUnit')
        tarck.time_all =convertMsToTime(result.allDuration)

        let routeLists:any=[]
        await result?.fastrack.forEach(async(el:any,index:any) => {   
            routeLists.push(
                {   date: moment(el?.date).format("YYYY-MM-DD") , 
                    distance: (el?.distance/1000).toFixed(0)  ,
                    duration: convertMsToTime(el.duration) ,  
                    routeid: el?.routeid ,
                    bound: el?.bound,
                    list: el?.list ,// listInDate
                    ...el
                },
            )
        });
        console.log("routeLists ",routeLists)
        tarck._id = routeLists[0]?.routeid
        tarck.list = routeLists[0]?.list
        tarck.bound = routeLists[0].bound
        
        dispatch(setPreviewDevice({active:false , device:null}))
        dispatch(setFastTrack(tarck))
        dispatch(setQuickTrackList(routeLists))
    } 
    return(   <FontAwesomeIcon icon={faRoute} style={{fontSize:"1.2em" }}  className="icon-opt"  onClick={()=>fastTrack()} />  )
}

export const SelectPopover=({id,contents,title,subtitle,selected,current,smallfont, addstyle ,position}:any)=>{
    const [popover,setPopover]=useState(false)
    const [backup,setBackup] = useState(contents)
    const [typing,setTyping] = useState('')
    const [listContent,setListContent] = useState(contents)

    const inputSearching=(word:any)=>{
        setTyping(word)
        if(word && word.length > 1){
            console.log("contents ",contents)
            const needle = word.toLowerCase() 
            const finding = contents.filter((e:any)=> e?.label.indexOf(needle) > -1)
            setListContent(finding) 
        
        }else{
            setListContent(backup)
        }
    }

    const closePopover=()=>{
        setPopover(false)
        setListContent(backup)
        setTyping("")
    }

    useEffect(()=>{ 
        console.log("contents ",contents)
        console.log("SelectPopovercurrent ", current)
    },[current])

    return(
    <div className="input" style={{...addstyle, ...{ position: "relative", padding:"0px"  }}} > 
        <IonRow id={"popover-select"+id} onClick={()=>setPopover(!popover)}>
            <IonCol size="10"  style={{padding:"0px"}} >
                <div className="set-center" style={{fontSize:smallfont?'.5em':'1rem',cursor:'pointer',padding:".4rem",alignItems:"flex-start",minHeight:"2.7rem" } } >
                    {current.label}
                </div>
            </IonCol>
            <IonCol className="set-center" size="2"  style={{padding:"0px"}}  >
                <IonButton fill="clear"  size="small" mode="ios" onClick={()=>{setPopover(!popover)}}>
                  <IonIcon icon={chevronDown} />
                </IonButton> 
            </IonCol>
        </IonRow>
        {
            popover &&
            <div className="bg-white select-popup-content" 
             onMouseLeave={()=>{setPopover(false)}} >
                <IonSearchbar  mode="ios"
                    style={{padding:'0px'}} placeholder="ค้นหา"
                    value={typing} 
                    onIonInput={(e)=>{inputSearching(e.detail.value)}}
                 ></IonSearchbar>
                <IonRadioGroup value={current.value} >
                    {   
                        contents.map((list:any,index:any)=> 
                        <IonItem key={index} onClick={()=>{return selected(list)}}> 
                            <IonRadio  mode="ios" value={list.value} aria-checked={list.value===current.value?true:false} labelPlacement="end" justify="start" style={{marginRight:'.5rem'}}> 
                              <IonText   color={"dark"}><small>{list.label}</small></IonText>
                            </IonRadio> 
                        </IonItem>
                        )
                     } 
                </IonRadioGroup>
            </div>  
        }
        {/* <IonPopover 
            mode="ios" isOpen={popover} 
            side={position?position.side: "left"} alignment={position? position.alignment:"center"}
            onIonPopoverDidDismiss={()=>{closePopover()}} trigger={"popover-select"+id} className="popover-select" triggerAction="click"
        >
            <IonContent class="ion-padding">
                <h5 style={{margin:'0px'}}>{title}</h5>
                <small>{subtitle}</small><br/>
                <IonSearchbar 
                    style={{padding:'0px'}} 
                    value={typing} 
                    onIonInput={(e)=>{inputSearching(e.detail.value)}}
                 ></IonSearchbar>
                <IonRadioGroup value={current.value} >
                    {   
                        listContent.map((list:any,index:any)=> 
                        <IonItem key={index} onClick={()=>{return selected(list)}}> 
                            <IonRadio  mode="ios" value={list.value} aria-checked={list.value===current.value?true:false} labelPlacement="end" justify="start" style={{marginRight:'.5rem'}}> 
                              <IonLabel color={"dark"}><small>{list.label}</small></IonLabel>
                            </IonRadio> 
                        </IonItem>
                        )
                     } 
                </IonRadioGroup>
             </IonContent>
        </IonPopover>  */}
    </div>    
    )
}

export const  SelectMultiplePopover=({ contents,current,title,subtitle, selected,clearSelect,position}:any)=>{ 
    const [popover,setPopover]=useState(false)
    const [backup,setBackup] = useState(contents)
    const [typing,setTyping] = useState('')
    const [listContent,setListContent] = useState(contents)
    const closePopover=()=>{
        setPopover(false)
        setListContent(backup)
        setTyping("")
    } 

    useEffect(()=>{ 
      

    },[current])

    const inputSearching=(word:any)=>{
        setTyping(word)
        if(word && word.length > 1){
            const needle = word.toLowerCase()
            const finding = backup.filter((e:any)=> e.label.toLowerCase().indexOf(needle) > -1)
            console.log("finding ",finding)
            setListContent(finding) 
        }else{
            setListContent(backup)
        }
    }

    const isChecked= (list:any)=>{
        if(current.length>0){
            const isselect =   current.find((e:any)=> e.value === list.value )
            if(isselect){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }

    return(
        <div className="input" style={{minHeight:'2.7rem'}}> 
        <IonRow id={"select-multiple-popover"} style={{minHeight:'2.7rem'}} onClick={()=>setPopover(true)}>
            <IonCol size="11.5" style={{minHeight:'2.7rem',padding:"0px"}} >
                {   current.length > 0 ?
                <div className="select-multiple-popover" >
               
                        {current.map((select:any,index:any)=> 
                        <div key={index} className="selected"> 
                            <IonLabel>{select.label}</IonLabel> 
                            <IonIcon icon={closeCircle} onClick={()=>{ setTimeout(()=>{setPopover(false) },200) ;return clearSelect(index)}}  /> 
                        </div> )}
                   
                </div>:<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center'}}>
                    <IonLabel > Select Device </IonLabel>
                </div> }
            </IonCol>
            <IonCol className="set-center" size=".5" style={{minHeight:'2.7rem' ,padding:"0px"}}  >
                <IonButton size="small" fill="clear" color={"dark"} >
                    <IonIcon icon={chevronDown} />
                </IonButton>
            </IonCol>
        </IonRow>
        <IonPopover 
            mode="ios" isOpen={popover} 
            side={position?position.side: "left"} alignment={position? position.alignment:"center"}
            onIonPopoverDidDismiss={()=>{closePopover()}} trigger={"select-multiple-popover"} className="popover-select" triggerAction="click"
        >
            <IonContent class="ion-padding">
                <h5 style={{margin:'0px'}}>{title}</h5>
                <small>{subtitle}</small><br/>
                <IonSearchbar 
                    style={{padding:'0px'}} 
                    value={typing} 
                    onIonInput={(e)=>{inputSearching(e.detail.value)}}
                 ></IonSearchbar> 
                    {   
                        listContent.map((list:any,index:any)=> 
                        <IonItem key={index} onClick={()=>{return selected(list)}}> 
                            <IonCheckbox  mode="ios" value={list.value} checked={isChecked(list)}  labelPlacement="end"  justify="start"  style={{marginRight:'.5rem'}}> 
                               <IonLabel color={"dark"}><small>{list.label}</small></IonLabel>
                            </IonCheckbox>
                        </IonItem>
                        )
                     }  
             </IonContent>
        </IonPopover> 
    </div>    
    )
}

export const  SelectMultiple=({options,value,selectOptions,smallfont}:any)=>{ 
      
    return(
        <div className="input" >
        <IonSelect  mode="ios"
            value={value} placeholder="Select" interface="popover" multiple={true} 
            onIonChange={(e:any)=>{return selectOptions(e.detail.value)}}  
        >
            {options.map((opt:any,index:any)=> 
              <IonSelectOption key={index} value={opt.value}  style={{fontSize:smallfont?'.5em':'1rem'}}   >
                <IonLabel  style={{fontSize:smallfont?'.5em':'1rem'}}   >{opt.label}</IonLabel>
              </IonSelectOption>
            )} 
        </IonSelect>
        </div>
    )
} 

const TableAdd=({id,role,action}:any)=>{
    const [isOpen,setIsOpen] = useState(false)
    return(<>
        <AddEditTableModal deviceId={id} role={role} action={action} isopen={isOpen} setCloseOpen={(e:any)=>{setIsOpen(e)}} />
        <IonButton fill="clear" size="small" onClick={(e)=>{setIsOpen(true)}}>
            <IonIcon icon={addCircle} color="primary" />
        </IonButton>
     </>)
}

export const Tables=({headers,rows , title,description ,tableHead ,role ,current, nextPage  ,tablestyle}:any)=>{ 
     useEffect(()=>{ },[current,role])

    const forwardPage=()=>{
        if(current < tableHead.all){
            const nxtP = current+1
            console.log("nxtP  ",nxtP)
            return  nextPage(nxtP)
        }
    }
    const prevPage=()=>{
        if(current > 1){
            const nxtP = current-1
            return  nextPage(nxtP)
        }
    } 

    return(<div className={`table-container ${tablestyle} `} style={{minWidth: "30rem", maxWidth: "70vw"}} >   
            <IonRow>
                <IonCol sizeXl="9"  sizeLg="7">
                    <h5 className="title " style={{display:'flex',flexDirection:'row',justifyContent:'cener'}} >
                        <strong>{title} </strong>  
                        {tableHead?.canAdd &&    <TableAdd role={role} action={"add"}/> }
                    </h5> 
                   {description &&  <sub> <strong style={{fontWeight:'bolder'}}>{tableHead.all} Total , </strong> {description}</sub> }
                </IonCol>  
            </IonRow>
            
            <IonGrid className="table" style={{minWidth: "30rem"}} >
                <IonRow className="row-header" >
                    {headers?.map((head:any,index:any)=>  
                        <IonCol 
                         size={head.id === "index" ? "1" :""}
                        key={index} id={head.id} 
                        className={`${ 
                            head.id.toLowerCase().indexOf("delete")  > -1 ||
                            head.id.toLowerCase().indexOf("active")  > -1 ||
                            head.id.toLowerCase().indexOf("edit")  > -1  ?
                             "set-center":"start-center "} `} >   
                            <IonLabel> {head.label}  </IonLabel>
                        </IonCol>  
                    )}
                </IonRow>
                {rows?.map((row:any,index:any)=> 
                    <IonRow key={index} className={`row-body ${index%2? "white":"light-tint" }` } >
                        {row.column?.map((col:any,colIndex:any)=> 
                            <IonCol     
                                key={colIndex} id={col.id}   
                                size={col.id === "index" ? "1" : ""}
                                className={`${ 
                                    col.id.toLowerCase().indexOf("delete")  > -1 ||
                                    col.id.toLowerCase().indexOf("active")  > -1 ||
                                    col.id.toLowerCase().indexOf("edit")  > -1  ?
                                    "set-center":"start-center "} 
                                `}
                            >  
                            {
                                col.id.toLowerCase().indexOf("delete")  > -1 ? <BtnDel id={col.id} role={role}/>: 
                                col.id.toLowerCase().indexOf("edit")  > -1  ? <BtnEdit id={col.id} role={role} />: 
                                col.id.toLowerCase().indexOf("active")  > -1  ? <BtnActive active={col.label} role={role} />: 
                                col.map? <BtnMapDialog position={col.position} content={col.label} />:
                                <IonLabel>{col.label} </IonLabel>
                            }
                            </IonCol>
                        )} 
                    </IonRow>
                )}
                <IonRow style={{marginTop:'1rem'}}>
                    <IonCol size=".5" className="set-center ion-text-center" >
                        <IonButton size="small" fill="clear" color={"medium"} onClick={()=>{nextPage(1)}} >
                            <IonIcon icon={chevronBackOutline} />
                            <IonIcon icon={chevronBackOutline} style={{marginLeft:'-10px'}}/> 
                        </IonButton>
                    </IonCol>
                    <IonCol size=".5"  className="set-center ion-text-center" onClick={(e)=>{prevPage()}} >
                        <IonButton  size="small" fill="clear"  color={"medium"} >
                            <IonIcon icon={chevronBackOutline} /> 
                        </IonButton>
                    </IonCol>
                    <IonCol size=".5"  className="set-center ion-text-center" >
                        <IonLabel> <small style={{letterSpacing:'3px'}}>{current}/{tableHead?.all}</small> </IonLabel>
                    </IonCol> 
                    <IonCol size=".5"  className="set-center ion-text-center" >
                        <IonButton  size="small" fill="clear"  color={"medium"}  onClick={(e)=>{forwardPage()}} >
                            <IonIcon icon={chevronForwardOutline} /> 
                        </IonButton>
                    </IonCol>
                    <IonCol size=".5" className="set-center ion-text-center" >
                        <IonButton size="small" fill="clear" color={"medium"}  onClick={()=>{nextPage(tableHead?.all)}}>
                            <IonIcon icon={chevronForwardOutline} />
                            <IonIcon icon={chevronForwardOutline} style={{marginLeft:'-10px'}}/> 
                        </IonButton>
                    </IonCol>
                    <IonCol size="7" ></IonCol>
                </IonRow>
            </IonGrid> 
        </div>
    )
}

export const TablesReport=({headers,rows , role , tablestyle}:any)=>{ 
    useEffect(()=>{ },[ role , rows.length  ]) 

    const calHeight=(row:any)=>{
        let tablesize = document.getElementById("table") 
        if(tablesize){
        let size =  Number(tablesize?.clientHeight)/row 
        return size+"%"
        }else{
            setTimeout(()=>{
                let table = document.getElementById("table")  
                return Number(table?.clientHeight)/row 
            },1000) 
        }
    }

    const updateSize=(id:any, size:any)=>{
        let col = document.getElementById(id)  
        // let rows:any =[]  
        // rows = document.getElementsByClassName("rows")
        // console.log("rows ",typeof rows ,rows.length)
        //rows.map((e:any)=>{
        //   return e.style.width = size
        //})
    }

   return(<div className={`table-container ${tablestyle} `} >    
           <IonGrid className="table" id="table">
               <IonRow className="row-header" >
                   {headers?.map((head:any,index:any)=>  
                       <IonCol push="auto"  key={index} id={head.id} 
                       onClick={(e:any)=>{console.log("column ",e) }}
                       style={{justifyContent:'flex-start', padding:'0px'}}
                       className={`set-center  ${ 
                           head.id.toLowerCase().indexOf("delete")  > -1 ||
                           head.id.toLowerCase().indexOf("active")  > -1 ||
                           head.id.toLowerCase().indexOf("edit")  > -1  ?
                            "set-center":"start-center "} `} > 
                            <div className="headers" style={{width:'100%',height:'3rem' }}>
                              <IonLabel  > {head.label}  </IonLabel>
                           </div>   
                           {rows.map((row:any,rowindex:any) => 
                            <div className={`set-center rows ${rowindex%2 === 0 && 'odd' }`} id={`row-${row.id}`}
                            style={{height:calHeight(rows.length),width:'100%',fontWeight:'200'}} >
                                {
                                    row.column.filter((col:any)=> col.id === head.id ).map((cell:any,rowindex:any) =>
                                    <div key={rowindex} className="cells" style={{height:'fit-content',width:'100%'}} >
                                        {cell.label}
                                    </div> 
                                    )
                                }
                            </div> 
                            )} 
                       </IonCol>  
                   )}
               </IonRow>
               {/* {rows?.map((row:any,index:any)=> 
                   <IonRow key={index} className={`row-body ${index%2? "white":"light-tint" }` } >
                       {row.column?.map((col:any,colIndex:any)=> 
                           <IonCol     
                               key={colIndex} id={col.id}  
                               push="auto" 
                               
                               className={`${ 
                                   col.id.toLowerCase().indexOf("delete")  > -1 ||
                                   col.id.toLowerCase().indexOf("active")  > -1 ||
                                   col.id.toLowerCase().indexOf("edit")  > -1  ?
                                   "set-center":"start-center "} 
                               `}
                           >  
                           {
                               col.id.toLowerCase().indexOf("delete")  > -1 ? <BtnDel id={col.id} role={role}/>: 
                               col.id.toLowerCase().indexOf("edit")  > -1  ? <BtnEdit id={col.id} role={role} />: 
                               col.id.toLowerCase().indexOf("active")  > -1  ? <BtnActive active={col.label} role={role} />: 
                               col.map? <BtnMapDialog position={col.position} content={col.label} />:
                               <IonLabel>{col.label} </IonLabel>
                           }
                           </IonCol>
                       )} 
                   </IonRow>
               )}  */}
           </IonGrid>

           
       </div>
   )
}




export default {
    DateStart,
    IconShare,
    DevicesList ,
    SwipBack,
    AppTable,
    DarkModeSegment,
    NoEvent,
    SelectPopover,
    Tables
}