import { IonButton, IonCol, IonContent, IonIcon, IonLabel, IonRow, IonText, IonTitle } from "@ionic/react"
import { printOutline } from "ionicons/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { configPDF, pdfMake } from "../../../lib/pdfMake" 

const RepTemp=({chart,details}:any)=>{
    const [chartImg,setChartImg] = useState("")

    let options = {
        series: [] , 
        chart: 	{  
            id: "line-temp"	,  width: 700 , height: 350 ,
            type: 'line',
            zoom: {  type: 'xy' }
        },
        xaxis: 	{  type: 'datetime', categories:[''] , show: true ,
            labels: {
                formatter: function (value:any) {
                return  moment(value).format("HH:mm DD/MM")
                }
            },
        },
        fill: 	{  type:'solid'	},
        stroke: {  curve: 'smooth'	, width: 0.3},
        markers: { size: [2.5] , strokeWidth: 0 , },
        tooltip: { shared: false,  },
        colors: ['#fff175','#fc7265','#aefc72','#66a6ed'],
        yaxis: {
            labels: {
                formatter: function (value:any) {
                  return value.toFixed(0) ;
                }
            },
        }, 
      };

      useEffect(()=>{
        const drawchart=async()=>{
            var element= document.querySelector("#line-chart") 
                if(!element?.innerHTML){ 
                options.xaxis.categories = chart.xCategories
                    let idleColor = '#fcec03'
                    let moveColor = '#99ff24'
                    let allColor = '#73bff5'
                    let stopColor = '#f01d0e'
                    options.colors=[allColor,stopColor,moveColor,idleColor]

                let series:any = []
                    series[0]= chart.seriesAll
                    series[1]= chart.seriesStop
                    series[2]= chart.seriesMove 
                    series[3]= chart.seriesIdle
                options.series = series
                console.log("series ",series) 
                var chrt:any = new ApexCharts(element, options) 
                chrt.render()
                setTimeout(async()=>{ 
                    let {imgURI}:any = await chrt?.dataURI()
                    setChartImg( imgURI) 
                },1000)  
            }
        }
        drawchart()
      },[])

    const printReport=()=>{
        pdfMake.createPdf({ 
            watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
            images:{ chart: chartImg  },
            content:[  
                {text: details.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(details.start).format("DD MMMM   HH:mm")} - ${moment(details.end).format(" DD MMMM  HH:mm")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${details?.name} `, alignment:'center'}, 
                { image:"chart" ,width: 500}
            ],
            defaultStyle: {
                font: "THSarabunNew",
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text: details.reportName ,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
        },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
        }).open()
    }

    return(
    <div  className="ion-padding" >    
        <IonButton  
           onClick={()=>{printReport()}}
           mode="ios" fill="clear" 
           style={{position:"relative", top:"1rem", right:"1rem"}} 
       >
           <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
           <IonIcon icon={printOutline}  /> 
       </IonButton>
       <IonRow className="ion-margin-bottom" >
            <IonCol size="12" className="ion-text-center ion-padding-left" >
                <IonTitle className="ion-margin-bottom ion-no-padding">{details.reportName}</IonTitle> 
 
                <IonLabel style={{fontSize:'.85em'}}>
                    <IonText className="ion-margin-end" >วันที่</IonText> 
                    <IonText>{moment(details.dateStart).format("DD MMMM   HH:mm")}</IonText> -
                    <IonText>{moment(details.dateEnd).format(" DD MMMM  HH:mm")}</IonText>
                </IonLabel><br/>
                <IonLabel style={{fontSize:'.9em'}}>
                    <IonText>ทะเบียนรถ​ : {details?.name} </IonText>
                </IonLabel>
            </IonCol>
            <IonCol size="12" className="set-center" >
                <div id={"line-chart"} ></div>
            </IonCol>
       </IonRow>
   </div>
    )
}

export default RepTemp;