import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonRow, IonText } from "@ionic/react"
import { table } from "console";
import moment from "moment";
import { TableDistance } from "../../components/ReportComponent/ReportTable";
import { printOutline } from "ionicons/icons";
import { pdfMake } from "../../lib/pdfMake" 


const RepDistanceDaily=({details,table}:any)=>{

    const printReport=()=>{
        let bodyTable:any = []  
        let headers = table.header.map((el:any)=>{ 
             return  {text: el.label.toUpperCase()  , alignment:'center', bold:true  } 
        })
        table.rowData.map((rows:any)=>{  
            let row =  rows.cols.map((cols:any)=>{ 
                return  {text: cols.label  , alignment:'center' }  
            })
            bodyTable.push(row)
        })  
        bodyTable.unshift(headers)
        console.log("bodyTable ",bodyTable)

        pdfMake.createPdf({ 
            watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
            content:[  
                {text:'รายงานระยะทางรายวัน' , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${ details.start } - ${ details.end } `, alignment:'center'},
                {text:`ทะเบียนรถ  ${details?.vehicle} `, alignment:'center'}, 
                {text:""},
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths:["*","*","*","*","*" ],
                      body: bodyTable  
                    }
                  } 
            ],
            defaultStyle: {
                font: "THSarabunNew",
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text:'รายงาระยะทางรายวัน' ,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
        },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
        }).open()

    }

    return(<div> 
        <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
        <IonGrid>
            <IonRow className="ion-justify-content-center" > 
                <IonCol size="10" >
                    <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >รายงานระยะทางรายวัน</IonLabel><br/>
                    <IonLabel style={{fontSize:'.8em'}}>ทะเบียน {details.vehicle} </IonLabel> <br/>
                    <IonLabel style={{fontSize:'.8em'}}>
                        <IonText>วันที่ {details.start}</IonText>
                        <IonText>-</IonText>
                        <IonText>วันที่ {details.end}</IonText>
                    </IonLabel>
                </IonCol>
                <IonCol size="10" >
                    <br/>
                    <TableDistance headers={table.header} rowsData={table.rowData} /> 
                </IonCol>
            </IonRow><br/>
        </IonGrid>
    </div>)
}
export default RepDistanceDaily;