import { IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonList, IonLoading, IonMenuButton, IonPage, IonRadio, IonRadioGroup, IonRange, IonRoute, IonRow, IonSpinner, IonText, IonToolbar, useIonAlert, useIonLoading, useIonToast } from "@ionic/react"
import { DropDownDevice } from "../components/AppComponent"
import { useEffect, useState } from "react" 
import { DateStartReport, DateEndReport, DateRangPopover } from "../components/ReportComponent/ReportComponent"
import moment from "moment"
import { addGeometry, allDevices, drawCircle, getStorage, passRoute, toastFail, toastSuccess } from "../actions"
import sha1 from "sha1" 
import { Swiper, SwiperSlide } from "swiper/react"
import { alertCircle, closeCircleOutline, list, listOutline, pause, play, stop } from "ionicons/icons"  
import { t } from "i18next"  
import { useDispatch, useSelector } from "react-redux"
import { getBackup, setBackupDevices, setDefaultMap, setDevices } from "../store/appSlice"
import { DivIcon, Icon, Map as MapType } from "leaflet"
import { getCircle, getPassRoute, getPassRouteIndex, getPassRouteInterval, getPlayAction,  getPolygon,  setCircle,  setPassRoute, setPassRouteIndex, setPassRouteInterval, setPlayAction, setPolygon  } from "../store/mapSlice"
import L from "leaflet"  
import { CardRoute, SlidBack, SideOverView, MapPassroute,   speeds } from "./PassrouteMap"
import { useHistory } from "react-router" 
import { Device as AppDevice } from "@capacitor/device"
import "./css/Passroute.css"
import { Device, Options, Route } from "./schema"
import { ModalGeozoneNotify } from "./Geozones"
 
const colors= ["#B314CD", "#EE15F3" , "#134985" , "#1560F3","#31E214","#EDF209","#F2B109" , "#E98611" ,"#F31515"]

let markerroute:any  = null ;
let markerstatus:any  = null ;
let routeplyline:any  = null ;

const Passroute=()=>{
    const [refSwiper,setRfSwip] = useState(null)
    const [vehicle,setVehicle] = useState({device_id:0, value:0 , label:""})
    const [dateend,setDateEnd] = useState(moment().format("YYYY-MM-DDT23:59").toString()) 
    const [datestart,setDateStart] = useState(moment().format("YYYY-MM-DDT00:00").toString()) 
    const [openDate , setOpenDate ] = useState(false)
    const [lists,setLists] = useState<Route[]>([])
    const [removing,setRemoving] = useState(false)
    const [playingid,setPlayingid] = useState<any>("")
    const [route,setRoute] = useState<Route|null>(null)
    const [speed,setSpeed] = useState({name:"4x",val:200}) 
    const [index,setIndex] = useState(0)
    const [device,setDevice] = useState<Device>()
    const backup = useSelector(getBackup)
    const [loading , setLoading] = useState(false)
    const [loadingSpinner , setLoadingSpinner] = useState(false)
    const [trackMarker,setTrackMarker] = useState(false) 
    const passroute:Route = useSelector(getPassRoute)  
    const [speedChoice,setSpeedChoice] = useState(false)
    const [color,setColor] = useState("#134985")
    const passRouteIndex = useSelector(getPassRouteIndex) 
    const [ionalert,dimiss] = useIonAlert();
    const playAction = useSelector(getPlayAction)
    const passrouteinterval = useSelector(getPassRouteInterval)
    const history = useHistory() 

    const [detail, showDetail] = useState(false)
    
    const [modalSave,setModalSave] = useState(false) 
    const [modalType,setModalType] = useState("add")
    const [addZone,setAddZone] = useState(false)
    const [name , setName] = useState("")
    const [radius,setRadius] = useState(0.5) 
    const polygon = useSelector(getPolygon)
    const circle = useSelector(getCircle)   
    const [currentIcon , setCurrentIcon] = useState(0) 
    const [optChecked , setOptChecked ] = useState<Number[]>([])
    const geoType:any = "circle"
    const [ toast , dimisstoast] = useIonToast();
    const [checkall , setCheckall] = useState<boolean | undefined>(false)
    const [options,setOptions] = useState<Options[]>([]) 
    const [checkin,setCheckin] = useState({   
        label:"เข้า" ,
        checked: true ,
        format:"{0} เข้า  {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    })
    const [checkout,setCheckout] = useState({   
        label:"ออก" ,
        checked: true ,
        format:"{0} ออก {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    })



    let mapref:MapType|any = null
    let playindex = 0
    let playininterval:any =  0;
    let routedraw: any[] = [] 
    let playinterval: NodeJS.Timeout | any = null
    let eventlocate:any= [0,0]
    let lastHeading = 0
    
    const dispatch = useDispatch()

    const changeRangeKnob=(val:any)=>{
        console.log("changeRangeKnob ", val)
        // setKnob(val)
        playindex = val;
        dispatch(setPassRouteIndex(val)) 
        if(mapref){
            eventlocate =  passroute?.route[val] 
            let listindex  = passroute?.list[val]
            let icon = passroute?.deviceIcon
        //    drawMarkerL(icon,listindex ,eventlocate)
            markerroute?.setRotationAngle(Number(listindex.heading))
            markerstatus?.setRotationAngle(Number(listindex.heading))
            // mapref.fitBounds(route?.route)
        }
        
    }

    const searhDevice=async()=>{
        console.log("searhDevice ",vehicle)
        setLoading(true)
        const deviceId = vehicle.device_id
        let start =  new Date(moment(datestart).zone(0).format()).getTime()
        let end = new Date(moment(dateend).zone(0).format()).getTime() 
        let devic = backup.find((e:any)=> e.device_id === deviceId )
        if(devic) { setDevice(devic) }
 
        let routelist = await passRoute({
            deviceId: deviceId,
            start: new Date(datestart).getTime() ,
            end: new Date(dateend).getTime() ,
        })
        console.log("routelist ",routelist)
        if(routelist.list!== null){
            let bound:any = [] = await routelist?.list?.map(  (el:any) =>{
                return [el.latitude ,el.longitude]
            })
            let routeid = await sha1(`start:${start},end:${end},id:${deviceId},create:${moment().format()}`)
            if(routelist.list.length >0){
                //let listRoute:any = routelist.list
                let newRoute:Route[] = [...lists,{
                    ...routelist ,
                    _id : routeid ,
                    name:  vehicle?.label ,
                    route: bound, 
                    color: "",
                    show: true ,
                    distance_all: (Number(routelist.distance_all)/1000).toFixed(2),
                    deviceIcon: devic?.deviceIcon ? devic?.deviceIcon : 1 ,  
                    typeId:  1 , 
                }]
                setLists(newRoute)
            }else{
                ionalert({
                    mode:"ios",
                    header : "ไม่พบเส้นทาง ! ",
                    message: `ไม่พบข้อมูลเส้นทางของ ${devic?.name} ในช่วงเวลาวันที่ ${ moment(start).format("DD/MM/YY HH:mm:ss")} - ${moment(end).format("DD/MM/YY HH:mm:ss") } `,
                    buttons: ['ตกลง']  
                }) 
            } 
            setLoading(false)
        }else{ 
            ionalert({
                mode:"ios",
                header : "ไม่พบเส้นทาง ! ",
                message: `ไม่พบข้อมูลเส้นทางของ ${devic?.name} ในช่วงเวลาวันที่ ${ moment(start).format("DD/MM/YY HH:mm:ss")} - ${moment(end).format("DD/MM/YY HH:mm:ss") } `,
                buttons: ['ตกลง']  
            })
            setLoading(false)
        } 
    }

    const removeRoute=(id:any)=>{ 
        setPlayingid("")
        setRoute(null)
        setRemoving(true) 

        setTimeout(()=>{
            let listRoute:any = lists
            listRoute = listRoute.filter((e:any)=> e._id !== id)
            setLists(listRoute)
            setRemoving(false)
        },speed.val)
    }
    

    useEffect(()=>{  
        const getdevices=async()=>{ 
            if(options.length === 0){
                let result = await allDevices({})  
                await dispatch(setDevices(result))
                await dispatch(setBackupDevices(result)) 
                let opts = result.map((e:any)=> {return {value: e.device_id ,label: e.name, ...e } })
                setOptions(opts)   
                let optionscheck:any[] = result.map((e:any)=> {return {checked:false, value: e.device_id ,label: e.name, ...e } })
                optionscheck.unshift({checked:false, device_id:0 ,value: 0,label:"ทั้งหมด"}) 
            }
        } 
        getdevices()  
  
    },[vehicle,passroute,passRouteIndex,options])

    useEffect(()=>{
      return(()=>{
            dispatch(setPassRoute(null))
            mapref?.eachLayer(function(layer:any){
                console.log("layer ",   layer)
                if(layer?.editing){
                    mapref?.removeLayer(layer)
                }
            });
       })
    },[])

    const selectRoute=async(route:Route,index:any)=>{ 
        console.log("route ",route)
        setLoading(true);
        let info = await AppDevice.getInfo() 
        if(info?.operatingSystem !== "mac" && info?.operatingSystem != "windows" && info?.operatingSystem !== "unknown"){
            history.push("/routehistoryMap",{
                device: device ,
                openDate: openDate ,
                lists : lists[index]  ,
                removing: removing ,
                playingid :playingid,
                route:route,
                speed:speed
            })
        } 
        setRoute(route);
        dispatch(setPassRoute(route))
        setPlayingid(route?._id);
        setLoading(false)
  
        if(mapref){
            eventlocate =  route?.route[0] 
            let listindex  = route?.list[0]
            let icon = route?.deviceIcon
            drawMarkerL(icon,listindex ,eventlocate)
            markerstatus?.setRotationAngle(Number(listindex.heading))
            mapref.fitBounds(route?.route)
        }
    }

    const getPopupcontent=(content:any)=>{
        return `
           <div style={{width:"12rem"}} className="ion-padding" >
            <IonGrid>
                <IonRow> 
                    <IonCol size="12" >
                        <IonLabel><strong>Time:</strong> ${content?.event_stamp} </IonLabel> <br/>
                        <IonLabel><strong>address:</strong> ${content?.address} </IonLabel> <br/>
                        <IonLabel> <strong>speed: </strong> ${content?.speed} ${t("kmHUnit")}</IonLabel><br/>
                        <IonLabel>driver: ${content?.driver_id} ${content?.driver_message} </IonLabel><br/>
                    </IonCol>
                </IonRow>
            </IonGrid>
          </div> 
        `
    }

    const drawMarkerL=async (icon:any,listindex:any,position:any)=>{
         if(markerroute){  mapref?.removeLayer(markerroute) }
        if(markerstatus){  mapref?.removeLayer(markerstatus) }
        if(routeplyline){  mapref?.removeLayer(routeplyline) }

         
        markerroute = L.marker(position, {icon: new Icon({  
            iconUrl: `../../assets/types/type-${icon}.png` ,
            rotationOrigin: "center", 
            iconSize: [60, 60], 
            iconAnchor: [25, 60], 
        })}).addTo(mapref) 
         

        // let popupcontent =  getPopupcontent(listindex)  
        // markerroute.bindPopup(popupcontent,{offset:[0, -66],keepInView:true,autoPan:true}).openPopup()
        markerstatus = L.marker(position,{icon:  new DivIcon({
            className: `marker-status-divicon  ${ 
                listindex?.status===7? 'marker-move': 
                listindex?.status===23?'marker-stop': 
                listindex?.status===24?'marker-idle':'marker-offline'
            }`, 
            iconSize: [20, 20],
            iconAnchor: [0, 63],  
        })}).addTo(mapref)

        routedraw?.push(position)
        if(routedraw.length-1 < passRouteIndex){
            let sliceroute = passroute?.route.slice(0,passRouteIndex)
            routedraw = sliceroute
        }
        routeplyline = L.polyline(routedraw,{color: color}).addTo(mapref)
         
        markerroute.on("contextmenu",(e:any)=>{
            mapref?.removeLayer(markerroute)
            mapref?.removeLayer(markerstatus) 
        })
      
        markerstatus?.setRotationOrigin("center" )   
        if(listindex?.status === 7){
            lastHeading = Number(listindex?.heading);
            markerstatus?.setRotationAngle(Number(listindex?.heading))
        }else{
            markerstatus?.setRotationAngle(lastHeading)
        }
        mapref?.invalidateSize() 
        if(trackMarker){
            mapref?.setView(position)
        }
    }  

    const playingRoute=()=>{
        console.log("playindex ",playindex ," passroute ", passroute?.list.length ," acton ",playAction)
       
        if(playindex+1 >= passroute?.route.length-1){
            routedraw=[]
            console.log("playindex more route ")
            clearInterval(playinterval) ; 
            dispatch(setPassRouteInterval(undefined))
        
            // dispatch(setPlayAction("stop"))   
           
            stopRoute({});
            if(routeplyline){ mapref?.removeLayer(routeplyline)   }  
        }else{ 
            eventlocate =  passroute?.route[playindex] 
            let list  = passroute?.list[playindex]
            drawMarkerL(passroute?.deviceIcon,list ,eventlocate) 
            if(trackMarker){
            // mapref?.setView( passroute?.route[playindex])
            }
        }
        playindex++; 
        return playindex
    }

    const playRoute=(e:any)=>{  
        dispatch(setPlayAction("playing"))  
        if(passroute?.list.length >5){
            console.log("action ",playAction)
            if( playAction ==="pause" && playindex!= passRouteIndex){
              playindex =passRouteIndex 
            } 
            console.log("playindex ",playindex ," passroute ", passroute?.list.length ," acton ",playAction)
    
            let interval   = setInterval(() => {
                let indexx =   playingRoute()
                dispatch(setPassRouteIndex(indexx)) 
            } ,speed.val) 
            dispatch(setPassRouteInterval(interval )) 
            console.log("interval ",interval)
        }
       } 

    const pauseRoute=async(e:any)=>{   
            dispatch(setPlayAction("pause"))  
            clearInterval(passrouteinterval) ; 
        
    }

    const stopRoute=async(e:any)=>{
        console.log("playindex ",passRouteIndex)
        console.log("playinterval ",passrouteinterval)
        clearInterval(passrouteinterval) ; 
        
            dispatch(setPlayAction("stop"))  
            playindex=0;
            routedraw =[];
            mapref?.removeLayer(routeplyline) 
            
            clearInterval(passrouteinterval) ; 
            dispatch(setPassRouteIndex(0))
       
    }

    const slelectSpeed=async(sp:any)=>{
        await pauseRoute({})

        setTimeout(()=>{ 
            setSpeed(sp)
            setSpeedChoice(false)
            if(playAction !== "stop"){
             playRoute(null)
            }
        },300)
    }

    const slideback=()=>{ 
        if(mapref){ mapref.eachLayer(function (layer:any) { 
                if(layer?._url === null || layer?._url === undefined){ 
                    mapref.removeLayer(layer);
                }
            })
        }
        setPlayingid(""); 
        dispatch(setPlayAction("stop"))  
        dispatch(setPassRoute(null))  
    }

    const statsSize=(length:any)=>{
       let size =  Number(length)/100 
       return size
    }

    const changeTrackMarker=async(val:any)=>{
        await pauseRoute({})
        setTrackMarker(val)
        setTimeout(()=>{ 
            if(playAction !== "play"){
              playRoute(null)
            }
        },300)
    }
    const changeindex=(val:any)=>{ 
        dispatch(setPassRouteIndex(val)) 
        playindex =val;

        let sliceRoute = passroute?.route.slice(0,val-1)
        routedraw = sliceRoute 
    }

    const saveGeofences=async()=>{
        let geometryArr:any = []
        if(geoType=== "polygon"){ 
          polygon.forEach((el:any) => {
            geometryArr = geometryArr.concat(el)
          }); 
        }else{ 
          circle.forEach((el:any) => {
            geometryArr = geometryArr.concat(el)
          });
        } 
        console.log("saveGeofences circle ",circle)
        if(geometryArr.length > 0){ 
            const body = {
                locationName: name ,
                geometry:   JSON.stringify(geometryArr)   ,  
                iconId : currentIcon , 
                checkin:  checkin.checked ,
                checkout: checkout.checked ,
                devices:  optChecked ,
                templatein: checkin.format ,
                templateout: checkout.format 
            }
            console.log("saveGeofences body ",body)
            if(optChecked.length > 0 && name !== ""){
                setLoading(true)
                const result = await addGeometry(body)
                if(result.status===1){
                    toast({
                        ...toastSuccess,
                        message:"Add geofences success."
                    })
                    dispatch(setCircle([]))
                    dispatch(setPolygon([]))
                    setName("") 
                    setModalSave(false) 
                    setAddZone(false)
                } 
                setLoading(false)
            }else if(name==="" || name === null || name === undefined){
                toast({
                    ...toastFail ,
                    message:"กรุณา ระบุชื่อพื้นที่ !!"
                })
            }else{ 
                toast({
                    ...toastFail ,
                    message:"กรุณาเลือกรถก่อนการบันทึก !!"
                })
            }
        }else{
            toast({
                ...toastFail ,
                message:"Not found geofences bounds. Please try again."
            })
        }
    }
 
    const changeOption=(checked:any, deviceId:any)=>{ 
        let checkeddevice = optChecked
        checkeddevice = checkeddevice.filter((id)=> id !== deviceId)
        let updateopt =  options.map((e)=>{
            return {...e , ...{ checked: deviceId===e.value?checked: e.checked  } }
        })
        setOptions(updateopt) 
        if(checked){ 
            checkeddevice.push(deviceId)
            setOptChecked(checkeddevice)
        }else{  
            setOptChecked(checkeddevice)
        }  
     } 
     
     const checkAll=async (checked:boolean)=>{  
        setCheckall(checked)
        let checkeddevice: any[] = []
        let options = backup.map((e:any)=> {
        if(checked){ checkeddevice.push( e.device_id) }
        return {
            value: e.device_id ,label: e.name, checked:checked 
        } }) 
        setOptions(options)
        setOptChecked(checkeddevice) 
     }
     const createCirclePoly=async(open:any, point:any,radius:any)=>{ 
        let position = await drawCircle(  point.latitude , point.longitude ,radius) 
        dispatch( setCircle(position))
        setModalSave(open) 
        if(mapref.getZoom() < 15){
            mapref.fitBounds(position)
        }
     }
    return(
        <IonPage>
            {/* <AuthCheck />  */}
            <IonContent color={"light"}><div style={{ height:"100vh",}}   > 
                <IonGrid>
                    <IonRow>
                        <IonCol  sizeMd="4" sizeLg="3.4" sizeXl="3" >
                        <div  className="ion-padding passroute-container"  >
                        <Swiper  
                            draggable={false}
                            allowTouchMove={false}
                            onSlideChange={(e) => console.log('refSwiper change',refSwiper)}
                            onSwiper={(e:any)=>{setRfSwip(e);console.log("refSwiper ",e)}}
                        >
                            <SwiperSlide style={{height:"100%"}}>
                                <h1 className="ion-no-margin ion-margin-bottom" > 
                               <IonLabel  style={{fontWeight:"bold",display:"flex",flexDirection:"row"}} >  
                                    <IonMenuButton color={"dark"} className="ion-hide-md-up" /> &nbsp;  
                                    <IonText>เส้นทางย้อนหลัง <br/>
                                        <sub style={{fontSize:".6em",fontWeight:"normal"}} >ประวัติการเดินทาง เส้นทางย้อนหลัง</sub>
                                    </IonText> 
                                </IonLabel> <br/> 
                                </h1>  
                                <br/>
                                <div style={{display:"flex" , flexDirection:"row"}} >
                                <DropDownDevice vehihle={vehicle} setVehicle={(e:any)=>{setVehicle(e) }} optionsbackup={options} />
                                {loadingSpinner && <IonSpinner name="circles" />}
                                </div>

                                <IonRow>
                                    <IonCol  sizeLg="12" sizeXl="6" className="ion-no-padding ion-padding-top ">
                                       <DateStartReport date={datestart} openDate={setOpenDate} bg={true}  showtime={false}/> 
                                    </IonCol>
                                    {/* <IonCol sizeLg="2"  className="set-center">  <IonButton size='small' fill='clear' color={"dark"}>ถึง</IonButton>      </IonCol> */}
                                    <IonCol  sizeLg="12" sizeXl="6" className="ion-no-padding ion-padding-top ion-padding-bottom">
                                       <DateEndReport date={dateend} openDate={setOpenDate} bg={true}  showtime={false}/> 
                                    </IonCol>
                                </IonRow> 
                                <IonButton  style={{height:"2.5rem"}} expand="block" mode="ios"  onClick={()=>{searhDevice()}}>
                                    <IonLabel>{loading && <IonSpinner name={"crescent"} />}&nbsp; Seach </IonLabel>
                                </IonButton>
                                <div className="set-center" style={{width:"100%",padding:".5rem",display:"flex",flexDirection:"row"}} >
                                    <IonRadioGroup value={color} onIonChange={(e:any)=>{setColor(e.detail.value)}} >
                                    { colors.map((color,index:any)=> 
                                        <IonRadio 
                                            key={index}
                                            value={color} className="radio-color" 
                                            mode="ios"  color={"transparent"}
                                            style={{backgroundColor: color }} 
                                        ></IonRadio> 
                                    )}  
                                    </IonRadioGroup>
                                </div>

                                <div style={{width:"100%",height:"1px",borderTop:"1px solid #DDD", margin:"1rem 0 .5rem"}} ></div> 
                                <IonList>
                                    {lists.map((e:Route,index:any)=> 
                                      <CardRoute 
                                        key={index} detail={e} index={index} 
                                        size={lists.length}  playingid={playingid}
                                        removing={removing}  setRoute={(e:Route)=>{selectRoute(e ,index) }}
                                        removeRoute={(e:any)=>{removeRoute(e)}}  
                                      />
                                    )} 
                                </IonList> 
                           </SwiperSlide>
                           <SwiperSlide> 
                             <IonGrid className="ion-no-padding">
                                <IonRow>
                                    <IonCol size="12" >
                                        <SlidBack  clearPlayId={(()=>{slideback();  })} />
                                    </IonCol>

                                    <IonCol size="12" className="ion-padding" style={{fontSize:".9em"}}>
                                        <IonLabel  style={{fontWeight:"bold" , display:"flex" , flexDirection:"row", alignItems:"center"}}>
                                            <IonCheckbox class="check-route"  aria-label=""  checked={trackMarker}  onIonChange={(e:any)=>{changeTrackMarker(e.detail.checked);}}></IonCheckbox> &nbsp;&nbsp;
                                            <IonText >{device?.name}</IonText>
                                        </IonLabel><br/>
                                        <IonLabel  style={{fontSize:"smaller"}} >{moment(datestart).format("DD/MM/YYYY HH:mm")} - {moment(dateend).format("DD/MM/YYYY HH:mm")} </IonLabel>
                                    </IonCol>
                                    <IonCol size="12" className="ion-padding-horizontal" style={{fontSize:".9em"}}>
                                        <IonRow>
                                            <IonCol size="4" style={{fontSize:"smaller"}}> 
                                              <IonLabel>ระยะทาง </IonLabel><br/>
                                              <IonText  style={{fontWeight:"bold"}}> {passroute?.distance_all.toString()} {t("kmUnit")} </IonText> 
                                            </IonCol>
                                            <IonCol size="4"  style={{fontSize:"smaller"}}> 
                                              <IonLabel>ระยะเวลา </IonLabel><br/>
                                              <IonText  style={{fontWeight:"bold"}}> {passroute?.time_all} </IonText> 
                                            </IonCol>
                                            <IonCol size="4" style={{fontSize:"smaller"}}> 
                                              <IonLabel>ความเร็วสูงสุด </IonLabel><br/>
                                              <IonText  style={{fontWeight:"bold"}}> {passroute?.speed_max.toString()} {t("kmHUnit")}</IonText>  
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                                <IonRow> 
                                    <IonCol size="12" className=" ion-padding-top ion-padding-horizontal" >
                                       <RangeStatus statsSize={statsSize} isInZone={passroute?.isInZone} iconZone={passroute?.iconZone} changeRangeKnob={(e:any)=>{console.log("changeRangeKnob ",e); changeRangeKnob(e)}} />
                                    </IonCol>  
                                </IonRow>
                             </IonGrid>
                             <SideOverView 
                                speed={speed}
                                passroute={passroute} 
                                playindex={playindex}  
                                statsSize={statsSize}
                                playRoute={()=>{return playRoute(null)}}
                                pauseRoute={()=>{return pauseRoute({})}}
                                stopRoute={()=>{return stopRoute({})}}
                                setSpeed={(e:any)=>{slelectSpeed(e)}}
                                changeIndex={(e:any)=>{changeindex(e)}}
                             />
                             
                           </SwiperSlide>
                        </Swiper>
                        </div>
                        </IonCol>
                        <IonCol style={{position:"relative"}} className="set-center" > 
                       
                        <div style={{position:"absolute", zIndex:9999 , bottom:"1rem",left:"1rem" ,borderRadius:"5px",background:"#FFF" }}>
                            <button  className="ion-boxshadow ion-radius" onClick={()=>{showDetail(!detail)}} style={{position:"relative",padding:"7px"}}>
                                <IonIcon icon={listOutline} style={{fontSize:"larger"}} color="primary" /> 
                                {!detail && playAction !== "stop" &&  <IonIcon icon={alertCircle} color="danger" style={{position:"absolute",right:"2px",top:"-.5rem"}}/>  }
                           </button>
                           {detail &&<div className="bg-light" style={{position:'absolute' ,bottom:"3rem",zIndex:999,width:"20rem",borderRadius:"5px" , fontSize:".8em",minHeight:"8rem",padding:"5px"}} >
                            <IonGrid>
                                <IonRow> 
                                    <IonCol size="12" style={{position:"relative"}} >
                                        <IonButton fill="clear" size="small" style={{position:"absolute",right:"5px",top:"0"}} onClick={()=>{showDetail(false)}} >  <IonIcon icon={closeCircleOutline} /> </IonButton> 

                                        <IonLabel><strong>Time:</strong> {passroute?.list[passRouteIndex]?.event_stamp} </IonLabel> <br/>
                                        <IonLabel><strong>address:</strong> {passroute?.list[passRouteIndex]?.address} </IonLabel> <br/>
                                        <IonLabel> <strong>speed: </strong> {passroute?.list[passRouteIndex]?.speed} {t("kmHUnit")}</IonLabel><br/>
                                        <IonLabel>driver: {passroute?.list[passRouteIndex]?.driver_id} {passroute?.list[passRouteIndex]?.driver_message} </IonLabel><br/>
                                   </IonCol>
                                </IonRow>
                           </IonGrid>
                           </div> }
                        </div>

                        { passroute && <div style={{position:"absolute", zIndex:9999 , bottom:"1rem",  borderRadius:"5px",background:"#FFF",minWidth:"30rem" }} >   
                         
                            <IonRow className="ion-justify-content-center" >
                             <IonCol size="10" className="ion-no-padding">
                                 <IonRange value={passRouteIndex} max={passroute?.route.length} onIonChange={(e)=>{ dispatch(setPassRouteIndex(e.detail.value)) }} ></IonRange>
                             </IonCol>
                             <IonCol size="11" className="ion-no-padding" style={{display:"flex",flexDirection:"row",justifyContent:"flex-start" }} >
                               
                                 <IonButtons style={{backgroundColor:"#FFF" , borderRadius:"5px"}} >
                                     <IonButton mode={"ios"}  onClick={(e)=>{  playRoute(e)}} color={"primary"} fill="clear" size="small"  disabled={playAction === "playing" ?true:false}>
                                         <IonIcon icon={play} />
                                     </IonButton>
                                     <IonButton mode={"ios"} onClick={(e)=>{ pauseRoute(e)}} color={"primary"}  fill="clear"  size="small"  disabled={playAction === "pause" ?true:false}>
                                         <IonIcon icon={pause} />
                                     </IonButton>
                                     <IonButton mode={"ios"} onClick={(e)=>{ stopRoute(e)}} color={"primary"}  fill="clear" size="small"   disabled={playAction === "stop" ?true:false}>
                                         <IonIcon icon={stop} />
                                     </IonButton>
                                 </IonButtons>
                                 <IonButtons 
                                 onMouseLeave={()=>{setSpeedChoice(false)}} 
                                 onMouseOver={()=>{setSpeedChoice(true)}} 
                                 style={{backgroundColor:"#FFF" , borderRadius:"5px",marginRight:".5rem"}} > 
                                 {!speedChoice &&
                                     <IonButton  onClick={()=>{setSpeedChoice(!speedChoice)}} mode={"ios"}  color={"primary"} fill="clear" size="small" >
                                         <IonLabel style={{fontSize:"small"}}>
                                             {speed.name}
                                         </IonLabel>
                                     </IonButton>  }{
                                     speedChoice &&  <>
                                     {  speeds.map((e:any,index:any)=>
                                         <IonButton  onClick={()=>{slelectSpeed(e)}} key={index} mode={"ios"}  fill="clear" size="small" 
                                                 color={e.val === speed.val ?"primary":"dark"} 
                                             >
                                                 <IonLabel style={{fontSize:"small"}}>
                                                 {e.name}
                                             </IonLabel>
                                         </IonButton>  
                                     )  } 
                                     </>
                                 }  
                                 </IonButtons>
                             
                             </IonCol>
                            </IonRow>  
                        </div>
                        }
                        <div style={{height:"97vh",width:"100%"}} > 
                        <MapPassroute 
                            cssClass={"ion-hide-md-down"}
                            route={route} 
                            playingid={playingid} 
                            speed={speed} 
                            passroute={passroute}
                            setPlayIndex={((e:any)=>{playindex = e } )}
                            playindex={playindex} 
                            setMapRef={(ref:any)=>{ mapref = ref}}
                            // stopRoute={()=>{stopRoute(null)}}
                            setModalssave={(open:any,point:any,radius:any)=>{ createCirclePoly(open,point,radius);}}
                            color={color}
                           />
                        </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
           </div></IonContent>

        <DateRangPopover start={datestart} setStart={setDateStart} end={dateend} setEnd={setDateEnd} openDate={setOpenDate} isOpen={openDate} />
        <ModalGeozoneNotify 
            modalSave={modalSave}
            modalType={modalType}
            setModalType={(e:any)=>setModalType(e)}
            setModalSave={(e:any)=>setModalSave(e)}
            name={name}
            setName={(e:any)=>{setName(e)}}
            saveUpdateGeozone={()=>{}}
            saveGeofences={()=>saveGeofences()} 
            changeOption={(a:any,b:any)=>changeOption(a,b)}
            checkin={checkin}
            setCheckin={(e:any)=>setCheckin(e)}
            checkout={checkout}
            setCheckout={(e:any)=>setCheckout(e)}
            currentIcon={currentIcon}
            setCurrentIcon={(e:any)=>setCurrentIcon(e)}
            options={options}
            checkAll={(e:any)=>checkAll(e)}
            checkall={checkall}
        />
    </IonPage>
    )
}
export default Passroute;

const stopoverview={
    stop: 1 ,
    stopDuration: "00:15:22",
    stop_location:"" ,
    start: "16/01/2024 07:29:40" ,
    end: "16/01/2024 07:58:01" , 
    stopMaxDuraion: "00:28:21" , 
    lastStopAddress: "สถานะที่จอดล่าสุด : ( 73.49 m) โรงแรมพจน์แสนเจริญโฮเต็ล ต.แชะ อ.ครบุรี จ.นครราชสีมา 30250",
    idleCount: 23
}

const RangeStatus=({statsSize,changeRangeKnob,isInZone,iconZone}:any)=>{ 
    const passroute:Route = useSelector(getPassRoute)
    const passRouteIndex = useSelector(getPassRouteIndex)
    const dispatch = useDispatch()

    useEffect(()=>{ 
        const getdefaultSett=async()=>{
            const defaultsett = await getStorage("default")
           console.log("defaultsett ",defaultsett)
           dispatch(setDefaultMap(defaultsett?.defaultMap.toLowerCase()))
        }
        getdefaultSett()
    },[passRouteIndex])
    
    return(
        <div className="wrap-range-status" >
        <div className="status-wrap" style={{position:"relative"}}>
          {passroute?.list.map((e,i)=>
            <div key={i} style={{width: `${statsSize(passroute?.list.length)}%` }}  className={`status-inrange ${ 
             e.status === 7 ? "bg-move" :  e.status  === 23 ? "bg-stop": e.status  === 24 ? "bg-idle": "bg-offline" }`}  >   </div>
          ) }
          {isInZone && <div style={{position:'absolute',bottom:"-1rem"}} > - </div>}
        </div>
        <IonRange 
             labelPlacement="start" mode="ios"  
             min={0} max={passroute?.count} className="range-passroute-status"
             value={passRouteIndex} onIonChange={(e:any)=>{return changeRangeKnob(e.detail.value) }} 
         ></IonRange>
     </div>
    )
}
