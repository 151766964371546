import { IonButton, IonLabel, IonIcon, IonGrid, IonCol, IonRow, IonText } from "@ionic/react";
import { printOutline } from "ionicons/icons";
import moment from "moment";
import { TableEvents } from "../../components/ReportComponent/ReportTable";
import { pdfMake } from "../../lib/pdfMake" 

const RepOverSpeed=({table,details}:any)=>{
    const printReport=()=>{
        let bodyTable:any = []  
        let headers = table.headers.map((el:any)=>{ 
             return  {text: el.label.toUpperCase()  , alignment:'center', bold:true  } 
        })
        table.rows.map((rows:any)=>{  
            let row =  rows.cols.map((cols:any)=>{  
                return  cols.id === "status" ? {text: cols.label?"ประตูเปิด":"ประตูปิด" , alignment:'center' } :{text: cols.label , alignment:'center' }   
            })
            bodyTable.push(row)
        })  
        bodyTable.unshift(headers)

        pdfMake.createPdf({ 
            watermark: { text: 'Andaman Tracking', color: '#FD5F06', opacity: 0.1, bold: true, italics: false },
            content:[  
                {text: details.reportName , fontSize: 16 , bold:true  , alignment:'center', marginBottom: 15},
                {text:`วันที่ ${moment(details.start).format("DD MMMM   HH:mm")} - ${moment(details.end).format(" DD MMMM  HH:mm")} `, alignment:'center'},
                {text:`ทะเบียนรถ  ${details?.name} `, alignment:'center'}, 
                {text:""},
                {   
                    layout: 'lightHorizontalLines',
                    marginTop: 10 ,
                    table:{
                      dontBreakRows:true,
                      headerRows: 1,
                      widths: headers.map((e:any)=>{return  e =="address" ? "*":e =="time"?"*" : "auto"}),
                      body: bodyTable  
                    }
                  } 
            ],
            defaultStyle: {
                font: "THSarabunNew",
            },
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        { 
                            text: details.reportName ,
                            fontSize: 10 ,
                            marginLeft: 16,
                            margin: [20, 20, 50, 20] 
                        } ,
                        { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', margin: [0, 20, 50, 0] }
                    ]
                }
        },
            footer: {
                columns: [
                    { 
                        text:"บริษัท อันดามันแทร็คกิ้ง จำกัด | Andaman Tracking .Co.Ltd  @Generate on  tracking.andamantracking.com" ,
                        fontSize: 9 ,
                        marginLeft: 15
                    } 
                ]
            },
        }).open()
    }

    return(<div>
        <IonButton  
            onClick={()=>{printReport()}}
            mode="ios" fill="clear" 
            style={{position:"relative", top:"1rem", right:"1rem"}} 
        >
            <IonLabel  style={{fontSize:".8em"}}>Print</IonLabel>  &nbsp;
            <IonIcon icon={printOutline}  /> 
        </IonButton>
        <IonGrid>
            <IonRow> 
                <IonCol size="12" >
                    <IonLabel style={{fontSize:"1.2em",fontWeight:"bold"}} >{details.reportName}</IonLabel><br/>
                    <IonLabel style={{fontSize:'.8em'}}>
                        <IonText>วันที่ {moment(details.start).format("DD MMM YYYY HH:mm")}</IonText>
                        <IonText>-</IonText>
                        <IonText>วันที่ {moment(details.end).format("DD MMM YYYY HH:mm")}</IonText>
                    </IonLabel>
                </IonCol>
            </IonRow><br/>

            <IonRow>  
                <IonCol size="12" >  
                   <TableEvents headers={table.headers} rowsData={table.rows} />
                </IonCol>
            </IonRow>
        </IonGrid>
    </div>)
}
export default RepOverSpeed;