
import { useEffect } from "react";
import Chart from "react-apexcharts";

export const ChrtSpeedFuel=({options,getUri}:any)=>{
     console.log("ChrtSpeedFueloptions ",options)

    useEffect(()=>{
        try{
            let chartEl = document.querySelector("#chart") 
            if(!chartEl?.innerHTML){
                var chart:any = new ApexCharts(chartEl, options)
                chart.render()
                setTimeout(async()=>{ 
                    let {imgURI}:any = await chart?.dataURI()
                    return getUri(imgURI)
                },1000)  
            }else{
                console.log("chartEl?.innerHTML ",chartEl?.innerHTML)
            }
        }catch(err){
            console.log("err ",err)
        }
        
    },[])
        
    return(
        <div id="chart">
            {/* <Chart   
                options={options}
                series={options.series}
                width={ "100%"}
                height={270}
                type="line"  
            />  */}
        </div>
    )
}

export default {}