import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonModal, IonPage, IonPopover, IonRange, IonRow, IonSpinner, IonText, IonToolbar, useIonActionSheet, useIonAlert, useIonToast } from "@ionic/react"
import { t } from "i18next"
import { play, pause, chevronBack, closeCircle, stop, options } from "ionicons/icons"
import L, { DivIcon, Icon, Map } from "leaflet"
import moment from "moment"
import { useState, useEffect, useRef } from "react"
import { MapContainer, LayersControl, useMap, TileLayer, FeatureGroup,GeoJSON, Popup, Polyline, Marker, Circle, Polygon, Tooltip} from "react-leaflet"
import { useSelector, useDispatch } from "react-redux"
import {  useHistory } from "react-router"
import { useSwiper } from "swiper/react"
import { LayersControlMap } from "../leaflet/LeafletLayers"
import { iconStop, pinstart, pinend, markerStart, markerStop } from "../leaflet/PublicMarker"
import { getPassRoute, getPlayAction, getPassRouteIndex, getPassRouteInterval, setPlayAction, setPassRouteInterval, setPassRouteIndex, getCircle, getPolygon, setCircle, setPolygon } from "../store/mapSlice"
import { FullscreenControl } from "react-leaflet-fullscreen"
import { Options, Route } from "./schema"
import { ModalGeozoneNotify } from "./Geozones"
import {Swiper as SwiperType} from "swiper"
import { addGeometry, toastFail, toastSuccess } from "../actions"
import toast from "react-hot-toast"
import { getBackup } from "../store/appSlice"

export const speeds=[
    {name:"2x",val:100},
    {name:"1.75x",val:200},
    {name:"1.5x",val:400},
    {name:"1.25x",val:600},
    {name:"ปกติ",val:800},
    {name:"0.75x",val:1000},
    {name:"0.5x",val:1200},
    {name:"0.25x",val:1400},
] 

let playindex = 0 
const colors= ["#B314CD", "#EE15F3" , "#134985" , "#1560F3","#31E214","#EDF209","#F2B109" , "#E98611" ,"#F31515"]

let markerroute:any  = null ;
let markerstatus:any  = null ;
let routeplyline:any  = null ;

 const PassrouteMap=()=>{
    const history = useHistory()
    const [propSts,setPropSts] = useState<any>(null) 
    const [speed,setSpeed] = useState( {name:"2x",val:100})
    const modal = useRef<HTMLIonModalElement>(null); 
    const [isOpen,setIsOpen] = useState( false )
    const passRouteIndex = useSelector(getPassRouteIndex) 
    const passroute:Route = useSelector(getPassRoute)  
    const [trackMarker,setTrackMarker] = useState(false)  
    const [speedChoice,setSpeedChoice] = useState(false)
    const [color,setColor] = useState("#134985")  
    const playAction = useSelector(getPlayAction)
    const passrouteinterval = useSelector(getPassRouteInterval) 
    const backup = useSelector(getBackup)

    const [modalSave,setModalSave] = useState(false) 
    const [modalType,setModalType] = useState("add")
    const [addZone,setAddZone] = useState(false)
    const [name , setName] = useState("")
    const [radius,setRadius] = useState(0.5) 
    const polygon = useSelector(getPolygon)
    const circle = useSelector(getCircle)   
    const [currentIcon , setCurrentIcon] = useState(0)
    const [loading , setLoading] =  useState(false)   
    const [optChecked , setOptChecked ] = useState<Number[]>([])
    const geoType:any = "circle"
    const [ toast , dimisstoast] = useIonToast();
    const [checkall , setCheckall] = useState<boolean | undefined>(false)
    const [options,setOptions] = useState<Options[]>([]) 
    const [checkin,setCheckin] = useState({   
        label:"เข้า" ,
        checked: true ,
        format:"{0} เข้า  {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    })
    const [checkout,setCheckout] = useState({   
        label:"ออก" ,
        checked: true ,
        format:"{0} ออก {1} เวลา {3}  {4}   http://maps.google.com/maps?f=q&q={5}&z=16"
    })

    const dispatch = useDispatch()

    let mapref:any = null;
    let playindex = 0
    let playininterval:any =  0;
    let routedraw: any[] = [] 
    let playinterval: NodeJS.Timeout | any = null
    let eventlocate:any= [0,0]
    let lastHeading = 0

    useEffect(()=>{ 
        console.log("history ",history.location?.state)
        if(history.location.state !== undefined){
            let locaState:any  = history.location?.state
            setPropSts(locaState) 
            setSpeed(locaState?.speed)
        }
        if(mapref){
            setIsOpen(true)
            eventlocate =  passroute?.route[0] 
            let listindex  = passroute?.list[0]
            let icon = passroute?.deviceIcon
            drawMarkerL(icon,listindex ,eventlocate)
            markerstatus?.setRotationAngle(Number(listindex.heading))
            mapref.fitBounds(passroute?.route)
        }
    },[propSts,passroute])

    const statsSize=(length:any)=>{
       let size =  Number(length)/100 
       return size
    }


    const getPopupcontent=(content:any)=>{
        return `
           <div style={{width:"12rem"}} className="ion-padding" >
            <IonGrid>
                <IonRow> 
                    <IonCol size="12" >
                        <IonLabel><strong>Time:</strong> ${content?.event_stamp} </IonLabel> <br/>
                        <IonLabel><strong>address:</strong> ${content?.address} </IonLabel> <br/>
                        <IonLabel> <strong>speed: </strong> ${content?.speed} ${t("kmHUnit")}</IonLabel><br/>
                        <IonLabel>driver: ${content?.driver_id} ${content?.driver_message} </IonLabel><br/>
                    </IonCol>
                </IonRow>
            </IonGrid>
          </div> 
        `
    }

    const drawMarkerL=async (icon:any,listindex:any,position:any)=>{
         if(markerroute){  mapref?.removeLayer(markerroute) }
        if(markerstatus){  mapref?.removeLayer(markerstatus) }
        if(routeplyline){  mapref?.removeLayer(routeplyline) }

         
        markerroute = L.marker(position, {icon: new Icon({  
            iconUrl: `../../assets/types/type-${icon}.png` ,
            rotationOrigin: "center", 
            iconSize: [60, 60], 
            iconAnchor: [25, 60], 
        })}).addTo(mapref) 
         

        let popupcontent =  getPopupcontent(listindex)  
        markerroute.bindPopup(popupcontent,{offset:[0, -66],keepInView:true,autoPan:true}).openPopup()
        markerstatus = L.marker(position,{icon:  new DivIcon({
            className: `marker-status-divicon  ${ 
                listindex?.status===7? 'marker-move': 
                listindex?.status===23?'marker-stop': 
                listindex?.status===24?'marker-idle':'marker-offline'
            }`, 
            iconSize: [20, 20],
            iconAnchor: [0, 63],  
        })}).addTo(mapref)

        routedraw?.push(position)
        if(routedraw.length-1 < passRouteIndex){
            let sliceroute = passroute?.route.slice(0,passRouteIndex)
            routedraw = sliceroute
        }
        routeplyline = L.polyline(routedraw,{color: color}).addTo(mapref)
         
        markerroute.on("contextmenu",(e:any)=>{
            mapref?.removeLayer(markerroute)
            mapref?.removeLayer(markerstatus) 
        })
      
        markerstatus?.setRotationOrigin("center" )   
        if(listindex?.status === 7){
            lastHeading = Number(listindex?.heading);
            markerstatus?.setRotationAngle(Number(listindex?.heading))
        }else{
            markerstatus?.setRotationAngle(lastHeading)
        }
        mapref?.invalidateSize() 
        if(trackMarker){
            mapref?.setView(position)
        }
    } 

    const playRoute=(e:any)=>{
         if( playAction ==="pause" && playindex!= passRouteIndex){
            playindex =passRouteIndex
            console.log(" playindex ",playindex ," passRouteIndex ",passRouteIndex)
         } 
        let interval = setInterval(()=>{
                if(passRouteIndex+1 <passroute?.route.length-1 ){
                    
                    eventlocate =  passroute?.route[passRouteIndex] 
                    let list  = passroute?.list[passRouteIndex]
                    drawMarkerL(passroute?.deviceIcon,list ,eventlocate) 
                    if(trackMarker){
                        mapref?.setView( passroute?.route[playindex])
                    }
                }else{
                    routedraw=[]
                    console.log("playindex more route ")
                    stopRoute({});
                    if(routeplyline){ mapref?.removeLayer(routeplyline)   }  
                } 
            playindex++;
            dispatch(setPassRouteIndex(playindex))
        },speed.val)
        dispatch(setPassRouteInterval(interval))
        dispatch(setPlayAction("playing"))  
        // clearInterval(playinterval) ; 
        playinterval = interval  
    }

    const pauseRoute=async(e:any)=>{  
        if(passRouteIndex > 15){
            dispatch(setPlayAction("pause"))  
            clearInterval(passrouteinterval) ; 
        }else{ 
            console.log("Plaease try pause again later")
        }
       
    }

    const stopRoute=async(e:any)=>{
        console.log("playindex ",passRouteIndex)
        console.log("playinterval ",passrouteinterval)
        clearInterval(passrouteinterval) ; 
        if(passRouteIndex > 5){
            dispatch(setPlayAction("stop"))  
            playindex=0;
            routedraw =[];
            mapref?.removeLayer(routeplyline) 
            // routeplyline = null;
    
            clearInterval(passrouteinterval) ; 
            dispatch(setPassRouteIndex(0))
        }else{
            console.log("Plaease try stop again later")
        }
    }

    const slelectSpeed=async(sp:any)=>{
        await pauseRoute({})

        setTimeout(()=>{ 
            setSpeed(sp)
            setSpeedChoice(false)
            if(playAction !== "stop"){
             playRoute(null)
            }
        },300)
    }

    const changeindex=(val:any)=>{ 
        dispatch(setPassRouteIndex(val)) 
        playindex =val;

        let sliceRoute = passroute?.route.slice(0,val-1)
        routedraw = sliceRoute 
    }

    const saveGeofences=async()=>{
        let geometryArr:any = []
        if(geoType=== "polygon"){ 
          polygon.forEach((el:any) => {
            geometryArr = geometryArr.concat(el)
          }); 
        }else{ 
          circle.forEach((el:any) => {
            geometryArr = geometryArr.concat(el)
          });
        } 
        if(geometryArr.length > 0){ 
            const body = {
                locationName: name ,
                geometry:   JSON.stringify(geometryArr)   ,  
                iconId : currentIcon , 
                checkin:  checkin.checked ,
                checkout: checkout.checked ,
                devices:  optChecked ,
                templatein: checkin.format ,
                templateout: checkout.format 
            }
            if(optChecked.length > 0 && name !== ""){
                setLoading(true)
                const result = await addGeometry(body)
                if(result.status===1){
                    toast({
                        ...toastSuccess,
                        message:"Add geofences success."
                    })
                    dispatch(setCircle([]))
                    dispatch(setPolygon([]))
                    setName("") 
                    setModalSave(false) 
                    setAddZone(false)
                } 
                setLoading(false)
            }else if(name==="" || name === null || name === undefined){
                toast({
                    ...toastFail ,
                    message:"กรุณา ระบุชื่อพื้นที่ !!"
                })
            }else{ 
                toast({
                    ...toastFail ,
                    message:"กรุณาเลือกรถก่อนการบันทึก !!"
                })
            }
        }else{
            toast({
                ...toastFail ,
                message:"Not found geofences bounds. Please try again."
            })
        }
    }
 
    const changeOption=(checked:any, deviceId:any)=>{ 
        let checkeddevice = optChecked
        checkeddevice = checkeddevice.filter((id)=> id !== deviceId)
        let updateopt =  options.map((e)=>{
            return {...e , ...{ checked: deviceId===e.value?checked: e.checked  } }
        })
        setOptions(updateopt) 
        if(checked){ 
            checkeddevice.push(deviceId)
            setOptChecked(checkeddevice)
        }else{  
            setOptChecked(checkeddevice)
        }  
     } 
     
     const checkAll=async (checked:boolean)=>{  
        setCheckall(checked)
        let checkeddevice: any[] = []
        let options = backup.map((e:any)=> {
        if(checked){ checkeddevice.push( e.device_id) }
        return {
            value: e.device_id ,label: e.name, checked:checked 
        } }) 
        setOptions(options)
        setOptChecked(checkeddevice) 
     }
    

    return(<IonPage>
        <IonHeader className="ion-no-border">
            <IonToolbar>
                <IonButtons slot="start" >
                   <IonBackButton text={"Back"} icon={chevronBack} ></IonBackButton>
                </IonButtons>
                
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <MapPassroute 
                cssClass={"ion-hide-md-up"}
                route={propSts?.route} 
                playingid={propSts?.playingid} 
                speed={speed} 
                passroute={passroute}
                setPlayIndex={((e:any)=>{playindex = e } )}
                playindex={playindex} 
                setMapRef={(ref:any)=>{ mapref = ref}} 
                color={"#134985"}
                setModalssave={(point:any)=>{ setModalSave(true)}}
        />  
        </IonContent>

    <IonModal  className="ion-hide-md-up"  
        isOpen={isOpen}   ref={modal}  
        initialBreakpoint={0.05}  
        breakpoints={[0.05, 0.1,0.2, 0.3,0.4,0.5,0.6,0.7, 0.8 , 0.9, 0.95,  1]}
        backdropDismiss={false}
        showBackdrop={false}
        keepContentsMounted={true}
        backdropBreakpoint={1}
    >
        <SideOverView 
            speed={speed}
            passroute={passroute} 
            playindex={playindex}  
            statsSize={statsSize}
            playRoute={()=>{return playRoute(null)}}
            pauseRoute={()=>{return pauseRoute({})}}
            stopRoute={()=>{return stopRoute({})}}
            setSpeed={(e:any)=>{slelectSpeed(e)}}
            changeIndex={(e:any)=>{changeindex(e)}}
        />
    </IonModal>
    <ModalGeozoneNotify 
        modalSave={modalSave}
        modalType={modalType}
        setModalType={(e:any)=>setModalType(e)}
        setModalSave={(e:any)=>setModalSave(e)}
        name={name}
        saveUpdateGeozone={()=>{}}
        saveGeofences={()=>saveGeofences()} 
        changeOption={(a:any,b:any)=>changeOption(a,b)}
        checkin={checkin}
        setCheckin={(e:any)=>setCheckin(e)}
        checkout={checkout}
        setCheckout={(e:any)=>setCheckout(e)}
        currentIcon={currentIcon}
        setCurrentIcon={(e:any)=>setCurrentIcon(e)}
        options={options}
        checkAll={(e:any)=>checkAll(e)}
        checkall={checkall}
   />
  </IonPage>) 
}
export default PassrouteMap;



export const SideOverView=({statsSize,speed,playRoute,pauseRoute,stopRoute,setSpeed,changeIndex}:any)=>{
    const passroute:Route = useSelector(getPassRoute)
    const [passRoute,setPassRoute] = useState<Route>()
    const playAction = useSelector(getPlayAction)
    const passRouteIndex = useSelector(getPassRouteIndex)

    const dispatch = useDispatch()

    useEffect(()=>{   
        if(passroute){
            // console.log("list count ",passroute?.count) 
            // setPassRoute(passroute) 
        }

        if(passroute && passRouteIndex >= passroute?.route.length){
            return ()=> stopRoute({})
          }
     },[passRouteIndex,passroute])
    
    const changeRangeKnob=(val:any)=>{
        return changeIndex(val)
       
    }
    const changeRangeKnobStart=()=>{
        return  pauseRoute({})
    }

    const statusToName=(sts:any)=>{ 
        if(sts === 7){
            return "Move"
        }else if(sts===24){
            return "Idle"
        }else if(sts===23) {
            return "Stop"
        }else{
            return "-"
        }
    }

    return(<div style={{width:"100%"}} >
   {passroute   && <div className="ion-no-padding ion-padding-horizontal" style={{backgroundColor:"#FBFBFB" , width:"100%" ,borderRadius:"5px",paddingBottom:".5rem"}} >
            <IonGrid>
                <IonRow>
                <IonCol  size="1.5"  >
                    <IonButton  disabled={playAction ==="playing"?true:false} fill="clear" mode="ios"  style={{fontSize:".7em"}}  onClick={()=>{return playRoute(null)}}>
                            <IonIcon icon={play} />
                        </IonButton>
                    </IonCol>
                    <IonCol  size="1.5"> 
                       <IonButton  disabled={playAction ==="pause"?true:false}  fill="clear" mode="ios" style={{fontSize:".7em"}}  onClick={()=>{return pauseRoute()}}>
                            <IonIcon  icon={pause} />
                       </IonButton>
                    </IonCol>
                    <IonCol size="1.5" >
                        <IonButton  disabled={playAction ==="stop"?true:false} fill="clear" mode="ios" style={{fontSize:".7em"}}  onClick={()=>{return stopRoute()}} > 
                            <IonIcon icon={stop} />
                        </IonButton>
                    </IonCol><IonCol size="3.5" ></IonCol><IonCol size="2" className="set-center " >
                        <IonButton color={"dark"} fill="clear" id="speeds-popover" style={{fontSize:".7em"}}>
                            <IonText>{speed?.name}</IonText>
                            <IonPopover mode="ios" trigger="speeds-popover"> 
                            <div  style={{width:"25rem"}}>
                               <IonButtons >
                                {
                                    speeds.map((speed:any,index:any)=> 
                                        <IonButton key={index} fill="clear" onClick={()=>{return setSpeed(speed)}} >{speed.name}</IonButton>
                                    )
                                }  
                                </IonButtons>
                            </div>
                            </IonPopover>
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="1.5" className="set-center">
                        <div style={{  width:'1.7rem',height:'1.7rem',transform:`rotate(${passroute?.list[passRouteIndex]?.heading}deg)`}} 
                        className={ `marker-status-divicon   ${ 
                            passroute?.list[passRouteIndex]?.status===7? 'marker-move': 
                            passroute?.list[passRouteIndex]?.status===23?'marker-stop': 
                            passroute?.list[passRouteIndex]?.status===24?'marker-idle':'marker-stop'
                        }`}></div>
                    </IonCol>
                   <IonCol>
                        <IonLabel
                            color={
                                passroute?.list[passRouteIndex]?.status === 7 ? "success":
                                passroute?.list[passRouteIndex]?.status === 24 ? "warning":
                                passroute?.list[passRouteIndex]?.status === 23 ? "danger":
                                "medium"
                            }
                            ><strong>{
                                passroute?.list[passRouteIndex]?.status === 7 ? "MOVE":
                                passroute?.list[passRouteIndex]?.status === 24 ? "IDLE":
                                passroute?.list[passRouteIndex]?.status === 23 ? "STOP":
                                "-"
                            }
                            </strong>
                        </IonLabel> <br/>
                        <IonLabel style={{fontSize:"small"}}> 
                            <IonText>{moment(passroute?.list[passRouteIndex]?.event_stamp ).format("HH:mm:ss DD/MM")} </IonText> &nbsp;
                            <IonText>Speed: {passroute?.list[passRouteIndex]?.speed} {t("kmHUnit")}</IonText>
                        </IonLabel>  
                    </IonCol> 
                    <IonCol size="12" style={{fontSize:"smaller"}}> 
                       <IonLabel> {passroute?.list[passRouteIndex]?.address } </IonLabel>  <br/>
                        <IonLabel> Driver : {passroute?.list[passRouteIndex]?.driver_id } {passroute?.list[passRouteIndex]?.driver_message}</IonLabel>
                    </IonCol>
                </IonRow> 
                <IonRow> 
                    <IonCol  size="12" className="set-center" > 
                        <IonRange pin={true}
                          mode="ios" value={passRouteIndex} max={passroute?.count-1} min={0} 
                          style={{width:"100%",padding:"0px"}}  
                          onIonKnobMoveStart={(e)=>{changeRangeKnobStart()}}
                          onIonKnobMoveEnd={(e)=>{changeRangeKnob(e.detail.value)  }}
                          pinFormatter={(value: number) => `${statusToName(passroute?.list[value]?.status)}`}
                        />
                        <div className="wrap-range-status" >
                            <div className="status-wrap">
                            {passroute?.list.map((e,i)=>
                                <div key={i} style={{width: `${statsSize(passroute?.list.length)}%` }}  className={`status-inrange  `}  >   
                                 {/* {e.isInZone && <div style={{width:`${statsSize(passroute?.list.length)}%`,height:"2rem", borderColor:"#CCC" }} > </div>} */}
                                 {e?.isInZone && passroute?.list[i+1]?.isInZone !== e?.isInZone  && <div style={{width:`${statsSize(passroute?.list.length)}%`,height:"2rem", backgroundColor:"#CCC" }} > </div> }
                                </div>
                            ) }
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
        }
    <div className="ion-padding" style={{backgroundColor:"#FBFBFB" , width:"100%" ,borderRadius:"5px",color:"#353535"}} >
        <IonGrid>
            <IonRow>
                <IonCol size="12" >
                    <IonLabel  style={{fontSize:"1em"}} ><strong>ภาพรวมการจอดรถ</strong></IonLabel>
                </IonCol>
            </IonRow>
            <IonRow style={{fontSize:".8em"}}>
                <IonCol size="12" style={{padding:"0.5rem", backgroundColor:"#FFFFFF",borderBottom:"1px solid #E5E5E5" }} >ภาพรวมการจอดรถจอดนิ่งจำนวน: &nbsp;{`${passroute?.stopCount}`}</IonCol>
                <IonCol size="12" style={{padding:"0.5rem", backgroundColor:"#FBFBFB",borderBottom:"1px solid #E5E5E5" }} >ระยะเวลาจอดรวม: &nbsp;{`${passroute?.stopDuration}`}</IonCol>
                <IonCol size="12" style={{padding:"0.5rem", backgroundColor:"#FBFBFB",borderBottom:"1px solid #E5E5E5" }} >เวลาเริ่มต้น: &nbsp;{moment(passroute?.startStop).format("DD/MM/YYYY HH:mm")}</IonCol>
                <IonCol size="12" style={{padding:"0.5rem", backgroundColor:"#FFFFFF",borderBottom:"1px solid #E5E5E5" }} >เวลาสิ้นสุด: &nbsp;{moment(passroute?.endStop).format("DD/MM/YYYY HH:mm")} </IonCol>
                <IonCol size="12" style={{padding:"0.5rem", backgroundColor:"#FBFBFB",borderBottom:"1px solid #E5E5E5" }} >ชั่วโมงระยะเวลาจอดรวม: &nbsp;{`${passroute?.stopMaxDuration ? passroute?.stopMaxDuration:"-"}`}</IonCol>
                <IonCol size="12" style={{padding:"0.5rem", backgroundColor:"#FFFFFF",borderBottom:"1px solid #E5E5E5" }} >สถานที่จอดล่าสุด: &nbsp;{`${passroute?.lastStopAddress}`}</IonCol>
                <IonCol size="12" style={{padding:"0.5rem", backgroundColor:"#FBFBFB",borderBottom:"1px solid #E5E5E5" }} >จอดติดเครื่องยนต์จำนวน: &nbsp;{`${passroute?.idleCount}`}</IonCol>
            </IonRow>
        </IonGrid>
    </div> 
    </div>)
}

export const MapPassroute=({setMapRef, color ,cssClass, setModalssave,updateRadius}:any)=>{
   const passRoute = useSelector(getPassRoute) 
   const playAction = useSelector(getPlayAction)
   const [stopPosition,setStopPosition] = useState({type: "FeatureCollection", features: []})
   const [markerAll,setMarkerAll] = useState({type: "FeatureCollection", features: []})
   const [radius,setRadius] = useState(1)
   const circle = useSelector(getCircle)
   const playindex  = useSelector(getPassRouteIndex)
   const dispatch = useDispatch()
   let map:Map | null = null

    const parseToGeoJson=async(list:any)=>{
        let mygeojson:any = {type: "FeatureCollection", features: []}
        let mymarkerAll:any = {type: "FeatureCollection", features: []}

        await list.map((point:any,index:any)=>{ 
            if(index+1 < list.length){
                if(point?.status===23 && point?.status !== list[index+1]?.status ){ 
                    let coordinate = [parseFloat(point?.longitude), parseFloat(point?.latitude)]; 
                    let properties = {point , ...{ iconStop } };       
                    let feature:any = {"type": "Feature", "geometry": {"type": "Point", "coordinates": coordinate}, "properties": properties} 
                    mygeojson.features.push(feature);
                }  
            }
            let coordinate = [parseFloat(point?.longitude), parseFloat(point?.latitude)];  
            let properties = {point   };       
            let feature:any = {"type": "Feature", "geometry": {"type": "Point", "coordinates": coordinate}, "properties": properties} 
            mymarkerAll.features.push(feature);
        }) 
        setStopPosition(mygeojson)  
        setMarkerAll(mymarkerAll) 
        
    }

    const popupContent=(feature:any)=>{ 
      let popup =  `  
        <div style={{width:"15rem"}} >
          <p>${feature.properties.point?.address}</p> 
          <button size="small"  mode="ios" id="btn-addzone-${feature.properties.point.id}"    > 
            add
          </button>
        </div>  
      ` 
      return popup
    }
 

    useEffect(()=>{    
        if(passRoute){  
            parseToGeoJson(passRoute?.route)   
        }
    },[passRoute,playAction])

    const createIcon=(feature:any)=>{ 
        const status = feature.properties.point.status
        return  new Icon({ 
            iconUrl:  `../../assets/${status}.png`  ,  
            iconSize: [16, 8],
            rotationOrigin: "center",
            iconAnchor: [0, 4], 
          })
    }

    return(<div style={{width:"100%" ,height:"100%"}} className={`${cssClass} `}> 
        <MapContainer
        id="map-passroute"
        center={[13.714538, 100.510928]}
        zoom={16}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{borderRadius:"15px"  ,marginBottom:".3rem", height:"100%"}}
        ref={async (comp)=>{  
            comp?.invalidateSize()  
            comp?.on("zoomlevelschange",()=>{
                comp?.invalidateSize() 
            }) 
            if(comp){
                map = comp
                return setMapRef(comp)
            }
            
        }} 
        >
          <TileLayer
            attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
            url={`https://api.mapbox.com/styles/v1/${"bthongbut"}/${"clm901e2x014n01r7gkm8gxo7"}/tiles/256/{z}/{x}/{y}@2x?access_token=${"pk.eyJ1IjoiYnRob25nYnV0IiwiYSI6ImNsNGo5b3Q3ZjE1NG8zcXN2aGxrZDRtbnIifQ.r0o9Uw4EkOMCVQXY1De2gw"}`}
          /> 
          <FullscreenControl />
           {passRoute && playAction === "stop"  && <PassRoutePolyLine color={color} trackMarker={true} count={playindex} stop={{}} setmap={(e:any)=>{return setMapRef(e)}} /> }
           {passRoute &&  playAction === "stop"  &&  <Polyline  positions={passRoute?.route} color={color} /> }
           {stopPosition &&   stopPosition.features.length > 0 && <FeatureGroup>
                <LayersControl position="bottomleft" > 
                    { stopPosition.features.map((fea:any,index:any)=> 
                            <Marker key={index} position={[fea.properties.point.latitude,fea.properties.point.longitude]} icon={iconStop} >
                                <Popup>
                                  <div className="set-center" style={{maxWidth:"18rem",padding:".3rem"}} >
                                    <p className="ion-text-center">{fea.properties.point.address }</p>
                                    <IonButtons> 
                                     <IonButton size="small" fill="clear" color={radius === 1 ?"primary":"dark"} onClick={()=>{setRadius(1);  setModalssave(false,fea.properties.point ,1)}}> 1 </IonButton>
                                     <IonButton size="small" fill="clear" color={radius === 2 ?"primary":"dark"} onClick={()=>{setRadius(2);  setModalssave(false,fea.properties.point ,2)}}> 2 </IonButton>
                                     <IonButton size="small" fill="clear" color={radius === 3 ?"primary":"dark"} onClick={()=>{setRadius(3);  setModalssave(false,fea.properties.point ,3)}}> 3 </IonButton>
                                     <IonButton size="small" fill="clear" color={radius === 4 ?"primary":"dark"} onClick={()=>{setRadius(4);  setModalssave(false,fea.properties.point ,4)}}> 4 </IonButton> 
                                     <IonButton size="small" fill="clear" color={radius === 5 ?"primary":"dark"} onClick={()=>{setRadius(5);  setModalssave(false,fea.properties.point ,5)}}> 5 </IonButton> 
                                     <IonButton size="small" fill="clear" color={radius === 6 ?"primary":"dark"} onClick={()=>{setRadius(6);  setModalssave(false,fea.properties.point ,6)}}> 6 </IonButton> 
                                     <IonButton size="small" fill="clear" color={radius === 7 ?"primary":"dark"} onClick={()=>{setRadius(7);  setModalssave(false,fea.properties.point ,7)}}> 7 </IonButton> 
                                     <IonButton size="small" fill="clear" color={radius === 8 ?"primary":"dark"} onClick={()=>{setRadius(8);  setModalssave(false,fea.properties.point ,8)}}> 8 </IonButton> 
                                     <IonButton size="small" fill="clear" color={radius === 9 ?"primary":"dark"} onClick={()=>{setRadius(9);  setModalssave(false,fea.properties.point ,9)}}> 9 </IonButton> 
                                     <IonButton size="small" fill="clear" color={radius === 10 ?"primary":"dark"} onClick={()=>{setRadius(10);  setModalssave(false,fea.properties.point ,10)}}> 10 </IonButton> 
                                    </IonButtons>
                                    <IonButtons> 
                                        <IonButton size="small" mode="ios" color={"primary"} fill={"outline"}  onClick={()=>{ return  setModalssave(true,fea.properties.point ,radius) }} >
                                            เพิ่มเป็นเขตพื้นที่
                                        </IonButton> 
                                        {circle && <IonButton size="small" mode="ios" color={"danger"} onClick={()=>{dispatch(setCircle(null)) }} >
                                            ยกเลิก
                                        </IonButton> }
                                    </IonButtons>

                                  </div> 
                                </Popup>
                            </Marker>
                        )
                    }
                   { circle && <Polygon positions={circle} color="#F45F0A"   ></Polygon>}
                    {
                        markerAll && markerAll.features.length > 0 &&
                        <LayersControl.Overlay   name="All Route" >
                            <GeoJSON //@ts-ignore
                            id={'passroute-marker-position'} //@ts-ignore
                            data={markerAll}
                            filled= {true} 
                            pointRadiusMinPixels= {2} 
                            pointRadiusScale= {2000} 
                            getPointRadius={ (f:any) => 11 - f.properties.scalerank }
                            getFillColor={ [200, 0, 80, 180]}
                            //@ts-ignore
                            pointToLayer={  (feature, latlng) => {
                                  return L.marker(latlng ,{ 
                                    icon : createIcon(feature) , 
                                    rotationAngle: feature.properties.point?.heading , 
                                    rotationOrigin:"center"
                                }).bindPopup( popupContent(feature))
                            }} 
                            />    
                        </LayersControl.Overlay>
                        }
                </LayersControl> 
            </FeatureGroup>
          }  
          <LayersControlMap  />
        </MapContainer>   
        </div>
    )
} 
 

export const PassRoutePolyLine=({ count,stop , setmap ,color}:any)=>{
    const passroute:Route = useSelector(getPassRoute)
    const map = useMap() 
    const playAction = useSelector(getPlayAction) 
    const playindex  = useSelector(getPassRouteIndex)
    // const routeIndex = useSelector(getPassRouteIndex)
    
    useEffect(()=>{  
        if(passroute){
          console.log("PassRoutePolyLine passroute ",  passroute ) 
          console.log("PassRoutePolyLine playindex ",  playindex ) 
          console.log("index ",  passroute.route.length-1) 
          if(count === 0 && playAction === "stop" && passroute?.route.length >0 ){
            setTimeout(()=>{   map?.fitBounds(passroute?.route)},1000)
          } 
        //   if(count >= passroute.route.length-1){
        //     return stop()
        //   }
        }
        return setmap(map)
      },[passroute , playindex])



    return(<>
      {passroute &&
       <FeatureGroup>
          {/* {passroute.route.length > 0  && <Polyline  positions={passroute?.route} color={color} /> } */}
          {passroute?.route && count < passroute.route.length && <>
              <MarkerRoute count={playindex}/>
              {/* <Popup minWidth={30} offset={[5, -65]} position={passroute?.route[count]} > 
                <div  style={{width:"15rem"}} >
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" >
                            popup 
                            <ul>
                                <li>  <strong>{count} : </strong> {passroute?.name}   </li>
                                <li>  <strong>{t('date')}</strong> {passroute?.list[count]?.event_stamp}  </li>
                                <li>  <strong>{t('home_speed')}</strong> {passroute?.list[count]?.speed} {t('kmHUnit')} </li>
                                <li>  <strong>{t('home_address')}</strong><br/>  {passroute?.list[count]?.address}</li>
                                <li>  <strong>{t('home_driver')}</strong><br/>   {passroute?.list[count]?.driver_message}</li>
                            </ul> 
                        </IonCol> 
                    </IonRow>
                </IonGrid> 
                </div>
              </Popup>  */}
              {passroute.route.length >0 &&<Marker icon={markerStart} position={passroute.route[0]} >
                   <Popup>
                     <div style={{width:"12rem" ,fontSize:".9em" }}  >
                        <IonLabel>status: {passroute.list[0]?.status} </IonLabel> <br/>
                        <IonLabel>{passroute.list[0]?.address } </IonLabel>
                     </div>
                   </Popup>
                </Marker>}
              {passroute.route.length >2 &&<Marker icon={markerStop} position={passroute.route[passroute.route.length -1 ]} >
                <Popup>
                     <div style={{width:"12rem" ,fontSize:".9em" }}  >
                        <IonLabel>status: {passroute.list[passroute.route.length -1]?.status} </IonLabel> <br/>
                        <IonLabel>{passroute.list[passroute.route.length -1 ]?.address } </IonLabel>
                     </div>
                </Popup>
             </Marker>}
             </> }  
       </FeatureGroup>
      }
    </>)
}

const MarkerRoute=({ count,  device,trackMarker}:any)=>{
    const passroute = useSelector(getPassRoute)  
    const routeIndex = useSelector(getPassRouteIndex)

    useEffect(()=>{ 
        console.log(routeIndex,"setPassRouteIndex  MarkerRoute routeIndex ",passroute?.list[routeIndex].latitude) 
    },[passroute,routeIndex])
    
    return( <FeatureGroup>
          {passroute?.list.length > 0? 
          <Marker position={passroute?.route[routeIndex]}
              icon={  new Icon({ 
                  iconUrl: `../assets/types/type-${passroute?.deviceIcon !== null && passroute?.deviceIcon !== undefined ? passroute?.deviceIcon : 1}.png`,
                  iconSize: [60, 60],
                  rotationOrigin: "center",
                  iconAnchor: [25, 60], 
                }) 
              }>
              <Popup minWidth={30} offset={[5, -65]}> 
                <div  style={{width:"15rem"}} >
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" >
                            <ul>
                                <li>  <strong>{routeIndex} : </strong> {passroute?.name}   </li>
                                <li>  <strong>{t('date')}</strong> {passroute?.list[routeIndex]?.event_stamp}  </li>
                                <li>  <strong>{t('home_speed')}</strong> {passroute?.list[routeIndex]?.speed} {t('kmHUnit')} </li>
                                <li>  <strong>{t('home_address')}</strong><br/>  {passroute?.list[routeIndex]?.address}</li>
                                <li>  <strong>{t('home_driver')}</strong><br/>   {passroute?.list[routeIndex]?.driver_message}</li>
                            </ul> 
                        </IonCol> 
                    </IonRow>
                </IonGrid> 
                </div>
              </Popup> 
              </Marker>:<></>}
  
              {passroute?.list.length > 0 ?
              <Marker 
              //@ts-ignore 
              rotationOrigin="center"  
              ref={(markerRef:any)=>{
                 markerRef?.setRotationAngle(passroute?.list[count].heading)
              }}
              icon={ new DivIcon({
                className: `marker-status-divicon ${ 
                  passroute?.list[routeIndex].status ===7? 'marker-move': 
                  passroute?.list[routeIndex].status ===23?'marker-stop': 
                  passroute?.list[routeIndex].status ===24?'marker-idle':'marker-offline'
                }`,
                iconSize: [20, 20], 
                iconAnchor: [5, 78],  
              })} 
              position={[Number(passroute?.list[routeIndex].latitude), Number(passroute?.list[routeIndex].longitude)]} 
                 
           />
           :<></>
        }
    </FeatureGroup> )
  }



export const SlidBack=({clearPlayId}:any)=>{
    const swiper = useSwiper()
    return(
        <IonButton fill="clear" 
         onClick={()=>{ 
            clearPlayId()
            swiper.slidePrev()
         }}
        > <IonIcon icon={chevronBack} />  <IonLabel>Back</IonLabel> </IonButton>
    )
}
export const ChooseRoute=({setRoute ,setLoading}:any)=>{
    const swiper = useSwiper() 
    
    return(
        <IonButton  color={"secondary"} mode="ios"
         onClick={async ()=>{
            setLoading(true) 
            await setRoute()
            swiper?.slideNext()
            setLoading(false)
         }}
         size="small"
        >  
          &nbsp;
          <IonLabel>Play</IonLabel>
        </IonButton>
    )
}

export const CardRoute=({size,index,detail,removing,removeRoute,hideRoute,playingid,setRoute}:any)=>{ 
    const [loading,setLoading] = useState(false)
    useEffect(()=>{
        console.log("loading Change ", loading)
    },[loading])
    return(
        <div className={`
        ${  size===1?"one":
            size >1 && index === 0 ?"top":
            size >1 && index === size-1?"bottom":
            "normal "
        } card-history `
              }
            style={{cursor:'pointer'}}
            
        > 
            <IonRow  > 
                <IonCol size="10" className="set-center" style={{flexDirection:"row",justifyContent:'flex-start'}} > 
                    <IonCheckbox class="check-route"  aria-label=""  checked={detail.show} onIonChange={(e)=>{hideRoute(detail._id,e.detail.checked)}} ></IonCheckbox>  
                    <IonLabel  style={{fontSize:'.8em',fontWeight:'bold',marginLeft:'1rem'}} > 
                        {detail.name}<br/>   
                    </IonLabel>
                </IonCol>
                <IonCol size="2" className="ion-text-right" >
                    {
                        removing ? <IonSpinner name="crescent"></IonSpinner> :
                        <IonIcon icon={closeCircle} color="danger" onClick={()=> removeRoute(detail._id)} /> 
                    }
                </IonCol> 
                <IonCol size="4" className="set-center"  style={{alignItems:"flex-start"}} > 
                    <IonLabel className="card-title" style={{marginBottom:'.5rem'}}>ระยะทาง</IonLabel> <sub >{detail.distance_all}</sub>
                </IonCol>
                <IonCol size="4" className="set-center"  style={{alignItems:"flex-start"}} > 
                    <IonLabel className="card-title" style={{marginBottom:'.5rem'}}>ระยะเวลา</IonLabel>  <sub>{detail.time_all}</sub>
                </IonCol>
                <IonCol size="4" className="set-center" style={{alignItems:"flex-end"}} >
                   
                    {loading ? 
                        <IonSpinner  name={"crescent"} /> :  
                        <ChooseRoute   setRoute={()=>{return setRoute(detail)}} setLoading={(e:any)=>{ console.log("loading "+e) ;setLoading(e)}}/>
                    } 
                </IonCol>
            </IonRow>
            {
                playingid === detail._id &&
                <IonRow style={{marginBottom:'1rem'}}>
                    <IonCol size="4" className="set-center" style={{alignItems:"flex-start"}}>
                        <IonLabel className="card-title" style={{marginBottom:'.5rem'}}>Start: </IonLabel> 
                         <sub>{moment(detail.datetime_start).format("HH:mm DD/MM/YY")}</sub>
                    </IonCol>
                    <IonCol size="4" className="set-center" style={{alignItems:"flex-start"}}>
                        <IonLabel  className="card-title" style={{marginBottom:'.5rem'}}>End: </IonLabel>
                         <sub>{moment(detail.datetime_stop).format("HH:mm DD/MM/YY")}</sub>
                    </IonCol>
                    <IonCol size="4" className="set-center" style={{alignItems:"flex-start"}}>
                        <IonLabel className="card-title" style={{marginBottom:'.5rem'}}>Speed max: </IonLabel>
                        <sub> {detail.speed_max} km/h</sub>
                    </IonCol>
                </IonRow>
            }

        </div>
    )
} 