import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonModal, IonPage, IonRadio, IonRadioGroup, IonRow, IonSearchbar, IonText, IonTextarea, IonTitle, IonToggle, IonToolbar, useIonToast } from "@ionic/react"
import { addCircle, addCircleOutline, chevronBackOutline, closeCircle, closeOutline, createOutline, help, helpCircleOutline, peopleOutline, settings, trashOutline } from "ionicons/icons";
import NavBar from "../../components/NavBar";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { DropDownDevice } from "../../components/AppComponent";
import { useDispatch, useSelector } from "react-redux";
import { getBackup, setBackupDevices, setDevices, setLoading } from "../../store/appSlice";
import { addEditEmployees, allDevices, delEmployees, getEmployees } from "../../actions";
import countries from "../../country.json"
import * as XLSX from 'xlsx';

import "../css/Employees.css"
interface Employee {
    id: Number
    fullname: String
    phone:  String
    birthDay:  String
    gender:  String
    note:  String
    deviceId:Number
    country:  String
    idCard:  String
    registrar:  String
    userId: Number
    accountId:Number
    enabled: Boolean
    lastUpdate: String
    deviceName:String
    username:String
}

const Employees=()=>{
    const history = useHistory()
    const [modal,setModal] = useState(false)
    const [employees , setEmployees] = useState<Employee[]>([])
    const [employee,setEmployee] = useState<Employee|null>()
    const [excel, setExcel] = useState(null);

    const [iontost] = useIonToast();
    const presentToast = ( message: any , color: "danger" | "warning" | "success") => {
        iontost({
            message : message,
            duration: 3000,
            position: 'bottom',
            color: color ,
            mode:"ios"
        });
    }; 
    const getEm=async()=>{
        let ems = await getEmployees()
        setEmployees(ems)
    }

    const delEm=async(id:any)=>{ 
        let ems = await delEmployees({id:id})
         if(ems.result){
            presentToast("Delete Success !!" , "success")
         }else{
            presentToast("Delete Fail !!" , "danger")
         }
         getEm()
    }

    useEffect(()=>{ 
        getEm() 
    },[])

    const handleFileUpload = (e:any) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        console.log("file ",file)
        reader.onload = (event:any) => {
          const workbook = XLSX.read(event.target.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const sheetData:any = XLSX.utils.sheet_to_json(sheet);
    
          setExcel(sheetData);
        };
    
        reader.readAsBinaryString(file);
    };


    return( <IonPage>
        <IonHeader color='transparent' className='ion-no-border'   > 
            <IonToolbar color='transparent' mode='ios' className='ion-no-padding'>  
                <NavBar />  
                    <IonButtons slot="start"  className='ion-hide-md-up  '  >
                    <IonButton size='small' onClick={()=>{history.goBack()}}> <IonIcon icon={chevronBackOutline} />  <IonText >Back</IonText> </IonButton>
                    </IonButtons>
                    <IonTitle style={{fontWeight:"bold",fontSize:"1.3em",marginLeft:"1rem" }} >{'Reports'}</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent   >
            <div className="ion-padding page-container"  > 

                <div   > 
                <IonGrid className="top-container ion-no-margin" style={{borderTopRadius:"0px"}}>
                    <IonRow className="ion-justify-content-center" >
                        <IonCol size="8"  className="set-center-row"  >
                            <IonLabel className="set-center-row" style={{fontSize:"1.3rem"}} >
                                <IonIcon icon={peopleOutline} /> &nbsp;
                                <IonLabel>พนักงาน</IonLabel>
                            </IonLabel>  
                            <IonSearchbar mode="ios" style={{maxWidth:"25rem"}}/>
                        </IonCol>
                        <IonCol size="4" className="set-center" style={{flexDirection:"row"}} >
  
                            {/* <IonButton size="small" fill="clear"  
                            onClick={()=>{window.open("../assets/manual/example_employee.xlsx","_blank")}} >
                                <IonIcon icon={helpCircleOutline} />
                            </IonButton>
                            <div className="choose-file" >
                                <input type="file" id="upload" hidden onChange={handleFileUpload}/>
                               
                                <label for="upload">นำเข้า</label>
                            </div> */}
                             
                            {/* <IonButton mode="ios" fill="outline"  size="small" > ส่งออก </IonButton> */}
                            <IonButton mode="ios"  size="small" onClick={()=>{setModal(true)}} >  
                               <IonLabel >เพิ่มใหม่</IonLabel> &nbsp;
                               <IonIcon icon={addCircle} />    
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>  
               
                <IonGrid className="  top-container" > 
                    <IonRow className="ion-justify-content-center" >
                        <IonCol size="10" >

                            <IonRow>
                                <IonCol size=".5"> <IonLabel>#</IonLabel></IonCol>
                                <IonCol size="2"> <IonLabel>พนักงาน</IonLabel></IonCol>
                                <IonCol size="2"> <IonLabel>ทะเบียน</IonLabel></IonCol>
                                <IonCol size="3"> <IonLabel>ชื่อผู้ใช้งาน</IonLabel> </IonCol>
                                <IonCol size="2"> <IonLabel>อัพเดตล่าสุด</IonLabel></IonCol>
                                <IonCol size="1" className="ion-text-center" >  </IonCol>
                            </IonRow> 
                            
                        </IonCol>
                    </IonRow>
                </IonGrid><br/>  
                <IonGrid className="ion-no-padding" >
                    <IonRow className="ion-justify-content-center top-container" >
                        <IonCol size="10" style={{minHeight:"5rem"}} >
                        {
                                employees.map((em:Employee,index:any)=> 
                                    <IonRow  key={index} style={{fontWeight:"normal"}} >
                                        <IonCol size=".5" className="flex-center"> <IonLabel>{index+1}</IonLabel></IonCol>
                                        <IonCol size="2" className="flex-center"> <IonLabel>{em.fullname}</IonLabel></IonCol>
                                        <IonCol size="2" className="flex-center"> <IonLabel>{em.deviceName}</IonLabel></IonCol>
                                        <IonCol size="3" className="flex-center"> <IonLabel>{em?.username}</IonLabel> </IonCol>
                                        <IonCol size="2" className="flex-center"> <IonLabel>{em?.lastUpdate}</IonLabel></IonCol>
                                        <IonCol size="2" className="flex-center">  
                                            <IonButton  fill="clear" onClick={()=>{delEm(em.id)}} > 
                                                <IonIcon icon={trashOutline} />    
                                            </IonButton> /
                                            <IonButton  fill="clear" onClick={()=>{ setModal(true);setEmployee(em) }} > 
                                                <IonIcon icon={createOutline}  />    
                                            </IonButton>     
                                        </IonCol>
                                    </IonRow> 
                                )
                            }
                        </IonCol>
                    </IonRow>
                    </IonGrid>
                </div>

            </div>
            <DialogAddEmployee 
                open={modal} 
                employee={employee} 
                setOpen={(e:any)=>{
                    setModal(e); 
                    !e&&setEmployee(null);
                    !e&&getEm() }
                } 
            />

        </IonContent>
    </IonPage>
    )
}
export default Employees;

const DialogAddEmployee=({open,setOpen,employee}:any)=>{
    const [gender,setGender] = useState("")
    const [fullname,setFullName] = useState("")
    const [emid,setEmId] = useState(null)
    const [phone,setPhone] = useState("")
    const [birthDay,setBirthDay] = useState("")
    const [note,setNote] = useState("")
    const [device,setDevice] = useState(null)
    const [vehihle,setVehicle] = useState({device_id:0 ,value: 0,name:"Select"})
    const [options,setOptions] = useState([])
    const [country,setCountry] = useState("Thailand")
    const [enabled,setEnabled ] = useState("1")
    const [idCard,setIdCard] = useState("")
    const [registrar,setRegistar] = useState("")
    const backup = useSelector(getBackup)
    const dispatch = useDispatch()
    const [iontost] = useIonToast();

    const presentToast = ( message: any , color: "danger" | "warning" | "success") => {
        iontost({
            message : message,
            duration: 3000,
            position: 'bottom',
            color: color ,
            mode:"ios"
        });
    };

    const saveEmployee=async()=>{ 
        const emp = {
            id: emid,
            fullname: fullname,
            phone: phone ,
            birthDay: birthDay ,
            gender: gender ,
            note: note ,
            deviceId: vehihle.device_id ,
            country: country ,
            idCard: idCard , 
            registrar:registrar ,
            enabled:  enabled === "1"? true :false
        }
        console.log("saveEmployee ",emp)
        const res = await addEditEmployees(emp)
        if(res.result){
            presentToast("Update Success !! ","success")
            setOpen(false)
        }else{
            presentToast("Update Fail !! ","danger")
        } 
    }

    useEffect(()=>{  
        const getdevices=async()=>{
            console.log("backup ",backup)
            let devices = []
            if(backup.length === 0){ 
                devices = await allDevices({}) 
                console.log("get allDevices ",devices)
                setLoading(false)
                await dispatch(setDevices(devices))
                await dispatch(setBackupDevices(devices)) 
               
            }else{
                devices = backup
            } 
            let options = devices.map((e:any)=> {return {value: e.device_id ,label: e.name, ...e } })
            setOptions(options)     
        } 
        getdevices()   
        if(open && employee !== null){
            console.log(" employee ",employee)
            setEmId(employee?.id)
            setPhone(employee?.phone)
            setFullName(employee?.fullname)
            setBirthDay(employee?.birthDay)
            setNote(employee?.note)
            setDevice(employee?.deviceId)
            let select:any = options.find((e:any)=> e.device_id === employee?.deviceId)
            setVehicle(select)
            setCountry(employee?.country)
            setIdCard(employee?.idCard)
            setRegistar(employee?.registar) 
            setGender(employee?.gender)
            console.log("emp ", employee?.enabled)
            setEnabled(employee?.enabled === true? "1":"0")
        }
    },[open])

    const inputIdCard=( target:any)=>{
       const inputval = target.value 
       console.log("idCard ",idCard)  
     
        try{
            let isNum = Number(inputval) 
            if(!Number.isNaN(isNum)){
                var result = ''
                if(inputval.length > 0){ result = `${inputval[0]}-` }
                if(inputval.length > 1){ result = `${inputval[0]}-${inputval[1]}` }
                if(inputval.length > 2){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}` }
                if(inputval.length > 3){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}${inputval[3]}` }
                if(inputval.length > 4){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}${inputval[3]}${inputval[4]}` }
                if(inputval.length > 5){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}${inputval[3]}${inputval[4]}-${inputval[5]}` }
                if(inputval.length > 6){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}${inputval[3]}${inputval[4]}-${inputval[5]}${inputval[6]}` }
                if(inputval.length > 7){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}${inputval[3]}${inputval[4]}-${inputval[5]}${inputval[6]}${inputval[7]}` }
                if(inputval.length > 8){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}${inputval[3]}${inputval[4]}-${inputval[5]}${inputval[6]}${inputval[7]}${inputval[8]}` }
                if(inputval.length > 9){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}${inputval[3]}${inputval[4]}-${inputval[5]}${inputval[6]}${inputval[7]}${inputval[8]}${inputval[9]}` }
                if(inputval.length > 10){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}${inputval[3]}${inputval[4]}-${inputval[5]}${inputval[6]}${inputval[7]}${inputval[8]}${inputval[9]}-${inputval[10]}` }
                if(inputval.length > 11){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}${inputval[3]}${inputval[4]}-${inputval[5]}${inputval[6]}${inputval[7]}${inputval[8]}${inputval[9]}-${inputval[10]}${inputval[11]}` }
                if(inputval.length > 12){ result = `${inputval[0]}-${inputval[1]}${inputval[2]}${inputval[3]}${inputval[4]}-${inputval[5]}${inputval[6]}${inputval[7]}${inputval[8]}${inputval[9]}-${inputval[10]}${inputval[11]}-${inputval[12]}` }
                setIdCard(result) 
            }else{
                presentToast("ข้อมูลไม่ถูกต้อง ระบุเฉพาะตัวเลข" ,"warning")
            }
        }catch(err){
            presentToast("ข้อมูลไม่ถูกต้อง ระบุเฉพาะตัวเลข" ,"warning")
        } 
    }

    return(  <IonModal className="dialog-add-employee"  isOpen={open} mode="ios" onIonModalDidDismiss={()=>{setOpen(false)}} >
        <IonContent className="ion-padding"  >
            <IonGrid>
                <IonRow  className="border-bottom" >
                    <IonCol size="11"  className="set-center-row  " style={{justifyContent:"flex-start",fontSize:"1.15em"}} >
                        <IonIcon icon={addCircleOutline} style={{fontSize:"1.5em"}} /> &nbsp;
                        <IonLabel>พนักงาน</IonLabel>
                    </IonCol>
                    <IonCol size="1" >
                        <IonButton fill="clear" onClick={()=>{setOpen(false)}} > <IonIcon icon={closeOutline} />  </IonButton>
                    </IonCol>
                </IonRow>
                <IonRow className="border-bottom" >
                    <IonCol size="12" > <IonLabel>ข้อมูลพนักงาน</IonLabel> </IonCol>
                    <IonCol size="12"> 
                        <IonToggle checked={enabled === "0"?false:true  } onIonChange={(e)=>{setEnabled(e.detail.checked?"1":"0")}} />
                    </IonCol>
                    <IonCol size="12"> 
                        <div className="input">
                            <IonInput mode="ios" placeholder="ชื่อ-นามสกุล" value={fullname} onIonChange={(e)=>{setFullName(e.detail.value!)}} ></IonInput>
                        </div>
                    </IonCol>
                    <IonCol size="6"> 
                        <div className="input">
                            <IonInput mode="ios" placeholder="เบอร์โทรศัพท์" value={phone} onIonChange={(e)=>{setPhone(e.detail.value!)}} ></IonInput>
                        </div>
                    </IonCol>
                    <IonCol size="6"> 
                        <div className="input">
                            <IonInput mode="ios" placeholder="วันเกิด" type="date" value={birthDay}  onIonChange={(e)=>{ setBirthDay(e.detail.value!)}} ></IonInput>
                        </div>
                    </IonCol>
                    <IonCol size="12" >

                        <IonRadioGroup value={gender} onIonChange={(e)=>{setGender(e.detail.value)}}>
                            <IonRadio value={"male"}   labelPlacement="end" style={{marginRight:"2rem"}}>ชาย</IonRadio> 
                            <IonRadio value={"female"}  labelPlacement="end">หญิง</IonRadio>
                        </IonRadioGroup>

                    </IonCol>
                    <IonCol size="6" > 
                        <div className="input"> 
                            <IonTextarea 
                            mode="ios" 
                            value={note} 
                            onIonChange={(e)=>{setNote(e.detail.value!)}}
                            placeholder="หมายเหตุ"> 
                            </IonTextarea>
                        </div> 
                    </IonCol>
                    <IonCol size="6">  
                        <DropDownDevice  
                        vehihle={vehihle} setVehicle={(e:any)=>{ setVehicle(e) }}  
                        optionsbackup={options}  
                        />
                    </IonCol> 
                </IonRow> 
                <IonRow className="border-bottom" >
                    <IonCol size="12" > <IonLabel>เอกสารราชการ</IonLabel> </IonCol>
                    <IonCol size="4"> 
                        <div className="input" style={{paddingRight:".3rem"}}>
                            <select value={country} style={{width:"100%",border:'none',background:"transparent",height:"2.5rem"}} >
                                {countries.countries.map((e)=> <option value={e.value} >{e.value}</option> ) }
                            </select>
                            {/* <IonInput mode="ios" placeholder="ประเทศ" value={country} ></IonInput> */}
                        </div>
                    </IonCol> 
                    <IonCol size="8"> 
                        <div className="input">
                            <IonInput 
                            mode="ios" placeholder="เลขบัตรประชาชน"  
                            maxlength={18} value={idCard} 
                            onIonChange={(e)=>{ setIdCard(e.detail.value!) }}
                            onIonBlur={(e)=>{inputIdCard(e.detail.target)}} 
                            ></IonInput>
                        </div>
                    </IonCol> 
                    <IonCol size="12"> 
                        <div className="input">
                            <IonInput mode="ios" placeholder="นายทะเบียนประจำ" value={registrar} onIonChange={(e)=>{setRegistar(e.detail.value!)}} ></IonInput>
                        </div>
                    </IonCol> 
                </IonRow><br/>
                <IonRow className="ion-justify-content-end" >
                    <IonCol size="3" className="set-center-row  "> <IonButton  size="small" onClick={()=>{saveEmployee()}} > <IonLabel>บันทึก</IonLabel> </IonButton> </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonModal>
    )
}


