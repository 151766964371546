import { IonIcon, IonLabel, IonRippleEffect, IonPopover, IonContent, IonToolbar, IonDatetime, IonRow, IonCol, IonButton, IonItem, IonList, IonModal, IonListHeader, useIonActionSheet, IonGrid, IonText, IonCard, IonButtons } from "@ionic/react"
import { search, caretDown, closeCircle, caretForward, chevronBackOutline, leaf } from "ionicons/icons"
import moment from "moment"
import {pdfMake} from "../../lib/pdfMake"  
import { useState, useEffect, useRef } from "react"
import ApexCharts from "apexcharts" 
import { circleOption } from "../../actions" 
import { PieChart } from "../../lib/ApexChart"
import { Device } from "@capacitor/device"
import{ Swiper as SwiperType }from "swiper"
import { Swiper , SwiperSlide } from "swiper/react"
import "../css/ReportComponent.css"

export const SearchPDF=({title,getPDFBase,detail}:any)=>{
    const exportPDF=async()=>{  
      pdfMake.tableLayouts = {
        exampleLayout: {
            defaultBorder: false,
            paddingTop: function (i, node) {
                return 0
            },
            paddingBottom: function (i, node) {
                return 0
            },
        }
      };  
  
  
      let pdf:any = await pdfMake.createPdf({
        images:{
          logo:'https://andamantracking.com/public/logo-report.png',
        }, 
        header:function(currentPage,pageCount,pageSize){
          console.log(` header ${currentPage} of ${pageCount}`)
          return{ 
            columns:[
              {image:'logo' ,width: 100, alignment:'left',margin:[20,20,20,20] }, 
              {text: `  ${currentPage} of ${pageCount}` ,alignment:'right' ,margin:[20,20,20,20] }
            ]
          }
        }, 
        content: [
          {text: title , marginTop: 50 , alignment:"center" , fontSize: 25 },
          { 
            columns: [
              {  
                  marginTop: 10 ,
                  marginBottom: 10,
                  width: '100%', 
                  table: {  
                    widths: ['*', "85%" ],  
                    body: [
                      [ {text:"รายละเอียด", fillColor:"#DBDBDB", margin: 2  , fontWeight: 900 , border:[true,true,false,false]},{text:"", fillColor:"#DBDBDB" , border:[false,true,true,false] }],
                      [ {text:"ทะเบียน", borderColor:"#DBDBDB", margin: 2 , border:[true,false,false,true]}, {text: detail?.vehihle, borderColor:"#DBDBDB" , border:[false,false,true,true]}],
                      [ {text:"ผู้ขับรถ", borderColor:"#DBDBDB", margin: 2 , border:[true,false,false,true]}, {text:"-", borderColor:"#DBDBDB" , border:[false,false,true,true]}],
                      [ {text:"เริ่มต้น", borderColor:"#DBDBDB", margin: 2 , border:[true,false,false,true]}, {text: detail?.start , borderColor:"#DBDBDB" , border:[false,false,true,true]}],
                      [ {text:"สิ้นสุด", borderColor:"#DBDBDB", margin: 2 , border:[true,false,false,true]}, {text: detail?.end , borderColor:"#DBDBDB" , border:[false,false,true,true]}],
                    ]
                  }  
              }, 
            ], 
            columnGap: 10
          }, 
        ],
        pageSize:'A4',
        pageOrientation:'portrait',
        // watermark: { text: 'ใช้เป็นตัวอย่างเท่านั้น', color: '#CCC', opacity: 0.3, bold: true, italics: false },
        pageMargins: 20, 
  
        defaultStyle:{
          font: "THSarabunNew", 
          italics:true,
        }
      }).getBlob((result)=>{ return result})
      console.log("pdf ",pdf) 
      return getPDFBase( URL.createObjectURL(pdf))
    }
    return(
      <div className='button-search ion-activatable ripple-parent' onClick={()=>{exportPDF()}}> 
        <IonIcon icon={search} />  
        <IonLabel>ค้นหา</IonLabel>
        <IonRippleEffect></IonRippleEffect>
      </div> 
    )
  }
   
export const ExportPDF=({title,detail}:any)=>{ 
  
    const exportPDF=async()=>{
      // PdfPrinter?.createPdfKitDocument(document , {
  
      // })
      pdfMake.tableLayouts = {
        exampleLayout: {
            defaultBorder: false,
            paddingTop: function (i, node) {
                return 0
            },
            paddingBottom: function (i, node) {
                return 0
            },
        }
      };  
  
  
      let pdf = await pdfMake.createPdf({
        images:{
          logo:'https://andamantracking.com/public/logo-report.png',
        }, 
        header:function(currentPage,pageCount,pageSize){
          return{
            background:"#eeeeee" ,
            columns:[
              {image:'logo' ,width: 100, alignment:'left',margin:[20,20,20,20] , background:"#eeeeee"},
              // {text: new Date().toLocaleString(),alignment:'left',margin:[10,10,0,0]},
              {text: `หน้่า ${currentPage}/${pageCount}` ,alignment:'right',margin:[0,10,10,0] }
            ]
          }
        }, 
        content: [
          {text: title , marginTop: 50 , alignment:"center" , fontSize: 15 },
          { 
            columns: [
              {  
                  marginTop: 10 ,
                  marginBottom: 10,
                  width: '100%',
                  // layout: 'lightHorizontalLines',
                  table: {  
                    widths: ['*', "85%" ],
                    body: [
                      [ {text:"รายละเอียด", fillColor:"#DBDBDB", margin: 2  , border:[true,true,false,false]},{text:"", fillColor:"#DBDBDB" , border:[false,true,true,false] }],
                      [ {text:"ทะเบียน", borderColor:"#DBDBDB", margin: 2 , border:[true,false,false,true]}, {text:'RE0000000001', borderColor:"#DBDBDB" , border:[false,false,true,true]}],
                      [ {text:"ผู้ขับรถ", borderColor:"#DBDBDB", margin: 2 , border:[true,false,false,true]}, {text:'12/12/2022', borderColor:"#DBDBDB" , border:[false,false,true,true]}],
                      [ {text:"เริ่มต้น", borderColor:"#DBDBDB", margin: 2 , border:[true,false,false,true]}, {text:'เอกนรินทร์ คำคูณ', borderColor:"#DBDBDB" , border:[false,false,true,true]}],
                      [ {text:"สิ้นสุด", borderColor:"#DBDBDB", margin: 2 , border:[true,false,false,true]}, {text:'PO0000000005', borderColor:"#DBDBDB" , border:[false,false,true,true]}],
                    ]
                  }  
              }, 
            ], 
            columnGap: 10
          },
          'This paragraph goes below all columns and has full width'
        ],
        pageSize:'A4',
        pageOrientation:'portrait',
        // watermark: { text: 'ใช้เป็นตัวอย่างเท่านั้น', color: '#CCC', opacity: 0.3, bold: true, italics: false },
        pageMargins: 20, 
  
        defaultStyle:{
          font: "THSarabunNew", 
          italics:true,
        }
      }).getDataUrl((result)=>{ return result  })
      console.log("pdf ",pdf)
    }
  
  
    return(
      <div className='export-excel ion-activatable ripple-parent' onClick={()=>{exportPDF()}} >
        <IonLabel>PDF</IonLabel>
        <img src={"../../assets/icon/pdf.png"}   />
        <IonRippleEffect></IonRippleEffect>
      </div>
    )
    }
    
export  const ExportExcel=()=>{ 
    return(
      <div className='export-excel ion-activatable ripple-parent'>
        <IonLabel>EXCEL</IonLabel>
        <img src={"../../assets/icon/excel.png"}   />
        <IonRippleEffect></IonRippleEffect>
      </div>
    )
  }
  
export const DateStartReport=({date,openDate,bg, showtime  }:any)=>{ 
  
      return( <div className={`date-rang-report ${bg && 'show-bg' }`}  id='date-rang-report'  style={{width:"10rem", height:"2.5rem",fontSize:".9em"}} onClick={()=>{return openDate(true)}}>
         <div className={`date-start ion-activatable ripple-parent `}  > 
           <IonText color={"dark"} > เริ่มต้น</IonText> &nbsp; 
            <IonLabel color={"dark"}>   {moment(date).local(true).format("DD MMM YY")} </IonLabel>
            <IonRippleEffect></IonRippleEffect>  
           {showtime && <IonLabel style={{marginLeft:".5rem"}} color={"dark"}>{moment(date).local(true).format("HH:mm")}</IonLabel> } &nbsp;  <IonIcon icon={caretDown} color={"dark"} />  
        </div> 
      </div> 
    ) 
  }
  
export const DateEndReport=({date,openDate,bg,showtime}:any)=>{  
    return(<div className={`date-rang-report  ${bg && 'show-bg' }`} id='date-rang-report' style={{width:"10rem",height:"2.5rem",fontSize:".9em"}} onClick={()=>{return openDate(true)}}>
      <div className={`date-start ion-activatable ripple-parent   `}  >
        <IonText color={"dark"} > สิ้นสุด</IonText>&nbsp;  
        <IonLabel color={"dark"}>   {moment(date).local(true).format("DD MMM YY")} </IonLabel>
        <IonRippleEffect></IonRippleEffect> 
        {showtime &&<IonLabel color={"dark"} style={{marginLeft:".5rem"}} > {moment(date).local(true).format("HH:mm")}</IonLabel> } &nbsp; <IonIcon icon={caretDown} color={"dark"} />     
      </div> 
    </div>) 
  }
export const MonthlyReport=({value,setMonth}:any)=>{  
    const [open ,setOpen] = useState(false)
    const monthref = useRef<null | HTMLIonDatetimeElement>(null);
    
    return(<div  style={{position:"relative"}}> 
    <div style={{display:'flex', flexDirection:"row" , alignItems:"center" , justifyContent:"center" }}>
      {/* <IonLabel className='ion-hide-md-down'  > </IonLabel> &nbsp; */}
      <button id='btn-report-months' className='reports-selection  ' style={{padding:".8rem 0 .8rem .5rem"}} onClick={()=>{setOpen(!open)}}  >
        <IonLabel className='ion-margin-end' >{moment(value).local(true).format('MMMM')}</IonLabel> <IonLabel color={"medium"} style={{fontSize:'.7em'}} ><span>|</span></IonLabel> <IonLabel className='ion-margin-horizontal'>{moment(value).format("YYYY")}</IonLabel>
        <IonIcon  slot='end' icon={caretDown} color='medium' style={{fontSize:'.6em'}}  />  &nbsp;
      </button> 
      {/* <IonPopover trigger='btn-report-months' className='monthly-report-selection' mode='ios' >
          <IonContent className='ion-padding'>
            <IonToolbar color={"transparent"}>
              <IonLabel>เลือกเดือน-ปี</IonLabel>
            </IonToolbar>
            <IonDatetime presentation="month-year" max={moment().format()} onIonChange={(e:any)=>{setMonth(e.detail.value!)}} value={value} preferWheel={true}></IonDatetime> 
          </IonContent>
        </IonPopover> */}
    </div>
    {open && <IonCard mode="md" className="ion-no-margin" style={{position:"absolute",marginLeft:" -10%",marginTop:".3rem"}} >
        <IonButton fill="clear" size="small" color={"danger"} onClick={()=>{setOpen(false)}} > <IonIcon icon={closeCircle}  /> </IonButton>
        <IonDatetime 
          ref={monthref}
          presentation="month-year" max={moment().format()} 
          onIonChange={(e:any)=>{setMonth(e.detail.value!);setOpen(false)}} 
          value={value} preferWheel={true}
          >
           <IonButtons slot="buttons">
            <IonButton color="primary" onClick={(e:any)=>{monthref?.current?.confirm();setOpen(false)}} > OK </IonButton> 
          </IonButtons> 
      </IonDatetime> 
     </IonCard>
     }
    </div>)
  }
  
export const DateRangPopover=({start,setStart,end,setEnd,isOpen,openDate ,daily}:any)=>{
    const [max,setMax] = useState(moment().format("YYYY-MM-DDT23:59"))
    const [min,setMin] = useState(moment().subtract(2,"year").format("YYYY-MM-DDT23:59"))
    const [isMobile , setIsMobile] = useState(false)
    useEffect(()=>{
      const checkOS=async()=>{
        let info = await Device.getInfo()
        if(info?.operatingSystem !== "mac" && info?.operatingSystem != "windows" && info?.operatingSystem !== "unknown"){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      } 
      checkOS() 
    },[start,end ])

    const changestart=(date:any)=>{ 
      if(daily){ 
        setEnd(moment(date).format("YYYY-MM-DDT23:59:59")) ; 
      }
      return setStart(date)
    }
  
    return(<> 
    {!isMobile ?  
        <div style={{ top:'0',left:"1rem",position:'absolute',width:"50vw" ,zIndex:99999,backgroundColor:"#FFF",margin:".5rem",borderRadius:"10px"}}>
         
        {isOpen &&<div style={{border:"1px solid #ddd",display:"flex",flexDirection:"row"}} >
            <IonGrid  > 
              <IonRow>
                <IonCol size='10' className='set-center'  style={{alignItems:'flex-start',paddingLeft:'1rem'}}>
                    <IonLabel style={{fontWeight:"bold",fontSize:"larger"}}>เลือกวันที่เริ่มต้น - สิ้นสุด</IonLabel>
                </IonCol>
                <IonCol size='2' style={{textAlign:'right'}} >
                  <IonButton size='small' onClick={()=>{ return openDate(false) }} > <IonIcon icon={closeCircle} /> </IonButton> 
                </IonCol>
                <IonCol size='6' >
                   <IonDatetime value={start} min={min} max={max} 
                  onIonChange={(e)=>{changestart(e.detail.value!); }}
                   style={{fontSize:".7em"}} ></IonDatetime>
                </IonCol>
                <IonCol size='6' >
                     <IonDatetime value={end}  min={start} onIonChange={(e)=>{return setEnd(e.detail.value!)}}  style={{fontSize:".7em"}}></IonDatetime>
                </IonCol>
              </IonRow> 
            </IonGrid>
        </div>}
        </div>
      :
      <IonModal  isOpen={isOpen} backdropDismiss={true} showBackdrop={true} onDidDismiss={()=>{return openDate(false)}} mode='ios' trigger='date-rang-report' initialBreakpoint={0.9}>
        <IonContent style={{position:"relative"}} scrollY > 
          <IonRow>
            <IonCol size='10' className='set-center'  style={{alignItems:'flex-start',paddingLeft:'1rem'}}>
                <IonLabel style={{fontWeight:"bold",fontSize:"larger"}}>เลือกวันที่เริ่มต้น - สิ้นสุด</IonLabel>
            </IonCol>
            <IonCol size='2' style={{textAlign:'right'}} >
              <IonButton size='small' onClick={()=>{ return openDate(false) }} > <IonIcon icon={closeCircle} /> </IonButton> 
            </IonCol>
            <IonCol size='12' >
               <IonDatetime value={start} min={min} max={max} onIonChange={(e)=>{changestart(e.detail.value!); }}></IonDatetime>
            </IonCol>
            <IonCol size='12' >
               <IonDatetime value={end}  min={start} onIonChange={(e)=>{return setEnd(e.detail.value!)}}></IonDatetime>
            </IonCol>
          </IonRow> 
        </IonContent>
      </IonModal>
    
    }
    </>
    )
}
  
  
export const DeviceTypeFiltew=({type,setType,multiple}:any)=>{
    const datetype =[
      {value:"one",label:"รายคัน"},
      {value:"group",label:"รายกลุ่ม"}
    ]
    const changeType=()=>{
      if(type.value === "one" ){
        return datetype[1] 
      }else{
        return datetype[0] 
      }
    }
  
    return(<>
      <IonButton size='small' fill='clear' color={"dark"} disabled={multiple? false:true} >
        <IonRow>
          <IonCol onClick={(e)=>{return setType(changeType()) }}>
            <IonLabel>{type.label}</IonLabel>
          </IonCol>
          <IonCol size='2' >
            <IonIcon icon={caretDown} color='medium' id='devices-type'  style={{fontSize:'.7em',marginLeft:'.5rem'}} />
          </IonCol>
        </IonRow> 
        <IonPopover trigger='devices-type' mode='ios'  dismissOnSelect={true}  >
          <IonContent>
            {
              datetype.map((el,index)=>
                <IonItem key={index} mode='md' button  onClick={()=>{return setType(el)}} >
                  <IonLabel style={{fontSize:".8em"}} >{el.label}</IonLabel>
                  <IonIcon slot='end' icon={caretForward} color='medium' style={{fontSize:'.6em'}} />
                </IonItem>
              )
            }
          </IonContent>
        </IonPopover>
      </IonButton>
    </>)
}
  
  
export const DateTypeFilter=({type,setType}:any)=>{
    const datetype =[
      {value:"date",label:"ช่วงเวลา"},
      {value:"month",label:"รายเดือน"}
    ]
    const changeType=()=>{
      if(type.value === "date" ){
        return datetype[1] 
      }else{
        return datetype[0] 
      }
    }
  
    return(<>
      <IonButton size='small' fill='clear' color={"dark"} >
        <IonRow>
          <IonCol onClick={(e)=>{return setType(changeType()) }}>
             <IonLabel>{type.label}</IonLabel> 
          </IonCol>
          <IonCol size='2' >
            <IonIcon icon={caretDown} color='medium'  style={{fontSize:'.7em',marginLeft:'.5rem'}}  id='date-type' />
          </IonCol>
        </IonRow>
        
        <IonPopover trigger='date-type' mode='ios'  dismissOnSelect={true}  >
          <IonContent>
            {
              datetype.map((el,index)=>
                <IonItem key={index} mode='md' button  onClick={()=>{return setType(el)}} >
                  <IonLabel style={{fontSize:".8em"}} >{el.label}</IonLabel>
                  <IonIcon slot='end' icon={caretForward} color='medium' style={{fontSize:'.6em'}} />
                </IonItem>
              )
            }
          </IonContent>
        </IonPopover>
      </IonButton>
    </>)
}
  
export  const ReportsSelection=({groups,selected,select,}:any)=>{ 
    const [isOpen,setIsOpen] = useState(false)
    const [present] = useIonActionSheet();
    const [reports, setReports] = useState([])
    const [ swiperRef , setSwiperRef] = useState<SwiperType>()

    const selectReportGroup=(report:any)=>{
      setReports(report)
      swiperRef?.slideTo(1)
    }

    const actionMobile=async (group:any)=>{
      console.log("group ",group)
      present({
        header: group.namegroup,
        buttons: await group.reports.map((rep:any)=>{ return {
            text: rep.name, 
            data: { action: rep, },
            handler:()=>{
              select(rep)
              setIsOpen(false)
            }
          } 
        })
      })
    } 

    return(<div className="report-container"  >
      <button className=' reports-selection '   id='report-sections'  style={{padding:'.8rem .5rem .8rem',width:"100%", justifyContent:"space-between"}}> 
        <IonLabel color={"dark"} style={{ fontSize:".9em"}}> {selected?.name}</IonLabel>  
        <IonIcon icon={caretDown} color='medium'  style={{fontSize:'.7em',marginLeft:'.5rem'}} />
      </button>
      <IonModal isOpen={isOpen} onIonModalDidDismiss={()=>{setIsOpen(false)}} className="ion-hide-md-up"  mode='ios'
         initialBreakpoint={0.5} breakpoints={[0.5, 0.75]}
       >
        <IonContent> 
          <IonList>
            <IonListHeader>
              <IonLabel>{"Reports"}</IonLabel>
              <IonButton fill="clear"  onClick={()=>{setIsOpen(false)}}> <IonIcon icon={closeCircle} /> </IonButton>
            </IonListHeader>
            {
              groups.map((group:any,index:any)=>
                <IonItem key={index} mode='md' button id={group.id}  onClick={()=>{actionMobile(group)}}>
                  <IonLabel style={{fontSize:".8em"}} >{group.namegroup}</IonLabel>
                  <IonIcon slot='end' icon={caretForward} color='medium' style={{fontSize:'.6em'}} />
                </IonItem>
              )
            }
          </IonList> 
        </IonContent>
      </IonModal>
     <div  id="expand-report-selection"   > 
        <Swiper
         draggable={false} 
         allowTouchMove={false} 
         onSwiper={(swiper) => setSwiperRef(swiper)} 
        >
          <SwiperSlide>
            <IonList>
              {  groups.map((group:any,index:any)=>
                <IonItem key={index} mode='md' button id={group.id} onClick={()=>{selectReportGroup(group?.reports)}}>
                    <IonLabel style={{fontSize:".8em"}} >{group.namegroup}</IonLabel>
                    <IonIcon slot='end' icon={caretForward} color='medium' style={{fontSize:'.6em'}} /> 
              </IonItem>  )}
            </IonList>
          </SwiperSlide>
          <SwiperSlide>
          <IonButton fill="clear" size="small" color={"primary"} onClick={()=>{ swiperRef?.slidePrev()}} > <IonIcon icon={chevronBackOutline} />  <IonLabel>Back</IonLabel></IonButton>
            <IonList>
              {
                reports.map((report:any,i:any)=>  
                  <IonItem key={i} mode='md' button id={report.id} disabled={!report?.enable} onClick={()=>{select(report); swiperRef?.slidePrev() ;setIsOpen(false);setReports([]) }}>
                  <IonLabel style={{fontSize:".8em" }} className='ion-text-wrap'  >{report.name}</IonLabel>
                  <IonIcon slot='end' icon={caretForward} color='medium' style={{fontSize:'.6em'}} />
                </IonItem>
                )
              }
            </IonList>
          </SwiperSlide>
        </Swiper>   
      </div>  
    </div>)
}
    
export const TextContent=({content}:any)=>{
   useEffect(()=>{
     console.log("content ",content)
    },[])
  return(<IonLabel>
    {
      content.map((el:any,index:any)=> 
        <p key={index} style={el.style}> {el.text}  </p>
      )
    }
    
  </IonLabel>)
}

var options:ApexCharts.ApexOptions = {
  series: [0], 
  colors : ['#5FBF05','#e9b702','#c73f0e','#85807e',"red"],
  labels: ['Team A'], 
  ...circleOption
};

export const CircleChartContent=({content,setRef,current}:any)=>{ 
  const [pieUri , setPieUri] = useState("") 
  const [chrtUri,setChrtUri] = useState("") 
  
  useEffect(()=>{  
    if(content){
      console.log("content ",content )
    }
   },[ chrtUri  ])
  return(
    <IonRow>
      <IonCol size="12" className="set-center" >
      <div id="report-content-circle" style={{height:'300px' }} >
     
        {/* <img src={pieUri} />   */}
           <PieChart  
            //@ts-ignore 
            types={"pie"} 
            labels={content?.labels} 
            colors={content?.colors}
            series={[10,5]}
            getUri={(e:any)=>{return setPieUri(e)}}
           /> 
      </div>
      </IonCol>
    </IonRow>
  )
}


export default {}