import { IonAvatar, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonRadio, IonRadioGroup, IonRippleEffect, IonRow, IonSearchbar, IonSpinner, IonText, IonTitle, IonToggle, useIonAlert, useIonToast } from "@ionic/react"
import {  addCircle, arrowForwardOutline, arrowRedo, calculator, chevronBack, chevronDown, chevronForward, chevronUp, close, map, mapOutline, pricetagOutline, reload, reloadOutline, search, searchCircle, searchOutline, share, syncOutline, trash, trashBin } from "ionicons/icons";
import { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { useHistory } from "react-router"; 
import { addSetRoute, allGeometry, allGeozones, allGeozonesOrder, geozoneDetail, getGeozone, getLongdokey } from "../actions";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTurnUp, faTurnDown, faUpLong, faCircle, faBridge, faLocationDot, faFerry, faRoute } from "@fortawesome/free-solid-svg-icons";
import { FeatureGroup, LayersControl, MapContainer, Marker, Polyline, Popup, GeoJSON, useMap } from "react-leaflet";
import { LayersControlMap } from "../leaflet/LeafletLayers";
import L , {  Icon, LatLngExpression, Marker as MarkerType, Popup as PopupType, Tooltip as TooltipType  , Map as MapType, tooltip} from 'leaflet';  
import * as Excel from "exceljs";
import FileSaver from "file-saver";
import { longdo, map as lmap, LongdoMap  } from "../lib/LongdoMap"
 
import { extendMoment } from "moment-range";
import * as Moment from "moment";  

import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker" 
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw'; 
import 'react-leaflet-markercluster/dist/styles.min.css';
import "./css/TravelRoute.css"

import './css/Longdo.css';
import { t } from "i18next";
import { borderCells } from "../lib/Excels"; 
import { defaultPin } from "../leaflet/PublicMarker"; 
import Longdo from "./Longdo";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

const moment = extendMoment(Moment);
moment.locale("th")


interface Zone{ 
    geoId: Number,
    name: String,
    canDelete: Boolean,
    icon: Number,
    position: {
        lon: Number , lat: Number
    }[] 
}
const polylineColor = ["#F44336","#4CAF50","#8BC34A","#FFEB3B","#FF5722","#E91E63","#9C27B0","#673AB7","#3F51B5","#2196F3","#03A9F4","#00BCD4","#FFC107","#009688","#FF9800","#795548","#607D8B","#B71C1C" ,"#AD1457","#FF8F00"]
 
const routeModes= [
    { val: 't' ,label:"เส้นทางที่เร็วที่สุดและหลีกเลี่ยงการจราจรติดขัดด้วยสภาพการจราจรแบบเรียลไทม์หรือที่คาดการณ์ไว้ (หากระบุเวลา)"} ,
    { val: 'c' ,label:"ค้นหาแบบไปตามถนนหลัก"} , // เส้นทางที่เร็วที่สุด (ไม่คำนึงถึงสภาพการจราจร) คืนเวลาการเดินทางคงที่"} ,
    { val: 'd' ,label:"เส้นทางที่สั้นที่สุด กลับเวลาเดินทางคงที่"} ,
    { val: 'e' ,label:"เส้นทางที่สั้นที่สุด ระยะเวลาเดินทางกลับตามสภาพการจราจร"} ,

]

const  formatDate=(difference:any) => { 
    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    let hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return `${days > 0 ? days+"วัน":'' }  ${hours >0 ?hours+" ชม.":''} ${minutes >0 ? minutes+" นาที":''}` 
}


const TravelRouteAdd: React.FC =()=>{
    const history = useHistory() 
    const [zones,setZones] = useState<any[]>([])
    const [zonesBackup,setZonesBackup] = useState<any[]>([])
    const [zone , setZone] = useState< any|null>()
    const [stations,setStations] = useState<any[]>([])
    const [modal,showModal] = useState(false)
    const [routeStation , setRouteStation] = useState([])
    const [alldistance , setAllDistance] = useState(0)
    const [allDriveDuraion , setAlDrivelDurartion] = useState<any>() 
    const [allDuraion , setAllDurartion] = useState<any>()
    const [keyword,setKeyword] = useState("")
    const [routeName,setRouteName] = useState("")
    const [loading,setLoading] = useState(false)
    const [spinner,setSpinner] = useState(false)
    const [ionalert, dimissalert] = useIonAlert()
    const [iontoast,dimisToast] = useIonToast();
    const [isCheckAll,setIsCheckAll] = useState(false)
    const [routeMode , setRouteMode] = useState('c')  
    const [routeType , setRouteType] = useState(1)  
    const [routeLocale , setRouteLocale] = useState("th") 
    const [routeLabel , setRouteLabel] = useState("distance") 
    const [LONGDOKEY,setLONGDOKEY] = useState(null)
    const [tabs,setTabs] = useState(0)
    function getCenterPolygon(arr:any) { 
        return arr.reduce(function (x:any,y:any) {
            return [x[0] + y[0]/arr.length, x[1] + y[1]/arr.length] 
        }, [0,0]) 
    }
    function timeStay(timein:any,timeout:any){ 
        const start = moment(timein);
        const end = moment(timeout);
        const r1 = moment.range(start, end);  
         
        if (r1.diff('second') < 60) return  r1.diff('second') + " วิ";
        else if (r1.diff('minute') < 60) return r1.diff('minute') + " นาที";
        else if (r1.diff('hour') < 24) return r1.diff('hour') + " ชม.";
        else return r1.diff('day') + " วัน"  
    }
 
    const fetchlongdokey=async()=>{
        let lkey = await getLongdokey({})  
        console.log("getLongdokey lkey ",lkey)
        setLONGDOKEY(lkey)
     }
    useEffect(()=>{
        if(LONGDOKEY===null){
            fetchlongdokey()
        }
        const getzone=async()=>{  
            // let {count,current,geofences,pageAll }:any = await allGeozones({page: 0, pager: false, limit:100}) 
            const geofences = await allGeozonesOrder({})
            setZonesBackup(geofences)
            setZones(geofences)
        }
        getzone()
        console.log("stations ",stations)
    },[routeStation,zone,spinner , routeLabel ,routeMode ,routeType])

    const selectStation=async (zone:any)=>{ 
        setZone(zone)
    }
    const addToTable=async (zone:any)=>{
        setLoading(true)
        const zonedetail:any = await geozoneDetail({geoid: zone?.id})  

        setLoading(false)
        let stattionupdate = stations
        let timein =  new Date(moment().format()) 
        timein.setDate(1)
        timein.setHours(0)
        timein.setMinutes(0) 
        timein.setSeconds(0)  

        if( stations?.length > 0 ) { 
            let lastin = stations[stations?.length-1].timeIn
            await timein.setHours(lastin?.getHours())  
            await timein.setMinutes(lastin?.getMinutes()+15)  
        }
        let timout:any = new Date(moment().format())
        timout.setDate(1) 
        timout.setSeconds(0) 
        timout.setHours(timein.getHours())
        await timout.setMinutes(timein.getMinutes()+15)
        
        const start = moment(timein);
        const end = moment(timout);
        const r1 = moment.range(start, end);    

        let station = {
            id: zone?.id ,
            check: false,
            order: stations?.length +1 ,
            name: zonedetail?.name ,
            dateIn: timein ,
            timeIn:  timein ,
            dateOut: timout,
            timeOut: timout,
            timeleave:"00:00",
            distanceBetween:"0" ,
            durationInStation: r1.duration("millisecond") ,
            point: zonedetail?.position,
            driveDuration: 0 ,
            mindate: timein.setDate(1) ,
        }
        stattionupdate.push(station) 
        setStations(stattionupdate)

        const zoneselect =  zone
        setZone(null)
        setTimeout(()=>{
            setZone(zoneselect)
        },200) 
    }

    const updateStation=async (st:any)=>{ 
        let newstations =await stations.map((station:any,i:any)=>{ 
            return station
            // if(i !== station.index && station.check === true ){
            //     return {...station , ...{check:false}}
            // }else{
            //     return station
            // }
        }) 

        let stationsupdate = newstations
        let station =  newstations[st.index]
        station = {...station ,...st.update} 
        stationsupdate[st.index] = station  
       
        setStations(stationsupdate) 
    }
   
    const calcaulateTwoPoint=async(twopoint:any)=>{
        let start = twopoint?.start
        let end = twopoint?.end
        console.log("twopoint ",twopoint)
        let boundPointStart = await start?.point.map((p:any)=> { return [p.lat ,p.lon]})
        let boundPointEnd = await end?.point.map((p:any)=> { return [p.lat ,p.lon]})
         
        let startp = await getCenterPolygon(boundPointStart)
        let endp = await getCenterPolygon(boundPointEnd)
        
        let polyline:any[] = [] 
        const {features , data ,meta } = await axios.get("https://api.longdo.com/RouteService/geojson/route",{
            params:{
                flon: startp[1],
                flat: startp[0] ,
                tlon: endp[1] ,
                tlat: endp[0] ,
                mode: twopoint?.mode,
                type: routeType,
                locale: routeLocale,
                key: LONGDOKEY
            }
        }).then((res)=>{   
            return res.data
        }).catch(err =>{ }) 

        await features.map(async(fea:any)=>{
            let coord = await  fea.geometry.coordinates.map((coord:any)=>{  polyline.push( [coord[1] , coord[0]])  })
            polyline.concat(coord)
        }) 

        return await {start,end,polyline,features ,data,meta}
    }
 
    const viewStation=async (twopoint:any)=>{
        const {start,end,polyline,features, data ,meta} = await calcaulateTwoPoint(twopoint)
        // console.log("data ",data)  
        let indexrow = twopoint.indexrow
        let station =  stations[indexrow]
        let km = data.distance / 1000
         
        station= {...station,...{distanceBetween:  Number(km.toFixed(1)) , driveDuration: data.interval*1000  }}
        let stationupdate = stations
        stationupdate[indexrow] = station  

        let alldistance = 0
        let driveDuration = 0
        let allDuration = 0
        await stationupdate.map((station:any)=>{
            alldistance += Number(station.distanceBetween)
            driveDuration = driveDuration + station?.driveDuration 
            allDuration = driveDuration + station.driveDuration + station?.durationInStation
        })   
        setAlDrivelDurartion(driveDuration)
        setAllDistance(Number(alldistance.toFixed(1)))
        setAllDurartion(allDuration)
        setStations(stationupdate)  
        const color =  station?.color !== undefined && station?.color !== null? station?.color : polylineColor[indexrow] 
        let rStation:any = [{ polyline:polyline ,color: color  ,direction: features , start:start ,end: end}]
        await setRouteStation(rStation)
    }
    
    const calMapStationDistance=async()=>{ 
        setSpinner(true)
        let routeSt:any = [] 
        let colorindex = 0
         await stations.map(async ( s:any,index:any)=>{
            if(index>0  ){
                await viewStation({ 
                    start : stations[index-1],
                    end: s ,
                    indexrow: index ,
                    mode: routeMode
                })  
                const {start,end,polyline,features , data} = await calcaulateTwoPoint({ 
                    start : stations[index-1],
                    end: s ,
                    mode: routeMode
                }) 
                const color = polylineColor[colorindex] 
                let rStation:any = { polyline: polyline ,color: color ,direction: features , start: start  ,end: end}
                // console.log("rStation ",rStation)
                routeSt = [...routeSt ,rStation]
                if(routeSt.length ===  stations?.length -1){ 
                    // console.log("routeSt ",routeSt)
                    setRouteStation(routeSt)
                    // showModal(true)
                } 

            } 
            if(colorindex >= polylineColor.length-1){ colorindex=0  }
            colorindex++;
        })  
        setSpinner(false) 
    }
 
 
 
    const moveRowUp=()=>{
       let stationsupdate = stations 
       let rowSelected = stations.filter((st:any)=> st.check === true)
             
       for (let i = 0 ; i < rowSelected.length ; i++) {
                // let indexChecked = await stations.indexOf(rowSelected[i])
            const indexChecked = stations.map((e:any) => e.id).indexOf(rowSelected[i].id);
            if(!loading && indexChecked >0){
                setLoading(true)
               if(rowSelected.length>0){
                    let firstrow =  rowSelected[0]
                    // let indexoffirstrow = stations.map((s:any)=> s.order).indexOf(firstrow.order); 
                    const indexoffirstrow = stations.map((e:any) => e.id).indexOf(rowSelected[i].id);
                    if(indexoffirstrow > 0 ){ 
                        let  stationA =   stations[indexoffirstrow-1]
                        let  stationB =   stations[indexoffirstrow]

                        let bTimeIn = new Date(stationB.timeIn)
                        let bTimeOut = new Date(stationB.timeOut)
                        
                        bTimeIn.setMinutes(bTimeIn.getMinutes()-15)
                        bTimeOut.setMinutes(bTimeOut.getMinutes()-15)

                        stationA = {...stationA ,...{order: stationA.order+1  , timeIn: stationB.timeIn , timeOut: stationB.timeOut   }}
                        stationB = {...stationB ,...{order: stationB.order-1  , timeIn: bTimeIn , timeOut: bTimeOut   }}
                        stationsupdate[indexoffirstrow] = stationA
                        stationsupdate[indexoffirstrow-1] =stationB
                        //re renders UI
                        const zoneselect =  zone
                        setZone(null)
                        setTimeout(()=>{
                            const newData = stationsupdate 
                            setStations(newData);
        
                            setZone(zoneselect)
                            setLoading(false)
                        },600)
                    }
                    }else{
                        console.log("rowSelected ",rowSelected)
                    }
                }else{
                    iontoast({
                        message:"กรุณาสักครู่ !!" ,
                        position:"top" ,
                        color:"warning",
                        duration: 1000
                    })
                }
            }
    }

    const moveRowDown=async ()=>{ 
            let stationsupdate = stations
            let rowSelected = stations.filter((st:any)=> st.check === true)
            console.log("rowSelected   ",rowSelected )
             
            for (let i =rowSelected.length-1 ; i >= 0; i--) { 
                const indexChecked = stations.map((e:any) => e.id).indexOf(rowSelected[i].id); 
                if(loading ===false && indexChecked <stations.length){
                    setLoading(true) 
                    
                    const indexoflastrow = await stations.map((e:any) => e.id).indexOf(rowSelected[i].id);
                    console.log("indexoflastrow   ",indexoflastrow )
                    if(  indexoflastrow < stations.length  ){ 
                        try{
                            let  stationA =   stations[indexoflastrow]
                            let  stationB =   stations[indexoflastrow+1]  
                  
                            stationA = {...stationA ,...{order: stationA.order+1 }}
                            stationB = {...stationB ,...{order:  stationB.order -1   }}
                            stationsupdate[indexoflastrow] = stationB
                            stationsupdate[indexoflastrow+1] = stationA 
    
                            console.log("stationsupdate ",stationsupdate)
                            //re renders UI
                            const zoneselect =  zone
                            setZone(null)
                            await setTimeout(()=>{  
                                // setStations((st)=> { return  stationsupdate})
                                const newData = stationsupdate 
                                setStations(newData);
                                setZone(zoneselect)
                                setLoading(false)
                            },600)
                        }catch(err){
                            setLoading(false)
                            console.log("err ",err)
                        }
                        
                    //   }
                    }else{
                        setLoading(false) 
                        iontoast({
                            message:"ไม่สามารถขยับไปยังรายการถัดไปได้ !!" ,
                            position:"top" ,
                            color:"warning",
                            duration: 1000
                        })
                    }  

                }else{
                    iontoast({
                        message:"กรุณาสักครู่ !!" ,
                        position:"top" ,
                        color:"warning",
                        duration: 1000
                    })
                }
                 
            }
            // let indexChecked = stations.indexOf(rowSelected[0])
            // console.log("indexChecked ",indexChecked)
        
     
    }

    const removeRow=()=>{
        let stationsupdate = stations
        let rowSelected = stations.filter((st:any)=> !st.check  ) 
        setStations(rowSelected) 
        const zoneselect =  zone
        setZone(null)
        setTimeout(()=>{
            setZone(zoneselect)
        },200)
    }

    const updateTableDate=async(val:DateTimeTableUpdate)=>{ 
        const date = val.datetime
        const rowindex = val.rowindex
        const typevalue = val.type

        let datenum = date.getDate()
        let updatestation = await stations.map((st:any,index:any)=>{
            let dateIn =new Date(st.dateIn)
            if(rowindex <= index  ){  
                let timein =new Date(st.timeIn) 
                let dateOut =new Date(st.dateOut) 
                let timout =new Date(st.timeOut) 
                 
                const start = moment(timein);
                const end = moment(timout);
                const r1 = moment.range(start, end);  
                
                if(typevalue ==="out"  ){ 
                    dateOut.setDate(datenum)
                    timout.setDate(datenum)  
                    if(index > rowindex ){
                        dateIn.setDate(datenum) 
                        timein.setDate(datenum)  
                    }
                } else{ 
                    dateIn.setDate(datenum) 
                    timein.setDate(datenum) 
                    dateOut.setDate(datenum)
                    timout.setDate(datenum) 
                } 
                let timeinstation = timout.getTime() -  timein.getTime()  
                return {...st ,
                    ...{
                        dateIn: dateIn ,
                        timeIn:  timein ,
                        dateOut: dateOut ,
                        timeOut: timout  ,
                        durationInStation: timeinstation
                    }
                } 
            }else{
                return st
            }
        })
        // console.log("updatestation ",updatestation)
        setStations(updatestation)

    }
    const updateTableTime=async (val:DateTimeTableUpdate)=>{
        // console.log("val ",val)
        const date = val.datetime
        const rowindex = val.rowindex
        const typevalue = val.type

        let hournum = date.getHours()
        let minnum = date.getMinutes()
        let updatestation = await stations.map((st:any,index:any)=>{
            let dateIn =new Date(st.dateIn)
            if(rowindex <= index  ){ 
                 
                let timein =new Date(st.timeIn) 
                let dateOut =new Date(st.dateOut) 
                let timout =new Date(st.timeOut)  
                if(typevalue ==="out"  ){ 
                    dateOut.setHours(hournum)
                    dateOut.setMinutes(minnum) 
                    
                    timout.setHours(hournum) 
                    timout.setMinutes(minnum) 
                    if(index > rowindex ){
                        dateIn.setHours(hournum) 
                        dateIn.setMinutes(minnum) 
    
                        timein.setHours(hournum) 
                        timein.setMinutes(minnum+15) 

                        timout.setMinutes(timein.getMinutes() +15) 
                    }
                } else{ 
                    dateIn.setHours(hournum) 
                    dateIn.setMinutes(minnum) 

                    timein.setHours(hournum) 
                    timein.setMinutes(minnum) 

                    dateOut.setHours(timein.getHours())
                    dateOut.setMinutes(timein.getMinutes()+15) 

                    timout.setHours(timein.getHours())
                    timout.setMinutes(timein.getMinutes()+15) 
                } 
                let timeinstation = timout.getTime() -  timein.getTime()  // r1.duration("millisecond")  
                return {...st ,
                    ...{
                        dateIn: dateIn ,
                        timeIn:  timein ,
                        dateOut: dateOut ,
                        timeOut: timout  ,
                        durationInStation: timeinstation
                    }
                } 
            }else{
                return st
            }
        }) 
        setStations(updatestation)
    }


    const printExcel=async ()=>{
        try{   
            var options = {
                filename: './streamed-workbook.xlsx',
                useStyles: true,
                useSharedStrings: true
            }; //@ts-ignore 
            let workbook = new Excel.Workbook(options);
            var ws = workbook.addWorksheet( "AddRoute" , {properties:{tabColor:{theme:1}}});
            
            ws.columns =[{width:10},{width: 20},{width: 15},{width: 15},{width: 15},{width: 15},{width: 15},{width: 15},{width: 15},{width: 20},{width: 20}] 
            // ws.getRow(1).alignment = {horizontal:'center' , vertical:'middle' }
            const header = ["ลำดับ" , "ชื่อสถานี" , "ละติจูด" , "ลองติจูด"  ,  "วันที่เข้า" , "เวลาที่เข้า" , "เวลาที่อยู่ในสถานี" , "วันที่ออก" ,"เวลาที่ออก","เวลาเดินทางไปสถานีถัดไป(ชั่วโมง:นาที)","ระยะห่างระหว่างสถานีถัดไป"]
            ws.addRow(header)
            const rowheader = ws.getRow(1); 
            rowheader.eachCell({includeEmpty: true}, ((cell:any) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }; 
                cell.font = { bold: true }
                cell.border = borderCells
            }));

            stations.map((st:any,index:any)=>{
                // console.log("st ",st)
                const row = [
                    index+1  ,
                    st?.name , 
                    st?.point[0].lat ,
                    st?.point[0].lon , 
                    new Date(st?.dateIn).getDate() ,
                    moment(st?.timeIn).format("HH:mm") ,
                    timeStay(st?.timeIn , st?.timeOut),
                    new Date(st?.dateOut).getDate() ,
                    moment(st?.timeOut).format("HH:mm") ,
                    st.timeleave ,
                    st?.distanceBetween
                ] 
                let rowadded = ws.addRow(row)
                rowadded.height =   rowadded.height*2 ;
                rowadded.eachCell({includeEmpty: true}, ((cell:any,colnum:any) => {  
                    cell.border = borderCells
                    cell.alignment = {wrapText: true}
                })); 
            })

            const rowoverview = stations.length +3
            ws.mergeCells(`A${rowoverview}:B${rowoverview}`);  
            ws.getCell(`A${rowoverview}`).value = "ระยะทางรวม"  
            ws.mergeCells(`C${rowoverview}:D${rowoverview}`);  
            ws.getCell(`C${rowoverview}`).value = alldistance + "  กิโลเมตร" 

            const rownxt = rowoverview +1
            ws.mergeCells(`A${rownxt}:B${rownxt}`);  
            ws.getCell(`A${rownxt}`).value = "รวมเวลาขับขี่"  
            ws.mergeCells(`C${rownxt}:D${rownxt}`);  
            ws.getCell(`C${rownxt}`).value = formatDate(allDriveDuraion) 
 
            const rowthrd = rownxt +1
            ws.mergeCells(`A${rowthrd}:B${rowthrd}`);  
            ws.getCell(`A${rowthrd}`).value = "ระยะเวลารวม"  
            ws.mergeCells(`C${rowthrd}:D${rowthrd}`);  
            ws.getCell(`C${rowthrd}`).value = formatDate(allDuraion) 

            const rowoverview1 = ws.getRow(rowoverview); 
            rowoverview1.eachCell({includeEmpty: true}, ((cell:any) => { 
                cell.border = borderCells
            }));
            const rowoverview2 = ws.getRow(rownxt); 
            rowoverview2.eachCell({includeEmpty: true}, ((cell:any) => { 
                cell.border = borderCells
            }));
            const rowoverview3 = ws.getRow(rowthrd); 
            rowoverview3.eachCell({includeEmpty: true}, ((cell:any) => { 
                cell.border = borderCells
            }));

            let fileName=`AddRoute.xlsx`;  
            workbook.xlsx.writeBuffer() 
            .then(function(buffer:any) { 
                const data: Blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                FileSaver.saveAs(data, fileName);
            }); 

        }catch(err){ 
            console.log("err ",err)
        }
    }
    const onSearchZone=(word:any)=>{
        console.log("word ",word)
        setKeyword(word);
        if(word.length > 1){
            let zonefilter = zonesBackup.filter((z:any)=> z?.name.toLowerCase().indexOf(word.toLowerCase()) > -1)
            console.log("zonefilter ",zonefilter)
            setZones(zonefilter)
        }else{
            setZones(zonesBackup)
        }
    }

    const saveRouteAdd=async ()=>{   
        if(routeName.length > 0 && !loading && stations.length > 1){
            setLoading(true)
            const stationsB =await stations.map((e:any)=> {  
                return {
                    stationId: e.id ,
                    dateIn:  moment(e.dateIn).format("DD"),
                    timeIn:  moment(e.timeIn).format("HH:mm") ,
                    dateOut: moment(e.dateOut).format("DD"),
                    timeOut: moment(e.timeOut).format("HH:mm") ,
                    stayDuration: timeStay(e.timeIn,e.timeOut) ,
                    timeLeave: e.timeleave ,
                    distanceBetween: e.distanceBetween,
                    latlng: `${e.point[0].lat},${e.point[0].lon}`  
                }
            })
            let  coordB:any[] = [] 
            await routeStation.map((e:any,routindex:any)=>{
                let coordStr = ""
                let lineNo = routindex+1 

                e.direction.map((d:any)=>{
                    d.geometry.coordinates.map((coord:any)=>{  
                        coordStr += `${coord[0]},${coord[1]}#`
                    })
                    coordB = [...coordB , {
                        coord: coordStr ,
                        name:   d?.properties?.name,
                        duration: d?.properties?.interval,
                        distance: d?.properties?.distance,
                        turn:   d?.properties?.turn, 
                        lineNo: lineNo
                    }]
                }) 
            }) 
            const body = {  
                name: routeName ,
                distance: alldistance ,
                driveDuration: await formatDate(allDriveDuraion) ,
                allDuration: await formatDate(allDuraion) ,
                beginStaionId: stationsB[0].stationId ,
                endStationId:  stationsB[stationsB.length-1].stationId  ,
                stationCount:  stationsB.length ,

                stations: stationsB ,
                coords: coordB
            }   
            const result = await addSetRoute({body:body}) 
            if(result){
                iontoast({
                    message:"เพิ่มเส้นทาง "+routeName+"สำเร็จ !!" ,
                    position:"top",
                    color:"success",
                    duration: 3000
                })
            }else {
                iontoast({
                    message:"เพิ่มเส้นทาง "+routeName+" ไม่สำเร็จ !!" ,
                    position:"top",
                    color: "danger",
                    duration: 3000
                })
            }
            setLoading(false) 
        }else if(stations.length <= 1 ){ 
            ionalert({
                message : "โปรดเลือกส้นทางก่อนบันทึกข้อมูล !!"
            })
        }else if(!loading && routeName.length >0){
            ionalert({
                message : "ไม่สามารถบันทึกได้ขณะนี้ ลองอีกครั้งภายหลัง !!"
            })
        }else{
            ionalert({
                header : "โปรดระบุชื่อเส้นทาง" ,
                mode:"ios"
            })
        }
    }

    const checkAll=async(checked:any)=>{ 
        setIsCheckAll(checked)
        let newstations =await stations.map((station:any,i:any)=>{ 
            return {...station , ...{check: checked}}
        })
        let stationsupdate = newstations 
        setStations(stationsupdate) 
    }
// ----------------------------------------

    const tabsChange=(index:any)=>{
        console.log("tabsChange index ",index)
        setTabs(index)
       
        if(index === 0    ){
            lmap.Route.clear();
            lmap.Overlays.clear() 
        }else  if(index === 1 && lmap != undefined){
            setTimeout(()=>{   lmap.resize()  }, 200)
        }
    }
    let stayin = 0  
    const getLBound=(bound:any)=>{
        let minLon = 0
        let minLat = 0
        let maxLon = 0
        let maxLat = 0
        for(let i=0; i < bound.length-1 ; i++){
            console.log("bound ",bound[i])
            let blat = bound[i][1]
            let blon = bound[i][0]

            if(blon > maxLon){ maxLon = blon}
            if(blat > maxLat){ maxLat = blat} 
        }
        for(let i= bound.length-1 ; i >0 ; i-- ){
            let blat = bound[i][1]
            let blon = bound[i][0]

            if(blon < maxLon){ minLon = blon}
            if(blat < maxLat){ minLat = blat} 
        }
        return { maxLon , maxLat , minLon , minLat }
    } 
    const searchawait=async()=>{ 
        let { markers ,bound}:any = await markerStations()
        console.log("markers ",markers)
        console.log("bound ",bound) 

       
        let routelist = lmap.Route.list()
        console.log("routelist ",routelist)
                // let isadd =   await routelist.find((e:any)=> e._geojson.properties.name  === marker._geojson.properties.name)
                // console.log("isadd ",station.name ," - ",isadd)
                // if(isadd !== undefined){
                //     lmap.Route.add({ lon: station.point[0].lon, lat: station.point[0].lat });
                // }else{
                //     lmap.Route.add(marker);
                // }


        let routesearch =   await lmap.Route.search(); 
        const color = polylineColor[0] 
        const linestyle  ={ 
            title: 'Dashline',
            detail: '-',
            label: 'Dashline',
            lineWidth: 4,
            lineColor: color ,//'rgba(255, 0, 0, 0.8)',
            lineStyle: longdo.LineStyle.Dashed, // if set an object with longdo.LineStyle.Dashed, the object will be dashed line.
            pointer: false
        }
        routesearch.line(longdo.RouteRestrict.Road, linestyle); 

        lmap.Route.enableContextMenu(); 
        
        //  await lmap.Route.search(); 
        // console.log("routeStation ",routeStation)
        // const guides = await lmap.Route.guide()
        // console.log("guide ",guides)
        // await guides.map((gui:any)=>{
        //     console.log("gui ",gui  )
        // })
        
        // let lbound = await getLBound(bound)
        // console.log("lbound ",lbound)
        // lmap.bound(lbound)
        // ต้องหา minLon minLat maxLon maxLat
        // lmap.bound({
        //     minLon: bound[0][0], minLat: bound[0][1],
        //     maxLon: bound[bound.length-1][0], maxLat: bound[bound.length-1][1]
        // })
        
      try{
        const routepLine =   await lmap.Route.line("road",false) 
        if(routepLine){ 
            console.log("routepLine ",routepLine.exportRouteLine())
            // lmap.Overlays.add(routepLine)
        }
      }catch(err){

      }
      
        setTimeout(async ()=>{ 
            let rdistance =  lmap.Route.distance(true);
            let rdisNoForm =  lmap.Route.distance(false);
            let rinterval =  lmap.Route.interval(true);
            console.log("rdistance ",rdistance , " rinterval ", rinterval , " rdisNoForm ",rdisNoForm)
           
            setAllDistance(rdistance)
            setAlDrivelDurartion(rinterval)

            // await stations.map((station:any)=>{ 
            //    stayin += station?.durationInStation  
            //     console.log("stayin ",stayin)
            // })   
            // console.log("stayin ",stayin ," + rdisNoForm " ,rdisNoForm ," = ", (stayin+rdisNoForm) ," ", formatDate(stayin+rdisNoForm))
            // console.log("driveDuration ",driveDuration,"-",formatDate(driveDuration) , " rintervrdisNoFormal ", rdisNoForm,"-",formatDate(rdisNoForm)  )
            // setAllDurartion(driveDuration+rdisNoForm) 
             
        },300)
    }
    async function initMap(){
        console.log("longdokey ",LONGDOKEY) 
    
        try{  
        //   if(lmap){  lmap.resize() ;lmap.Route.clear()}
          lmap.Layers.setBase(longdo.Layers.GRAY); 
        //   lmap.location(longdo.LocationMode.Pointer);  
        
          console.log("lmap ",lmap)
          if(lmap != undefined && lmap != null){ 
            lmap.Route.enableContextMenu();
            lmap.Route.auto(true);
           

            lmap.Route.placeholder(document.getElementById('result')); 
            lmap.Event.bind("ready", async function () {
                console.log("map ready ------------- ") 
                // let { markers ,bound}:any = await markerStations()
                //     layoutCSS = {
                //         height: "81vh",
                //         display: 'flex',
                //         alignItems: 'center',
                //         justifyContent: 'center'
                //     }; 
                //     console.log("---lmap.Route.search();  ",lmap.Route)
                //     console.log("markers ",markers)
                //     console.log("bound ",bound)
                //     lmap.Route.enableContextMenu();
                    
                //     await lmap.Route.search(); 
                searchawait()
                    
                    const color = polylineColor[1] 
                    // lmap.Layers.resize()
            }) 

            console.log("stations ",stations)
            // lmap.Route.search();
            // lmap.Route.line("destination","#FD5F06");
            lmap.resize() 
            setTimeout(()=>{ 
                let rdistance =  lmap.Route.distance(true);
                console.log("rdistance ",rdistance)
                setAllDistance(rdistance)
                lmap.Layers.resize()
            },300)
          }
        }catch(err){
          console.log("initMap err ",err)
        } 
    }



    const markerStations=async()=>{
        let bound:any = []
        let markers:any = []
       await stations.map(async (station:any, indexstation:any)=>{ 
                     
        
            console.log("indexstation ",station , indexstation) 
            if(station.point.length > 0 ){ 
                bound.push([station.point[0].lon , station.point[0].lat ])
                const point = {lon:  station.point[0].lon , lat: station.point[0].lat }
                let y =10
                var marker = new longdo.Marker(point ,{
                    icon: {
                        html: ` <div class="set-center" > 
                                <img src="../../assets/icon-place/map-icon-0.png" style="width: 1.5rem" /> 
                                <div  > <strong style="font-size:1.2em; ">  ${station.order} ${station.name}  </strong> </div>
                            <div>
                           `,
                        offset: { x: 0, y: y+(indexstation*10) }
                    },   
                    name: station.name
                });  
               
                if(indexstation > 0){
                    let stationsbfore = stations.slice(0,indexstation) 
                    let isDubplicate:null = await stationsbfore.find((e)=> e.name === station.name )  
                    if(isDubplicate != null && isDubplicate !== undefined){  
                        var emptyMarker =  new longdo.Marker(point ,{
                            icon: {
                                html: ` `,
                                offset: { x: 0, y:0 }
                            },    
                        });
                        markers = [...markers ,emptyMarker ]
                        lmap.Route.add(emptyMarker);
                    }else{ 
                        lmap.Route.add(marker);
                    }
                }else{ 
                    markers = [...markers ,marker ]
                    lmap.Route.add(marker);
                }
              
                // let routelist = lmap.Route.list()
                // console.log("routelist ",routelist)
                // let isadd =   await routelist.find((e:any)=> e._geojson.properties.name  === marker._geojson.properties.name)
                // console.log("isadd ",station.name ," - ",isadd)
                // if(isadd !== undefined){
                //     lmap.Route.add({ lon: station.point[0].lon, lat: station.point[0].lat });
                // }else{
                //     lmap.Route.add(marker);
                // }
                
              
                
                // lmap.Route.line(longdo.RouteRestrict.Road, linestyle);  
                // lmap.Route.line(longdo.RouteRestrict.Ferry, linestyle);  
                // lmap.Route.line(longdo.RouteRestrict.Tollway, linestyle);   
                // lmap.Route.line(longdo.RouteRestrict.Road ,linestyle  )

            //  lmap.Route.add(Marker);
            } 
            
        }) 
        return{ bound , markers}
    }

    const routeresult = document.getElementById('result')
    const createRouteMart=async()=>{ 
        try{ 
            if(routeresult){
                console.log("stations ",stations)
                // let markers:any = []
                // let bound:any = []
                console.log("routeStation ",routeStation.length)
                lmap.Event.bind("ready", async function () {
                    console.log("map ready ------------- ")
                    lmap.Route.placeholder(document.getElementById('result'));
                    lmap.Route.auto(true);
                    console.log("routeStation ",routeStation) 
                    // lmap.Route.mode(longdo.RouteMode.Traffic)  
                    // lmap.Route.enableRestrict(longdo.RouteRestrict.Road, true);   
                try{
                    lmap.Route.enableContextMenu();
                   let { markers ,bound}:any = await markerStations()
                   console.log("markers ,bound ",markers ,bound)
                    layoutCSS = {
                        height: "81vh",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }; 
                    console.log("---lmap.Route.search();  ",lmap.Route)
                    console.log("markers ",markers)
                    console.log("bound ",bound) 
                }catch(error){
                    console.log("route.search error ",error)
                }
            }) 
           
        }
        }catch(err){
            console.log("err  ",err)
        } 
    }
    
    const createshowroute=()=>{
        setTabs(1)
        console.log(" lmap && stations ",lmap , stations)
        if( lmap && stations ){
            // lmap.Route.mode(longdo.RouteMode.Cost)
            // lmap.Route.enableRestrict(longdo.RouteRestrict.Road, true); 

            // lmap.Route.enableRoute(longdo.RouteType.Tollway, false);
            // lmap.Route.enableRoute(longdo.RouteType.Ferry, false);
            createRouteMart()

            // lmap.Route.search();
            // lmap.resize()
            setTimeout(()=>{   lmap.resize()  }, 200)
        } 
    }

    const clearRoute=()=>{
        console.log("clear route lmap ",lmap )
        if(  lmap  ){
            lmap.Route.clearPath()
            lmap.Route.clearDestination()
            lmap.Route.clear()
        }
        setTabs(0)
    }

    const changRouteType=(type:any)=>{
        setRouteType(type)
        // if(lmap != undefined){ 
            // lmap.Route.clear()
           
        // }
    }

    const changeRouteLabel=(label:any)=>{
        setRouteLabel(label)
        if(label ==="distance"){ lmap.Route.label( longdo.RouteLabel.Distance); }
        else if(label ==="time"){ lmap.Route.label( longdo.RouteLabel.Time); }
        else if(label ==="hide"){ lmap.Route.label( longdo.RouteLabel.Hide); }
        lmap.Route.clear();
        // lmap.Route.enableRestrict(longdo.RouteRestrict.Road, true);
        setTimeout(()=>{searchawait()},200)
    }

    return(
    <IonPage>
        <NavBar />
        <IonContent className="ion-padding" >
        <IonLoading mode="ios" isOpen={loading} message={t('loading').toString()} />
           <Tabs className={"home-screen-tabs "}  selectedIndex={tabs}  onSelect={(index) => tabsChange(index)}>
                <TabList  >
                    <Tab  style={{fontSize:".9em"}}> กำหนดสถานี </Tab>
                    <Tab  style={{fontSize:".9em"}}>  แผนที่ แนะนำการเดินทาง </Tab> 
                </TabList>
                <TabPanel> 
        {/* <IonLabel  style={{ marginLeft:"2rem", fontWeight:"bold" , fontSize:"1.7em",marginTop:"1rem"}} > Add Route </IonLabel> */}
                <IonGrid> 
                    <IonRow>
                        <IonCol size="4" >
                            {/* <IonButton fill="clear" onClick={()=> history.goBack()} color={"secondary"} > <IonIcon icon={chevronBack} /> &nbsp; <IonText >Back</IonText></IonButton> */}
                            <IonTitle   className="set-center-row ion-no-padding" onClick={()=> history.goBack()} 
                                style={{fontSize:"1.6rem", fontWeight:"bold",  width:"fit-content"}}>
                                <IonIcon icon={chevronBack} />  ค้นหาเส้นทางแนะนำ
                            </IonTitle> 
                        </IonCol>
                        <IonCol size="8" style={{display:"flex" , flexDirection:"row" , justifyContent:"flex-end"}}  >
                            <IonButtons>
                                <IonButton fill="clear" onClick={()=>{saveRouteAdd()}} >
                                    <IonLabel> Save </IonLabel>
                                </IonButton>
                                <IonButton fill="clear"  color={"danger"} onClick={()=> history.goBack()}  >
                                    <IonLabel> Cancel </IonLabel>
                                </IonButton>
                            </IonButtons>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="2.7" >
                            <div className="addroute-searh-station" >
                                <IonItem lines="none" style={{borderRadius:"10px"}} >  
                                    <IonInput mode="ios" placeholder="ชื่อเส้นทาง"  value={routeName} onIonChange={(e:any)=>{setRouteName(e.detail.value)}} style={{backgroundColor:"transparent"}} >  </IonInput>
                                </IonItem>
                                <div style={{width:"100%", height:"2px", borderBottom:"1px solid #DDD", margin:"1rem 0px 1rem"}} ></div> 
                                <IonItem lines="none" style={{borderRadius:"10px"}} > 
                                    <IonIcon icon={search} style={{fontSize:"1em" , marginRight:".5rem"}} />
                                    <IonInput 
                                        placeholder="Search" mode="ios" value={keyword} 
                                        style={{backgroundColor:"transparent",}}
                                        onIonChange={(e:any)=>{onSearchZone(e.detail.value);  }}
                                        onIonInput={(e:any)=>{onSearchZone(e.detail.value);  }}
                                    ></IonInput>
                                </IonItem>
                                <br/> 
                                <IonList className="ion-no-padding"  style={{height:"60vh",overflowY:"scroll"}} >
                                    {
                                        zones.map((z,i) => 
                                            <IonItem key={i} className={`zone-selection ${zone?.id === z?.id && "selected" }`} onClick={()=>{addToTable(z)}} >
                                                <IonLabel> {z.name} </IonLabel>
                                            </IonItem> 
                                        )
                                    }
                                </IonList> 
                            </div>
                        </IonCol>
                        <IonCol size=".3" className="set-center" >
                            {/* <IonButton fill="clear" color={"dark"} onClick={()=>{addToTable()}}>
                                <IonIcon icon={chevronForward} />
                            </IonButton>  */}
                        </IonCol>
                        <IonCol size="9" >
                        <div style={{borderBottom:"1px solid #DDD" , padding:"0 .5rem 0", display:"flex",flexDirection:"row"}} className="addroute-searh-station" >
                            <IonButtons style={{marginRight:".5rem" ,borderRight:"1px solid #DDD"}}>
                                <IonButton fill="clear" color={"dark"} onClick={()=>{ moveRowUp() }}>
                                    <IonIcon icon={chevronUp} /> 
                                </IonButton>
                                <IonButton fill="clear" color={"dark"}  onClick={()=>{ moveRowDown()}}>
                                    <IonIcon icon={chevronDown} />  
                                </IonButton>
                            </IonButtons>
                            <IonButtons> 
                                <IonButton fill="clear" color={"success"} onClick={()=>{printExcel()}} >
                                    <IonIcon icon={share} /> &nbsp;
                                    <IonText>ส่งออก</IonText>
                                </IonButton>
                                <IonButton fill="clear" color={"primary"} onClick={()=>{setTabs(1); lmap.Route.clear();lmap.Route.enableRestrict(longdo.RouteRestrict.Road, true);setTimeout(()=>{searchawait()},200)}} >
                                    <IonIcon icon={map} /> &nbsp;
                                    <IonText>ดูแผนที่</IonText>
                                </IonButton>
                                <IonButton  fill="clear" color={"secondary"}  onClick={()=>{calMapStationDistance()}} >
                                    <IonIcon icon={calculator} /> &nbsp;
                                    <IonText>คำนวนระยะทาง</IonText> 
                                </IonButton>
                                <IonButton id="route-mode-popover" fill="clear" color={"secondary"}   >
                                    <FontAwesomeIcon icon={faRoute} />  &nbsp;
                                    <IonText>โหมดเส้นทาง</IonText>
                                    <IonPopover mode="ios" trigger="route-mode-popover" > 
                                        <IonContent className="ion-padding" >
                                            <IonRadioGroup value={routeMode} onIonChange={(e)=>{setRouteMode(e.detail.value)}} >
                                            { routeModes.map((mode:any ,index:any)=>
                                                <IonRadio key={index} value={mode?.val} labelPlacement="end" >
                                                    <small className="ion-text-wrap" >{mode?.label}</small>
                                                </IonRadio>
                                            )  }
                                            </IonRadioGroup>  
                                        </IonContent>
                                    </IonPopover>
                                </IonButton>
                                <IonButton fill="clear" color={"danger"} onClick={()=>{removeRow()}}>
                                    <IonIcon icon={trash} /> &nbsp;
                                    <IonText>ลบ</IonText>
                                </IonButton>
                            </IonButtons>
                        </div>
                            <div className="set-center-row" style={{borderBottom:"1px solid #DDD" ,justifyContent:"flex-start",padding:".2rem"}}>
                            <IonRadioGroup value={routeType} onIonChange={(e)=>{changRouteType(e.detail.value)}} >
                                <IonRadio  value={1} labelPlacement="end" >  <small className="ion-text-wrap" > Road </small></IonRadio> &nbsp;&nbsp;
                                <IonRadio  value={8} labelPlacement="end" >  <small className="ion-text-wrap" > Ferry </small></IonRadio>&nbsp;&nbsp;
                                <IonRadio  value={16} labelPlacement="end" >  <small className="ion-text-wrap" > Tollway </small></IonRadio>
                            </IonRadioGroup>  &nbsp;&nbsp; | &nbsp;&nbsp;
                            <IonRadioGroup value={routeLocale} onIonChange={(e)=>{setRouteLocale(e.detail.value)}} > 
                                <IonRadio  value={"th"} labelPlacement="end" >  <small className="ion-text-wrap" > TH </small></IonRadio> &nbsp;&nbsp;
                                <IonRadio  value={"en"} labelPlacement="end" >  <small className="ion-text-wrap" > EN </small></IonRadio> 
                            </IonRadioGroup> &nbsp;&nbsp; | &nbsp;&nbsp;
                            <IonRadioGroup value={routeLabel} onIonChange={(e)=>{setRouteLabel(e.detail.value)}} > 
                                <IonRadio  value={""} labelPlacement="end" >  <small className="ion-text-wrap" > Time </small></IonRadio> &nbsp;&nbsp;
                                <IonRadio  value={"distance"} labelPlacement="end" >  <small className="ion-text-wrap" > Distance </small></IonRadio> 
                            </IonRadioGroup>
                            
                            
                            </div>
                        <div   >
                            <TableStationSelected
                                stations={stations}
                                updateStation={(st:StationTableUpdate)=>{ updateStation(st) }}
                                viewStation={(st:StationTableUpdate)=>{ viewStation(st);  showModal(true) }}
                                checkStation={(c:any)=>{ }}
                                updateDate={(val:DateTimeTableUpdate)=>{ updateTableDate(val) }}
                                updateTime={(val:DateTimeTableUpdate)=>{ updateTableTime(val) }}
                                timeStay={timeStay}
                                isCheckAll={isCheckAll}
                                setCheckAll={(checked:any)=>checkAll(checked)}
                            />
                            <div className="table-bottom-station" >
                                <IonLabel style={{marginRight:"1rem"}}> ระยะทางรวม : <span>{alldistance}</span> กิโลเมตร </IonLabel>
                                <IonLabel style={{marginRight:"1rem"}}> รวมเวลาขับขี่ :  <span>{allDriveDuraion}  </span> </IonLabel>
                                <IonLabel style={{marginRight:"1rem"}}> ระยะเวลา :  <span>{formatDate(allDuraion)}</span>  </IonLabel>
                                {loading && <IonSpinner name="crescent" />}
                            </div>
                        </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </TabPanel>

        <TabPanel> 
            <IonRow>
                <IonCol size="8" >
                <div  style={{ ...layoutCSS  , padding:'.5rem',  background: '#fff'  }}>
                    <LongdoMap id="longdo-map" mapKey={LONGDOKEY} callback={initMap} /> 
               </div>
                </IonCol>
                <IonCol>
                    <IonButton  mode="ios" size="small" onClick={()=>{clearRoute()}} ><IonIcon icon={chevronBack} /> กลับ </IonButton>
                    <IonButton fill="clear" size="small" onClick={()=>{lmap.Route.clear();lmap.Overlays.clear()}} ><IonIcon icon={trashBin} /></IonButton>
                    <IonButton fill="clear" size="small" 
                        onClick={()=>{
                        lmap.Route.clear();lmap.Route.enableRestrict(longdo.RouteRestrict.Road, true)
                        ;setTimeout(()=>{searchawait()},200)}} >
                            <IonIcon icon={syncOutline} />
                        </IonButton>  
                    <IonButton fill="clear" size="small" onClick={()=>{lmap.resize()}} ><IonIcon icon={map} /></IonButton>
                    <IonButton fill="clear"  mode="ios" size="small"  
                        onClick={()=>{changeRouteLabel(routeLabel === "distance"? "time" : routeLabel === "time"? "hide" :"distance")}} ><IonIcon icon={pricetagOutline} /> &nbsp; {routeLabel.toUpperCase()}  </IonButton>
                    <IonButton id="popover-button-type" fill="clear" size="small" onClick={()=>{lmap.resize()}} >  ประเภท   
                    <IonPopover trigger="popover-button-type" dismissOnSelect={true}>
                        <IonContent>
                            <IonList>
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.enableRestrict(longdo.RouteRestrict.Road, true);setTimeout(()=>{searchawait()},200)}}>  Road | <small>การขับขี่หรือการเดินถนน</small> </IonItem> 
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.enableRestrict(longdo.RouteRestrict.Air, true);setTimeout(()=>{searchawait()},200)}} > Air | <small>ระบบขนส่งสาธารณะ, สายการบิน</small> </IonItem>
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.enableRestrict(longdo.RouteRestrict.Railway, true);setTimeout(()=>{searchawait()},200)}} > Railway | <small>ระบบขนส่งสาธารณะ รถไฟระหว่างเมือง</small></IonItem>
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.enableRestrict(longdo.RouteRestrict.Ferry, true);setTimeout(()=>{searchawait()},200)}} > Ferry | <small>ขับรถหรือขนส่งสาธารณะ เรือข้ามฟาก</small></IonItem>
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.enableRestrict(longdo.RouteRestrict.Tollway, true);setTimeout(()=>{searchawait()},200)}} > Tollway | <small>การขับรถ, ทางด่วน</small></IonItem>
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.enableRestrict(longdo.RouteRestrict.Bus, true);setTimeout(()=>{searchawait()},200)}} > Bus | <small> รถโดยสารประจำทาง </small></IonItem>
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.enableRestrict(longdo.RouteRestrict.Metro, true);setTimeout(()=>{searchawait()},200)}} > Metro | <small> ระบบขนส่งสาธารณะ, ระบบขนส่งมวลชน </small></IonItem>
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.enableRestrict(longdo.RouteRestrict.All, true);setTimeout(()=>{searchawait()},200)}} > All | <small> ทั้งหมด </small></IonItem>
                            </IonList>
                        </IonContent>
                    </IonPopover>
                    </IonButton> 
                    <IonButton id="popover-button-mode" fill="clear" onClick={()=>{lmap.resize()}} > โหมด   
                    <IonPopover trigger="popover-button-mode" dismissOnSelect={true}>
                        <IonContent>
                            <IonList>
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.mode(longdo.RouteMode.Traffic);setRouteMode("t");setTimeout(()=>{searchawait()},200)}}>  
                                 <IonLabel className="ion-text-wrap" >
                                    <h3>Traffic</h3>
                                    <p>(หลบรถติด) ขับรถตามเส้นทางที่เร็วที่สุด หลีกเลี่ยงการจราจรติดขัดด้วยข้อมูลแบบเรียลไทม์และคาดการณ์</p>
                                 </IonLabel>
                                </IonItem> 
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.mode(longdo.RouteMode.Cost);setRouteMode("c");setTimeout(()=>{searchawait()},200)}} >   
                                  <IonLabel className="ion-text-wrap" > 
                                    <h3>Cost</h3>
                                    <p>(ถนนหลัก) ขับรถโดยเส้นทางที่เร็วที่สุด</p>
                                 </IonLabel>
                                </IonItem>
                                <IonItem button={true} detail={false} onClick={()=>{lmap.Route.clear();lmap.Route.mode(longdo.RouteMode.Distance);setRouteMode("d");setTimeout(()=>{searchawait()},200)}} > 
                                    <IonLabel className="ion-text-wrap" > 
                                     <h3> Distance</h3>  
                                     <p>(ทางลัด) ขับรถโดยเส้นทางที่เร็วที่สุด</p> 
                                    </IonLabel>
                                </IonItem>
                                <IonItem button={true} detail={false} onClick={()=>{
                                    lmap.Route.clear();lmap.Route.mode(longdo.RouteMode.Walk);
                                    setTimeout(()=>{searchawait()},200)
                                }} >
                                 <IonLabel className="ion-text-wrap" >  
                                    <h3>Walk</h3>  <p>(ขนส่งมวลชน) เดินตามเส้นทางที่สั้นที่สุดหรือใช้ระบบขนส่งสาธารณะที่เร็วที่สุด</p></IonLabel>
                                 </IonItem>
                                <IonItem button={true} detail={false} onClick={()=>{
                                    lmap.Route.clear();
                                    lmap.Route.mode(longdo.RouteMode.Both);
                                    ;setRouteMode("d")
                                    setTimeout(()=>{searchawait()},200)}} 
                                > 
                                    <IonLabel className="ion-text-wrap" > 
                                        <h3>Both</h3> <p>ขับรถตามเส้นทางที่เร็วที่สุด ไม่ต้องสนใจทิศทางในการขับรถ *เพื่อการคำนวณเท่านั้น อย่าทำเช่นนี้ในชีวิตจริง</p>
                                    </IonLabel>
                                </IonItem>
                                <IonItem button={true} detail={false} onClick={()=>{
                                    lmap.Route.clear();lmap.Route.mode(longdo.RouteMode.Fly);
                                    setTimeout(()=>{searchawait()},200)}} 
                                > 
                                    <IonLabel> 
                                       <h3>Fly</h3> <p>บินตรงสู่จุดหมาย </p> 
                                    </IonLabel>
                                </IonItem> 
                            </IonList>
                        </IonContent>
                    </IonPopover>
                    </IonButton>
                     
                
                <div id="result"></div> 
                </IonCol>
            </IonRow>
            
        </TabPanel>
        </Tabs>
        </IonContent>
        <StationsModal 
            show={modal} 
            loading={spinner}
            stations={stations}
            routeStation={routeStation} 
            setModal={(e:any)=>{showModal(e)}} 
            setRouteMode={(e:any)=>{setRouteMode(e);calMapStationDistance()}} 
            routeMode={routeMode} 
            alldistance={alldistance}
            allDriveDuraion={formatDate(allDriveDuraion)}
            allDuraion={formatDate(allDuraion)}
            longdokey={LONGDOKEY}
        />
    </IonPage>
    )
} 
export default TravelRouteAdd;
interface GeoJson{
    type: String 
    features: any[]
  }
  let layoutCSS = {
    height: "80vh",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
const StationModal=({show,loading,setModal,routeStation ,setRouteMode ,routeMode ,alldistance , allDriveDuraion ,allDuraion,stations,longdokey}:any)=>{
    const [longkey, setLongdokey] =  useState("c94f652b5cae8310d405976b9f86413c")  
    const [onTollWay,setOnTollWay] = useState(false)

    async function initMap(){
        console.log("longdokey ",longdokey)
        // const lkey = await getLongdokey({})
        // setLongdokey(lkey)
    
        try{ 
          lmap.Layers.setBase(longdo.Layers.GRAY); 
          lmap.location(longdo.LocationMode.Pointer);  
          lmap.resize()
          console.log("initMap lmap ", lmap)
        }catch(err){
          console.log("initMap err ",err)
        } 
    }
    function getCenterPolygon(arr:any) { 
        return arr.reduce(function (x:any,y:any) {
            return [x[0] + y[0]/arr.length, x[1] + y[1]/arr.length] 
        }, [0,0]) 
    }
    const routeresult = document.getElementById('result')

    const markerStations=async()=>{
        let bound:any = []
        let markers:any = []
       await stations.map(async (station:any, indexstation:any)=>{ 
                    
            console.log("indexstation ",station , indexstation)
            // let boundPointStart = await stations.map((p:any)=> { return [p.lon ,p.lat]}) 
            if(station.point.length > 0 ){ 
                bound.push([station.point[0].lon , station.point[0].lat ])
                const point = {lon:  station.point[0].lon , lat: station.point[0].lat }
                var marker = new longdo.Marker(point ,{
                    icon: {
                        html: `<img src="../../assets/icon-place/map-icon-0.png" style="width: 1.5rem" />`,
                        offset: { x: 0, y: 0 }
                    },   
                }); 
                var markerName = new longdo.Marker(point ,{
                    icon: {
                        html: `<div> ${station.name} </div>`,
                        offset: { x: 0, y: 15 }
                    },   
                }); 
                // new longdo.Tooltip(station.name, lmap)
              
                // markers.push(marker)
                // // lmap.Overlays.add(marker );   
                // // lmap.Overlays.add(markerName );   
                // lmap.Route.add(marker);
            //  lmap.Route.add(Marker);
            } 
            
        }) 
        return{ bound , markers}
    }
    const createRouteMart=async()=>{

        try{ 
            // if(routeresult){
                console.log("stations ",stations)
                // let markers:any = []
                // let bound:any = []
                console.log("routeStation ",routeStation.length)
                lmap.Event.bind("ready", async function () {
                    console.log("map ready ------------- ")
                    // lmap.Route.placeholder(document.getElementById('result'));
                
                    console.log("routeStation ",routeStation)
                   
                try{
                   let { markers ,bound}:any = await markerStations()
                    layoutCSS = {
                        height: "81vh",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }; 
                    console.log("---lmap.Route.search();  ",lmap.Route)
                    console.log("markers ",markers)
                    console.log("bound ",bound)
                    lmap.Route.enableContextMenu();
                    
                    
                    const color = polylineColor[1] 
                    lmap.Route.search();
                    // let routelineexported =  await lmap.Route.exportRouteLine({ 
                    //     lineWidth: 2,
                    //     lineColor: 'rgba(0, 0, 0, 1)',
                    //     fillColor: 'rgba(255, 0, 0, 0.4)',
                    //     visibleRange: { min: 7, max: 18 },})
                    // console.log("routelineexported ",routelineexported)
                    
                
                    // lmap.Route.line(longdo.RouteRestrict.Road , { lineColor: "red" , lineWidth: 5, }); 
                    lmap.Layers.resize()
                    // lmap.Route.line(longdo.RouteRestrict.Road , { lineColor: "red" , lineWidth: 5, });  lmap.resize()
                }catch(error){
                    console.log("route.search error ",error)
                }
            }) 
           
        // }
        }catch(err){
            console.log("err  ",err)
        } 
    }
    useEffect(()=>{ 
        // console.log("routeresult ",routeresult) 
        
      
        if(show && lmap && routeStation ){
            lmap.Route.mode(longdo.RouteMode.Cost)
            lmap.Route.enableRestrict(longdo.RouteRestrict.Road, true); 

            lmap.Route.enableRoute(longdo.RouteType.Tollway, false);
            lmap.Route.enableRoute(longdo.RouteType.Ferry, false);
            createRouteMart()

            lmap.Route.search();
            lmap.resize()
        }    
    },[show,routeStation,stations , ])

    const changeMode=async(mode:any)=>{
        // lmap.Route.clear(); 
        lmap.Route.mode(mode);
        await createRouteMart()
        lmap.Route.search()
        lmap.resize()
    }


    return(
    <IonModal className="station-modal" mode="ios" isOpen={show} onIonModalDidDismiss={(e)=>{setModal(false)}} >
        <IonContent className="ion-padding" >
            <IonGrid>
                <IonRow>
                    <IonCol size="12" className="ion-text-right" >
                        {loading && <IonSpinner name="crescent" />}
                        <IonButton mode="ios" color={"danger"}  size="small"  onClick={()=>{setModal(false)}}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonCol> 
                </IonRow> 
                <IonRow> 
                    <IonCol size="8" >  
                     
                      <div  style={{ ...layoutCSS  , padding:'.5rem',  background: '#fff'  }}>
                        <LongdoMap id="longdo-map" mapKey={longdokey} callback={initMap} />
                     
                    </div>
                    </IonCol>
                    <IonCol size="4" >
                    <IonRow>
                        <IonCol size="12" > 
                        <IonButton size="small"  onClick={()=>{ changeMode(longdo.RouteMode.Traffic)}} ><IonLabel>เส้นทางที่เร็วที่สุด หลบจราจร</IonLabel></IonButton>
                            <IonButton size="small"  onClick={()=>{changeMode(longdo.RouteMode.Cost); }} ><IonLabel>ไปตามถนนหลัก</IonLabel></IonButton>
                            <IonButton size="small"  onClick={()=>{changeMode(longdo.RouteMode.Distance)}} ><IonLabel>ค้นหาแบบไปทางลัด</IonLabel></IonButton>
                            &nbsp;|&nbsp;
                            <IonCheckbox value={onTollWay} labelPlacement="end" onIonChange={(e)=>{setOnTollWay(e.detail.checked);lmap.Route.search() ;lmap.Route.enableRoute(longdo.RouteType.Tollway, e.detail.checked)}}  > <IonLabel>ขึ้นทางด่วน</IonLabel> </IonCheckbox>
                            {/* <IonButton size="small"   onClick={()=>{lmap.Route.enableRoute(longdo.RouteType.Tollway, false)}} ><IonLabel>ไม่ขึ้นทางด่วน</IonLabel></IonButton>
                            <IonButton size="small"   onClick={()=>{lmap.Route.enableRoute(longdo.RouteType.Tollway, true)}} ><IonLabel>ขึ้นทางด่วน</IonLabel></IonButton> */}
                            &nbsp;|&nbsp;
                            <IonButton size="small"  onClick={()=>{lmap.Route.enableRestrict(longdo.RouteRestrict.Bike, true);lmap.Route.search()}}><IonLabel>เดินทางด้วยมอเตอร์ไซค์</IonLabel></IonButton>
                            <IonButton size="small"  ><IonLabel>แสดงเวลาบนเส้น</IonLabel></IonButton>
                            <IonButton size="small"  ><IonLabel>แสดงระยะทางบนเส้น</IonLabel></IonButton>
                        
 
                        </IonCol>
                     </IonRow>
                    <div id="result"></div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonModal>
    )
}
const StationsModal=({show,loading,setModal,routeStation ,setRouteMode ,routeMode ,alldistance , allDriveDuraion ,allDuraion}:any)=>{
    const [mapRef ,setMapRef] = useState<MapType|null>(null)
    const [stationGeoJson , setStationGeoJson] = useState<GeoJson|null >(null) 
    const [markerStation , setMarkerStation] = useState<any[]>([])
    
    useEffect(()=>{ 
        console.log("routeStation ",routeStation)
        if(routeStation){
            createStation()
        }

    },[loading])
    
    const createStation=async ()=>{
        let mygeojson:any = {type: "FeatureCollection", features: []}
        if(routeStation){
           await  routeStation.map((r:any,index:any)=>{
                let coordinate = r.polyline[r.polyline.length-1]
                // console.log("coordinate  ",coordinate)
                // console.log("createStation  ",r)
                    console.log("route station start  ", r.start?.name ," end  ",  r.end?.name)
                if(index === 0 && r.start){
                    let properties = {...r , ...{name:r.start?.name   } 
                    };      
                    let feature:any = {"type": "Feature", "geometry": {"type": "Point", "coordinates": coordinate}, "properties": properties}  
                    mygeojson.features.push(feature); 
                }
                if(r.end != undefined){ 
                    console.log("r.end? ",r.end)
                    let properties = {...r , ...{name:r.end?.name   } 
                    };      
                    let feature:any = {"type": "Feature", "geometry": {"type": "Point", "coordinates": coordinate}, "properties": properties}  
                    mygeojson.features.push(feature); 
                }
            })
           
        } 
        console.log("createStation ", mygeojson)
        setStationGeoJson( mygeojson )   
    }

    const boundmap=async(map:MapType)=>{
        setMapRef(map)
        if(routeStation){
            let poly :any = []
            await routeStation.map((r:any)=>{ 
                poly= [...poly, r.polyline]
            })
            let bound:any[] = []
            await poly.map(async(p:any)=> { 
            await p.map((coord:any)=> {  bound.push(coord)})
            })
            console.log("bound ",bound)
            
            setTimeout(()=>{   
                map.fitBounds(bound,{padding:[100,-100]})
            },500)
        }
    } 
    const createMarker=(feature:any, latlng:any)=>{
        console.log("createMarker feature ",latlng,feature  )
        let markergroup:any[] = [] //routeStation
        let markerzone =  L.marker( feature.geometry.coordinates ,  { icon :  defaultPin })
          .bindTooltip( feature?.properties?.name ,{ offset: [15,  30] ,direction:"bottom" , className: 'leaflet-tooltip-geozone' , permanent: true})
          mapRef?.on('zoom',(e)=>{  
              markerzone?.openTooltip() 
          }) 
          markergroup.push(markerzone) 
        setMarkerStation(markerStation)
        return markerzone
      }

      
    return(<>
     <IonModal className="station-modal" mode="ios" isOpen={show} onIonModalDidDismiss={(e)=>{setModal(false)}} >
        <IonContent className="ion-padding" >
            <IonGrid>
                <IonRow>
                    <IonCol size="12" className="ion-text-right" >
                        {loading && <IonSpinner name="crescent" />}
                        <IonButton mode="ios" color={"danger"}  size="small"  onClick={()=>{setModal(false)}}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonCol> 
                </IonRow> 
                <IonRow> 
                    <IonCol size="8" > 
                        <MapContainer 
                            id='map-route-add' 
                            zoom={16}
                            center={[14.340027, 100.586026]}
                            scrollWheelZoom={true}
                            zoomControl={false}
                            style={{borderRadius:"10px",height:'70%' }}
                            ref={async (comp)=>{  
                                comp?.invalidateSize()    
                                if(comp){
                                    setMapRef(comp) 
                                } 
                            }} 
                        >    
                            <UpdateMap setmap={(map:MapType)=>{boundmap(map) ; }} />  
                            {mapRef &&<LayersControl     position={'bottomright'} >  
                                { stationGeoJson &&
                                    <LayersControl.Overlay checked  name={ "สถานี"}  >
                                    <GeoJSON //@ts-ignore
                                        id={ 'route-geozone-position'} //@ts-ignore
                                        data={stationGeoJson} 
                                        //@ts-ignore
                                        pointToLayer={  (feature, latlng) => {
                                        return createMarker(feature, latlng)
                                        
                                        }} 
                                        />     
                                    </LayersControl.Overlay> 
                                }  
                              </LayersControl>}
                              { routeStation.map((r:any,i:any)=>    <PolylinRoute polyline={r.polyline} color={r.color} />  )}
                            {/* { routeStation.map((r:any,i:any)=>
                                <FeatureGroup key={i} > 
                               <Marker position={ r.polyline[r.polyline.length-1] } icon={new Icon({ 
                                        iconUrl: `../assets/icon-place/map-icon-0.png`,
                                        rotationOrigin: "center",
                                        iconSize:  [30, 30] ,
                                        iconAnchor:  [15, 30] , 
                                        tooltipAnchor: [0, 0],
                                        })} >
                                            <Tooltip className="route-road" permanent={true} direction="bottom"  offset={[0,30]}> 
                                                <div className="ion-padding " > <IonLabel color={"dark"} >{r.end.name}  </IonLabel></div>
                                            </Tooltip>
                                        </Marker> 
                                 <Marker position={ r.polyline[0] } icon={new Icon({ 
                                    iconUrl: `../assets/icon-place/map-icon-0.png`,
                                    rotationOrigin: "center",
                                    iconSize:  [30, 30] ,
                                    iconAnchor:  [15, 30] , 
                                    tooltipAnchor: [0, 0],
                                    })} >
                                        <Tooltip className="route-road" permanent={true} direction="bottom"   offset={[0,30]}> 
                                            <div className="ion-padding"> <IonLabel color={"dark"} >{r.start.name}</IonLabel> </div>
                                        </Tooltip>
                                    </Marker>
                               {r.polyline &&  <PolylinRoute polyline={r.polyline} color={r.color} />} 
                                </FeatureGroup>
                            )} */}
                            <LayersControlMap  />
                        </MapContainer>
                    </IonCol>
                    <IonCol >
                       <IonGrid>
                        <IonRow>
                            <IonCol size="12" >
                             <IonRadioGroup value={routeMode} onIonChange={(e)=>{setRouteMode(e.detail.value)}} >
                                { routeModes.map((mode:any,index:any)=>
                                    <IonRadio key={index} value={mode?.val} labelPlacement="end" >
                                        <small className="ion-text-wrap" >{mode?.label}</small>
                                    </IonRadio>
                                )  }
                             </IonRadioGroup> 
                             </IonCol>
                             <IonCol size="12" > 
                                <IonLabel className="ion-margin" ><strong> สรุปภาพรวม </strong></IonLabel><br/>
                                    <IonLabel style={{marginRight:"1rem", fontSize:".8em"}}> ระยะทางรวม : <span>{alldistance}</span> กิโลเมตร </IonLabel> /
                                    <IonLabel style={{marginRight:"1rem", fontSize:".8em"}}> รวมเวลาขับขี่ :  <span>{allDriveDuraion}  </span> </IonLabel> /
                                    <IonLabel style={{marginRight:"1rem", fontSize:".8em"}}> ระยะเวลา :  <span>{allDuraion}</span>  </IonLabel> 
                             
                            </IonCol>
                             <IonCol size="12" >
                                <IonLabel  className="ion-margin" > <strong>เส้นทาง</strong> </IonLabel>
                                <IonList style={{height:" 50vh", overflowY:"scroll"}} > 
                                {
                                    routeStation.map((r:any,index:any)=>
                                        <div key={index} style={{width:"100%" , border:"1px solid #DDD", borderRadius:".5rem",marginBottom:".5rem"}} >   
                                        <IonRow> 
                                            <IonCol size="12" >
                                            <p className="ion-no-margin"><u>{ r?.start?.name}</u> <IonIcon icon={arrowForwardOutline} mode="ios"  style={{color: r.color }}/>   <u>{r.end.name} </u></p>
                                            
                                            </IonCol>
                                            <IonCol size="5.5" >  
                                            <IonLabel style={{fontSize:".75em"}} >เวลาที่อยู่ในสถานี:  { formatDate(r.start.durationInStation)}    </IonLabel><br/>
                                            <IonLabel style={{fontSize:".75em"}} >เวลาเข้า: { moment( r.start.timeIn).format("DD/MM/YY HH:mm")} </IonLabel><br/>
                                            <IonLabel style={{fontSize:".75em"}} >เวลาออก: { moment( r.start.timeOut).format("DD/MM/YY HH:mm")} </IonLabel><br/>
                                            </IonCol>
                                            <IonCol size="1" className="set-center">  </IonCol>
                                            <IonCol size="5.5"  >   
                                                <IonLabel style={{fontSize:".75em"}} >เวลาที่อยู่ในสถานี:  { formatDate(r.end.durationInStation)}    </IonLabel><br/>
                                                <IonLabel style={{fontSize:".75em"}} >เวลาเข้า: {  moment( r.end.timeIn).format("DD/MM/YY HH:mm")} </IonLabel><br/>
                                                <IonLabel style={{fontSize:".75em"}} >เวลาออก: {  moment( r.end.timeOut).format("DD/MM/YY HH:mm")} </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        </div> 
                                    )
                                }
                                </IonList>
                             </IonCol>
                        </IonRow>
                       </IonGrid>
                    </IonCol>
                </IonRow>
            </IonGrid> 
        </IonContent>
     </IonModal>
    </>)
}
export const UpdateMap=({setmap}:any)=>{
    const map = useMap()
    useEffect(()=>{ 
        map?.invalidateSize() ;
        setmap(map)
     },[])
    return(<></>)
}
const PolylinRoute=({polyline,color}:any)=>{
    const map = useMap()

    useEffect(()=>{ 
        map?.invalidateSize()
        if(polyline){
            map.fitBounds(polyline)
        }
    },[])
    return(
        <Polyline positions={polyline} color={color} />
    )
}
 
interface DateTimeTableUpdate {
    datetime: Date  
    rowindex: Number  
    type: String
}

interface StationTableUpdate {
    start : any
    end:any
    indexrow: Number
}

const TableStationSelected=({stations,updateStation,updateDate,updateTime,viewStation ,timeStay , isCheckAll,setCheckAll}:any)=>{
    useEffect(()=>{  
        // console.log("stattion change ", stations)
    },[stations])
    
 
    return(
    <div className="table-station-selected" >
        <table style={{width:"100%" }}>
        <tbody> 
            <tr  className="addroute-searh-station">
                <CellsHeading width={'3%'}  > <IonCheckbox checked={isCheckAll}  onIonChange={(e)=>{setCheckAll(e.detail.checked)}} /> </CellsHeading> 
                <CellsHeading width={"5%"}  > <IonLabel>ลำดับ</IonLabel> </CellsHeading>
                <CellsHeading width={"12.5%"} > <IonLabel>ชื่อสถานี</IonLabel> </CellsHeading>
                <CellsHeading width={"11.5%"}  > <IonLabel>วันที่เข้า</IonLabel> </CellsHeading>
                <CellsHeading width={"11%"}  > <IonLabel>เวลาที่เข้า</IonLabel> </CellsHeading>
                <CellsHeading width={"11.5%"}  > <IonLabel>วันที่ออก</IonLabel> </CellsHeading>
                <CellsHeading width={"11%"}  > <IonLabel>เวลาที่ออก</IonLabel> </CellsHeading>
                <CellsHeading width={"11.5%"}  > <IonLabel>เวลาที่อยู่ใน</IonLabel> </CellsHeading>
                <CellsHeading width={"11.5%"}  > <IonLabel>เวลาเดินทางสถานีถัดไป</IonLabel> </CellsHeading>
                <CellsHeading width={"11.5%"}  > <IonLabel>ระยะห่างระหว่างสถานี</IonLabel></CellsHeading>
            </tr>  
            {
                            stations?.map((s:any,index:any)=> 
                            <tr key={index}  className="addroute-searh-station">
                                <SmCellsData width={"3%"}  force={"#FFF"} >    <IonCheckbox   checked={s.check}
                                    onIonChange={(e)=>{updateStation({update:{...s , check:e.target.checked } , index:index } ) }} /> 
                                </SmCellsData>
                                <SmCellsData width={"5%"}  force={"#FFF"}>    <IonLabel> {index+1} </IonLabel> </SmCellsData>
                                <SmCellsData width={"12.5%"} force={"#FFF"}>  <IonLabel> {s.name} </IonLabel> </SmCellsData>
                                <SmCellsData width={"11.5%"} force={"#FFF"}>  
                                    <CellDateTime 
                                        id={`stations-in-${s.name}-date-${index}`}
                                        label={s.dateIn } 
                                        format={"DD"}
                                        presentation={"date"}
                                        min={ s.mindate}
                                        changedatetime={(date:any)=>{
                                            // updateStation({update:{...s , ...{dateIn : new Date(date) }} , index:index})
                                            updateDate({datetime:new Date(date)  , rowindex:index ,type:"in" })
                                        }}  
                                    /> 
                                </SmCellsData>
                                <SmCellsData width={"11%"}  force={"#FFF"}>   
                                    {/* <IonLabel> {moment(s.timeIn).format("HH:mm")}</IonLabel>  */}
                                    <CellDateTime 
                                        id={`stations-in-${s.name}-time-${index}`}
                                        label={s.timeIn } 
                                        format={"HH:mm"}
                                        presentation={"time"}
                                        min={ s.mindate}
                                        changedatetime={(date:any)=>{
                                            // updateStation({update:{...s , ...{timeIn : new Date(date) }} , index:index})
                                            updateDate({datetime:new Date(date)  , rowindex:index ,type:"in"})
                                        }}  
                                    /> 
                                </SmCellsData>
                                <SmCellsData width={"11.5%"} force={"#FFF"}>  
                                    <CellDateTime 
                                        id={`stations-out-${s.name}-date-${index}`}
                                        label={s.dateOut } 
                                        format={"DD"}
                                        presentation={"date"}
                                        changedatetime={(date:any)=>{
                                            // updateStation({update:{...s , ...{dateIn : new Date(date) }} , index:index})
                                            updateDate({datetime:new Date(date)  , rowindex:index ,type:"out"})
                                        }}  
                                    /> 
                                    {/* <IonLabel> {moment(s.dateOut).format("DD")}</IonLabel>*/}
                                </SmCellsData> 
                                <SmCellsData width={"11%"}  force={"#FFF"}>   
                                    <CellDateTime 
                                        id={`stations-out-${s.name}-time-${index}`}
                                        label={s.timeOut } 
                                        format={"HH:mm"}
                                        presentation={"time"}
                                        changedatetime={(date:any)=>{
                                            // updateStation({update:{...s , ...{timeIn : new Date(date) }} , index:index})
                                            updateDate({datetime:new Date(date)  , rowindex:index,type:"out" })
                                        }}  
                                    />  
                                </SmCellsData>
                                <SmCellsData width={"11.5%"} force={"#FDFDF"}> <IonLabel> 
                                     {/* {msToTime(s.durationInStation) }  */}
                                     {timeStay(s.timeIn,s.timeOut)}
                                </IonLabel> </SmCellsData>
                                <SmCellsData width={"11.5%"} force={"#FFF"}>  <IonLabel>{s.timeleave}</IonLabel> </SmCellsData>
                                <SmCellsData width={"11.5%"} force={"#FFF"}>  
                                    {/* <IonLabel>
                                        { index > 0 ? s.distanceBetween: "-" } 
                                    </IonLabel> <br/> */}
                                   { index > 0 &&( s.distanceBetween === null || s.distanceBetween === 0  || s.distanceBetween === '') ? <IonButton fill="clear" onClick={()=>{viewStation({
                                        start :  stations[index-1]  ,
                                        end: s ,
                                        indexrow: index
                                    } )}} >
                                        <IonLabel> ดูระยะทาง </IonLabel>
                                    </IonButton> :   <IonLabel>
                                        { index > 0 ? s.distanceBetween: "-" } 
                                    </IonLabel> }
                                </SmCellsData>
                            </tr>  
                            )
                        } 
            </tbody>
        </table>
    </div>
    )
}
 
const CellsHeading=({width , children}:any)=>{ 
    return(
        <th className="cell-heading ion-text-center " 
        style={{width:width, padding:"1rem .5rem .5rem .5rem ",cursor:"pointer",border:"1px solid #DDD" }}> 
            <IonLabel>{children}</IonLabel>
        </th>
    )
}
const CellsData=({width , children , force}:any)=>{ 
    return(
        <th  className="cells-data ion-text-center ion-activatable ripple-parent"  
        style={{width:width, padding:"1rem .5rem .5rem .5rem " ,background : force ,cursor:"pointer",border:"1px solid #DDD" }}> 
            <IonLabel>{children}</IonLabel>
            <IonRippleEffect></IonRippleEffect>
        </th>
    )
}
const SmCellsData=({width , children , force}:any)=>{ 
    return(
        <th  className="cells-data ion-text-center ion-activatable ripple-parent"  
        style={{width:width, padding:".5rem .3rem .3rem .3rem "  ,cursor:"pointer",border:"1px solid #DDD" }}> 
            <IonLabel>{children}</IonLabel>
            <IonRippleEffect></IonRippleEffect>
        </th>
    )
}

const CellDateTime=({id , label ,changedatetime , presentation , format , min}:any)=>{
    const [open,setIsOpen] = useState(false)

    return(<div  style={{width:"100%" , height:"100%"}} >
        <IonButton 
            id={id} size="small"
            color={"dark"} fill="clear"  
            onClick={()=>{setIsOpen(true)}} 
        >
         <IonLabel color={"dark"}  style={{display:"flex" , flexDirection:"row",justifyContent:"center" , alignItems: 'center', }} >
            <IonText color={"dark"} >{moment(label).format(format)}</IonText> &nbsp;
            <IonIcon icon={chevronDown} style={{fontSize:".7em"}}  />
         </IonLabel><br/>
            <IonPopover trigger={id} mode="ios" isOpen={open} showBackdrop={false} side="bottom" alignment="center" onIonPopoverDidDismiss={()=>{setIsOpen(false)}}  >
                <IonDatetime 
                    value={moment(label).format('YYYY-MM-DDTHH:mm:ss')}   
                    defaultValue={ moment(min).format()} 
                    min={ moment(min).startOf('month').format()} 
                    max={moment(label).endOf('year').format()} 
                    onIonChange={(e)=>{  changedatetime(e.detail.value) ;  }} 
                    presentation={presentation} preferWheel={true}> 
                </IonDatetime>
            </IonPopover>
        </IonButton>  
    </div>)
}


const LongdoRouteAdd=({stations}:any)=>{ 
    const [sizes, setSizes] = useState([400,   'auto']);
    // const [result,setResult] = useState([]) 
    const [longdokey, setLongdokey] =  useState("")  
  
    async function initMap(){
      const lkey = await getLongdokey({})
      setLongdokey(lkey)
  
      try{ 
        lmap.Layers.setBase(longdo.Layers.GRAY); 
        lmap.location(longdo.LocationMode.Pointer); 
        // markercluster = new lmc.MarkerCluster(map, {  minClusterSize: 1,  });
 
      }catch(err){
        console.log("initMap err ",err)
      } 
    }   
    useEffect(()=>{
        console.log("stations   ",stations)
        const createRouteMart=async()=>{
                await stations.map(async (station:any)=>{
                     let boundPointStart = await station?.point.map((p:any)=> { return [p.lon ,p.lat]}) 
                      console.log("boundPointStart ",boundPointStart)
            // lmap.bound(res?.bound); 
                    //  let startp = await getCenterPolygon(boundPointStart) 
                    //  lmap.Route.placeholder(document.getElementById('route-longdo-map'));
                    //  var Marker = new longdo.Marker(
                    //      { lon: startp[1], lat: startp[0] },
                    //      { title: station?.name , detail:  `อยู่ในสถานี ${station?.durationInStation} นาที` }
                    //    ); // Create Marker Overlay
                       
                    //  lmap.Route.add(Marker);
     
                 })
                 // lmap.Route.search(); 
             
        }
        if(stations ){createRouteMart()}
    },[])

    return(
     <div  style={{ ...layoutCSS  , padding:'.5rem',  background: '#fff'  }}>
        <LongdoMap id="route-longdo-map" mapKey={longdokey} callback={initMap} />
      </div>
    )
}